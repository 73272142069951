export default {
    // Team Task
    admin_input_search: 'Search',
    admin_input_project_info: 'Please enter task ID, name',
    admin_input_text: 'Search content',
    admin_input_user_info: 'Please enter user ID, nickname',
    admin_enter_group_info: 'Please enter group ID and name',
    admin_enter_user_info: 'Enter user ID and name',
    admin_input_user_info_email: 'Please enter user ID, nickname, email',

    admin_cancel: 'Cancel',
    admin_confirm: 'Confirm',
    admin_check: 'Check',
    admin_delete: 'Delete',
    admin_save: 'Save',
    admin_edit: 'Edit',
    admin_manage: 'Manage',
    admin_remove: 'Remove',
    admin_add: 'Add',
    admin_crew: 'Team Member',

    admin_task: 'Task',
    admin_item_id: 'Item ID',
    admin_batch_id: 'Batch ID',
    admin_process_id: 'Process ID',
    admin_task_id: 'Task ID',
    admin_task_name: 'Task name',
    admin_task_type: 'Task type',
    admin_process: 'Process',
    admin_qc: 'Quality inspection',
    admin_audit: 'Audit',
    admin_execute: 'Execute',
    admin_working_out: 'Workload',
    admin_execute_description: 'Number of executed sheets/number of executable sheets/total number of batch jobs',
    admin_placeholder_description: '-- Table not initialized, click to execute',
    admin_fields_description: 'Field description',
    admin_schedule: 'Progress',
    admin_performance: 'Performance',
    admin_performance_execute_description: 'Execute: number of auditing passes/number of executed sheets',
    admin_performance_audit_description: 'Audit: number of quality inspection passes/number of audited sheets',
    admin_performance_qc_description: 'Quality inspection: number of quality inspection passes/number of inspected sheets',
    admin_handle: 'Operation',
    admin_reset: 'Reset',
    admin_reject: 'Reject',
    admin_setting_members: 'Set operator (n people have been assigned) : this task has n operators, click to set',
    admin_operate_reject_description: 'Rejected job (number of jobs n) : there are n rejected jobs in this task, please click to reset',
    admin_operate_difficult_description: 'Pending job (number of jobs n) : there are n pending jobs in this task, please click to reset',
    admin_operate_rework_description: 'Revised job (number of jobs n) : there are n rejected & revised jobs in this task, please click to reset',
    admin_start_time: 'Start time',
    admin_end_time: 'End time',
    admin_schedule_execute_description: 'Execute: number of executed sheets/number of executable sheets/total number of batch jobs',
    admin_schedule_audit_description: 'Audit: number of audited sheets/number of executable sheets/total number of batch jobs',
    admin_schedule_qc_description: 'Quality inspected: number of inspected sheets/number of executable sheets/total number of batch jobs',

    // mission details
    admin_settings: 'Set people',
    admin_export_record: 'Export job record',
    admin_export_performance: 'Export performance',
    admin_list_performance: 'Performance list',
    admin_chart_performance: 'Performance chart',
    admin_list_job: 'Job list',
    admin_task_description: 'Task description',
    admin_no_description: 'No description yet',
    admin_total_time: 'Accumulative work time: {num} seconds',
    admin_total_sheet: 'Total number of job sheets',
    admin_total_box: 'Total number of job boxes',
    admin_total_mark_number: 'Total number of job annotations',
    admin_total_count: 'Total number of job points',
    admin_total_approved: 'Total approvals',
    admin_total_rejected: 'Total rejections',
    admin_total_reset: 'Total resets',
    admin_total_time_vocie: 'Total working time(voice)',

    admin_user: 'User',
    admin_cumulative: "Accumulative work time",
    admin_time_unit: 'Unit: seconds (s)',
    admin_create_time: 'Add time',
    admin_update_time: 'Update time',
    admin_amount: 'Number of sheets',
    admin_box: 'Number of boxes',
    admin_points: 'Number of points',
    admin_mark_number: 'Number of annotations',
    admin_working_hours_voice: 'Work time(voice)',

    // execution
    admin_assignments: 'Number of jobs',
    admin_execute_amount: 'Number of job sheets executed',
    admin_picture_description: 'For image annotation: number of graphs, such as number of boxes, number of points, number of regions',
    admin_text_description: 'For text annotation: number of key entries',
    admin_voice_description: 'For voice annotation: number of valid voice fragments',
    admin_execute_points: 'Points used to execute jobs',
    admin_operation_time: 'Work time',
    admin_speech: 'Voice only',

    admin_audited: 'Audited',
    admin_daily_performance: 'Daily performance',
    admin_date: 'Date',
    admin_working_hours: 'Work time',
    admin_total_working_hours: 'Total working time',
    admin_jobs: 'Job',

    // audit
    admin_execute_audit_amount: 'Number of audited sheets of executed job',
    admin_mark_number_amount: 'Number of audited annotations of executed job',
    admin_counting: "Number of approvals",
    admin_execute_passed: 'Number of approved sheets',
    admin_rejecting: 'Number of rejections',
    admin_execute_rejected: 'Number of rejected sheets of executed job',
    admin_reseting: 'Number of resets',
    admin_execute_reseted: 'Number of reset sheets of executed job',
    admin_qced: 'Inspected',
    admin_qc_passed_amount: 'Number of approved sheets in quality inspection',
    admin_qc_reject_amount: 'Number of rejected sheets in quality inspection',
    admin_reseted: 'Reset',
    admin_reseted_description: 'Number of reset sheets in quality inspection',

    // qc
    admin_qc_amount: 'Number of inspected sheets in quality inspection',
    admin_qc_label: 'Number of inspected annotations in quality inspection',
    admin_qc_pass_frame: 'Number of approved sheets in quality inspection',
    admin_qc_reject_frame: 'Number of rejected sheets in quality inspection',
    admin_qc_reset_frame: 'Number of reset sheets in quality inspection',

    // job list
    admin_all_job: 'All jobs',
    admin_unclaimed: 'Unclaimed',
    admin_active: 'Work in progress',
    admin_submitted: 'Submitted',
    admin_passed: 'Approved',
    admin_rejected: 'Rejected',
    admin_rejected_work: 'Reject ',
    admin_difficult_work: 'Pending',
    admin_expired: 'Expired',
    admin_rework_work: 'Do-over',

    admin_job_id: 'ID',
    admin_job_name: 'Name',
    admin_status: 'Status',
    admin_view_data: 'View',
    admin_operator: 'Operator',
    admin_time_remaining: 'Remaining time',
    admin_batch_reset: 'Batch reset',
    admin_batch_rejected: 'Batch reject',
    admin_operating_points: 'Number of job points',
    admin_submission_time: 'Submit time',
    admin_button_explain: 'Button description',
    admin_explain_reset: 'Reset: Remove all results of this job and set its status to unclaimed. This can be executed by all operators',
    admin_explain_reject_submitted: 'Reject: cancel the status of submitted, so this operator can re-execute it',
    admin_explain_reject_passed: 'Reject: cancel the status of approved, so this operator can re-execute it',
    admin_job_status: 'Job status',
    admin_passed_time: 'Approve time',
    admin_audited_time: 'Audit time',
    admin_failure_reason: 'Expiration reason',
    admin_rejected_reason: 'Rejection reason',
    admin_job_results: 'Results',
    admin_record: 'Record',
    admin_type: 'Type',
    admin_operation_status: 'Operation status',
    admin_operation_type: 'Operation type',
    admin_receive_time: 'Claim time',
    admin_creation_time: 'Create time',
    admin_operate_time: 'Operation time',
    // set up operators
    admin_nickname: 'Nickname',
    admin_type:'Type',
    admin_role: 'Role',
    admin_email: 'Email',
    admin_mobile: 'Phone number',
    admin_group: 'Group',
    admin_user_label: 'User tag',
    admin_allocated_number: 'Number of operators assigned',
    admin_recommend: 'Recommend',
    admin_updated: 'Updated',
    admin_created_time: 'Add date',
    admin_add_tag: 'New tag',
    admin_tag: 'Tag',
    admin_enter_tag_name: 'Please enter tag name',
    admin_custom_tag: 'Press Enter for customized tag',
    admin_no_data: 'No data',
    // team members
    admin_new_user: 'New user',
    admin_name: 'Name',
    admin_password: 'Password',
    admin_phone: 'Cellphone',
    admin_team_operator: 'Team operator',
    admin_team_administrator: 'Team administrator',
    admin_enter_user_name: 'Please enter user name',
    admin_enter_password: 'Please enter password',
    admin_enter_email: 'Please enter email',
    admin_enter_phone: 'Please enter contact number',
    admin_unselected: 'Not selected',
    admin_non_panelist: 'Non-group member',
    admin_mission: 'Participate in task',
    admin_contact_number: 'Contact number',
    admin_edit_employee: 'Edit user information',

    admin_batch_import_user: 'Bulk import user',
    admin_upload_excel: 'Please upload Excel table',
    admin_select_drag: 'Click select file or drag file here',
    admin_support_extension: 'Supported file types:.csv. Xls. XLSX',
    admin_click_download: 'Click to download Excel template',
    admin_next_step: 'Next step',
    admin_import_member: 'Import member list',
    admin_select_excel: 'Re-select Excel file',
    admin_import: 'Import',

    admin_setting_team: 'Team Settings',
    admin_team_name: 'Team name',
    admin_enter_team_name: 'Please enter team name!',
    admin_setting_logo: 'Set LOGO',
    admin_address: 'Address',
    admin_domain: 'Domain name',
    admin_payment: 'Pay',
    admin_open: 'On',
    admin_close: 'Off',

    admin_team_management: 'Group management',
    admin_add_group: 'Add group',
    admin_group_name: 'Group name',
    admin_number_people: 'Number of people',
    admin_edit_group_info: 'Edit group information',
    admin_mass_transfer: 'Batch transfer',
    admin_input_find_user: 'Search user',
    admin_add_member: 'Add member',
    admin_add_email: 'Add email',
    admin_send_email: 'Send invite email',
    admin_enter_email_tip: 'Please enter one email address in each row',
    admin_invitation_err_list: 'Invitation failure list',
    admin_invitation_err_email: 'Incorrect input format or incorrect email format',
    admin_email_duplicated: 'The email address already exists',
    admin_invate_success: 'Invite successfully',
    admin_select_role_tip: 'Please select role',
    admin_operation_tips: 'Operation tips',
    admin_delete_current_member: 'Are you sure you want to delete current member?',
    admin_team_info: 'New group information',
    admin_sure_deleting_group: 'Are you sure you want to delete this group?',
    admin_think_again: 'Think again',
    admin_remove_current_member: 'Do you really want to remove the current member?',
    admin_user_label_management: 'User tag management',
    admin_sure_delete_current_tab: 'Do you really want to delete current user tag ?',
    admin_added_successfully: 'Successfully added',
    admin_select_group: 'Select group',
    admin_deter_transfer: 'Confirm to transfer',
    admin_sure_delete_checked_members: 'Do you really want to remove selected members?',
    admin_valide_password: 'Please enter a combination of 6-16 digits and letters',
    admin_valide_phone: 'Please enter contact number with correct format',
    admin_valide_telephone: 'Please enter phone number with correct format',
    admin_valide_domain: 'Incorrect domain name format',
    admin_valide_name: 'Name cannot be empty',
    admin_valide_email: 'Email cannot be empty',
    admin_valid_status: '请选择状态',
    admin_email_format_error: 'Email format is not correct',
    admin_password_empty: 'Password cannot be empty',
    admin_phone_empty: 'Phone number cannot be empty',
    admin_choose_one_role: 'Select at least one role',
    admin_import_failure_reason: 'Reason of import failure',
    admin_upload_excel_file: 'Please upload Excel file',
    admin_import_result: 'Import result',
    admin_import_data_description: 'You have successfully imported {num1} sets of data and failed to import {num2} sets of data',
    admin_upload_file_restrictions: 'Only one file can be uploaded once',
    admin_incorrect_format: 'Incorrect format',
    admin_upload_file_format_incorrect: 'Incorrect upload file format',
    admin_file_format: 'Please upload file in following formats: .jpg, .png, .jpeg',
    admin_new_tag_selected_user: 'Add new tags to selected users',
    admin_rwtdtcm: 'Do you really want to remove the current member?',
    admin_delete_current_members: 'Do you really want to delete current member?',
    admin_cannot_delete_current_account: 'Current account cannot be deleted',
    admin_saved: 'Saved successfully',
    admin_enter_group_name: 'Please enter group name',
    admin_group_name_format: 'Group name should have 0-25 characters',
    admin_enter_label_name: 'Please enter label name...',
    admin_label_name: 'Label name',
    admin_label_name_format: 'Label name should have 0-25 characters',
    admin_loading: 'Job text loading...',
    admin_unchecked: 'No job has been selected',
    admin_success: 'Successful operation',
    admin_resource_failed: 'Failed to obtain job resource',
    admin_performance_ranking: 'Performance ranking',
    admin_batch_delete_member: 'Batch delete member',
    admin_batch_transfer_member: 'Batch transfer member',
    admin_batch_set_member: 'Batch assign',
    admin_set_user_sel_all: 'Select all team members',
    admin_set_user_cancel_all: 'Un-select all team members',
    admin_upload_filesize_limit: 'Image upload size limit',
    admin_upload_filesize_limit_con: 'The file size should not exceed 2M'
};
