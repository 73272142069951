
import Util from '@/utils/tools.js';
import Cookies from 'js-cookie';
import {i18n} from '../../locale';
// 取到需要权限判断的路由表
import {
    routes
} from '@/router';

// console.log(routes,'routesroutesroutes')
const app = {
    state: {
        cachePage: [],
        lang: '',
        isFullScreen: false,
        openedSubmenuArr: [], // 要展开的菜单数组
        menuTheme: 'dark', // 主题
        themeColor: '',
        pageOpenedList: [{
            title: i18n.t('router_home'),
            path: '',
            name: 'Home'
        }],
        currentPageName: '',
        currentPath: [
            {
                title: i18n.t('router_home'),
                path: '',
                name: 'Home'
            }
        ], // 面包屑数组
        menuList: [],
        userPermissions: [],
        // routers: [
        //     otherRouter,
        //     ...appRouter
        // ],
        // tagsList: [...otherRouter.children],
        messageCount: 0,
        dontCache: ['text-editor', 'artical-publish', 'perform-task'], // 在这里定义你不想要缓存的页面的name属性值(参见路由配置router.js)
        userInfoRequest: {},
        noticeID: '',
        hasLogin: true,
        prevPageUrl: null,
        setUserLimit: 10,
        setUserPage: 1,
        setUserKw: '',
        settings: {
            open_audit_edit: '1'
        },
        requestUserStat: false,
        getBase64Process: 0,
        shrink: Cookies.get('menu-shrink') === 'true',
        selectedUserList: [],
        loginStatus:false,
        // menuListAll:[
        //     {
        //         name:i18n.t('menu_home'),
        //         route:'home',
        //         path:'/home',
        //         icon:'',
        //         color:'#2db7f5',
        //     },
        //     {
        //         name:i18n.t('menu_newboss'),
        //         route:'newboss',
        //         path:'/newboss',
        //         color:'#2b85e4',
        //     },
        //     {
        //         name:i18n.t('menu_trainingcamp'),
        //         route:'trainingcamp/train',
        //         path:'/trainingcamp/train',
        //         color:'#19be6b',
        //         module_route:'module_student'
        //     },
        //     {
        //         name:i18n.t('menu_trainingcamp_myexam'),
        //         route:'trainingcamp/myexam',
        //         path:'/trainingcamp/myexam',
        //         color:'#19be6b',
        //         module_route:'module_student'
        //     },
        //     {
        //         name:i18n.t('menu_match'),
        //         route:'match',
        //         path:'/match',
        //         color:'#19be6b',
        //         module_route:'module_student'
        //     },
        //     {
        //         name:i18n.t('menu_evaluation'),
        //         route:'evaluation',
        //         path:'/evaluation',
        //         color:'#19be6b',
        //         module_route:'module_student'
        //     },
        //     {
        //         name:i18n.t('menu_student'),
        //         route:'student',
        //         color:'#ff9900',
        //         module_route:'module_student',
        //         list:[
        //             {
        //                 name:i18n.t('menu_trainingcamp_mytrain'),
        //                 route:'trainingcamp/mytrain',
        //                 path:'/trainingcamp/mytrain',
        //                 icon:'',
        //                 module_route:'module_student'
        //             },
        //             {
        //                 name:i18n.t('menu_trainingcamp_myteststat'),
        //                 route:'trainingcamp/myteststat',
        //                 path:'/trainingcamp/myteststat',
        //                 icon:'',
        //                 module_route:'module_student'
        //             },
        //             {
        //                 name:i18n.t('menu_trainingcamp_myhomework'),
        //                 route:'trainingcamp/myhomework',
        //                 path:'/trainingcamp/myhomework',
        //                 color:'#ff9900',
        //                 module_route:'module_student'
        //             },
        //             {
        //                 name:i18n.t('menu_trainingcamp_mywrong'),
        //                 route:'trainingcamp/mywrong',
        //                 path:'/trainingcamp/mywrong',
        //                 icon:'',
        //                 module_route:'module_student'
        //             },
        //             {
        //                 name:i18n.t('menu_trainingcamp_mycollect'),
        //                 route:'trainingcamp/mycollect',
        //                 path:'/trainingcamp/mycollect',
        //                 icon:'',
        //                 module_route:'module_student'
        //             },
        //             {
        //                 name:i18n.t('menu_cdkey_mycdkey'),
        //                 route:'cdkey/mycdkey',
        //                 path:'/cdkey/mycdkey',
        //                 color:'#2b85e4',
        //                 module_route:'module_student'
        //             },
        //             {
        //                 name:i18n.t('menu_trainingcamp_mycorrection'),
        //                 route:'trainingcamp/mycorrection',
        //                 path:'/trainingcamp/mycorrection',
        //                 color:'#2b85e4',
        //                 module_route:'module_student'
        //             },
        //         ]
        //     },
        //     {
        //         name:i18n.t('menu_teacher'),
        //         route:'teacher',
        //         color:'#ff9900',
        //         module_route:'module_teacher',
        //         list:[
        //             {
        //                 name:i18n.t('menu_trainingcamp_examlist'),
        //                 route:'trainingcamp/examlist',
        //                 path:'/trainingcamp/examlist',
        //                 color:'#ff9900',
        //                 module_route:'module_teacher'
        //             },
        //             {
        //                 name:i18n.t('menu_trainingcamp_homeworklist'),
        //                 route:'trainingcamp/homeworklist',
        //                 path:'/trainingcamp/homeworklist',
        //                 color:'#ff9900',
        //                 module_route:'module_teacher'
        //             },
        //             {
        //                 name:i18n.t('menu_trainingcamp_matchlist'),
        //                 route:'trainingcamp/matchlist',
        //                 path:'/trainingcamp/matchlist',
        //                 color:'#ff9900',
        //                 module_route:'module_teacher'
        //             },
        //             {
        //                 name:i18n.t('menu_trainingcamp_teststat'),
        //                 route:'trainingcamp/teststat',
        //                 path:'/trainingcamp/teststat',
        //                 color:'#2b85e4',
        //                 module_route:'module_teacher'
        //             },
        //             {
        //                 name:i18n.t('menu_trainingcamp_mygroup'),
        //                 route:'trainingcamp/mygroup',
        //                 path:'/trainingcamp/mygroup',
        //                 icon:'',
        //                 module_route:'module_teacher'
        //             },
        //             {
        //                 name:i18n.t('menu_user_mygroups'),
        //                 route:'user/mygroups',
        //                 path:'/user/mygroups',
        //                 icon:'',
        //                 module_route:'module_teacher'
        //             },
        //         ]
        //     },
        //     {
        //         name:i18n.t('menu_manager'),
        //         route:'manager',
        //         color:'#2b85e4',
        //         list:[
        //             {
        //                 name:i18n.t('menu_user'),
        //                 route:'user/mylist',
        //                 path:'/user/mylist',
        //                 color:'#2b85e4',
        //             },
        //             {
        //                 name:i18n.t('menu_mysite'),
        //                 route:'mysite',
        //                 path:'/mysite',
        //                 color:'#67C23A',
        //             },
        //             {
        //                 name:i18n.t('menu_cdkey_code'),
        //                 route:'cdkey/list',
        //                 path:'/cdkey/list',
        //                 color:'#2b85e4',
        //             },
        //             {
        //                 name:i18n.t('menu_trainingcamp_stat'),
        //                 route:'trainingcamp/stat',
        //                 path:'/trainingcamp/stat',
        //                 color:'#2b85e4',
        //             },
        //             {
        //                 name:i18n.t('menu_trainingcamp_dataverify'),
        //                 route:'trainingcamp/dataverify',
        //                 path:'/trainingcamp/dataverify',
        //                 icon:''
        //             },
        //         ]
        //     },
        //     {
        //         name:i18n.t('menu_super'),
        //         route:'super',
        //         color:'#2b85e4',
        //         list:[
        //             {
        //                 name:i18n.t('menu_user'),
        //                 route:'user/list',
        //                 path:'/user/list',
        //                 color:'#2b85e4',
        //             },
        //             {
        //                 name:i18n.t('menu_site'),
        //                 route:'site',
        //                 path:'/site',
        //                 color:'#2db7f5',
        //             },
        //             {
        //                 name:i18n.t('menu_cdkeys'),
        //                 route:'cdkey/managelist',
        //                 path:'/cdkey/managelist',
        //                 color:'#2b85e4',
        //             },
        //             {
        //                 name:i18n.t('menu_wxmini_managelist'),
        //                 route:'wxmini/managelist',
        //                 path:'/wxmini/managelist',
        //                 color:'#2b85e4',
        //             },
        //             {
        //                 name:i18n.t('menu_certcode_list'),
        //                 route:'certcode/list',
        //                 path:'/certcode/list',
        //                 color:'#2b85e4',
        //             },
        //             {
        //                 name:i18n.t('menu_certcode_dispense_list'),
        //                 route:'certcode/dispense-list',
        //                 path:'/certcode/dispense-list',
        //                 color:'#2b85e4',
        //             }
        //         ]
        //     },
        //     {
        //         name:i18n.t('menu_dataset'),
        //         route:'dataset',
        //         color:'#ff9900',
        //         list:[
        //             {
        //                 name:i18n.t('menu_dataset_category'),
        //                 route:'dataset/category',
        //                 path:'/dataset/category',
        //                 icon:''
        //             },
        //             {
        //                 name:i18n.t('menu_dataset_map'),
        //                 route:'dataset/map',
        //                 path:'/dataset/map',
        //                 icon:''
        //             },
        //             {
        //                 name:i18n.t('menu_dataset_node'),
        //                 route:'dataset/node',
        //                 path:'/dataset/node',
        //                 icon:''
        //             },
        //             {
        //                 name:i18n.t('menu_dataset_label'),
        //                 route:'dataset/label',
        //                 path:'/dataset/label',
        //                 icon:''
        //             },
        //             {
        //                 name:i18n.t('menu_dataset_data'),
        //                 route:'dataset/data',
        //                 path:'/dataset/data',
        //                 icon:''
        //             },
        //             {
        //                 name:i18n.t('menu_dataset_evaluation'),
        //                 route:'dataset/evaluation',
        //                 path:'/dataset/evaluation',
        //                 color:'#2b85e4',
        //             },
        //         ]
        //     },
        //     {
        //         name:i18n.t('menu_user_teacher'),
        //         route:'user/teacher',
        //         color:'#2b85e4',
        //         list:[
        //             {
        //                 name:i18n.t('menu_user_teacher_list'),
        //                 route:'user/teacher-list',
        //                 path:'/user/teacher-list',
        //                 icon:''
        //             },
        //             {
        //                 name:i18n.t('menu_user_teacher_notice'),
        //                 route:'user/teacher-notice',
        //                 path:'/user/teacher-notice',
        //                 icon:''
        //             },
        //         ]
        //     },
        //     {
        //         name:i18n.t('menu_production'),
        //         route:'production',
        //         icon:'ios-people',
        //         color:'#2db7f5',
        //         list:[
        //             {
        //                 name:i18n.t('menu_production_project'),
        //                 route:'production/project',
        //                 path:'/production/project',
        //                 icon:''
        //             },
        //             {
        //                 name:i18n.t('menu_production_tast'),
        //                 route:'production/task',
        //                 path:'/production/task',
        //                 icon:''
        //             },
        //             {
        //                 name:i18n.t('menu_dataset_chatgpt'),
        //                 route:'system/chatgpt',
        //                 path:'/system/chatgpt',
        //                 icon:''
        //             },
        //         ]
        //     },
        //     {
        //         name:i18n.t('menu_wxmini'),
        //         route:'wxmini',
        //         path:'/wxmini',
        //         color:'#19be6b',
        //     },
        //     {
        //         name:i18n.t('menu_myinfo'),
        //         route:'myinfo',
        //         path:'/myinfo',
        //         color:'#67C23A',
        //     },
        //     {
        //         name:i18n.t('menu_system'),
        //         route:'system',
        //         path:'/system',
        //         color:'#F56C6C',
        //     },
        // ],
        moduleList:[
            // {
            //     title:'刷题练习',
            //     route:'module_student',
            // },
            // {
            //     title:'组卷班级',
            //     route:'module_teacher',
            // },
            // {
            //     title:'组织管理',
            //     route:'module_manager',
            // },
            // {
            //     title:'内容生产',
            //     route:'module_content',
            // },
            // {
            //     title:'平台管理',
            //     route:'module_platform',
            // },
        ],
        userModuleList:[],
        curModule:'',
        breadCrumbList:JSON.parse(localStorage.getItem('breadCrumbList') || '[]'),  //面包屑列表
        curPath:'',
        chromTip:false,
        userSites:[],
    },
    mutations: {
        setUserSites(state, data){
            state.userSites = data;
        },
        changeChromTip(state, data) {
            state.chromTip = data;
        },
        changeSelectedUserList (state, data) {
            state.selectedUserList = data;
        },
        changeGetBase64Process (state, data) {
            state.getBase64Process = data;
        },
        changeUserStat (state, data) {
            state.requestUserStat = data;
        },
        changeSetUserLimit (state, data) {
            state.setUserLimit = data;
        },
        changeSetUserPage (state, data) {
            state.setUserPage = data;
        },
        changeSetUserKw (state, data) {
            state.setUserKw = data;
        },
        changePrevUrl (state, data) {
            state.prevPageUrl = data;
        },
        setLoginStatus (state, data) {
            state.hasLogin = data;
        },
        setNoticeID (state, data) {
            state.noticeID = data;
        },
        requestUserInfo (state, userInfoRequest) {
            state.userInfoRequest = userInfoRequest;
        },
        setPermissions (state, data) {
            state.userPermissions = data;
        },
        setTagsList (state, list) {
            state.tagsList.push(...list);
        },
        updateModuleMenu(state,data){
            let userModuleList = [];
            let keys = Object.keys(data.menu)
            let allModuleMenu = routes.filter((item)=>{
                return item.children
            })[0].children.filter((item)=>{
                return item.children;
            });

            allModuleMenu.forEach((item)=>{
                let len = keys.filter((sItem)=>{
                    return sItem == item.meta.module_route
                }).length
                if(len){
                    userModuleList.push(item);
                }
            })
            state.userModuleList = userModuleList;
            if(data.curModule == 'hide'){
                state.curModule = '';
            }else{
                if(!state.curModule) {
                    if(data.route.path == '/'){
                        let module = localStorage.getItem(this.state.user.userInfo.id + 'curModule');
                        let index = state.userModuleList.findIndex((sItem)=>{
                            return sItem?.meta?.module_route == module;
                        });
                        state.curModule = index == -1 ? this.state.user.userInfo.module_default : (localStorage.getItem(this.state.user.userInfo.id + 'curModule') || this.state.user.userInfo.module_default);
                    }else{
                        let index = state.userModuleList.findIndex((item)=>{
                            return item.meta.module_route == data.route.meta.module_route;
                        })
                        console.log(index,data.route.meta.module_route,state.userModuleList,'dddd')
                        if(index > -1){
                            state.curModule =data.route.meta.module_route;
                        }else{
                            state.curModule = this.state.user.userInfo.module_default;
                        }

                    }

                }

                let menuList = Util.formateMenu({menu:this.state.user.userInfo.auth_menus,all:routes,curModule:state.curModule,showCdkey:this.state.user.userInfo.site.service_type != 1});
                // if (menuList.length === 0){
                state.menuList = menuList;
            }



        },
        changeModule(state,data){
            state.curModule = data.curModule;
            localStorage.setItem(this.state.user.userInfo.id + "curModule", state.curModule);

            // console.log(this.state.user,'this.state.user')
            if(!this.state.user.userInfo.site) return;
            // if(this.state.user.userInfo.site.service_type == 1){
            //
            //     data.all.forEach((item)=>{
            //         if(item.list){
            //             item.list = item.list.filter((sItem)=>{
            //                 return sItem.route != 'cdkey/list' && sItem.route != 'cdkey/mycdkey';
            //             })
            //         }
            //     })
            // }

            let menuList = Util.formateMenu({menu:this.state.user.userInfo.auth_menus,all:routes,curModule:state.curModule,showCdkey:this.state.user.userInfo.site.service_type != 1});
            state.menuList = menuList;
        },
        clearCurModule(state,data){
            state.curModule = '';
        },
        setCurPath(state,data){ //设置当前路径
            state.curPath = data;
        },
        changeMenuTheme (state, theme) {
            state.menuTheme = theme;
        },
        changeMainTheme (state, mainTheme) {
            state.themeColor = mainTheme;
        },
        addOpenSubmenu (state, name) {
            let hasThisName = false;
            let isEmpty = false;
            if (name.length === 0) {
                isEmpty = true;
            }
            if (state.openedSubmenuArr.indexOf(name) > -1) {
                hasThisName = true;
            }
            if (!hasThisName && !isEmpty) {
                state.openedSubmenuArr.push(name);
            }
        },
        closePage (state, name) {
            state.cachePage.forEach((item, index) => {
                if (item === name) {
                    state.cachePage.splice(index, 1);
                }
            });
        },
        initCachepage (state) {
            if (localStorage.cachePage) {
                state.cachePage = JSON.parse(localStorage.cachePage);
            }
        },
        removeTag (state, name) {
            state.pageOpenedList.map((item, index) => {
                if (item.name === name) {
                    state.pageOpenedList.splice(index, 1);
                }
            });
        },
        pageOpenedList (state, get) {
            let openedPage = state.pageOpenedList[get.index];
            if (get.argu) {
                openedPage.argu = get.argu;
            }
            if (get.query) {
                openedPage.query = get.query;
            }
            state.pageOpenedList.splice(get.index, 1, openedPage);
            localStorage.pageOpenedList = JSON.stringify(state.pageOpenedList);
        },
        clearAllTags (state) {
            state.pageOpenedList.splice(1);
            state.cachePage.length = 0;
            localStorage.pageOpenedList = JSON.stringify(state.pageOpenedList);
        },
        clearOtherTags (state, vm) {
            let currentName = vm.$route.name;
            let currentIndex = 0;
            state.pageOpenedList.forEach((item, index) => {
                if (item.name === currentName) {
                    currentIndex = index;
                }
            });
            if (currentIndex === 0) {
                state.pageOpenedList.splice(1);
            } else {
                state.pageOpenedList.splice(currentIndex + 1);
                state.pageOpenedList.splice(1, currentIndex - 1);
            }
            let newCachepage = state.cachePage.filter(item => {
                return item === currentName;
            });
            state.cachePage = newCachepage;
            localStorage.pageOpenedList = JSON.stringify(state.pageOpenedList);
        },
        setOpenedList (state) {
            state.pageOpenedList = localStorage.pageOpenedList ? JSON.parse(localStorage.pageOpenedList) : [otherRouter.children[0]];
        },
        setCurrentPath (state, pathArr) {
            state.currentPath = pathArr;
        },
        setCurrentPageName (state, name) {
            state.currentPageName = name;
        },
        setAvator (state, path) {
            localStorage.avatorImgPath = path;
        },
        switchLang (state, lang) {
            Cookies.set('lang', lang, { expires: 7 });
            state.lang = lang;
            i18n.locale = lang;
            // console.log(lang,'+++++++++')
        },
        clearOpenedSubmenu (state) {
            state.openedSubmenuArr.length = 0;
        },
        setMessageCount (state, count) {
            state.messageCount = count;
        },
        increateTag (state, tagObj) {
            if (!Util.oneOf(tagObj.name, state.dontCache)) {
                state.cachePage.push(tagObj.name);
                localStorage.cachePage = JSON.stringify(state.cachePage);
            }
            state.pageOpenedList.push(tagObj);
            localStorage.pageOpenedList = JSON.stringify(state.pageOpenedList);
        },
        formatBreadCrumbList(state,data){
            let arr = data.matched[data.matched.length - 1] ? data.matched[data.matched.length - 1].path.split(':') : data.path;
            let path = arr[0];
            state.breadCrumbList = JSON.parse(localStorage.getItem('breadCrumbList')) || [];
            if(arr.length > 1){
                path=path.substring(0,path.length-1);
            }
            //先看看面包屑中有没有  如果有直接截取
            let index = state.breadCrumbList.findIndex((item)=>{
                return item.path == path;
            })
            // console.log(JSON.stringify(state.breadCrumbList),index,'=======')
            // return;
            if(index != -1){
                state.breadCrumbList.splice(index +1);
            }else{
                let isExist = false;
                let existIndex;
                for(let i =0;i<state.menuList.length; i++){
                    if(state.menuList[i].children){
                        let arr = state.menuList[i].children.filter((item)=>{
                            return item.path == path;
                        })
                        if(arr.length){
                            isExist = true;
                            existIndex = i;
                            break;
                        }
                    }else{
                        // console.log(state.menuList[i].path,path)
                        if(state.menuList[i].path == path){
                            isExist = true;
                            break;
                        }
                    }
                }
                if(isExist){    //是否为左侧菜单
                    // console.log(state.menuList,'state.menuList')
                    state.breadCrumbList = [];
                    if(state.menuList[0].name == 'router_home'){
                        state.breadCrumbList = [{
                            path:state.menuList[0].path,
                            title:state.menuList[0].name,

                        }];
                    }

                    // if(existIndex || existIndex == 0){
                    //     state.breadCrumbList.push({
                    //         title:state.menuList[existIndex].title
                    //     })
                    // }
                    state.breadCrumbList.push({
                        path:path,
                        title:data.meta.title,
                        query:data.query,
                        params:data.params,
                        name:data.name
                    })
                }else{ //详情
                    if(!state.breadCrumbList.length  && state.menuList.length && path != state.menuList[0].path){
                        state.breadCrumbList = [{
                            path:state.menuList[0].path,
                            title:state.menuList[0].name,
                        }];
                    }
                    state.breadCrumbList.push({
                        path:path,
                        title:data.meta.title,
                        query:data.query,
                        params:data.params,
                        name:data.name
                    })

                }
            }
            localStorage.setItem('breadCrumbList',JSON.stringify(state.breadCrumbList));
        },
        changeBreadCrumbList(state,data){
            state.breadCrumbList = data;
            localStorage.setItem('breadCrumbList',JSON.stringify(data));

        },
        loginSuccessRouter(state,data){
            if(data.form == 'website'){
                data.vm.$router.push({
                    path:'/myinfo/teacher'
                })
            }else{
                // console.log(data.fullPath,'data.fullPath')
                if(data.fullPath){
                    data.vm.$router.push({
                        path:data.fullPath
                    })
                }else{
                    data.vm.$router.push({
                        path:'/'
                    })
                }

            }
        },
        changeLoginStatus(state,data){
            state.loginStatus = data;
        }
    }
};

export default app;
