import CourseTeacherHome from '../views/layout/courseTeacherHome'
const courseTeacherRoute = {
    path: '/course-teacher-route',
    redirect: '/course/teacher-list/',
    name: 'course-teacher-route',
    meta:{
        title:'课程教学',
        module_route:'module_course_teacher'
    },
    component: CourseTeacherHome,
    children:[
        {
            path: '/course/teacher-list/:tab?',
            route:'course/teacher-list',
            name: 'course/teacher-list',
            meta:{
                title:'router_course_teacher_list',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/kecheng.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/list.vue'),
        },
        {
            path: '/trainingcamp/trainstat',
            route: 'trainingcamp/trainstat',
            name: 'trainingcamp/trainstat',
            meta:{
                title:'router_trainingcamp_trainstat',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/homework.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/analysis/trainstat.vue'),
        },
        {
            path: '/trainingcamp/groupAnalysis',
            route: 'trainingcamp/groupAnalysis',
            name: 'trainingcamp/groupAnalysis',
            meta:{
                title:'router_trainingcamp_groupAnalysis',
                module_route:'module_course_teacher',
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/analysis/groupAnalysis.vue'),
        },
        {
            path: '/trainingcamp/group-course-analysis',
            route: 'trainingcamp/group-course-analysis',
            name: 'trainingcamp/group-course-analysis',
            meta:{
                title:'router_trainingcamp_group_course_analysis',
                module_route:'module_course_teacher',
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/analysis/groupCourseAnalysis.vue'),
        },
        // {
        //     path: '/dataset/self-data',
        //     route: 'dataset/self-data',
        //     name: 'dataset_self-data',
        //     meta:{
        //         title:'router_dataset_data',
        //         module_route:'module_course_teacher',
        //         img:require('@/assets/images/new_icon/tikuguanli.png'),
        //         requiresAuth:true,
        //     },
        //     component: ()=>import('../views/course/questionBankManage.vue'),
        // },
        {
            path:"/self-data/category",
            route:"self-data/category",
            name:"self-data/category",
            meta:{
                title:"router_course_category",
                module_route:'module_course_teacher',
                requiresAuth:true,
            },
            component: ()=>import('../views/course/category.vue'),
        },
        {
            path: '/teacher/course/detail/:tab?',
            route:'courseDetail',
            name: 't_courseDetail',
            meta:{
                title:'router_course_detail',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/detail.vue'),
        },
        {
            path: '/course/createTasks',
            route:'createTasks',
            name: 'createTasks',
            meta:{
                title:'router_course_create_tasks',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/createTasks.vue'),
        },
        {
            path: '/course/task/exam',
            route:'course/task/exam',
            name: 'course/task/exam',
            meta:{
                title:'router_course_task_exam',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/kecheng.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/taskExam.vue'),
        },
        {
            path: '/course/task/randomExam',
            route:'course/task/randomExam',
            name: 'course/task/randomExam',
            meta:{
                title:'router_course_task_random_exam',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/kecheng.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/randomExam.vue'),
        },
        {
            path:"/teacher/course/analysisStudents",
            route:"teacher/course/analysisStudents",
            name:"teacher/course/analysisStudents",
            meta:{
                title:"学生学情分析",
                module_route:"module_course_teacher",
                requiresAuth:true
            },
            component:()=>import("../views/course/stuAnalysisDetail.vue")
        },
        {
            path: '/teacher/course/interactExam',
            route: 'teacher/course/interactExam',
            name: 'teacher/course/interactExam',
            meta:{
                title:'menu_course_interact_exam',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/interactExam.vue'),
        },
        {
            path: '/course/detail',
            route:'courseDetail',
            name: 't2_courseDetail',
            meta:{
                title:'router_course_detail',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/detail.vue'),
        },
        {
            path: '/teacher/dataset/mapCreate',
            route:'teacher/dataset/mapCreate',
            name: 'teacher/dataset/mapCreate',
            meta:{
                title:'图谱设置',
                module_route:'module_course_teacher',
                requiresAuth:true,
            },
            component: ()=>import('../views/dataset/mapCreate.vue'),
        },
        {
            path: '/course/category',
            route:'courseDetail',
            name: 't4_courseDetail',
            meta:{
                title:'router_course_category',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/category.vue'),
        },
        {
            path: '/course/user/detail/:tab?/:id?',
            route:'user/detail',
            name: 'course/user/detail',
            meta:{
                title:'router_user',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/user/userDetail.vue'),
        },
        {
            path: '/course/interact/add',
            route:'course/interact/add',
            name: 'course/interact/add',
            meta:{
                title:'router_course_interact_add',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/kecheng.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/interactAdd.vue'),
        },
        {
            path: '/course/interact/record',
            route:'course/interact/record',
            name: 'course/interact/record',
            meta:{
                title:'router_course_interact_record',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/kecheng.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/interactRecord.vue'),
        },
        {
            path: '/course/task/exam/preview',
            route: 'course/task/exam/preview',
            name: 'preview-exam',
            meta:{
                title:'menu_trainingcamp_preview_exam',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/components/taskList/previewExam.vue'),
        },

        {
            path: '/course/analysis/paperAnalysis',
            route:'course/analysis/paperAnalysis',
            name: 'course/analysis/paperAnalysis',
            meta:{
                title:'router_course_analysis_exam',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/kecheng.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/analysis/paperAnalysis.vue'),
        },
        {
            path: '/course/interact/detail',
            route:'course/interact/detail',
            name: 'course/interact/detail',
            meta:{
                title:'router_course_interact_detail',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/kecheng.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/interactDetail.vue'),
        },
        {
            path: '/manager/trainingcamp/mygroup-create',
            route: 'trainingcamp/mygroup-create',
            name: 'm_mygroup-create',
            meta:{
                title:'router_trainingcamp_mygroup_create',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/my_group.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/myGroupCreate.vue'),
        },
        {
            path:"/liveroom-manager",
            name:"liveroom-manager",
            route:'liveroom-manager',
            meta:{
                title:'live_room',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/exam.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/live/liveRoomTeacherList.vue'),
        },
        
        {
            path: '/manager/dataset/mapCreate',
            route:'manager/dataset/mapCreate',
            name: 'manager-map-create',
            meta:{
                title:'设置知识图谱',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/dataset/mapCreate.vue'),
        },
        {
            path: '/trainingcamp/stuExamDetail',
            route: 'trainingcamp/stuExamDetail',
            name: 'stuExamDetail',
            meta:{
                title:'router_trainingcamp_stuExamDetail',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/homework.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/analysis/stuExamDetail.vue'),
        },
        {
            path: '/dataset/private-data',
            route: 'dataset/private-data',
            name: 'dataset/private-data',
            meta:{
                title:'router_dataset_private_data',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/tiku.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/privateData.vue'),
        },
        {
            path: '/course/dataset/data-detail/:tab?/:id?',
            route:'dataset/data-detail',
            name: 'course-data-detail',
            meta:{
                title:'menu_trainingcamp_data_detail',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/dataset/dataDetail.vue'),
        },
        {
            path: '/teaching-source',
            route:'teaching-source',
            name: 'teaching-source',
            meta:{
                title:'router_teaching_source',
                module_route:'module_course_teacher',
                img:require('@/assets/images/new_icon/ziyuan.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/sourceData.vue'),
        },
    ]
};

export default courseTeacherRoute;
