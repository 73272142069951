export default {
    template_audio_file_placeholder: '音频',
    template_image_label_tool: '图片标注工具',
    template_image_basic_tool: '基础工具',
    template_image_special_tool: '特殊工具',
    template_external_link: '第三方链接',
    template_form_checkbox: '多选框',
    template_form_checkbox_header: '请在下列选项中选择一个合适的选项',
    template_form_group: '表单组',
    template_form_group_header: '属性',
    template_form_radio: '单选框',
    template_form_radio_header: '单选框',
    template_form_select: '下拉框',
    template_form_select_header: '下拉框',
    template_form_upload: '文件上传',
    template_data_is_valid: '数据清洗',
    template_data_is_valid_header: '数据是否有效',
    template_key_point: '有序关键点',
    template_layout: '布局',
    template_multi_input: '多行输入',
    template_multi_input_header: '多行输入',
    template_show_img: '图片展示',
    template_image_placeholders: '图片占位符',
    template_show_text: '文本描述',
    template_single_input: '单行输入',
    template_single_input_header: '单行输入',
    template_tag: '标签',
    template_select_file: '选择文件',
    template_task_file_placeholder: '图片',
    template_image_file_shown_here_processed_operator: '您的图片文件将在此处展示，操作员将对其进行处理',
    template_key_point_group: '有序关键点组',
    template_key_points: '关键点示意图',
    template_outside_the_graph: '是否在图外',
    template_covered: '是否被遮挡',
    template_back_to_previous_step: '回到上一步',
    template_back_to_step_one: '回到第一步',
    template_label_least_once: '表示在所有标注物中该标签至少使用一次',
    template_tag_group_least_once: '表示在每一个标注物中至少使用一个该组内标签',
    template_text_file_placeholder: '文本',
    template_text_content_show_opeator_handle: '您的文本内容将在此处展示，操作员将对其进行处理',
    template_video_file_placeholder: '视频',

    template_title_optional: '标题(选填)',
    template_remarks_optional: '备注(选填)',
    template_multi_input_maxRows: '最大行数',
    template_data_source_anchor_point: '数据源锚点',
    template_editable_not_repeat: '可编辑，勿重复',
    template_editing_options: '编辑选项',
    template_enter_title: '请输入标题',
    template_enter_comments: '请输入备注信息',
    template_set_option_content_sort: '您可以设置选项内容，对选项排序',
    template_add_option: '添加选项',
    template_batch_add_option: '批量添加',
    template_batch_add_option_tips: '每行一个条目,保存时会自动去重,为空的行会忽略',
    template_sets_option_layout: '设置选项布局方式',
    template_horizontal_array: '水平排列',
    template_vertical_array: '竖直排列',
    template_whether_must: '是否必须',
    template_whether_must_required: '是否必填',
    template_upload_type: '上传类型',
    template_set_file_format_receive: '在此设置您所接收的文件类型及文件格式',
    template_picture: '图片',
    template_audio: '音频',
    template_video: '视频',
    template_other: '其他',
    template_file_format: '文件格式',
    template_limit_size_file: '限制单个文件大小',
    template_min: '最小',
    template_max: '最大',
    template_limit_length_file: '限制单个文件时长',
    template_shortest: '最短',
    template_longest: '最长',
    template_number_file_upload: '允许上传的文件数量',
    template_upload_one_file_default_modify: '默认允许上传一个文件，如需多个请修改。',
    template_key_point_group_list: '关键点组列表',
    template_key_point_group_name: '关键点组名',
    template_edit_point_location: '编辑点位置',
    template_in_figure: '换图',
    template_delete: '删除',
    template_list: '列表',
    template_add_key_points: '添加关键点',
    template_add_key_points_group: '添加关键点组',
    template_excel_import: 'Excel导入',
    template_key_components_excel_import_templates: '关键点组件Excel导入模板',
    template_download_template: '下载模板',
    template_edit_location_key_points_sample_diagram: '编辑关键点在示例图上的位置',
    template_save: '保存',
    template_close: '关闭',
    template_edit_key_point_location: '编辑关键点位置',
    template_replace_picture: '更换图片',
    template_edit_key_points: '编辑关键点',
    template_adjust_order_key_points: '在此您可调整关键点顺序',
    template_light_position_enable_drag: '按照右侧关键点高亮的顺序依次点击关键点在图片上对应的位置, 可拖动调整位置',
    template_keep_at_least_one: '至少保留一个',
    template_upload_sample_diagram_first: '请先上传示例图',
    template_upload_failed_try_again: '上传失败请重试',
    template_data_empty: '数据为空',
    template_data_invalid: '数据无效',
    template_label_attr_edit: '标签属性编辑',
    template_color: '颜色',
    template_short_code: '显示名称',
    template_min_width: '最小宽度',
    template_maxi_width: '最大宽度',
    template_min_height: '最小高度',
    template_max_height: '最大高度',
    template_width: '宽',
    template_height: '高',
    template_label_included_submitted_job_result: '提交的作业结果中必须包含此标签',
    template_not_must: '非必须',
    template_must: '必须',
    template_figure: '示例图',
    template_update_figure: '更新示例图',
    template_file_upload_failes: '文件 {fileName} 上传失败',
    template_set_layout_width_ratio: '设置布局宽度比例',
    template_ratio_width_two_columns: '两列宽度比例',
    template_upload_sample_image: '请上传您的示例图片',
    template_upload_file_size: '支持格式为.png, .jpg, .jpeg; 文件大小限制2M',
    template_click_select_the_image: '点击选择图片',
    template_file_size_exceeds_limit: '文件大小超出限制',
    template_file_size_2M: '文件{fileName}大小超过2M.',
    template_open_text_editor: '打开文本编辑器',
    template_set_label_type: '设置标签类型',
    template_single_tag: '单标签',
    template_label_group: '标签组',
    template_advanced_settings: '高级设置',
    template_batch_change_label_color: '批量修改标签颜色',
    template_system_default_value: '系统默认值为',
    template_polygon_point_distance_setting: '多边形点距离设置',
    template_whether_enable_tag_echo_search: '是否开启标签回显和搜索功能',
    template_no_limit: '表示无限制',
    template_open: '开启',
    template_can: '可以',
    template_can_not: '不可以',
    template_client_unSupport: '桌面端暂不支持此功能',
    template_whether_drawn_outside_picture: '是否可绘制到图片外',
    template_label_coordinate_points_following_graph: '对以下图形的坐标点打标签',
    template_trapezoidal: '梯形',
    template_quadrilateral: '四边形',
    template_cuboid: '长方体',
    template_line: '线',
    template_triangle: '三角形',
    template_open_tag_group_lock: '开启标签组锁定',
    template_whether_tag_group_expanded_default: '标签组是否默认展开',
    template_opened: '已开启',
    template_off: '已关闭',
    template_unfold: '展开',
    template_closed: '闭合',
    template_label_group_presentation: '标签组展示方式',
    template_list_type: '列表式',
    template_card_type: '卡片式',
    template_whether_global_label_is_unique: '全局标签是否唯一',
    template_sets_whether_label_is_unique: '设置标签是否唯一.',
    template_indicates_label_has_at_most_one_label: '是: 表示标注物至多有一个标签',
    template_indicates_label_may_have_multiple_labels: '否: 表示标注物可以有多个标签',
    template_setting_highest_priority: '该设置具有最高优先级',
    template_label_only_have_one_label: '是，标注物只能有一个标签',
    template_labels_can_have_multiple_labels: '否，标注物可以有多个标签',
    template_whether_the_label_must_have: '标签是否必须有',
    template_the_label_must_have_label: '是，标注物必须有标签',
    template_labels_may_not_have_labels: '否，标注物可以没有标签',
    template_edit_label: '编辑标签',
    template_editable_label_properties: '可点击输入框右侧齿轮️编辑标签属性, 标签输入框内需填写标签的输出值, 显示名称为展示于作业员的标签名称',
    template_add_tags: '添加标签',
    template_whether_tags_group_unique: '组内标签是否唯一',
    template_sets_whether_label_in_the_group_is_unique: '设置该组内的标签是否唯一.',
    template_indicates_tag_one_label_within_group: '是: 表示标注物至多使用该组内的一个标签',
    template_indicates_nnotator_multiple_tags_within_group: '否: 表示标注物可以使用该组内的多个标签',
    template_tags_may_use_most_one_label_within_group: '是, 标注物至多使用该组内的一个标签',
    template_tags_can_use_multiple_tags_within_group: '否, 标注物可以使用该组内的多个标签',
    template_whether_there_must_be_tags_within_group: '组内标签是否必须有',
    template_tags_must_have_set_tags: '是，标注物都必须有该组标签',
    template_tags_may_not_have_set_tags: '否，标注物可以没有该组标签',
    template_add_three_level_label: '添加三级标签',
    template_add_secondary_tags: '添加二级标签',
    template_add_tag_group: '添加标签组',
    template_enable_text_search: '开启文本搜索功能',
    template_tag_group_title: '标签组标题(点击筛选标签)',
    template_select_the_category_tab: '所选分类的标签(点击选择标签)',
    template_table_data_format_incorrect: '表格数据格式不正确',
    template_shortcut_used: '快捷键已使用',
    template_shape_enable: '已开启的图形类型',
    template_need_add_shape: '请添加可以绘制的类型',
    template_keyPoint_color_set_all: '关键点颜色批量设置',
    template_default_tag_header: '类型选择',
    template_default_form_radio1_header: '是不是数字',
    template_default_form_radio1_yes: '是',
    template_default_form_radio1_no: '不是',
    template_default_single_input1_header: '请对您看到的图片进行一个描述',
    template_default_form_radio2_header: '请判断转录是否准确',
    template_default_form_radio2_yes: '准确',
    template_default_form_radio2_no: '不准确',
    template_default_mul_input_header: '若转录不准确，请您进行修改',
    template_default_form_radio3_header: '描述的内容是否与金融有关',
    template_default_form_radio4_header: '角色',
    template_default_form_radio4_role1: '角色1',
    template_default_form_radio4_role2: '角色2',
    template_default_form_radio5_header: '语气',
    template_default_form_radio5_tone1: '平和',
    template_default_form_radio5_tone2: '急促',
    template_no_match_text: '无匹配的文本',

    'template-audio-file-placeholder': '音频',
    'template-task-file-placeholder': '图片',
    'template-video-file-placeholder': '视频',
    'template-text-file-placeholder': '文本',
    'template-form-checkbox': '多选框',
    'template-form-group': '表单组',
    'template-form-radio': '单选框',
    'template-form-select': '下拉框',
    'template-form-upload': '文件上传',
    'template-data-is-valid': '数据清洗',
    'template-external-link': '第三方链接',
    'template-key-point-group': '有序关键点组',
    'template-show-img': '图片展示',
    'template-show-text': '文本描述',
    'template-single-input': '单行输入',
    'template-tag': '标签',
    'template-key-point': '有序关键点',
    'template-layout': '布局',
    'template-multi-input': '多行输入',

    'template-components-desc': '本组件不会在执行页面展示, 只用于标注工具配置',
    'template-open-picture-type': '已开启的图形类型 (会覆盖分类的默认配置):',
    'template-add-draw-type': '请添加可以绘制的类型',

    'template-option-1': '选项一',
    'template-option-2': '选项二',
    'template-option-3': '选项三',
    'template-local-settings': '局部设置',
    'template-upload-data': '请上传您采集的数据文件',
    'template-follow-illustration-annotation': '请按下方示例图进行标注',

    'template-nose': '鼻子',
    'template-neck': '脖子',
    'template-left-eye': '左眼',
    'template-right-eye': '右眼',
    'template-left-ear': '左耳',
    'template-right-ear': '右耳',
    'template-left-shoulder': '左肩',
    'template-right-shoulder': '右肩',
    'template-left-elbow': '左臂肘',
    'template-right-elbow': '右臂肘',
    'template-left-wrist': '左手腕',
    'template-right-wrist': '右手腕',
    'template-left-hip': '臀部左',
    'template-right-hip': '臀部右',
    'template-left-knee': '左膝',
    'template-right-knee': '右膝',
    'template-left-ankle': '左脚踝',
    'template-right-ankle': '右脚踝',
    'template-head-key': '头部关键点',
    'template-describe-picture': '请对您看到的图片进行一个描述',
    'template-enter-information-input': '请在输入框内输入信息',
    'template-sample-file-annotate': '请按下方示例文件进行标注',

    'template-type-selection': '类型选择',
    'template-refrigerator': '冰箱',
    'template-banana': '香蕉',
    'template-ham': '火腿',
    'template-setting-key-point-colors': '关键点颜色批量设置',
    'template-start': '起始',
    'template-end': '结束',
    'template-order-uniform': '顺序等分',
    'template-custom-bisecting': '自定义等分',
    'templat-info': '批量添加{limit} ',
    'template-info-span': '每行一个条目,保存时会自动去重,为空的行会忽略',

};
