export default {
    codekey_manage_add:'新增激活码授权',
    codekey_manage_add_first_category:'一级分类',
    codekey_manage_add_second_category:'二级分类',
    codekey_manage_add_company:'组织',
    codekey_manage_add_price:'一码单价(元)',
    codekey_manage_add_price_change:'修改',
    codekey_manage_add_count:'数量(个)',
    codekey_manage_add_timelong:'有效时间',
    codekey_manage_add_status:'状态',
    codekey_manage_add_count_placeholder:'请输入数量(个)',
    codekey_manage_add_remark:'备注',
    codekey_manage_add_remark_placeholder:'请输入备注',
    codekey_manage_cancel:'取消',
    codekey_manage_save:'保存',
    codekey_manage_recharge:'充值',
    codekey_manage_recharge_category:'已选择分类',
    codekey_manage_table_name:'组织名称',
    codekey_manage_table_category:'激活分类',
    codekey_manage_count:'数量',
    codekey_manage_table_timelong:'激活码时长',
    codekey_manage_table_active_rate:'激活率',
    codekey_manage_create_time:'创建时间',
    codekey_manage_update_time:'更新时间',
    codekey_manage_op:'操作',
    codekey_manage_more:'更多',
    codekey_manage_open:'启用',
    codekey_manage_disable:'禁用',
    codekey_manage_company_message:'请选择组织',
    codekey_manage_price_message:'请输入一码单价',
    codekey_manage_timelong_message:'请选择有效时间',
    codekey_manage_status_message:'请选择状态',
    codekey_manage_op_success:'操作成功',

    codekey_list_add_user:'添加成员',
    codekey_list_surplus:'剩余',
    codekey_list_select_dateset:'选择题库',
    codekey_list_mode:'分发模式',
    codekey_list_select_user:'选择用户',
    codekey_list_copy_url:'复制邀请链接',
    codekey_list_selected_user:'已选用户',
    codekey_list_issue:'自主分发',
    codekey_list_need_code_num:'需要的激活码个数',
    codekey_list_num_placeholder:'请输入个数',
    codekey_list_active_category:'激活分类',
    codekey_list_surplus_num:'剩余个数',
    codekey_list_code_timelong:'激活码时长',
    codekey_list_active_user:'已激活人数',
    codekey_list_unactive_user:'未激活人数',
    codekey_list_active_rate:'激活率',
    codekey_list_name:'姓名',
    codekey_list_company:'租户',
    codekey_list_select_user_message:'请选择用户',
    codekey_list_user_num_max:'选择人数不能大于总个数',
    codekey_list_num_max:'输入个数不能大于总个数',
    codekey_list_status:'状态',

    codekey_my_data_num:'试题数量',
    codekey_my_copy:'复制',
    codekey_my_day:'天',
    codekey_my_hours:'小时',
    codekey_my_minute:'分钟',
    codekey_my_second:'秒',
    codekey_my_go_train:'前往练习',
    codekey_my_active:'立即激活',
    codekey_my_active_success:'激活成功',
    codekey_my_copy_success:'复制成功',
    codekey_my_copy_fail:'复制失败',

    codekey_my_cdkey_select_category:'选择激活题库',
    codekey_my_cdkey_category:'题库所在分类',
    codekey_my_cdkey_select_category_message:'请选择题库',

    codekey_detail_active_category:'已激活分类',
    codekey_detail_active_user:'已激活人数',
    codekey_detail_unactive_category:'未激活人数',
    codekey_detail_active_rate:'激活率',
    codekey_detail_active_list:'激活码明细列表',
    codekey_detail_record_list:'操作记录',
    codekey_detail_change_category:'更改题库',
    codekey_detail_cur_active_category:'当前已激活题库',
    codekey_detail_confirm_category:'确定更改为',
    codekey_detail_table_userid:'用户ID',
    codekey_detail_table_name:'用户名',
    codekey_detail_active_dataset1:'一级分类',
    codekey_detail_active_dataset2:'二级分类',
    codekey_detail_active_dataset:'激活题库(三级)',
    codekey_detail_code:'激活码',
    codekey_detail_status:'状态',
    codekey_detail_active_time:'激活时间',
    codekey_detail_remaining_time:'剩余时间',
    codekey_detail_op_user:'操作人',
    codekey_detail_create_time:'创建时间',
    codekey_detail_op:'操作',
    codekey_detail_more:'更多',
    codekey_detail_disabled:'禁用',
    codekey_detail_disabled_success:'禁用成功',
    codekey_detail_type:'类型',
    codekey_detail_recharge_list:'充值记录',
}
