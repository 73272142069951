import Cookies from 'js-cookie';
import app from './app';
import {tokenName} from '@/router/init.js'
import constant from '@/utils/constant.js'

const user = {
    state: {
        userInfo: {
            accessToken: Cookies.get(constant.tokenName) || '',
            userName: '',
        },
        userSetting:{

        }
    },
    mutations: {
        //
        'login': (state, data) => {
            state.userInfo = data;
        },
        'logout': (state) => {
            let domain = location.hostname.split('.');
            domain = [domain[domain.length - 2], domain[domain.length - 1]].join('.');
            // Cookies.remove('liteAccessToken', { domain: domain });
            
            Cookies.remove('access'); // todo
            Cookies.remove('liteMenuShrink'); // todo
            state.userInfo = {};
            app.state.settings = {};
        },
        'setUserSetting':(state,data)=>{
            state.userSetting=data;
        }
    },
    actions: {

    }
};

export default user;
