export default {
    project_team: '团队',
    project_crowdsourcing: '众包',
    project_ai: 'AI模型',
    project_search: '搜索',
    project_project: '项目',
    project_external_name: '外部名称',
    project_internal_name: '内部名称',
    project_enter_external_name: '请输入外部名称',
    project_enter_internal_name: '请输入内部名称',
    project_search_project_nameid: '输入项目名称、ID、发布者',
    project_search_user_nameid: '输入用户ID、名称',
    project_search_category: '输入分类ID、模板名称',
    project_search_content: '输入查找内容',
    project_search_project_name: '输入项目名称',
    project_cancel: '取消',
    project_delete: '删除',
    project_remove: '移除',
    project_edit: '编辑',
    project_save: '保存',
    project_sure: '确定',
    project_back: '返回',
    project_close: '关闭',
    project_save_success: '保存成功',
    project_save_error: '保存失败',
    project_delete_success: '删除成功',
    project_create: '创建',
    project_create_date: '创建日期',
    project_operation_tip: '操作提示',
    project_operation: '操作',

    project_previous_step: '上一步',
    project_save_and_exit: '保存并退出',
    project_exit: '退出',
    project_continue: '继续',
    project_next_step: '下一步',
    project_release: '发布',
    project_configuration_updated: '已更新项目配置',
    project_select_type: '选择类型',

    project_add_permission: '添加权限',
    project_edit_permission: '编辑权限信息',
    project_permission_name: '权限名称',
    project_enter_permission: '请输入查找权限',
    project_enter_permission_name: '请输入权限名称',
    project_permission_description: '权限描述',
    project_enter_permission_description: '请输入权限描述',
    project_sure_delete_permission: '确定要删除该权限吗？',
    project_permission_name_null: '权限名不能为空',
    project_permission_description_null: '权限描述不能为空',

    project_add_role: '添加角色信息',
    project_role_name: '角色名称',
    project_enter_role: '请输入查找角色',
    project_enter_role_name: '请输入角色名称',
    project_enter_role_description: '请输入角色描述',
    project_sure_delete_role: '确定要删除该角色吗？',
    project_role_description: '角色描述',
    project_member: '成员',
    project_role_name_null: '角色名不能为空',
    project_role_description_null: '角色描述不能为空',
    project_role_permission_configuration: '角色权限配置',
    project_name: '名称',
    project_description: '描述',
    project_input_template_description: '请输入模型描述...',
    project_sample_request: '请求示例',
    project_input_sample_request: '请输入请求示例...',
    project_please_sample_request: '请输入请求示例',
    project_input_extract_field: '输入提取字段',
    project_extract: '提取',
    project_request_field_configuration: '请求字段配置',
    project_response_sample: '响应示例',
    project_input_response_sample: '请输入响应示例...',
    project_please_response_sample: '请输入响应示例',
    project_response_field_configuration: '响应字段配置',
    project_input_response_field_configuration: '请输入响应字段配置',
    project_input_configuration_parameters: '请输入配置参数...',
    project_input_value: '请输入value值',
    project_input_example_json_format: '响应示例请输入json格式数据',
    project_field_configuration_value_incorrectly: '字段配置中, 以下Value值配置有误：',
    project_requesting_field_configuration: '请求字段配置中： ',
    project_request_field: '请求字段',
    project_responsing_field_configuration: '响应字段配置中： ',
    project_response_field: '响应字段',
    project_input_json_data: '请输入JSON数据...',
    project_please_input_json_data: '请输入JSON数据',
    project_no_json_data: '不是JSON数据格式',

    project_no_path_to_this_field: '无该字段路径',
    project_permission: '权限',
    project_configuration_success: '配置成功',
    project_new_role: '新建角色',
    project_new_success: '新建成功',
    project_add_member: '添加成员',
    project_batch_change_role: '批量更换角色',
    project_batch_remove_member: '批量移除成员',
    project_sure_remove_current_member: '真的要移除当前成员吗？',
    project_sure_remove_checked_member: '确定要移除勾选的成员吗？',
    project_select_role: '选择角色',
    project_please_select_role: '请选择角色',
    project_sure_transfer: '确定转移',
    project_user_id: '用户ID',
    project_user_name: '用户名称',
    project_user_email: '绑定邮箱',
    project_removed: '已移除',
    project_nickname: '昵称',
    project_email: '绑定邮箱',
    project_type: '类型',
    project_role: '角色',
    project_add: '添加',
    project_added: '已添加',

    project_new_category: '新增分类',
    project_preview: '预览',
    project_set_label: '设置标签',
    project_task_type: '任务类型',
    project_label_type: '标注类型',
    project_category_name: '分类名称',
    project_category_type: '绘制类型',
    project_keyword: '关键词',
    project_small_icon: '小图标',
    project_big_icon: '大图标',
    project_check: '查看',
    project_data_field_value: '数据字段值',
    project_template_field_value: '模板字段值',
    project_delete_category: '删除分类',
    project_sure_delete_category: '确定删除该分类',
    project_picture_class: '图片类',
    project_voice_class: '语音类',
    project_text_class: '文本类',
    project_video_class: '视频类',
    project_label_class: '标注类',
    project_collection_class: '采集类',
    project_external_links: '外部链接',
    project_create_category: '创建分类',
    project_sure_create: '确定创建',
    project_base_info: '基本信息',
    project_enter_field_value: '请输入字段值',
    project_setting_small_icon: '设置小图标',
    project_setting_big_icon: '设置大图标',
    project_chinese_template: '中文模板',
    project_keyword_separated: '关键词,用逗号隔开',
    project_preview_image: '预览图片',
    project_setting_previews: '设置预览图',
    project_english_template: '英文模板',
    project_null: '不能为空',
    project_task_type_null: '任务类型不能为空',
    project_data_field_null: '数据字段值不能为空',
    project_template_field_null: '模板字段值不能为空',
    project_category_name_null: '分类名称不能为空',
    project_keyword_null: '关键词不能为空',
    project_description_null: '描述内容不能为空',
    project_notice_words_limit: '公告字数在0~255之间',
    project_edit_category: '编辑分类',
    project_sure_edit: '确定修改',

    project_new_model: '新增模型',
    project_edit_model_category: '编辑模型分类',
    project_model_name: '模型名称',
    project_head_map: '头图',
    project_upload_pictures: '上传图片',
    project_sort: '排序',
    project_program_script: '程序脚本',
    project_input_program_script: '请输入程序脚本',
    project_fill_explain: '填写说明',
    project_input_name: '请输入名称',
    project_atmost_100_characters: '不超过100字符',
    project_atmost_200_characters: '不超过200字符',
    project_edit_model: '编辑模型',
    project_model_type: '模型类别',
    project_label: '标签',
    project_input_label: '请输入标签,用逗号隔开',
    project_address_interface: '接口地址',
    project_input_address_interface: '请输入接口地址',
    project_request_configuration: '请求配置',
    project_result_configuration: '结果配置',
    project_not_json_data_format: '不是json数据格式',
    project_please_json_data: '请输入json数据',
    project_select_model_type: '请选择模型类别',
    project_input_configuration_params: '请输入配置参数',
    project_new_model_category: '新增模型分类',
    project_sure_delete_model: '确认要删除该模型吗？',
    project_model_category_name: '模型类别名称',
    project_operation_success: '操作成功',
    project_model_information: '模型信息',
    project_model_id: '模型ID',
    project_response_configuration: '响应配置',
    project_whether_enable: '是否启用',
    project_delete_model: '删除模型',
    project_category_no_model: '该分类没有模型',
    project_saved: '已保存',
    project_view_models: '点击【查看】可查看此类型的全部模型',
    project_mdoel_category_management: '模型分类管理',

    project_view_all_types: '查看全部类型',
    project_create_this_project: '创建此项目',
    project_create_such_projects: '创建此类项目',
    project_content_audit: '内容审核',
    project_preview_this_template: '预览此模板',
    project_preview_template: '预览模板',
    project_result_null: '结果为空',
    project_template_preview: '模板预览',
    project_no_preview_template_image: '该分类暂无可预览的模板图片',
    project_project_name: '项目名称',
    project_input_project_name: '请输入您的项目名称',
    project_project_time: '项目时间',
    project_select_start_time: '请选择开始时间',
    project_select_end_time: '请选择结束时间',
    project_peoject_description: '项目描述',
    project_input_projecy_description: '输入项目描述',
    project_project_overview: '请概述您的项目需求和验收标准。您也可以跳过此项，直接上传说明文件',
    project_more_info: '补充说明',
    project_drag_file_tip: '点击选择文件或拖拽文件到此处',
    project_upload_file_format: '最多上传50个文件，支持扩展名：.doc, .docx, .pdf, .jpg, .png, .jpeg',
    project_file_extensions: '支持文件扩展名',
    project_upload_file_extensions: '支持上传文件扩展名',
    project_work_file_extensions: '支持作业文件扩展名',
    project_project_name_null: '项目名称不能为空',
    project_project_name_format: '请输入2-30个字符',
    project_start_time_null: '开始时间不能为空',
    project_end_time_null: '结束时间不能为空',
    project_upload_success: '  上传成功',
    project_upload_failed: '  上传失败',
    project_format_incorrect: '  格式不正确',
    project_data_saving_failed: '数据保存失败',
    project_support_extensions: '支持上传数据包扩展名',
    project_ftp: 'FTP服务器',
    project_input_ftp: '请输入FTP服务器',
    project_ftp_tips: '请将您的数据文件通过FTP软件上传到此地址',
    project_input_user_name: '请输入用户名',
    project_input_password: '请输入密码',
    project_uploading_wait_complete: '文件上传中，请等待上传完成',
    project_project_id: '项目ID',
    project_projecy_type: '项目类型',
    project_start_time: '开始时间',
    project_end_time: '结束时间',
    project_upload_data: '上传数据包',
    project_data_package: '数据包',
    project_upload_attachment: '请上传需求文件',
    project_upload_file_size: '上传文件大小',
    project_upload_file_num: '上传文件数量',
    project_project_template: '项目模板',
    project_release_success: '发布成功',
    project_release_failure: '发布失败',
    project_your_description: '您的描述',
    project_show_operator_content: '您展示给操作员浏览的内容',
    project_operator_module: '操作员模块',
    project_want_operator_module: '您希望操作员操作的模块',
    project_layout_modules: '布局模块',
    project_adjust_layout: '调整操作界面的布局',
    project_template_design: '模板设计',
    project_attribute_edit: '属性编辑',
    project_change_upload: '更换数据上传方式',
    project_webpage_upload_csv: '网页上传:表格数据 .csv .xls ',
    project_webpage_upload_txt: '网页上传:文本文件 .txt',
    project_webpage_upload_zip: '网页上传:数据文件包 .zip',
    project_ftp_upload_zip: '通过FTP上传 .zip',
    project_network_upload: '通过网盘上传(文件大小不限)',
    project_step1_project: 'STEP 1: 项目描述',
    project_step2_template: 'STEP 2: 模板设置',
    project_step3_data: 'STEP 3: 上传您的数据',
    project_step4_sure: 'STEP 4: 确认项目发布信息',
    project_preview_release: '预览发布',
    project_template_settings: '模板设置',
    project_category_null: '没有找到这个分类，您可以点此 {feedback} 或直接 {contact}',
    project_contact_us: '联系我们',
    project_submit_feedback: '提交反馈',

    project_step1_batch: 'Step 2 : 分配批次',
    project_directory_allocation: '按目录分配',
    project_draw_frame_processing: '是否进行抽帧处理',
    project_usage_scenarios: '使用场景',
    project_track_mark_video: '1.对MP4等视频进行跟踪标注',
    project_track_mark_picture: '2.对已抽取的图片进行跟踪标注',
    project_assigns_frames: '每个作业分配帧数',
    project_enter_assigns_frames: '请输入分配帧数',
    project_divide_amount_work: '按作业量等量分配',
    project_total_work: '作业总量',
    project_set_total_work: '设置作业总量',
    project_count: '个',
    project_nodata_upload_file: '暂无数据，请先上传文件',
    project_total_job_size: '作业总大小 ',
    project_amount_work_batch: '每个批次设置的作业量',
    project_input_positive_integer: '请输入正整数',
    project_save_next: '保存，下一步',
    project_free_distribution: '按作业量自由分配',
    project_setting_total_number: '已设置作业总量',
    project_batch_name: '批次名',
    project_input_batch_name: '请输入批次名',
    project_working_out: '作业量',
    project_delete_batch: '删除该批次',
    project_add_batch: '添加批次',
    project_note_set_equal_total: '注：已设置作业量须等于作业总量',
    project_file_parsing: '文件解析中',
    project_tips_choose_batch: '您已选择 {batch_num} 个批次，一旦确定将无法重选，请想好了再决定',
    project_sure_next: '确定，下一步',
    project_tips_setting_batch: '每个批次设置的作业数 {file_num}，一旦确定将无法重选，请想好了再决定',
    project_jobs_number_null: '作业数不能为空',
    project_input_a_positive_integer: '请输入一个正整数',
    project_jobs_less_one: '设定作业数不小于1',
    project_setting_less_works: '设定作业数应小于作业总数',
    project_batch_one: '批次1',
    project_batch_name_null: '批次名不能为空',
    project_jobs_size: '{count}个作业,共{size}',
    project_input_jobs_numbers: '请输入0~{num}之间的作业数',
    project_input_number_frames: '请输入帧数',
    project_set_equal_total: '已设置作业总量须等于作业总量',
    project_at_least_one_batch: '至少保留一个批次',
    project_input_nickname_2: '至少2个字符',
    project_input_nickname_30: '至多30个字符',
    project_current_seeting: '当前设置',
    project_number_batch: '个批次',

    project_step2_setting_process: 'Step 3 : 设置工序',
    project_add_process: '添加工序',
    project_setting_process_information: '设置工序信息',
    project_process_name: '工序名称',
    project_input_step_name: '请输入工序名称',
    project_process_type: '工序类型',
    project_parents_node: '父节点',
    project_not_checked: '未选择',
    project_load_raw_result: '加载原始结果',
    project_note: '说明',
    project_applies_initialization_data_present: '适用于有初始化数据的情况',
    project_redo_preserve_previous_result: '或重做且保留之前结果的情况',
    project_category: '分类',
    project_helpers: '辅助工具',
    project_setting_conditions: '设置条件',
    project_reset_condition: '清除条件',
    project_choose_template: '选择模板',
    project_execute: '执行',
    project_audit: '审核',
    project_qc: '质检',
    project_process_name_null: '工序名称不能为空',
    project_process_name_dontnot_repead: '工序名称不能重复',
    project_process_name_num_err: '工序名称为2-6字符',
    project_please_choose_process_type: '请选择工序类型',
    project_please_choose_category: '请选择分类',
    project_please_choose_template: '请选择模板',
    project_template: '模板',
    project_choose: '选择',
    project_excute_condition: '执行条件',
    project_view_edit: '查看/编辑',
    project_right: '是',
    project_no: '否',
    project_setting: '设置',
    project_not_choose_template: '未选择模板',
    project_atleast_one_step: '至少保留一个工序',
    project_step_flow_conditions: '此工序无可设置的流转条件，如需添加流转条件，请在此工序模板中添加单选组',
    project_step_no_template_selected: '此工序未选择模板',
    project_step_name_null: '工序名称不能为空',
    project_audit_qc_parents_step: '审核、验收工序须指定父分布',
    project_execute_choose_category: '执行工序须选择分类',
    project_execute_choose_template: '执行工序须选择模板',
    project_parent_distribution_not_execution: '父分布不是执行',
    project_no_template_parent_distribution: '父分布未选择模板',

    project_step3_task_setting: 'Step 4: 任务设置',
    project_save_setting: '保存设置',
    project_all_execute: '全选执行',
    project_all_audit: '全选审核',
    project_all_qc: '全选质检',
    project_batch_setting: '批量设置',
    project_setting_task_info: '设置任务信息',
    project_task_name: '任务名称',
    project_tickets_received_each_time: '每次领取作业数',
    project_effective_execution_time: '有效执行时间',
    project_effective_execution_times: '有效执行时间(s)',
    project_setting_person: '设置人员',
    project_hint: '温馨提示：平台已帮您默认选中全部作业员，点击【设置人员】可进行人员编辑',
    project_check_all: '全选所有人',
    project_cancel_check_all: '取消全选所有人',
    project_choose_platform: '选择平台',
    project_choose_target: '选择目标',
    project_batch_setting_task_information: '批量设置任务信息',
    project_select_edit_field: '选择编辑字段',
    project_number_less_one: '数值不能小于1',
    project_input_integer: '请输入一个整数',
    project_task_starttime_lt_project_starttime: '任务开始时间不能早于项目开始时间',
    project_task_starttime_gt_project_starttime: '任务开始时间不能晚于任务结束时间',
    project_task_endtime_lt_project_endtime: '任务结束时间不能早于任务开始时间',
    project_task_endtime_gt_project_endtime: '任务结束时间不能晚于项目结束时间',
    project_platform_target: '平台及目标',
    project_process: '工序',
    project_platform: '平台',
    project_target: '目标',
    project_name_null: '名称不能为空',
    project_choose_targetplatform: '请选择目标平台',
    project_not_checked_task: '未勾选任务',
    project_task_set_publishing_goals: '团队平台须设置发布目标',

    project_template_name: '模板名称',
    project_template_category_name: '模板分类',
    project_template_name_null: '模板名称不能为空',
    project_template_content_null: '模板内容不能为空',
    project_require_template_data_error: '获取模板数据出错',
    project_new_template: '创建模板',
    project_default_template: '默认模板',
    project_use_this_copy_template: '使用该复制模板',
    project_input_check_template_name: '输入查找内容 模板名称/ ID',
    project_template_id: '模板ID',
    project_use_copy_template: '使用复制模板',
    project_use_this_template: '使用该模板',
    project_copy_error: '复制失败',
    project_common_template: '公共模板',
    project_private_template: '私有模板',

    project_select_download_batch: '选择下载批次: ',
    project_select_download_step: '选择下载工序: ',
    project_select_download_file_format: '选择下载文件格式: ',
    project_config_options: '配置项',
    project_select_all: '全选',
    project_chinese_escape: '中文转义',
    project_no_escape: '不转义',
    project_filter_results: '过滤结果',
    project_no_filter: '不过滤',
    project_delete_null_result: '去除空结果',
    project_ftp_cannot_empty: 'FTP地址不能为空',
    project_ftp_push: '推送至FTP',
    project_ftp_pushing: '正在推送至FTP: ',
    project_time: '时间',
    project_download_history: '下载历史',
    project_batch_download_package_results: '批量下载打包结果',
    project_Batch_download_detection_information: '批量下载检测信息',
    project_data_packages: '打包数据',
    project_data_packages_type: '选择打包类型',
    project_data_packages_name: '下载包名称',
    project_refresh: '刷新',
    project_result: '结果',
    project_batch: '批次',
    project_batch_id: '批次ID',
    project_work_id: '作业ID',
    project_batch_designation: '批次名称',
    project_data_name: '数据名称',
    project_input_data_name: '请输入数据名称，ID',
    project_step: '工序',
    project_file_format: '文件格式',
    project_status: '状态',
    project_packaging_progress: '打包进度',
    project_successful_jobs_packaged: '打包成功作业数',
    project_failed_jobs_packaged: '打包失败作业数',
    project_create_time: '创建时间',
    project_update_time: '更新时间',
    project_select_time: '选择时间',
    project_a_pack: '一个包（包含多个批次）',
    project_num_pack: '多个包（一个批次一个包）',
    project_start_packing_time: '开始打包时间',
    project_wrap_up_time: '打包结束时间',
    project_download: '下载',
    project_pack_result: '打包结果',
    project_send_result_to_dataset: '推送至数据集/FTP',
    project_dataset: '数据集',
    project_dataset_input_text: '请输入数据集ID、名称',
    project_push: '推送',
    project_ftp_address: 'FTP地址',
    project_testing_information: '检测信息',
    project_select_atleast_one_batch: '至少选择一个批次',
    project_select_steps: '请选择工序',
    project_select_file_format: '请选择文件格式',
    project_select_configuration: '请选择配置项',
    project_change_package_type: '请选择打包类型后，进行打包',
    project_submit_successful_wait_results: '提交成功,等待生成结果',
    project_check_starting_ending_time: '请核实起止时间',
    project_check_starting_time: '请核实开始时间！',
    project_check_ending_time: '请核实结束时间！',
    project_try_again_after_seconds: '{num} 秒后重试',

    project_input_batch_name_id: '输入批次名称、ID',
    project_batch_status: '批次状态',
    project_project_count: '项目统计',
    project_work_schedule: '作业进度',
    project_difficulty: '挂起中',
    project_refused: '驳回中',
    project_total_project_operation: '项目作业总量',
    project_batch_numbers: '批次数',
    project_step_number: '工序数',
    project_task_number: '任务数',
    project_participants: '参与人数',
    project_cumulative_working_hours: '累计工时',
    project_project_schedule: '批次进度',
    project_process_id: '工序ID',
    project_task_id: '任务ID',
    project_task_names: '任务名',
    project_number_executions: '执行数',
    project_number_jobs: '作业总数',
    project_username: '用户名',
    project_password: '密码',
    project_data_file_path: '数据文件路径',
    project_attachment_path: '附件路径',
    project_demand_attachment: '需求附件',
    project_file_name: '文件名',
    project_file_size: '文件大小',
    project_upload_time: '上传时间',
    project_no_description: '暂无描述',
    project_teams: ' (团队)',
    project_crowdsourcings: ' (众包)',
    project_ais: ' (AI模型)',

    project_export_data: '导出绩效',
    project_total_number_workers: '作业员总数',
    project_total_number_jobs: '作业总张数',
    project_exexute_numbers: '执行张数',
    project_execute_boxes: '执行框数',
    project_execute_points: '执行点数',
    project_total_marks_jobs: '作业总标注数',
    project_total_points_jobs: '作业总点数',
    project_total_time: '作业总时间(s)',
    project_account: '账号',
    project_belongs_team: '所属团队',
    project_jobs_numbers: '作业张数',
    project_jobs_boxes: '作业框数',
    project_jobs_points: '作业点数',
    project_correct: '正确率',
    project_operation_time: '总时长(s)',
    project_operation_average_time: '平均时长(s)',
    project_number_rejections: '被驳回次数',
    project_distribution_platform: '分配平台',
    project_fields_explain: '字段说明',
    project_unit_s: '单位： 秒( s )',

    project_batch_setup_completed: '批次设置已完成',
    project_batch_setup_not_completed: '批次设置未完成',
    project_project_created_successfully: '创建成功',
    project_input_operator: '输入操作人',
    project_operator: '操作人',
    project_event: '事件',
    project_content: '内容',
    project_create_project: '创建项目',
    project_sure_delete_current_project: '确定要删除当前项目吗？',
    project_sure_restart_current_project: '确定要重启当前项目吗？',
    project_sure_pause_current_project: '确定要暂停当前项目吗？',
    project_sure_recover_current_project: '确定要恢复当前项目吗？',
    project_sure_completed_current_project: '确定要把当前项目设置成已完成吗？',
    project_publisher: '发布人',
    project_leader: '负责人',
    project_project_status: '项目状态',
    project_starting_time: '开始时间',
    project_finish_time: '结束时间',
    project_copy_success: '复制成功',
    project_restart_success: '重启成功',
    project_recover_success: '恢复成功',
    project_pauset_success: '暂停成功',
    project_project_setup: '项目已设置',

    project_project_settings: '项目设置',
    project_publish_project: '发布项目',
    project_start_project: '开始项目',
    project_confirm_change: '确认更改',
    project_prev_project_settings: '上一步,项目设置',
    project_next_allocation_person: '下一步,分配人员',
    project_save_project: '保存项目',
    project_project_start_end_time: '项目周期',
    project_sel_project_start_end_time: '请选择项目起止时间',
    project_project_start_end_time_hint: '结束时间不能等于开始时间',
    project_web_upload: '网页上传',
    project_ssh_upload: 'SSH上传',
    project_input_attachment_path: '请输入附件路径',
    project_upload_attached_file: '请将您的附件文件上传到此地址',
    project_ftp_upload: 'FTP上传',
    project_ftp_server: 'FTP服务器',
    project_input_ftp_server: '请输入FTP服务器',
    project_upload_ftp_server: '请将您的数据文件通过FTP软件上传到此地址',
    project_demand_document: '需求文件',
    project_upload_attachments: '上传文件',
    project_upload_file_extension_support: '最多上传50个文件，支持扩展名',
    project_endtime_gt_starttime: '结束时间不能小于开始时间',
    project_upload_file_limit: '文件上传限制',
    project_file_size_lt_500: '文件太大，不得超过500M',
    project_file_size_mt_1: '上传的图片必须大于1KB',
    project_deleted: '已删除',
    project_work_process: '作业流程',

    project_export_work_record: '导出作业记录',
    project_data_list: '数据列表',
    project_operation_records: '操作记录',
    project_founder: '创建人',
    project_project_details: '项目详情',
    project_batch_list: '批次列表',
    project_project_performance: '项目绩效',
    project_quality_control: '在线验收',
    project_acceptance_check: '验收',
    project_download_result: '下载结果',
    project_releasing: '发布中',
    project_auditing: '审核中',
    project_doing: '作业中',
    project_paused: '已暂停',

    project_input_template_name_id: '输入模板名称、ID、创建人',
    project_sure_delete_current_template: '确定要删除当前模板吗',
    project_copy_successful_template_name: '复制成功,模板名称: ',
    project_sure_this_edit: '确认放弃本次编辑吗？',
    project_download_CSV: '点击下载 CSV 模板',
    project_download_XLSX: '点击下载 XLSX 模板',
    project_input_datafile_path: '请输入数据文件路径',
    project_upload_this_address: '请将您的数据文件上传到此地址',
    project_company_announcements: '公司公告',
    project_edit_announcements: '编辑公告',
    project_upload_filesize_limit: '图片上传文件大小限制',
    project_upload_filesize_limit_con: '上传文件太大，不得超过2M',
    project_project_basicInfo: '项目基本信息',
    project_project_uploadfile: '上传数据文件',
    project_project_pageuploadInst: '网页上传说明',
    project_pageuploadInst_desc1: '上传多个文件，将记录为多个批次分别统计作业进度',
    project_pageuploadInst_desc2: '我们标注界面支持通过VPN或虚拟私有云的方式访问标注数据，保护数据隐私，相关操作请参考文档',
    project_private_data_deploymen: '私有数据部署',
    project_configure_tool: '配置标注工具',
    project_configure_team: '配置执行团队',
    project_edit_template_tip: '禁止删除正在被使用的项目模板，若需删除项目模板，请将以下项目暂停:',
    project_modifying_classification_tip: '修改分类会重置已配置的模板',
    project_edit_template_warning: '修改此模版将影响以下项目，是否继续编辑 ?',
    project_data_container: '数据容器',
    project_operating_instruction: '操作说明',
    project_layout: '布局',
    project_operational_components: '操作组件',
    project_category_no_component: '该分类无可用组件',
    project_lack: '缺少',
    project_modules: '模块',
    project_totle_users: '总人数',
    project_totle_teams: '总团队数',
    project_amount_data: '处理数据量(万条)',
    project_storage_capacity: '硬盘空间量(GB)',
    project_supported_modules: '支持扩展模块',
    project_acceleration_tool: '加速工具',
    project_available_data: '可用数据量',
    project_remaining_space: '剩余存储空间',
    project_no_data: '无',
    project_unpack_tip: '解包提示',
    project_unpack_succ: '解包成功',
    project_unpack_err: '解包失败',
    project_unpack_process: '解包进度',
    project_process_tip: '项目在 {name} 工序的总进度',
    project_upload_tip1: '支持读取的作业文件格式',
    project_upload_tip2: '支持的上传数据包的数据格式',

    project_projectAudit_title: '项目审核',
    project_projectAudit_reject: '删除项目',
    project_projectAudit_info: '项目信息',
    project_projectAudit_id: '项目ID：',
    project_projectAudit_tenant: '所属组织：',
    project_projectAudit_name: '项目名称：',
    project_projectAudit_input_name: '请输入项目名称',
    project_projectAudit_type: '项目类型：',
    project_projectAudit_label_template: '标注模板：',
    project_projectAudit_choose: '选择',
    project_projectAudit_edit: '编辑',
    project_projectAudit_data_address: '数据存储地址',
    project_projectAudit_ftp: 'FTP服务器',
    project_projectAudit_ftp_address: '服务器地址：',
    project_projectAudit_userName: '用户名：',
    project_projectAudit_password: '密码：',
    project_projectAudit_upload_data: '上传数据',
    project_projectAudit_reject_desc: '确认拒绝该项目吗？',
    project_projectAudit_sure: '确定',
    project_projectAudit_file_name: '文件名',
    project_projectAudit_upload_time: '上传时间',
    project_projectAudit_file_size: '文件大小',
    project_projectAudit_choose_type: '请选择项目类型',
    project_stepSetting_end: '项目结束',
    project_own_team: '自有团队',
    project_own_team_desc: '提供自定义标注模板、工作流程的项目配置，拆分项目工作分配给自由团队',
    project_beisai_center: '倍赛数据运营中心',
    project_beisai_center_desc: '由专业的项目经理及数据处理团队负责对接您需求，保证数据质量',
    project_batch_allocation: '批次分配方式',
    project_batches_num: '项目批次数',
    project_ftp_normal: 'FTP正常',
    project_ftp_error: 'FTP异常， ',

    project_select_all: '全选',
    project_config_options: '配置项',
    project_no_escape: '不转义',
    project_no_filter: '不过滤',
    project_delete_null_result: '去除空结果',
    project_time: '时间',
    project_start_time: '开始时间',
    project_end_time: '结束时间',
    project_data_packages: '打包数据',
    project_download_history: '下载历史',
    project_batch_download_package_results: '批量下载打包结果',
    project_Batch_download_detection_information: '批量下载检测信息',
    project_refresh: '刷新',
    project_result: '结果',
    project_batch: '批次',
    project_batch_id: '批次ID',
    project_batch_name: '批次名称',
    project_step: '工序',
    project_file_format: '文件格式',
    project_status: '状态',
    project_packaging_progress: '打包进度',
    project_successful_jobs_packaged: '打包成功作业数',
    project_failed_jobs_packaged: '打包失败作业数',
    project_create_time: '创建时间',
    project_start_packing_time: '开始打包时间',
    project_wrap_up_time: '打包结束时间',
    project_download: '下载',
    project_pack_result: '打包结果',
    project_testing_information: '检测信息',
    project_select_atleast_one_batch: '至少选择一个批次',
    project_select_steps: '请选择工序',
    project_select_file_format: '请选择文件格式',
    project_select_configuration: '请选择配置项',
    project_submit_successful_wait_results: '提交成功,等待生成结果',
    project_check_starting_ending_time: '请核实起止时间！',
    project_check_starting_time: '请核实开始时间！',
    project_check_ending_time: '请核实结束时间！',
    project_input_batch_name_id: '输入数据名称',
    project_search: '搜索',
    project_sort: '排序',
    project_batch_status: '批次状态',
    project_total_work: '作业总量',
    project_update_time: '更新时间',
    project_total_project_operation: '项目作业总量',
    project_batch_numbers: '批次数',
    project_step_number: '工序数',
    project_task_number: '任务数',
    project_participants: '参与人数',
    project_cumulative_working_hours: '累计工时',
    project_project_schedule: '批次进度',
    project_process_id: '工序ID',
    project_task_id: '任务ID',
    project_task_names: '任务名',
    project_number_executions: '执行数',
    project_number_jobs: '作业总数',
    project_execute: '执行',
    project_upload_data: '上传数据',
    project_ftp: 'FTP服务器',
    project_username: '用户名',
    project_password: '密码',
    project_data_file_path: '数据文件路径',
    project_attachment_path: '附件路径',
    project_project_template: '项目模板',
    project_file_name: '文件名',
    project_file_size: '文件大小',
    project_upload_time: '上传时间',
    project_no_description: '暂无描述',
    project_teams: ' (团队)',
    project_crowdsourcings: ' (众包)',
    project_ais: ' (AI模型)',
    project_audit: '审核',
    project_qc: '质检',
    project_acceptance_check: '验收',
    project_export_data: '导出数据',
    project_total_number_workers: '工人总数',
    project_total_time: '作业总时间(s)',
    project_total_number_jobs: '作业总张数',
    project_total_marks_jobs: '作业总标注数',
    project_total_points_jobs: '作业总点数',
    project_account: '账号',
    project_belongs_team: '所属小组',
    project_jobs_numbers: '作业张数',
    project_jobs_boxes: '作业框数',
    project_jobs_points: '作业点数',
    project_correct: '正确率',
    project_operation_time: '作业时长(s)',
    project_operation_average_time: '作业平均时长(s)',
    project_number_rejections: '被驳回次数',
    project_search_content: '输入查找内容',
    project_task_name: '任务名称',
    project_distribution_platform: '分配平台',
    project_process_type: '工序类型',
    project_fields_explain: '字段说明',
    project_unit_s: '单位： 秒( s )',
    project_exexute_numbers: '执行张数',
    project_execute_boxes: '执行框数',
    project_execute_points: '执行点数',
    project_search_project_nameid: '输入项目名称、ID',
    project_close: '关闭',
    project_founder: '创建人',
    project_name: '项目名称',
    project_type: '类型',
    project_operation: '操作',
    project_batch_list: '批次列表',
    project_project_performance: '项目绩效',
    project_download_result: '下载结果',
    project_releasing: '发布中',
    project_auditing: '审核中',
    project_doing: '作业中',
    project_paused: '已暂停',
    project_complete: '完成',

    project_detection_successful: '检测成功',
    project_join_time: '加入时间',
    project_check_details: '查看详情',
    project_item_status: '项目状态',
    project_item_type: '项目类型',
    project_belongs_tenant: '所属组织',
    project_view: '查看',
    project_belonged_project: '所属项目',
    project_pack_status: '打包状态',
    project_unpack_status: '解包状态',
    project_pack_progress: '打包进度',
    project_unpack_progress: '解包进度',
    project_pack_succ: '打包成功',
    project_pack_fail: '打包失败',
    project_pack_succ_num: '打包成功数',
    project_pack_fail_num: '打包失败数',
    project_pack_stop: '停止打包',
    project_pack_restart: '重新打包',
    project_pack_stop_confirm: '确认停止打包吗？',
    project_pack_restart_confirm: '确认重新打包吗？',
    project_pack_record: '打包结束记录',
    project_pack_sticky: '置顶/取消置顶',
    project_pack_refresh: '刷新列表',
    project_result_name: '结果名称',

    project_step_name_progress: '项目内数据在 {name} 工序的总进度',
    project_correct_calcu_instruction: '正确率计算说明',
    project_correct_calcu_desc: '正确率：被通过数 / （被通过数 + 被驳回数 + 被重置数）',

    project_list_type:'项目类型',
    project_tag:'项目标签',
    project_batch_count:'批次个数',
    project_data_count:'数据总量',
    project_join_user_count: '参与人数',
    project_create_tab_info:'项目信息',
    project_create_tab_data:'选择数据',
    project_create_tab_batch:'设置批次',
    project_create_tab_procedure:'设置工序',
    project_create_tab_personnel:'分配人员',

    project_create_info_name:'项目名称',
    project_create_info_date:'项目截止时间',
    project_create_info_person:'项目负责人',
    project_create_info_careful:'注意事项',
    project_create_info_file:'项目需求文件',
    project_create_info_file_btn:'上传附件',
    project_create_info_file_message:'上传说明',
    project_create_info_rule:'规则模版',
    project_create_info_next:'下一步',
    project_create_save:'保存并退出',
    project_create_pre:'上一步',
    project_create_info_rule_one:'后端注意事项',
    project_create_info_rule_two:'规则',
    project_create_info_name_tip:'请输入项目名称',
    project_create_info_date_tip:'请选择项目截止时间',
    project_create_info_person_tip:'请选择项目负责人',
    project_create_info_careful_tip:'请输入注意事项',
    project_create_info_file_tip:'请上传项目需求文件',
    project_create_info_file_tip_num:'最多只能上传5个文件',

    project_create_data_upload_btn:'上传数据',
    project_create_data_search:'搜索',
    project_create_data_input:'请输入关键字，如数据名称、ID、数据标签',
    project_create_data_table_id:'数据ID',
    project_create_data_table_name:'数据集名称',
    project_create_data_table_num:'数据量',
    project_create_data_table_user:'发布者',
    project_create_data_table_date:'上传时间',
    project_create_data_table_op:'操作',
    project_create_data_table_check:'预览',
    project_create_data_table_dele:'删除',
    project_create_data_table_select_tip:'请选择数据',
    project_create_data_table_select_length_tip:'仅可选择一项',

    project_create_batch_table_num:'编号',
    // project_create_batch_table_task:'工作任务',
    // project_create_batch_table_skill:'技能集',
    // project_create_batch_table_knowledge:'知识点',
    project_create_batch_table_total:'数据总量',

    project_create_procedure_procedure:'工序',
    project_create_procedure_produce:'执行',
    project_create_procedure_audit:'审核',
    project_create_procedure_check:'质检',
    project_create_procedure_acceptance:'验收',


    project_create_personnel_batch:'批量设置',
    project_create_personnel_publish:'发布项目',
    project_create_personnel_select:'选择字段',
    project_create_personnel_date:'开始时间-截止时间',
    project_create_personnel_cancel:'取消',
    project_create_personnel_confirm:'确定',
    project_create_personnel_modal_tip:'请选择正确数据',
    project_create_personnel_batch_name:'批次名称',
    project_create_personnel_batch_num:'数量',
    project_create_personnel_batch_set:'设置',
    project_create_personnel_batch_update:'更新',
    project_create_personnel_receive_count:'单次任务个数',
    project_create_personnel_receive_expire:'单次任务时间',

    project_publish_change:'更改配置',
    project_publish_continue:'继续发布',

    project_batch_progress:'进度',

    project_detail_data:'项目数据',
    project_detail_stat:'项目绩效',
    project_detail_export:'结果导出',
    project_detail_op:'操作记录',

    project_detail_data_id:'数据ID',
    project_detail_data_id_placeholder:'请输入数据ID',
    project_detail_data_name:'数据名称',
    project_detail_data_name_placeholder:'请输入数据名称',
    project_detail_data_batch:'批次',
    project_detail_data_batch_placeholder:'请选择批次',
    project_detail_data_date:'更新时间',
    project_detail_data_date_placeholder:'请选择更新时间',
    project_detail_data_work_result:'作业结果',
    project_detail_data_op_record:'操作记录',
    project_detail_data_reset:'重置',
    project_detail_data_search:'查询',
    project_detail_data_batch_id:'批次ID',
    project_detail_data_batch_name:'批次名称',
    project_detail_data_sort:'顺序',
    project_detail_data_process:'作业进度',
    project_detail_data_date:'更新时间',
    project_detail_data_check:'查看',
    project_detail_data_record:'操作记录',
    project_detail_data_no_record:'暂无操作记录',
    project_detail_data_result:'作业结果',
    project_detail_data_no_result:'数据暂无结果',

    project_detail_export_batch:'选择下载批次',
    project_detail_export_all:'全选',
    project_detail_export_step:'选择下载工序',
    project_detail_export_script:'选择下载打包脚本',
    project_detail_export_config:'配置项',
    project_detail_export_pack_type:'打包类型',
    project_detail_export_confirm_pack:'生成下载文件',
    project_detail_export_export_list:'下载列表',
    project_detail_export_pack_id:'打包ID',
    project_detail_export_pack_script:'打包脚本',
    project_detail_export_batch:'批次',
    project_detail_export_status:'状态',
    project_detail_export_file_name:'文件名',
    project_detail_export_export:'下载',
    project_detail_export_one_pack:'一个包（包含多个批次）',
    project_detail_export_packs:'多个包（一个批次一个包）',
    project_detail_export_select_batch:'请选择下载批次',

    project_detail_info_batch_num:'批次个数',
    project_detail_info_step_num:'工序个数',
    project_detail_info_work_num:'作业总量',
    project_detail_info_work_person:'作业人数',
    project_detail_info_flow:'流程示意图',
    project_detail_info_attention:'注意事项',





};
