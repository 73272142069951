import {request} from '@/utils/request.js'

//阿里考试码列表
const datasetCertCodeList = (data) => {
    return request({
        url: '/dataset-cert-code/list',
        method: 'post',
        data
    })
}

//阿里考试码分发列表
const datasetCertCodeDispenseList = (data) => {
    return request({
        url: '/dataset-cert-code/dispense-list',
        method: 'post',
        data
    })
}

//导入上传文件
const importUploadfile = (data) => {
    return request({
        url: '/dataset-cert-code/import-uploadfile',
        method: 'post',
        data
    })
}

//按激活码设置分销商
const setDistributors = (data) => {
    return request({
        url: '/dataset-cert-code/set-distributors',
        method: 'post',
        data
    })
}

//按激活码设置分销商
const distribution = (data) => {
    return request({
        url: '/dataset-cert-code/distribution',
        method: 'post',
        data
    })
}

//激活码导出
const download = (data) => {
    return request({
        url: '/dataset-cert-code/download',
        method: 'post',
        data
    })
}


export default {
    datasetCertCodeList,
    datasetCertCodeDispenseList,
    importUploadfile,
    setDistributors,
    distribution,
    download
}
