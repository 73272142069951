export default {
    audio_play: 'Play',
    audio_pause: 'Pause',
    audio_zoomIn: 'ZoomIn',
    audio_zoomOut: 'ZoomOut',
    audio_autoPlay_on_selected: 'Auto play when selected',
    audio_play_in_loop: 'Loop',
    audio_result_counter: 'Result Counter',
    audio_clear_result: 'Clear all',
    audio_segment_time: 'time',
    audio_segment_valid: 'validity',
    audio_segment_text: 'Transcription',
    audio_segment_attr: 'attribute',
};
