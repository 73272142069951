import { render, staticRenderFns } from "./studentHome.vue?vue&type=template&id=b386212e&scoped=true&"
import script from "./studentHome.vue?vue&type=script&lang=js&"
export * from "./studentHome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b386212e",
  null
  
)

export default component.exports