import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/layout/Home.vue'
import StudentHome from '../views/layout/studentHome.vue'
import TeacherHome from '../views/layout/teacherHome.vue'
import ManagerHome from '../views/layout/managerHome.vue'
import ContentManagerHome from '../views/layout/contentManagerHome.vue'
import ContentProductionHome from '../views/layout/contentProductionHome.vue'
import PlatformHome from '../views/layout/platformHome.vue'
import { i18n } from '@/locale';
import Cookies from 'js-cookie';
import constant from '@/utils/constant.js'

import store from '../store/index'
var i18nLang = new Vue({i18n})

Vue.use(VueRouter)

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

import CertRoute from './certRoute';
import ExamineRoute from './examineRoute';
import ExamineTeacherRoute from './examineTeacherRoute';
import CourseRoute from './courseRoute';
import CourseTeacherRoute from './courseTeacherRoute';
import ExperimentRoute from './experimentRoute';
// import InfoStudentRoute from './infoStudentRoute';
import InfoTeacherRoute from './infoTeacherRoute';
import InfoManagerRoute from './infoManagerRoute';
import contentProduceRoute from './contentProduceRoute';
import contentManageRoute from './contentManageRoute';
import platformRoute from './platformRoute';
// console.log(CertRoute,'CertRouteCertRoute')

export const routes = [
  {
    path: '/',
    name: 'Home',
    // redirect: '/home',
    component: Home,
    children:[
      CertRoute,
      CourseRoute,
      CourseTeacherRoute,
      ExamineRoute,
      ExamineTeacherRoute,
      ExperimentRoute,
      // InfoStudentRoute,
      InfoTeacherRoute,
      InfoManagerRoute,
      contentProduceRoute,
      contentManageRoute,
      platformRoute,
      // {
      //   path: '/system/chatgpt',
      //   redirect: '/system/chatgpt',
      //   name: 'system-chatgpt-module',
      //   meta:{
      //     title:'Chat GPT',
      //     module_route:'module_chatgpt'
      //   },
      //   component:StudentHome,
      //   children:[
      //     {
      //       path: '/system/chatgpt',
      //       route:'system/chatgpt',
      //       name: 'system-chatgpt',
      //       meta:{
      //         title:'chatGPT',
      //         module_route:'module_chatgpt',
      //         img:require('@/assets/images/new_icon/GPT.png'),
      //         requiresAuth:true,
      //       },
      //       component: ()=>import('../views/system/chatGpt.vue'),
      //     },
      //     {
      //       path: '/system/chatgpt-exclusive-course',
      //       route:'system/chatgpt',
      //       name: 'system-chatgpt',
      //       meta:{
      //         title:'课程专属智能问答',
      //         module_route:'module_chatgpt',
      //         img:require('@/assets/images/new_icon/GPT.png'),
      //         requiresAuth:true,
      //       },
      //       component: ()=>import('../views/system/chatGPTexclusiveCourse.vue'),
      //     }
      //   ]
      // }
    ]
  },
  {
    path: '/myinfo/:tab?',
    name: 'myinfo',
    meta:{
      title:'router_myinfo',
      requiresAuth:true,
    },
    component: ()=>import('../views/user/myinfo.vue'),
  },
  // 直播
  {
    path:"/liveroom-detail",
    route:'liveroom-detail',
    name: 'teacher/liveroom-detail',
    meta:{
      title:'classroom_live_streaming',
      // module_route:'module_course_teacher',
      requiresAuth:true,
    },
    component: ()=>import('../views/live/liveRoomDetail.vue'),
  },
  // 在线编程
  {
    path:"/program",
    route:"program",
    name:"program",
    meta:{
      title:"online_programming",
      requiresAuth:true,
      // module_route:'module_experiment',
    },
    component:()=>import("../views/trainingcamp/programView.vue")
  },
  // 虚拟仿真实验
  {
    path:"/virtual",
    route:"virtual",
    name:"virtual",
    meta:{
      title:"menu_experiment_virtual",
      requiresAuth:true,
      // module_route:'module_experiment',
    },
    component:()=>import("../views/trainingcamp/virtualExperiment.vue")
  },
  // 虚拟仿真实验
  {
    path:"/aliyun",
    route:"aliyun",
    name:"aliyun",
    meta:{
      title:"menu_experiment_aliyun",
      requiresAuth:true,
      // module_route:'module_experiment',
    },
    component:()=>import("../views/trainingcamp/aliyunExperiment.vue")
  },
  {
    path: '/practical-experiment',
    route: 'practical-experiment',
    name: 'practical-experiment',
    meta:{
      title:'router_trainingcamp_practical_experiment',
      // module_route:'module_experiment',
      img:require('@/assets/images/new_icon/collect.png'),
      requiresAuth:true,
    },
    component: ()=>import('../views/trainingcamp/practicalExperiment.vue'),
  },
  {
    path:"/flowchart",
    name:"flowchart",
    route:'flowchart',
    meta:{
      title:'online_experiments',
      // module_route:'module_experiment',
      requiresAuth:true,
    },
    component: ()=>import('../views/experiment/flowChart.vue'),
  },
  {
    path: '/login',
    name: 'login',
    meta:{
      title:'router_login',
      requiresAuth:false,
    },
    component: ()=>import('../views/guest/login.vue'),
  },
  {
    path: '/home',
    name: 'home',
    meta:{
      // title:'router_home',
      requiresAuth:false,
    },
    component: ()=>import('../views/guest/home.vue'),
  },
  {
    path: '/quicklogin/:access_token',
    name: 'quicklogin',
    meta:{
      title:'router_quicklogin',
      requiresAuth:false,
    },
    component: ()=>import('../views/guest/quicklogin.vue'),
  },
  {
    path: '/register/:id?',
    name: 'register',
    meta:{
      title:'router_register',
      requiresAuth:false,
    },
    component: ()=>import('../views/guest/register.vue'),
  },
  {
    path: '/registersite',
    name: 'registersite',
    meta:{
      title:'router_registersite',
      requiresAuth:false,
    },
    component: ()=>import('../views/guest/registersite.vue'),
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    meta:{
      title:'router_forget_password',
      requiresAuth:false,
    },
    component: ()=>import('../views/guest/forget-password.vue'),
  },
  {
    path: '/wx-help',
    name: 'wx-help',
    meta:{
      title:'router_wx_help',
      requiresAuth:false,
    },
    component: ()=>import('../views/wxmini/help.vue'),
  },
  {
    path: '/exam',
    name: '/exam',
    meta:{
      title:'考试',
      requiresAuth:true,
    },
    component: ()=>import('../views/trainingcamp/stuExam.vue'),
  },
  // 直播
  {
    path:"/liveroom-detail",
    route:'liveroom-detail',
    name: 'liveroom-detail',
    meta:{
        title:'classroom_live_streaming',
        requiresAuth:true,
        // module_route:'module_course',
    },
    component: ()=>import('../views/live/liveRoomDetail.vue'),
  },
  // 直播
  {
    path:"/teacher/liveroom-detail",
    route:'liveroom-detail',
    name: 'teacher/liveroom-detail',
    meta:{
        title:'classroom_live_streaming',
        module_route:'module_course_teacher',
        requiresAuth:true,
    },
    component: ()=>import('../views/live/liveRoomDetail.vue'),
  },
  {
    path: '*',
    redirect:'/'
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]
// console.log(routes,'routesroutesroutesroutes')
const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach((to,form,next)=>{
  // if(to.meta.title){
  //   document.title = i18nLang.$t(to.meta.title);
  // }
  // console.log(to.fullPath,'to.fullPathto.fullPathto.fullPath')
  // console.log(to.meta.module_route ,'to.meta.module_route to.meta.module_route ')
  if(to.meta.module_route && to.meta.module_route != store.state.app.curModule){
    let arr = to.meta.module_route.split(',');
    let status = arr.every((item)=>{
      return item != store.state.app.curModule;
    })
    if(status && store.state.app.curModule){
      store.commit('changeModule', {menu:store.state.user.userInfo.auth_menus,all:store.state.app.menuListAll,curModule:to.meta.module_route});

    }
    let status1 = arr.every((item)=>{
      return item != store.state.app.curModule;
    })
    if(status1){
      store.commit('changeBreadCrumbList',[])
    }

  }
  router.app.$options.store.commit('formatBreadCrumbList',to);
  store.commit('setCurPath',to.path)



  if(!Cookies.get(constant.tokenName) && to.meta.requiresAuth){
    if(to.path ==='/login' ){
      next();

    }else{
      next({path:`/login?from=${to.fullPath}`})
    }
  }else{
    next();
  }
  if(Cookies.get(constant.tokenName) && to.meta.requiresAuth && !(to.path == '/course/interactExam' && !to.query.isResult) && !(to.path == '/trainingcamp/exam' && !to.query.isResult)){
    store.commit('changeLoginStatus',true);
  }else{
    store.commit('changeLoginStatus',false);
  }


});

export default router
