import ContentManagerHome from '../views/layout/contentManagerHome.vue'
const contentManageRoute = {
    path: '/content_manager',
    redirect: '/home_content_manager',
    name: 'content_manager',
    meta:{
      title:'内容管理',
      module_route:'module_content_manager'
    },
    component:ContentManagerHome,
    children:[
      {
        path: '/home_content_manager',
        route:'home_content_manager',
        name: 'home_content_manager',
        meta:{
          title:'router_home',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/home/homeContentManager.vue'),
      },
      {
        path: '/dataset/category',
        route:'dataset/category',
        name: 'dataset/category',
        meta:{
          title:'router_dataset_category',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/category.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/category.vue'),
      },
      {
        path: '/dataset/category-detail/:tab?/:id?',
        route:'dataset/category-detail',
        name: 'category-detail',
        meta:{
          title:'menu_trainingcamp_category_detail',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/categoryDetail.vue'),
      },
      {
        path: '/dataset/category-verify',
        route:'dataset/category-verify',
        name: 'category-verify',
        meta:{
          title:'分类审核',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/categoryVerify.vue'),
      },
      {
        path: '/dataset/category-data-verify',
        route:'dataset/category-data-verify',
        name: 'category-data-verify',
        meta:{
          title:'分类数据审核',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/categoryDataVerify.vue'),
      },
      {
        path: '/dataset/map',
        route:'dataset/map',
        name: 'dataset/map',
        meta:{
          title:'router_dataset_map',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/map.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/map.vue'),
      },
      {
        path: '/dataset/map-detail/:tab?/:id?',
        route:'dataset/map-detail',
        name: 'map-detail',
        meta:{
          title:'分类详情',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/mapDetail.vue'),
      },
      {
        path: '/dataset/map-aitrain-list/:tab?/:id?',
        route:'dataset/map-aitrain-list',
        name: 'map-aitrain-list',
        meta:{
          title:'训练考点',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/mapAiTrainDataList.vue'),
      },
      {
        path: '/dataset/map-aitrain-datalist/:tab?/:id?',
        route:'dataset/map-aitrain-datalist',
        name: 'map-aitrain-data-list',
        meta:{
          title:'AI训练结果列表',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/mapAiTrainDataList.vue'),
      },
      {
        path: '/dataset/map-aigenerate-list',
        route:'dataset/map-aigenerate-list',
        name: 'map-aigenerate-list',
        meta:{
          title:'生成考点',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/mapAiGenerateList.vue'),
      },
      {
        path: '/dataset/map-aigenerate-keypointlist/:tab?/:id?',
        route:'dataset/map-aigenerate-keypointlist',
        name: 'map-aigenerate-data-list',
        meta:{
          title:'生成考点结果列表',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/mapAiGenerateKeypointList.vue'),
      },
      {
        path: '/dataset/map-aimatch-list/:tab?/:id?',
        route:'dataset/map-aimatch-list',
        name: 'map-aimatch-list',
        meta:{
          title:'匹配考点',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/mapAiMatchKeypointList.vue'),
      },
      {
        path: '/dataset/map-aimatch-keypointlist/:tab?/:id?',
        route:'dataset/map-aimatch-keypointlist',
        name: 'map-aimatch-data-list',
        meta:{
          title:'AI匹配结果列表',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/mapAiMatchKeypointList.vue'),
      },
      {
        path: '/dataset/node',
        route:'dataset/node',
        name: 'dataset/node',
        meta:{
          title:'router_dataset_node',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/node.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/node.vue'),
      },
      {
        path: '/dataset/label',
        route:'dataset/label',
        name: 'dataset/label',
        meta:{
          title:'router_dataset_label',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/key_point.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/label.vue'),
      },
      {
        path: '/dataset/label-detail/:tab?/:id?',
        route:'dataset/label-detail',
        name: 'label-detail',
        meta:{
          title:'router_dataset_label',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/labelDetail.vue'),
      },
      {
        path: '/dataset/data',
        route:'dataset/data',
        name: 'dataset/data',
        meta:{
          title:'router_dataset_data',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/data.vue'),
      },
      {
        path: '/dataset/data-detail/:tab?/:id?',
        route:'dataset/data-detail',
        name: 'data-detail',
        meta:{
          title:'menu_trainingcamp_data_detail',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/dataDetail.vue'),
      },
      {
        path: '/dataset/datacorrection',
        route:'dataset/datacorrection',
        name: 'dataset/datacorrection',
        meta:{
          title:'router_dataset_data_correction',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/dataCorrection.vue'),
      },
      // 易错题列表
      {
        path: '/dataset/errorpronelist',
        route:'dataset/errorpronelist',
        name: 'dataset/errorpronelist',
        meta:{
          title:'易错题',
          module_route:'module_content_manager',
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/errorProneQuestionList.vue'),
      },
      {
        path: '/dataset/datacollect',
        route:'dataset/datacollect',
        name: 'dataset/datacollect',
        meta:{
          title:'router_dataset_data_collect',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/dataCollect.vue'),
      },
      {
        path: '/dataset/datarecords/:id?',
        route:'dataset/datarecords',
        name: 'dataset',
        meta:{
          title:'操作记录',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/datarecords.vue'),
      },
      {
        path: '/dataset/mapCreate',
        route:'dataset/mapCreate',
        name: 'map-create',
        meta:{
          title:'添加知识图谱',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/mapCreate.vue'),
      },
      {
        path: '/dataset/similar',
        route:'dataset/similar',
        name: 'similar',
        meta:{
          title:'相似题',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/similar.vue'),
      },
      {
        path: '/dataset/star-statistics',
        route:'dataset/star-statistics',
        name: 'star-statistics',
        meta:{
          title:'星级统计',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/starStatistics.vue'),
      },
      {
        path: '/dataset/data-search-record',
        route:'dataset/data-search-record',
        name: 'data-search-record',
        meta:{
          title:'搜索历史',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/dataSearchRecord.vue'),
      },
      {
        path: '/dataset/edit-data',
        route:'dataset/edit-data',
        name: 'dataset/edit-data',
        meta:{
          title:'menu_dataset_edit_data',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/editData.vue'),
      },
      {
        path: '/dataset/import-category-data',
        route:'dataset/import-category-data',
        name: 'import-category-data',
        meta:{
          title:'导入',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/importCategoryData.vue'),
      },
      // {
      //   path: '/dataset/evaluation',
      //   route:'dataset/evaluation',
      //   name: 'dataset/evaluation',
      //   meta:{
      //     title:'menu_dataset_evaluation',
      //     module_route:'module_content_manager',
      //     img:require('@/assets/images/new_icon/evaluation_manage.png'),
      //     requiresAuth:true,
      //   },
      //   component: ()=>import('../views/evaluation/manage.vue'),
      // },
      {
        path: 'dataset/evaluation-detail/:tab?/:id?',
        route:'dataset/evaluation-detail',
        name: 'evaluation-detail',
        meta:{
          title:'menu_dataset_evaluation_detail',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/evaluation/manageDetail.vue'),
      },
      {
        path: 'dataset/check-chart',
        route:'dataset/check-chart',
        name: 'check-chart',
        meta:{
          title:'menu_dataset_check_chart',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/evaluation/checkChart.vue'),
      },
      {
        path: '/trainingcamp/mygroup-create',
        route: 'trainingcamp/mygroup-create',
        name: 'trainingcamp_mygroup-create',
        meta:{
          title:'router_trainingcamp_mygroup_create',
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/my_group.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/trainingcamp/myGroupCreate.vue'),
      },
      {
        path:"/datasource-library",
        route:"datasource-library",
        name:"datasource-library",
        meta:{
          title:"resource_management",
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/my_group.png'),
          requiresAuth:true
        },
        component: ()=>import('../views/dataset/datasourceLibrary.vue')
      },
      {
        path:"/experiment-manage",
        route:"experiment-manage",
        name:"experiment-manage",
        meta:{
          title:"experimental_management",
          module_route:'module_content_manager',
          img:require('@/assets/images/new_icon/my_group.png'),
          requiresAuth:true
        },
        component:()=>import("../views/dataset/experimentalManage.vue")
      },
    ]
  };

  export default contentManageRoute;
