import {
    apiBase,
    staticBase,
    helpBase,
    wxAppid,
    wss,
    tokenName
} from '../router/init';

const CONSTANT = {
    URL:apiBase,
    STATIC_URL:staticBase,
    DOWNLOADFILE_URL:'/pack/download',
    APPID:wxAppid,
    REDIRECT_URI:helpBase,
    WSS:wss,
    tokenName:tokenName
};
export default CONSTANT;
