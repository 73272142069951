import {request} from '@/utils/request.js'

//租户列表
const siteList = (data) => {
    return request({
        url: '/site/list',
        method: 'post',
        data
    })
}
const siteDetail = (data) => {
    return request({
        url: '/site/detail',
        method: 'post',
        data
    })
}

const siteCreate = (data) => {
    return request({
        url: '/site/create',
        method: 'post',
        data
    })
}

const siteUpdate = (data) => {
    return request({
        url: '/site/update',
        method: 'post',
        data
    })
}
const siteDelete = (data) => {
    return request({
        url: '/site/delete',
        method: 'post',
        data
    })
}

const invitationList = (data) => {
    return request({
        url: '/invitation/list',
        method: 'post',
        data
    })
}
const invitationDetail = (data) => {
    return request({
        url: '/invitation/detail',
        method: 'post',
        data
    })
}

const invitationCreate = (data) => {
    return request({
        url: '/invitation/create',
        method: 'post',
        data
    })
}

const invitationUpdate = (data) => {
    return request({
        url: '/invitation/update',
        method: 'post',
        data
    })
}
const invitationDelete = (data) => {
    return request({
        url: '/invitation/delete',
        method: 'post',
        data
    })
}
//邀请链接表单
const invitationForm = (data) => {
    return request({
        url: '/invitation/form',
        method: 'post',
        data
    })
}

//启用邀请链接
const invitationStart = (data) => {
    return request({
        url: '/invitation/start-using',
        method: 'post',
        data
    })
}

//停用邀请链接
const invitationStop = (data) => {
    return request({
        url: '/invitation/stop-using',
        method: 'post',
        data
    })
}

//启用租户
const siteOpen = (data) => {
    return request({
        url: '/site/open',
        method: 'post',
        data
    })
}

//停用租户
const siteClose = (data) => {
    return request({
        url: '/site/close',
        method: 'post',
        data
    })
}


const siteForm = (data) => {
    return request({
        url: '/site/form',
        method: 'post',
        data
    })
}

//更新租户logo
const siteUpdateLogo = (data) => {
    return request({
        url: '/site/update-logo',
        method: 'post',
        data
    })
}

//地区列表
const placeList = (data) => {
    return request({
        url: '/place/list',
        method: 'post',
        data
    })
}

const siteStatByStar = (data) => {
    return request({
        url: '/site/stat-by-star-section',
        method: 'post',
        data
    })
}
const siteStatByProvince = (data) => {
    return request({
        url: '/site/stat-by-province',
        method: 'post',
        data
    })
}

//组织开通题库
const categoryToSiteDetail = (data) => {
    return request({
        url: '/dataset-category/to-site-detail',
        method: 'post',
        data
    })
}


export default {
    siteList,
    siteDetail,
    siteCreate,
    siteUpdate,
    siteDelete,
    invitationList,
    invitationDetail,
    invitationCreate,
    invitationUpdate,
    invitationDelete,
    invitationForm,
    invitationStart,
    invitationStop,
    siteOpen,
    siteClose,
    siteForm,
    siteUpdateLogo,
    placeList,
    siteStatByStar,
    siteStatByProvince,
    categoryToSiteDetail
}
