export default {
    tool_collection: 'Collection task',
    tool_external_links: 'External link',
    tool_picture_judgment: 'Image judgment',
    tool_batch_pass: 'Batch approval',
    tool_batch_rejection: 'Batch rejection',
    tool_bulk_reset: 'Batch reset',
    tool_batch_troublesome_work: 'Batch pending',
    tool_clear_and_exit: 'Clear and exit',
    tool_shortcuts: 'Shortcuts to view larger images',
    tool_approved: 'Approved',
    tool_reject: 'Reject',
    tool_reset: 'Reset',
    tool_previous: 'Previous',
    tool_next: 'Next',
    tool_view_mask: 'View mask graph',
    tool_view_original: 'View original image',
    tool_view_default: 'View mark graph (default)',
    tool_unlabeled_map: 'View mark graph (no label graph)',
    tool_space: 'Space',
    tool_modify: 'Modify',
    tool_no_remaining_jobs: 'No remaining jobs',
    tool_pass: 'Approve',
    tool_diffcult_job: 'Pend',
    tool_rejected: 'Rejected',
    tool_submit: 'Submit',
    tool_cancel: 'Cancel',
    tool_label_mode: 'Switch between annotation mode and adjustment mode',
    tool_two_modes: 'Submit, available in both modes',
    tool_curve: 'Select curve',
    tool_closed_curve: 'Select closed curve',
    tool_line: 'Select the line tool',
    tool_rectangle: 'Select rectangle tool',
    tool_polygon_frame: 'Select draw polygon box tool',
    tool_quadrilateral: 'Choose quadrilateral tools',
    tool_polyline: 'Select multi-segment tool',
    tool_cuboid: 'Select cuboid tool',
    tool_trapezoid: 'Select trapezoid tool',
    tool_triangle: 'Select triangle tool',
    tool_select_point: 'Select point tool',
    tool_auxiliary_line: 'Hide or show auxiliary line',
    tool_switch_label: 'Display or hide label',
    tool_press_lift: 'Hide all tags, press to hide and lift to show',
    tool_narrow_picture: 'Zoom out',
    tool_zoom_picture: 'Zoom in',
    tool_diaplasis: 'Reset',
    tool_tilt_left: 'Tilt towards left',
    tool_tilt_right: 'Tilt towards right',
    tool_greatly: 'Enhance',
    tool_angle_reset: 'Angle reset',
    tool_polygon_share_N: 'Press N in annotation mode to enable shared egdes for polygons',
    tool_side_share: 'Select which edge to share',
    tool_rect_size: 'Show or hidden rectangular size tip',
    tool_switch_mask: 'Switch mask picture',
    tool_cancel_mark: 'Canceling ongoing annotation',
    tool_left_mouse: 'Mouse left button',
    tool_key_point: 'Select a key point in adjustment mode',
    tool_delete_group: 'Highlight entire group - right click to delete entire group',
    tool_switch_picture: 'Select picture with up and down button ',
    tool_right_mouse: 'Mouse right button',
    tool_delete_selection_label: 'Delete selected label in modification mode',
    tool_delete_all_in_adjust_mode: 'Delete all labels in adjustment mode',
    tool_delete_all: 'Delete all',
    tool_fillreject_reason: 'Please enter rejection reason',
    tool_fillreset_reason: 'Please enter reset reason',
    tool_operate_tips: 'Operation tips',
    tool_exit_review: 'Do you want to clear claimed jobs and exit auditing?',
    tool_request_failed: 'Acquire job resource failed',
    tool_timed_out: 'Task timeout',
    tool_no_jobs: 'No job left',
    tool_no_job: 'No job left',
    tool_loading: 'Loading...',
    tool_error: 'Error occurred',
    tool_reject_empty: 'Rejection reason cannot be empty',
    tool_reset_empty: 'Reset reason cannot be empty',
    tool_reason_empty: 'The reason cannot be empty',
    tool_undone: 'Annotation unfinished',
    tool_result_empty: 'Result is empty',
    tool_required_item: 'Mandatory item must not be empty',
    tool_quit: 'Quit',
    tool_all_cancel: 'Cancel all',
    tool_all_select: 'Select all',
    tool_determine: 'Confirm',
    tool_original_image: 'Original image (E)',
    tool_enter: 'Enter (Enter)',
    tool_esc: 'Cancel (ESC)',
    tool_reason: 'Reason',
    tool_result: 'Result',
    tool_d: 'Approve (D)',
    tool_w: 'Reject (W)',
    tool_q: 'Reset (Q)',
    tool_modify_space: 'Modify (space)',
    tool_text_loading: 'Assignment text loading...',
    tool_filename: 'Filename',
    tool_created_time: 'Create date',
    tool_updated_time: 'Change date',
    tool_status: 'Status',
    tool_reject_reason: 'Rejection reason',
    tool_handle: 'Operation',
    tool_view: 'View',
    tool_failed: 'Request failed',
    tool_data_loading: 'Loading job data...',
    tool_support_video: 'Your browser does not support video play.',
    tool_play_pause: 'Play/Pause',
    tool_add_label: 'Add new label',
    tool_cancel_label: 'Cancel label',
    tool_right_arrow: 'Right arrow',
    tool_left_arrow: 'Left arrow',
    tool_next_frame: 'Next frame',
    tool_previous_frame: 'Last frame',
    tool_analyze: 'Load complete, analyzing',
    tool_loadings: 'Loading',
    tool_load_failed: 'Loading failed',
    tool_resource_failed: 'Assignment resource loading failed',
    tool_resource_failed_tips: "Incorrect original data. Please record job ID and process it following administrator's instruction",
    tool_result_list: 'Annotation result list',
    tool_Reasons_for_rejection_of_rework: 'Reason for revised job to be rejected',
    tool_request_picture_failed: 'Request picture failed',
    tool_play_video: 'Annotation imcompleted, please play the whole video at least once',
    tool_submit_success: 'Submitted successfully',
    tool_play_speed: 'Play speed',
    tool_total_time: 'Total duration',
    tool_h: 'Hours',
    tool_m: 'Minutes',
    tool_s: 'Seconds',
    tool_switch_label_modes: 'Switch modes of annotations and non-modes',
    tool_undo_label_result: 'Cancel the result of the last annotation',
    tool_copy_label_result: 'Copy selected annotation',
    tool_precision_rate: 'Accuracy',
    tool_filter: 'Filter',
    tool_close: 'Close',
    tool_rejection_accuracy: 'Are you sure you want to reject job with {type}{persentage} accuracy rate?',
    tool_think_again: 'Think again',
    tool_pass_accuracy: 'Are you sure you want to approve job with {type}{persentage} accuracy rate?',
    tool_gt: 'Greater than',
    tool_lt: 'Less than',
    tool_ge: 'Greater than or equal to',
    tool_le: 'Less than or equal to',
    tool_eq: 'Equals',
    tool_review_operator: 'Audit by operator',
    tool_review_order: 'Audit by Data Order',
    tool_review_type_order: 'Order',
    tool_review_type_random: 'Random',
    tool_default_all: 'Default all',
    tool_qc: 'Inspected',
    tool_percent_pass: "Qualification rate",
    tool_top_jobs: 'Previous {num} jobs',
    tool_last_jobs: 'Next {num} jobs',
    tool_job_information: 'Assignment information',
    tool_job_id: 'Assignment ID',
    tool_item_id: 'Project ID',
    tool_task_id: 'Task ID',
    tool_operator: 'Operator',
    tool_task_name: 'Task name',
    tool_job_file_name: 'Assignment file name',
    tool_done: 'Done',
    tool_surplus: 'Remaining',
    tool_overdue: 'Will be expired after',
    tool_task_timeout_alert: "Imminent task timeout alert",
    tool_timeout_rollback: 'The task will timeout after {num}, all the jobs that have been claimed will be returned',
    tool_timeout_refresh: 'Task timeout, refresh page to reclaim',
    tool_task_timeout: 'Task timeout alert',
    tool_email: 'Email',
    tool_form_result: 'Form result',
    tool_tagging_content: 'Annotation content',
    tool_label: 'Label',
    tool_text_point: 'Text location',
    tool_attribute: 'Attribute',
    tool_jmvt: 'Workload of this task',
    tool_number_job: 'Number of jobs',
    tool_mark_number: 'Number of annotations',
    tool_point: 'Number of points',
    tool_serial: 'Sequence number',
    tool_time: 'Time',
    tool_click_selected: 'Click to select',
    tool_view_reject: 'View rejection reason',
    tool_draw_point: 'Select draw point',
    tool_draw_line: 'Select to draw line',
    tool_draw_rec: 'Select draw rectangle box',
    tool_draw_polygons: 'Select draw polygon box',
    tool_draw_polyline: 'Select multiple segments',
    tool_check_before_after_jobs: 'View previous and next job',
    tool_submit_difficult_job: 'Pend',
    tool_submit_exit: 'Submit and exit',
    tool_submit_D: 'Submit (D)',
    tool_save_temp: 'Save',
    tool_saving_temp: 'Saving',
    tool_resource_not_picture: 'Assignment resource is not a picture',
    tool_submitexit_confirmation: 'Confirm to submit and exit',
    tool_exit_confirmation: "Confirm to exit",
    tool_submit_exit_description: 'You have {num} jobs that have not been executed, do you confirm to submit and exit? Once you exist, all unexecuted jobs will be returned to the public task pool (including rejected jobs).',
    tool_exit_description: 'You have {num} jobs that have not been executed, do you confirm to submit and exit? Once you exist, all unexecuted jobs will be returned to the public task pool (including rejected jobs).',
    tool_rotation_angle: 'Angle of rotation',
    tool_left_rotate: 'Rotate left',
    tool_right_rotate: 'Rotate right',
    tool_job_result: 'Assignment result',
    tool_drag: 'Drag tool available',
    tool_annotated_schema: 'Annotation mode',
    tool_not_annotated_schema: 'Non-annotation mode',
    tool_key_switch: 'Press X to switch',
    tool_edit: 'Edit',
    tool_delete: 'Delete',
    tool_asterisk_tag_group: 'Select at least one label from an asterisked label group',
    tool_tags_not_selected: 'Required tag not selected',
    tool_selection_not_tagged: 'The last selected text has not been tagged',
    tool_already_exist: 'Exists',
    tool_audio_loading_failed: 'Audio loading failure',
    tool_regional_broadcasting: 'Region broadcast',
    tool_shrink: 'Volume down',
    tool_amplification: 'Volume up',
    tool_play: "Play",
    tool_pause: 'Pause',
    tool_leave_content_lost: 'Are you sure you want to leave this page? Unsaved content may be lost.',
    tool_all_label_once: 'Indicates that the tag is used at least once in all annotations',
    tool_label_group_once: 'Indicates that at least one tag from the group is used in each annotation',
    tool_search_tag: 'Search tag',
    tool_tag_list: 'Tag list',
    tool_tag_group_locking: 'Tag group lock has been enabled, currently only tags from {num} can be used,',
    tool_select_file: 'Select file',
    tool_file_shown_deal_operator: 'Your project file will be shown here so the operator can process it',
    tool_text_shown_deal_operator: 'Your text will be displayed here so the operator can process it',
    tool_out_picture: 'Whether outside picture',
    tool_occluded: 'Whether covered or not',
    tool_back_previous_step: 'previous step',
    tool_back_step_one: 'Restart',
    tool_complete_key_points_current_group: 'Please complete drawing the key points of the current group',
    tool_schematic_key_points: 'Key points diagram',
    tool_currently_selected_object_tags: 'Number of tags of selected object',
    tool_mark_tabs: 'Mark graph (with tags)',
    tool_mark_tabs_m: 'Mark graph (with tags)(M)',
    tool_mark_no_tabs: 'Mark graph (without tags)',
    tool_mark_no_tabs_l: 'Mark graph (without tags)(L)',
    tool_mark: 'Mask graph',
    too_mark_fill: 'Mark graph(fill)',
    tool_mark_n: 'Mask graph (N)',
    tool_original: 'Original image',
    tool_not_support_video_playback: 'Your browser does not support video play.',
    tool_last_one_received_task: 'This is the last task of all the tasks claimed',
    tool_first_one_received_task: 'This is the first task of all the tasks claimed',
    tool_rejected_quality_inspection: 'Rejected',
    tool_return: 'Return',
    tool_label_group_title: 'Tag group title (click to filter tags)',
    tool_selected_categories_labels: 'Select tags for categories (click to select tags)',
    tool_enter_confirm: 'Confirm by pressing Enter',
    tool_pictures_show: 'Show picture',
    tool_error_task_reason_fill_tips: 'Enter reason',
    tool_error_task_reason_fill_placeholder: 'P;ease enter reason for error',
    tool_new_version_has_release: 'A new version has been released',
    tool_version_update_tips: 'Click here to update',
    tool_fill_parse_error: 'File parsing failed',
    tool_request_timeout: 'Request timeout',
    tool_request_error: 'Network exception, please try again later',
    tool_video_frame: ' Frame',
    tool_video_pre_frame: 'Pre Frame',
    tool_video_next_frame: 'Next Frame',
    tool_video_play_pause: 'Play|Pause',
    tool_video_add_item: 'New',
    tool_video_cancel_item: 'Cancel',
    tool_video_help_line: 'guide Line',
    tool_video_capture_finish: 'Extraction completed, in total ',
    tool_video_video_size: 'Video Size',
    tool_video_item_visible: 'shown?',
    tool_video_item_del: 'Remove',
    tool_video_play_progress: 'Progress',
    tool_video_play_speed: 'Speed',
    tool_single_view: 'Single view',

    tool_audio_right_view_results: '请点击右侧 "预览" 查看采集结果...',
    tool_audio_support_audio: '您的浏览器不支持 audio 标签。',
    tool_audio_click_view: '点击查看',
    tool_analy_tip_desc: '多人拟合正确率=重复的字段/全部字段  （全部字段包括被标注内容、标签、文本位置及标注属性）',
    tool_preview: '预览',
    tool_collection_information_ID: '采集信息ID',
    tool_marks_fill: 'Mark image (fill)',
    tool_frame_20: '每秒播放帧数 (1-20的数字)',
    tool_view_job_result: 'View job results',
    tool_auditing: 'Auditing',

};
