export default {
    cloud_create: 'Create',
    cloud_totate: 'Rotate',
    cloud_delete: 'Delete',
    cloud_delete_all: 'Delete all',
    cloud_angle: 'Viewing angle',
    cloud_rect_current: 'Rectangular(current)',
    cloud_rect_all: 'Rectangular(all)',
    cloud_close: 'Close',
    cloud_view: 'View',
    cloud_adjust: 'Adjust',
    cloud_reset: 'Reset',
    cloud_edit: 'Edit',
    cloud_add: 'Add',
    cloud_subtract: 'Subtract',
    cloud_cancel: 'Cancel',
    cloud_complete: 'Complete',
    cloud_check: 'Check',
    cloud_move: 'Move',
    cloud_line: 'Path line',
    cloud_delete_current_frame: 'Delete all(current frame)',
    cloud_delete_current_frame_text: 'Are you sure you want to delete all of the object in the current frame? ',

    cloud_delete_all_frame: 'Delete(all frame)',
    cloud_delete_all_frame_text: 'The job result can not be recovered, are you sure you want to clear all of the annotation result of the entire job?',

    cloud_delete_rect_dom_now: 'delete rectangle(current)',
    cloud_delete_rect_dom_all: 'delete rectangle(all)',
    copy_result_to_next_frame: 'copy result to next frame',

    delete_mesh_all_frame: 'delete (all frame)',
    delete_mesh_all_frame_text: "Are you sure you want to delete annotation result of current object in all frame ? ",

    cloud_ok: "OK",

    cloud_leak_mark: 'omitted annotation',
    cloud_all_marked: 'annotated all',
    cloud_lw: 'length: X, width: X',
    cloud_lh: 'length: X, height: X',
    cloud_wh: 'width: X, height: X',

    cloud_unit: 'M',
    cloud_item_label: "Label:",
    cloud_item_width: "Width:",
    cloud_item_height: "Height:",
    cloud_item_deep: "Deep:",
    cloud_item_position:"position:",

    cloud_ai_create:"ai create",

    delete_data_text: "Are you sure you want to delete the current data?",
    delete_data_all_text: "Are you sure you want to delete all of the data?",

    show_one: "single display",

    cloud_visibility:"visibility",

    cloud_all:"all",
    cloud_inside:"inside",
    cloud_outside:"outside",

    cloud_point_size:"point",
    cloud_small:"S",
    cloud_middle:"M",
    cloud_large:"L",

    cloud_full:"full screen",

    cloud_drawing_mode:"Drawing mode",
    cloud_brush:"brush",
    cloud_round:"round",
    cloud_square:"square",

    cloud_ground_offset:"Ground offset:",

    cloud_toggle_2D_images:"toggle 2D images:",

    cloud_lock_labeled_points:"lock annotated points",

    cloud_empty_points_message1:"Too many blank points at present, no need to detect",
    cloud_empty_points_message2:",No detection is required"
};
