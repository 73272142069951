<template>
<div class="head-main">
  <div class="head-main-l">
    <img class="head-main-logo" v-if="logoImage" :src="logoImage" height="100%">
<!--    <img v-else src="../../../assets/images/login/logo_h.jpg" height="100%">-->
    <el-tooltip effect="dark" :content="$store.state.user.userInfo.site && $store.state.user.userInfo.site.name" placement="bottom">
      <p>{{$store.state.user.userInfo.site && $store.state.user.userInfo.site.name}}</p>
    </el-tooltip>
    <Poptip trigger="hover" placement="bottom-start">
      <div class="switching-organizations">切换组织<img src="../../../assets/images/new_icon/switch.png" alt=""></div>
      <div class="switch-content" slot="content">
        <div class="title">请选择切换的组织</div>
        <div class="sub-title">各组织数据独立，切换组织数据不同步</div>
        <div class="zz-list">
          <div class="item-list" :class="userInfo?.site?.id===item?.site_id?'currentzz':''" v-for="item in organizationList" :key="item.id">
            <div class="left">
              <div class="item-title">{{ item.site.name }}</div>
              <div class="item-id">ID: {{ item.site_id }}</div>
            </div>
            <div class="right">
              <div class="btn" v-if="userInfo?.site?.id===item?.site_id">当前组织</div>
              <div class="btn-switch" v-else @click="switchSite(item)">切换组织</div>
            </div>
          </div>
        </div>
      </div>
    </Poptip>
  </div>



  <!-- <div class="header-middle-con">
    <notice-board></notice-board>
  </div> -->
  <div class="header-avator-con">
    <div v-if="!noMenu">
      <p class="module-menu" :class="curModule == item.meta.module_route ? 'active' : ''" v-for="item in userModuleList" v-show="showMenu(item)" :key="item.meta.module_route" @click="changeModule(item)">
        {{ item.meta.title }}
      </p>
<!--      <p class="module-menu" @click="goExperiment">云起实验室</p>-->
      <p class="module-menu" v-if="menuAuth.indexOf('module_experiment') > -1"  @click="goYgkw">云工开物</p>
    </div>

    <p class="help-head" v-if="menuAuth.indexOf('module_help') > -1">
      <a :href="$store.state.app.settings.help_url" target="_blank"><img src="../../../assets/images/new_icon/help_icon.png" width="22" height="22" /></a>
    </p>
    <div class="user-dropdown-menu-con" style="float:right;">
      <Row type="flex" justify="end" align="middle" class="user-dropdown-innercon">
<!--        <Dropdown transfer trigger="click" @on-click="handleClickUserDropdown" placement="bottom-end">-->
<!--          <a href="javascript:void(0)" class="name_id">-->
<!--            <span class="main-user-name">{{ userInfo.nickname }} </span><span>(ID:{{userInfo.id}})</span>-->
<!--            <Icon type="md-arrow-dropdown" />-->
<!--          </a>-->
<!--          <DropdownMenu slot="list">-->
<!--            <DropdownItem name="ownSpace"><router-link to="/myinfo">{{ $t('common_user_ownspace') }}</router-link></DropdownItem>-->
<!--            <DropdownItem name="loginout" divided>{{ $t('common_user_quit') }}</DropdownItem>-->
<!--          </DropdownMenu>-->
<!--        </Dropdown>-->
        <Poptip trigger="hover" placement="bottom-end">
          <Avatar
              :src="userInfo.avatar ? getUserAvatar(userInfo.avatar) : ''"
              :class="!userInfo.avatar ? 'no-avatar': ''"
              style="margin-left: 6px;"
              v-if="userInfo.avatar"
              >
          </Avatar>
          <img v-else style="width: 40px;height: 40px;border-radius: 40px;" src="../../../assets/images/new_icon/head_img.png" />
          <div slot="content">
            <div class="user-info">
              <Avatar
                  :src="userInfo.avatar ? getUserAvatar(userInfo.avatar) : ''"
                  :class="!userInfo.avatar ? 'no-avatar': ''"

                  style="width: 100px;height: 100px;border-radius: 100px;"
                  v-if="userInfo.avatar"
              >
                {{userInfo.avatar ? '' :(userInfo.nickname && userInfo.nickname[0].toUpperCase())}}
              </Avatar>
              <img v-else style="width: 100px;height: 100px;border-radius: 100px;" src="../../../assets/images/new_icon/head_img.png" />

              <p class="user-info-item user-info-tit">{{userInfo.nickname}}</p>
              <p class="user-info-item" @dblclick="changeTheme()">ID {{userInfo.id}}</p>
              <p class="user-info-item">组织 {{userInfo.site && userInfo.site.name}}</p>
<!--              <div class="user-info-roles user-info-item">-->
<!--                <span v-for="(item,index,key) in userInfo.auth_roles" :style="{backgroundColor:roleColor[key]}" :key="index">{{item}}</span>-->
<!--              </div>-->
              <div class="user-info-item user-info-bot" style="padding-bottom: 20px">
                <Button type="primary" style="margin-right: 10px" @click="goMyinfo">修改信息</Button>
                <Button @click="handleClickUserDropdown('loginout')">退出登录</Button>
              </div>
            </div>
          </div>
        </Poptip>

      </Row>
    </div>
    <div>
      <el-popover
          placement="bottom"
          width="180"
          trigger="click">
        <div class="role-list">
          <div :class="curRole == index ? 'active' : ''" v-for="(item,index,key) in userInfo.can_change_roles" :key="index" @click="changeRole(index)">{{item}}</div>
        </div>
        <div slot="reference" class="change-role">
          <img src="../../../assets/images/common/change_people.png" width="18" height="18" />
          <p>切换角色</p>
          <img src="../../../assets/images/common/down.png" width="8" height="6" />
        </div>
      </el-popover>
    </div>
<!--    <message-tip v-model="mesCount" style="float:right"></message-tip>-->
<!--    <full-screen v-model="isFullScreen" @on-change="fullscreenChange" style="float:right"></full-screen>-->

  </div>
</div>
</template>

<script>
import Cookies from 'js-cookie';
import {tokenName} from '@/router/init.js'
import util from '@/utils/tools.js'

// import messageTip from "@/views/layout/components/message-tip";
// import noticeBoard from "@/views/layout/components/notice-board";
// import fullScreen from "@/views/layout/components/fullscreen";
import {routes} from "@/router/index.js";
export default {
  name: "topView",
  data(){
    return {
      shrink: Cookies.get('liteMenuShrink') === 'true',
      openedSubmenuArr: this.$store.state.app.openedSubmenuArr,
      isFullScreen:false,
      curModule:'',
      userModuleList:[],
      roleColor:['#164e63','#0e7490','#06b6d4','#0369a1','#0ea5e9','#1d4ed8','#60a5fa'],
      menuAuth:'',
      // organizationList:[],
      // siteInfo:{},
      // curRole:'',
    }
  },
  props:{
    noMenu:{
      type:Boolean,
      default:false
    }
  },
  // components:{
  //   messageTip,
  //   noticeBoard,
  //   fullScreen
  // },
  watch:{
    '$store.state.app.userModuleList':{
      handler(newV){
        if(newV.length){
          this.userModuleList = JSON.parse(JSON.stringify(newV));
        }
      },
      immediate:true
    },
    '$store.state.app.curModule':{
      handler(newV){
        this.curModule = newV;
      },
      immediate:true
    },
    userInfo:{
      handler(newV){
        console.log(newV)
        // this.getUserDetail();
        if(newV.id){
          this.menuAuth = Object.keys(newV.auth_menus).join(',');
          // console.log(this.menuAuth,'this.menuAuth')
        }

      },
      immediate:true,
    }
  },
  computed: {
    curRole(){
      return this.$store.state.user?.userInfo?.siteUser?.current_role || 'role_student';
    },
    organizationList(){
      return this.$store.state.app.userSites || [];
    },
    menuList () {
      return this.$store.state.app.menuList;
    },

    settings () {
      return this.$store.state.app.settings;
    },
    userInfo () {
      return this.$store.state.user.userInfo;
    },
    pageTagsList () {
      return this.$store.state.app.pageOpenedList; // 打开的页面的页面对象
    },
    currentPath () {
      return this.$store.state.app.currentPath; // 当前面包屑数组
    },
    cachePage () {
      return this.$store.state.app.cachePage;
    },
    menuTheme () {
      return this.$store.state.app.menuTheme;
    },
    mesCount () {
      return parseInt(this.$store.state.app.messageCount);
    },
    $lang () {
      return this.$store.state.app.lang;
    },
    logoImage(){
      return this.$store.state.user.userInfo.site && this.$store.state.user.userInfo.site.logo;
    }
  },
  created(){
    // this.getUserDetail();
  },
  methods:{
    changeTheme(){
      document.documentElement.classList.toggle("dark");
    },
    showMenu(item){
      if(item.name==="system-chatgpt-module" && this.$store.state.user?.userInfo?.site?.open_chatgpt==='1'){
        return false;
      }else{
        return true;
      }
    },

    handleClickUserDropdown (name) {

      //用户中心操作 个人信息
      if (name === 'ownSpace') {
        //跳转个人信息
        // this.$router.push({
        //   name: 'ownspace_index'
        // });

        //退出登录操作
      } else if (name === 'loginout') {

        //删除cookie
        // Cookies.remove('liteAccessToken');
        let domain = util.getDomain();
        Cookies.remove(tokenName,{domain})
        //删除store
        this.$store.commit('logout', this);
        this.$store.commit('clearOpenedSubmenu');
        this.$store.commit('clearCurModule');



        //跳转到登录页
        this.$router.push({
          name: 'login'
        });
      }
    },
    fullscreenChange (isFullScreen) {
    },
    getUserAvatar (url) {
      if (url.indexOf('http') > -1) {
        return url;
      } else {
        return this.staticBase + url;
      }
    },
    goMyinfo(){
      if(this.$route.path == '/myinfo') return;
      this.$router.push({
        path: '/myinfo'
      });
    },
    changeModule(data){
      // this.curModule = data.route;
      if(data.meta.module_route == this.$store.state.app.curModule){
        return;
      }
      this.$store.commit('changeModule', {menu:this.$store.state.user.userInfo.auth_menus,all:this.$store.state.app.menuListAll,curModule:data.meta.module_route});
      console.log(this.$store.state.app.menuList,'this.$store.state.app.menuList')
      this.$store.commit('setCurPath',this.$store.state.app.menuList[0].path);
      this.$store.commit('changeBreadCrumbList',[]);
      this.$router.push({
        path: this.$store.state.app.menuList[0].path
      });
    },
    // goExperiment(){
    //   window.open('https://developer.aliyun.com/adc/','_blank');
    // },
    goYgkw(){
      window.open('https://ygkw.edu360.cn/chat/home','_blank');
    },
    // 用户信息
    getUserDetail(){
      this.api.user.userDetail({
        user_id:this.userInfo.id,
      }).then(res=>{
        // this.siteInfo=res?.user?.site || {};
        // this.organizationList=res.userSites||[];
        // this.curRole = res?.user?.siteUser?.current_role || 'role_student';
      });
    },
    // 切换组织
    switchSite(item){
      this.api.user.userChangeSite({
        site_id:item.site_id
      }).then(()=>{
        const key=this.userInfo.id+"curModule";
        window.localStorage.removeItem(key);
        this.$router.push({
          path:'/'
        });
        window.location.reload();
      });
    },
    changeRole(data){
      let params = {
        rolename:data
      };
      this.api.user.userChangeRole(params).then((res)=>{
        const key=this.userInfo.id+"curModule";
        window.localStorage.removeItem(key);
        this.$router.push({
          path:'/'
        });
        window.location.reload();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.head-main{
  padding: 0 20px;
  height: 48px;
  background: #fff;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
  position: relative;
  z-index: 999;
  .head-main-l{
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    >img{
      padding: 5px;
      width: 50px;
      height: 50px;
      object-fit: cover;
      object-position: left;
    }
    >p{
      font-size: 26px;
      max-width: 230px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .switching-organizations{
      width: 87px;
      height: 24px;
      background: #EEEEEE;
      border-radius: 6px;
      color:#5578F6;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
      >img{
        height: 10px;
        margin-left: 3px;
        cursor: pointer;
      }
    }
    .switch-content{
      width: 390px;
      max-height: 440px;
      overflow-y: auto;
      background: #FFFFFF;
      box-shadow: 0px 0px 24px 3px rgba(31,71,105,0.16);
      border-radius: 6px;
      padding: 20px;
      scrollbar-width: none;
      &::-webkit-scrollbar{
        display: none;
        width: 0;
      }
      .title{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .sub-title{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
      .zz-list{
        margin-top: 20px;
        .item-list{
          border-bottom: 1px solid #EEEEEE;
          padding: 20px;
          display: flex;
          justify-content: space-between;
          background-color: white;
          border-radius: 4px;
          &:nth-last-child(1){
            border-bottom: none;
          }
          .left{
            .item-title{
              font-weight: bold;
              max-width: 220px;
              overflow: hidden;
              text-overflow:ellipsis;
              font-size: 14px;
            }
            .item-id{
              color:#999999;
              font-size: 14px;
            }
          }
          .right{
            .btn{
              width: 64px;
              height: 24px;
              background: #EEEEEE;
              border-radius: 6px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #5578F6;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .btn-switch{
              width: 66px;
              height: 26px;
              background: #FFFFFF;
              border: 2px solid #5578F6;
              border-radius: 6px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #5578F6;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }
        }
        .currentzz{
          background-color: #F6F7FA;
          .left{
            .item-title{
              color: #5578F6;
            }
          }
        }
      }
    }
  }

  .header-middle-con{
    position: absolute;
    left: 0;
    top: 0;
    right: 340px;
    bottom: 0;
    // padding: 10px;
    padding-left: 10px;
    overflow: hidden;
  }
  .header-avator-con{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // width: 250px;
    .switch-theme-con{
      display: inline-block;
      width: 40px;
      height: 100%;
    }
    .message-con{
      display: inline-block;
      width: 30px;
      padding: 12px 0 13px;
      text-align: center;
      cursor: pointer;
      i{
        vertical-align: middle;
      }
    }
    .change-skin{
      font-size: 14px;
      font-weight: 500;
      padding-right: 5px;
    }
    .switch-theme{
      height: 100%;
    }
    .user-dropdown{
      &-menu-con{
        // position: absolute;
        // right: 0;
        // top: 0;
        // width: 150px;
        height: 100%;
        .main-user-name{
          max-width: 100px;
          display: inline-block;
          word-break: keep-all;
          vertical-align: middle;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: right;
        }
      }
      &-innercon{
        height: 100%;
        padding-right: 14px;
      }
    }
    .full-screen-btn-con{
      display: inline-block;
      width: 30px;
      padding: 13px 0;
      text-align: center;
      cursor: pointer;
      i{
        vertical-align: middle;
      }
    }
    .lock-screen-btn-con{
      display: inline-block;
      width: 30px;
      padding: 13px 0;
      text-align: center;
      cursor: pointer;
      i{
        vertical-align: middle;
      }
    }
  }
  .user-info{
    font-size: 12px;
    width: 240px;

    .user-info-roles{
      display: flex;
      flex-wrap: wrap;
      >span{
        margin: 0 5px 5px 0;
        padding: 5px 10px;
        background-color: #2d8cf0;
        color:#FFFFFF;
        border-radius: 4px;
      }
    }
    .user-info-item{
      margin-top: 10px;
    }
    .user-info-tit{
      font-size: 14px;
      font-weight: bold;
    }
    .user-info-bot{
      padding-top: 15px;
      border-top: 1px solid #dcdee2;
    }
  }
  .module-menu{
    margin-right: 20px;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
    padding: 10px 10px;
    //border-radius: 30px;
  }
  // .module-menu:nth-child(6){
  //   margin-right: 30px;
  // }
  .active{
    font-weight: bold;
    //color: #ffffff;
    //background-color: #2d8cf0;
    border-bottom: 4px solid #4E83FF;

  }
  .module-menu:hover{
    font-weight: bold;
    border-bottom: 4px solid #4E83FF;
  }
  .help-head{
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .change-role{
    margin-right: 34px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: #333333;
    cursor: pointer;
    >p{
      margin: 0 6px;
    }
  }

}
.role-list{
  //margin: 0 10px;
  max-height: 600px;
  overflow-y: auto;
  >div{
    height: 54px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: #333333;
    border-bottom: 1px solid #EEEEEE;
    cursor: pointer;
  }
  .active{
    font-weight: bold;
    color: #5578F6;
  }
}
</style>
