import {request,downLoadRequest} from '@/utils/request.js'

// 项目列表
const projects = (data) => {
    return request({
        url: '/project/list',
        method: 'post',
        data
    })
}

// 项目表单字段
const projectForm = (data) => {
    return request({
        url: '/project/form',
        method: 'post',
        data
    })
}

// 发布-选择分类
const publish = (data) => {
    return request({
        url: '/project/publish',
        method: 'post',
        data
    })
}

// 用户列表
const userList = (data) => {
    return request({
        url: '/user/list',
        method: 'post',
        data
    })
}

// 发布-更新信息
const projectPublish = (data) => {
    return request({
        url: '/project/publish',
        method: 'post',
        data
    })
}

// 发布-上传项目数据文件
const uploadfile = (data) => {
    return request({
        url: '/uploadfile/upload',
        method: 'post',
        data
    })
}

// 发布-解析数据文件
const getParse = (data) => {
    return request({
        url: '/uploadfile/parse',
        method: 'post',
        data
    })
}

// 上传文件列表
const uploadfileList = (data) => {
    return request({
        url: '/uploadfile/list',
        method: 'post',
        data
    })
}

// 上传文件列表
const projectDetail = (data) => {
    return request({
        url: '/project/detail',
        method: 'post',
        data
    })
}

// 项目数据列表
const dataList = (data) => {
    return request({
        url: '/data/list',
        method: 'post',
        data
    })
}

// 项目数据列表
const dataDetail = (data) => {
    return request({
        url: '/data/detail',
        method: 'post',
        data
    })
}

// 项目数据-查看操作记录
const workRecords = (data) => {
    return request({
        url: '/work/records',
        method: 'post',
        data
    })
}

// 项目导出-新建导出
const packBuild = (data) => {
    return request({
        url: '/pack/build',
        method: 'post',
        data
    })
}

// 项目导出记录
const packList = (data) => {
    return request({
        url: '/pack/list',
        method: 'post',
        data
    })
}

// 项目导出-打包记录浏览/下载
// const packDownload = (params) => {
//     return downLoadRequest({
//         url: '/pack/download',
//         method: 'GET',
//         params,
//         "responseType":"blob",
//     })
// }
const packDownload = (data) => {
    return request({
        url: '/pack/download',
        method: 'post',
        data
    })
}
const userstatsList = (data) => {
    return request({
        url: '/stat/users',
        method: 'post',
        data
    })
}
const userstatList = (data) => {
    return request({
        url: '/stat/user',
        method: 'post',
        data
    })
}

const projectDelete = (data) => {
    return request({
        url: '/project/delete',
        method: 'post',
        data
    })
}

const projectStatUsers = (data) => {
    return request({
        url: '/stat/users',
        method: 'post',
        data
    })
}

const projectStatListByBatch = (data) => {
    return request({
        url: '/stat/list-by-batch',
        method: 'post',
        data
    })
}

const projectRecovery = (data) => {
    return request({
        url: '/project/recovery',
        method: 'post',
        data
    })
}

const projectDataEdit = (data) => {
    return request({
        url: '/data/edit',
        method: 'post',
        data
    })
}


export default {
    projects,
    projectForm,
    publish,
    userList,
    projectPublish,
    uploadfile,
    getParse,
    uploadfileList,
    projectDetail,
    dataList,
    dataDetail,
    workRecords,
    packBuild,
    packList,
    packDownload,
    userstatList,
    userstatsList,
    projectDelete,
    projectStatUsers,
    projectStatListByBatch,
    projectRecovery,
    projectDataEdit,
}
