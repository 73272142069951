const examineTeacherRoute = {
    path: '/teacher-examine-route',
    redirect: '/course/teacher-list/:tab?',
    name: 'teacher-examine-route',
    meta:{
        title:'考试管理',
        module_route:'module_examine_teacher'
    },
    component:()=>import('../views/layout/examineTeacherHome.vue'),
    children:[
        // {
        //     path: '/course/list',
        //     route:'course/teacher-list',
        //     name: 'course/teacher-list',
        //     meta:{
        //         title:'router_course_teacher_list',
        //         module_route:'module_examine_teacher',
        //         img:require('@/assets/images/new_icon/kecheng.png'),
        //         requiresAuth:true,
        //     },
        //     component: ()=>import('../views/course/examineCourseList.vue'),
        // },
        {
            path: '/trainingcamp/examlist/:tab?',
            route: 'trainingcamp/examlist',
            name: 'trainingcamp/examlist',
            meta:{
                title:'router_trainingcamp_examlist',
                module_route:'module_examine_teacher',
                img:require('@/assets/images/new_icon/exam.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/examlist.vue'),
        },

        {
            path: '/trainingcamp/materialCreate',
            route: 'trainingcamp/materialCreate',
            name: 'materialCreate',
            meta:{
                title:'menu_trainingcamp_materialCreate',
                module_route:'module_examine_teacher',
                img:require('@/assets/images/new_icon/tikuguanli.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/materialCreate.vue'),
        },
        {
            path: '/trainingcamp/paperAnalysis',
            route: 'trainingcamp/paperAnalysis',
            name: 'paperAnalysis',
            meta:{
                title:'router_trainingcamp_paperAnalysis',
                module_route:'module_examine_teacher',
                img:require('@/assets/images/new_icon/homework.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/analysis/paperAnalysis.vue'),
        },
        {
            path: '/trainingcamp/teacher/exam-detail/:tab?/:id?',
            route: 'trainingcamp/exam-detail',
            name: 'teacher-exam-detail',
            meta:{
                title:'menu_trainingcamp_exam_detail',
                module_route:'module_examine_teacher',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/examDetail.vue'),
        },
        {
            path: '/trainingcamp/certmanage',
            route: 'trainingcamp/certmanage',
            name: 'trainingcamp_certmanage',
            meta:{
                title:'menu_trainingcamp_certmanage',
                module_route:'module_examine_teacher',
                img:require('@/assets/images/new_icon/train.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/certManage.vue'),
        },
        {
            path: '/teacher/trainingcamp/exam',
            route:'trainingcamp/exam',
            name: 'teacher/trainingcamp/exam',
            meta:{
                title:'menu_trainingcamp_exam',
                module_route:'module_examine_teacher',
                img:require('@/assets/images/new_icon/exam.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/exam.vue'),
        },
        {
            path:"/liveroom-invigilation-management",
            route:'liveroom-invigilation-management',
            name: 'liveroom-invigilation-management',
            meta:{
                title:'invigilation',
                module_route:'module_examine_teacher',
                requiresAuth:true,
            },
            component: ()=>import('../views/live/liveInvigilationManagement.vue'),
        },
        // {
        //     path: '/dataset/self-data',
        //     route: 'dataset/self-data',
        //     name: 'dataset_self-data',
        //     meta:{
        //         title:'router_dataset_data',
        //         module_route:'module_teacher',
        //         img:require('@/assets/images/new_icon/tikuguanli.png'),
        //         requiresAuth:true,
        //     },
        //     component: ()=>import('../views/course/questionBankManage.vue'),
        // },
        // {
        //     path:"/self-data/category",
        //     route:"self-data/category",
        //     name:"self-data/category",
        //     meta:{
        //         title:"router_course_category",
        //         module_route:'module_teacher',
        //         requiresAuth:true,
        //     },
        //     component: ()=>import('../views/course/category.vue'),
        // },
    ]
};

export default examineTeacherRoute;


