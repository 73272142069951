import ContentProductionHome from '../views/layout/contentProductionHome.vue'
const contentProduceRoute = {
    path: '/content_production',
    redirect: '/home_content_production',
    name: 'content_production',
    meta:{
      title:'内容生产',
      module_route:'module_content_production'
    },
    component:ContentProductionHome,
    children:[
      {
        path: '/home_content_production',
        route:'home_content_production',
        name: 'home_content_production',
        meta:{
          title:'router_home',
          module_route:'module_content_production',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/home/homeContentProduction.vue'),
      },
      {
        path: '/production/project',
        route:'production/project',
        name: 'project',
        meta:{
          title:'router_project',
          module_route:'module_content_production',
          img:require('@/assets/images/new_icon/project_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/production/project/list.vue'),
      },
      {
        path: '/production/task/:tab?/:subtab?',
        route:'production/task',
        name: 'task',
        meta:{
          title:'router_task',
          module_route:'module_content_production',
          img:require('@/assets/images/new_icon/my_task.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/production/task/tasks.vue'),
      },
      {
        path: '/production/task-execute',
        route:'production/task-execute',
        name: 'task-execute',
        meta:{
          title:'router_task_execute',
          module_route:'module_content_production',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/production/task/execute.vue'),
      },
      {
        path: '/production/task/task-detail/:id/:tab/:subtab',
        route:'production/task/task-detail',
        name: 'task-detail',
        meta: {
          title: 'router_task_detail',
          module_route:'module_content_production',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: () =>
            import('@/views/production/task/taskDetail.vue')
      },
      {
        path: '/production/stats',
        route:'production/stats',
        name: 'stats',
        meta:{
          title:'router_project_stats',
          module_route:'module_content_production',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/production/stats/list.vue'),
      },
      {
        path: '/production/stat',
        route:'production/stat',
        name: 'stat',
        meta:{
          title:'router_project_stat',
          module_route:'module_content_production',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/production/stat/list.vue'),
      },
      {
        path: '/production/project-detail/:id/:tab',
        route:'production/project-detail',
        name: 'project-detail',
        meta:{
          title:'router_project_detail',
          module_route:'module_content_production',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/production/project/projectDetail.vue'),
      },
      {
        path: '/production/project-create',
        route:'production/project-create',
        name: 'project-create',
        meta:{
          title:'router_project_create',
          module_route:'module_content_production',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/production/project/projectCreate.vue'),
      },
      {
        path: '/production/dict',
        route:'production/dict',
        name: 'production/dict',
        meta:{
          title:'router_production_dict',
          module_route:'module_content_production',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/production/dict/index.vue'),
      },
    ]
  };

export default contentProduceRoute;