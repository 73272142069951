export default {
    task_add:'添加设置',
    task_check:'查看设置',
    task_edit:'编辑设置',
    task_dele_tip:'确认删除设置吗？',
    task_table_index:'任务序号',
    task_table_taskid:'任务ID',
    task_table_projectid:'项目ID',
    task_table_project: '项目名称',
    task_table_batch: '批次名称',
    task_table_batchid: '批次ID',
    task_table_type:'项目类型',
    task_table_step:'工序名称',
    task_table_amount:'数量',
    task_table_progress:'进度',
    task_table_performance:'绩效',
    task_table_endtime:'截止时间',
    task_table_operation:'操作',
    task_add_name_tip:'请输入名称',
    task_add_key_tip:'请输入key',
    task_add_value_tip:'请输入value',
    task_add_desc_tip:'请输入描述',

    task_quality:'验收',
    task_work_id:'作业ID',
    task_overview:'总览',
    task_section:'第',
    task_topic:'题',
    task_work_tasks:'工作任务',
    task_skill_set:'技能集',
    task_first_level:'一级知识点',
    task_question_type:'题型',
    task_difficulty_level:'难度等级',
    task_knowledge_points:'知识点',
    task_newly_build:'新建',
    task_test_title:'试题标题',
    task_correct_option:'正确选项',
    task_set_correct_option:'设为正确选项',
    task_delete:'删除',
    task_add_options:'添加选项',
    task_analysis:'解析',
    task_pre_question:'上一题',
    task_next_question:'下一题',
    task_task_timed_out:'任务已超时',
    task_re_claim:'任务已超时，请重新领取',
    task_get_task_again:'重新领取任务',
    task_return_to_task:'返回我的任务',
    task_attention:'注意事项',
    task_attachment:'任务附件',
    task_feedback:'总体反馈',
    task_option_e:'选项E',
    task_option_f:'选项F',
    task_option_a:'选项A',
    task_option_b:'选项B',
    task_option_c:'选项C',
    task_option_d:'选项D',
    task_option_placeholder:'单选题答案中不能出现"以上都不对"、"其他都正确"之类的选项；多选题禁止出现全选题。',
    task_title_placeholder:'题干使用空缺句"（）"，句末用；注意术语大小写一致；避免使用否定句；多选题题干后需要标注（正确答案几个）',
    task_analysis_placeholder:'本题考察知识点为***，请解释各选项内容的正确错误原因，注意解释原因时不要出现选项A、B、C字样，即选项和选项内容不做绑定',
    task_add_max:'最多只能添加6项',
    task_complete_data:'您有漏填的项, 请按要求填写后再提交',
    task_last_data:'已经是最后一条数据～',
    task_first_data:'已经是第一条数据～',
    task_selected_one:'只能选择一个正确选项',
    task_disabled_all:'多选题禁止出现全选题',

    task_reset:'驳回重做',
    task_reject:'驳回修改',
    task_pass:'通过',
    task_enter_comment:'请输入评论',
    task_disabled_operated:'此条数据不可再操作',
    task_reject_success:'驳回成功',
    task_reset_success:'重置成功',
    task_tasks_operation_execute_tip: '',
    task_tasks_operation_refuse_tip: '驳回作业: 被审核等后续流程审批不通过而打回的作业',
    task_tasks_operation_refused_tip: '返工作业: 打回到前序的作业再次提交需要再次审核的作业',
    task_tasks_operation_difficute_tip: '挂起作业: 暂不提交而挂起的作业',

    task_detail_worklist_fetch : '领取作业',
    task_detail_worklist_working:'继续作业',
    task_detail_worklist_redo:'修改作业',
    task_detail_worklist_refused_revise:'修改驳回作业',
    task_detail_worklist_difficult_revise:'领取挂起作业',
    task_detail_worklist_refusesubmit_receive: '领取返工作业',

    task_detail_placeholder:'请输入搜索内容',
    task_detail_work_id:'作业ID',
    task_detail_work_status:'状态',
    task_detail_date:'更新时间',
    task_detail_check_result:'查看结果',
    task_detail_op:'操作',
    task_detail_placeholder:'请输入搜索内容',
    task_detail_placeholder:'请输入搜索内容',

    production_stat_usename:'用户',
    production_stat_projectname:'项目名称',
    production_stat_category:'分类',
    production_stat_batch:'批次',
    production_stat_step:'工序',
    production_stat_work_time:'作业时长(秒)',
    production_stat_work_count:'作业数',
    production_stat_allow_count:'通过数',
    production_stat_refuse_count:'驳回数',
    production_stat_reset_count:'重置数',
    production_stat_difficult_count:'挂起数',
    production_stat_operation:'操作',
}
