export default {
    audio_play: '播放',
    audio_pause: '暂停',
    audio_zoomIn: '放大',
    audio_zoomOut: '缩小',
    audio_autoPlay_on_selected: '选中后自动播放',
    audio_play_in_loop: '循环播放',
    audio_result_counter: '标注结果条数',
    audio_clear_result: '清空标注',
    audio_segment_time: '时间',
    audio_segment_valid: '有效性',
    audio_segment_text: '转录内容',
    audio_segment_attr: '属性',
}
