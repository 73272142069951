import {request} from '@/utils/request.js'

// 获取接口权限列表
const permissions = (data) => {
    return request({
        url: '/auth/permissions',
        method: 'post',
        data
    })
}
// 获取菜单权限列表
const menus = (data) => {
    return request({
        url: '/auth/menus',
        method: 'post',
        data
    })
}
// 获取角色表单
const roleForm = (data) => {
    return request({
        url: '/auth/role-form',
        method: 'post',
        data
    })
}

// 创建角色
const roleCreate = (data) => {
    return request({
        url: '/auth/role-create',
        method: 'post',
        data
    })
}
// 获取角色列表
const rolesList = (data) => {
    return request({
        url: '/auth/roles',
        method: 'post',
        data
    })
}
// 获取角色列表
const roleDelete = (data) => {
    return request({
        url: '/auth/role-delete',
        method: 'post',
        data
    })
}

// 获取角色详情
const roleDetail = (data) => {
    return request({
        url: '/auth/role-detail',
        method: 'post',
        data
    })
}

// 修改角色
const roleUpdate = (data) => {
    return request({
        url: '/auth/role-update',
        method: 'post',
        data
    })
}

// 创建角色
const groupCreate = (data) => {
    return request({
        url: '/auth/group-create',
        method: 'post',
        data
    })
}
// 获取角色列表
const groupsList = (data) => {
    return request({
        url: '/auth/groups',
        method: 'post',
        data
    })
}
// 获取角色列表
const groupDelete = (data) => {
    return request({
        url: '/auth/group-delete',
        method: 'post',
        data
    })
}

// 获取角色详情
const groupDetail = (data) => {
    return request({
        url: '/auth/group-detail',
        method: 'post',
        data
    })
}

// 修改角色
const groupUpdate = (data) => {
    return request({
        url: '/auth/group-update',
        method: 'post',
        data
    })
}


// 创建接口权限
const permissionCreate = (data) => {
    return request({
        url: '/auth/permission-create',
        method: 'post',
        data
    })
}
// 修改接口权限
const permissionUpdate = (data) => {
    return request({
        url: '/auth/permission-update',
        method: 'post',
        data
    })
}
// 删除接口权限
const permissionDelete = (data) => {
    return request({
        url: '/auth/permission-delete',
        method: 'post',
        data
    })
}
// 创建菜单权限
const menuCreate = (data) => {
    return request({
        url: '/auth/menu-create',
        method: 'post',
        data
    })
}
// 修改菜单权限
const menuUpdate = (data) => {
    return request({
        url: '/auth/menu-update',
        method: 'post',
        data
    })
}
// 删除菜单权限
const menuDelete = (data) => {
    return request({
        url: '/auth/menu-delete',
        method: 'post',
        data
    })
}

// 获取角色列表
const settingList = (data) => {
    return request({
        url: '/setting/list',
        method: 'post',
        data
    })
}

// 创建菜单权限
const settingCreate = (data) => {
    return request({
        url: '/setting/create',
        method: 'post',
        data
    })
}
// 修改菜单权限
const settingUpdate = (data) => {
    return request({
        url: '/setting/update',
        method: 'post',
        data
    })
}
// 删除菜单权限
const settingDelete = (data) => {
    return request({
        url: '/setting/delete',
        method: 'post',
        data
    })
}

// 消息队列统计
const systemMqStat = (data) => {
    return request({
        url: '/system/mq-stat',
        method: 'post',
        data
    })
}

//websocket统计
const systemWsStat = (data) => {
    return request({
        url: '/system/ws-stat',
        method: 'post',
        data
    })
}

// 在线用户统计
const systemOnlineUsers = (data) => {
    return request({
        url: '/system/online-users',
        method: 'post',
        data
    })
}
const systemOnlineUsersAll = (data) => {
    return request({
        url: '/system/online-users-all',
        method: 'post',
        data
    })
}

// 阿里云社区题库每周统计
const systemAliStat = (data) => {
    return request({
        url: '/system/ali-stat',
        method: 'post',
        data
    })
}
const systemChatgpt = (data) => {
    return request({
        url: '/system/chatgpt',
        method: 'post',
        data
    })
}

const systemChatgptList = (data) => {
    return request({
        url: '/system/chatgpt-list',
        method: 'post',
        data
    })
}

const systemAuthRefresh = (data) => {
    return request({
        url: '/system/auth-refresh',
        method: 'post',
        data
    })
}


const systemRefreshCache = (data) => {
    return request({
        url: '/system/refresh-cache',
        method: 'post',
        data
    })
}



export default {
    permissions,
    menus,
    roleForm,
    roleCreate,
    rolesList,
    roleDelete,
    roleDetail,
    roleUpdate,
    groupCreate,
    groupsList,
    groupDelete,
    groupDetail,
    groupUpdate,
    permissionCreate,
    permissionUpdate,
    permissionDelete,
    menuCreate,
    menuUpdate,
    menuDelete,
    settingList,
    settingCreate,
    settingUpdate,
    settingDelete,
    systemMqStat,
    systemOnlineUsers,
    systemOnlineUsersAll,
    systemAliStat,
    systemChatgpt,
    systemChatgptList,
    systemWsStat,
    systemAuthRefresh,
    systemRefreshCache
}
