import {request} from '@/utils/request.js'

//创建活动
const create = (data) => {
    return request({
        url: '/activity/create',
        method: 'post',
        data
    })
}

//编辑活动
const update = (data) => {
    return request({
        url: '/activity/update',
        method: 'post',
        data
    })
}

//活动列表
const list = (data) => {
    return request({
        url: '/activity/list',
        method: 'post',
        data
    })
}

//删除活动
const activityDelete = (data) => {
    return request({
        url: '/activity/delete',
        method: 'post',
        data
    })
}

//下架活动
const offline = (data) => {
    return request({
        url: '/activity/offline',
        method: 'post',
        data
    })
}

//活动详情
const detail = (data) => {
    return request({
        url: '/activity/detail',
        method: 'post',
        data
    })
}

//上架活动
const online = (data) => {
    return request({
        url: '/activity/online',
        method: 'post',
        data
    })
}

//活动报名列表
const activityApplyList = (data) => {
    return request({
        url: '/activity/activity-apply-list',
        method: 'post',
        data
    })
}

//导出活动报名列表
const exportActivityApply = (data) => {
    return request({
        url: '/activity/export-activity-apply',
        method: 'post',
        data
    })
}

//主办方列表
const organizeList = (data) => {
    return request({
        url: '/activity/organize-list',
        method: 'post',
        data
    })
}

//创建主办方
const organizeCreate = (data) => {
    return request({
        url: '/activity/organize-create',
        method: 'post',
        data
    })
}

//编辑主办方
const organizeUpdate = (data) => {
    return request({
        url: '/activity/organize-update',
        method: 'post',
        data
    })
}

//删除主办方
const organizeDelete = (data) => {
    return request({
        url: '/activity/organize-delete',
        method: 'post',
        data
    })
}

//活动详情二维码
const detailQrcode = (data) => {
    return request({
        url: '/activity/detail-qrcode',
        method: 'post',
        data
    })
}

//活动表单项
const activityForm = (data) => {
    return request({
        url: '/activity/form',
        method: 'post',
        data
    })
}

//专题列表
const activityCategoryList = (data) => {
    return request({
        url: '/activity/category-list',
        method: 'post',
        data
    })
}

//创建专题
const activityCategoryCreate = (data) => {
    return request({
        url: '/activity/category-create',
        method: 'post',
        data
    })
}

//更新专题
const activityCategoryUpdate = (data) => {
    return request({
        url: '/activity/category-update',
        method: 'post',
        data
    })
}

//专题详情
const activityCategoryDetail = (data) => {
    return request({
        url: '/activity/category-detail',
        method: 'post',
        data
    })
}

//删除专题
const activityCategoryDelete = (data) => {
    return request({
        url: '/activity/category-delete',
        method: 'post',
        data
    })
}


export default {
    create,
    update,
    list,
    activityDelete,
    offline,
    detail,
    online,
    activityApplyList,
    exportActivityApply,
    organizeList,
    organizeCreate,
    organizeUpdate,
    organizeDelete,
    detailQrcode,
    activityForm,
    activityCategoryList,
    activityCategoryCreate,
    activityCategoryUpdate,
    activityCategoryDetail,
    activityCategoryDelete
}
