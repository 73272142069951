import {request} from '@/utils/request.js'

//获取岗位测评列表
const evaluationList = (data) => {
    return request({
        url: '/dataset-train/evaluation-list',
        method: 'post',
        data
    })
}

//获取岗位测评列表
const evaluationCreate = (data) => {
    return request({
        url: '/dataset-train/evaluation-create',
        method: 'post',
        data
    })
}

//获取岗位测评列表
const evaluationUpdate = (data) => {
    return request({
        url: '/dataset-train/evaluation-update',
        method: 'post',
        data
    })
}

//获取岗位测评列表
const evaluationDelete = (data) => {
    return request({
        url: '/dataset-train/evaluation-delete',
        method: 'post',
        data
    })
}

export default {
    evaluationList,
    evaluationCreate,
    evaluationUpdate,
    evaluationDelete
}
