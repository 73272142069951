import {request} from '@/utils/request.js'

//图谱试题列表
const mapDataList = (data) => {
    return request({
        url: '/dataset-map/map-data-list',
        method: 'post',
        data
    })
}

//图谱试题列表
const mapDataListV1 = (data) => {
    return request({
        url: '/dataset-map/map-data-list-v1',
        method: 'post',
        data
    })
}

//更新课程
const courseUpdate = (data) => {
    return request({
        url: '/dataset-course/update',
        method: 'post',
        data
    })
}

//课程详情
const courseDetail = (data) => {
    return request({
        url: '/dataset-course/detail',
        method: 'post',
        data
    })
}

//添加课程
const courseCreate = (data) => {
    return request({
        url: '/dataset-course/create',
        method: 'post',
        data
    })
}

//下架课程
const courseOffline = (data) => {
    return request({
        url: '/dataset-course/offline',
        method: 'post',
        data
    })
}

//上架课程
const courseOnline = (data) => {
    return request({
        url: '/dataset-course/online',
        method: 'post',
        data
    })
}

//课程列表
const courseList = (data) => {
    return request({
        url: '/dataset-course/list',
        method: 'post',
        data
    })
}

//分类列表
const categoryTree = (data) => {
    return request({
        url: '/dataset-category/tree',
        method: 'post',
        data
    })
}

//资源列表
const sourceList = (data) => {
    return request({
        url: '/dataset-source/list',
        method: 'post',
        data
    })
}

//组织私有三级题库列表
const selfThirdList = (data) => {
    return request({
        url: '/dataset-category/self-third-list',
        method: 'post',
        data
    })
}

//批量上传教学资源
const teachingSourceBatch = (data) => {
    return request({
        url: '/dataset-source/teaching-source-batch',
        method: 'post',
        data
    })
}

//上传教学资源用户列表
const teachingSourceUserList = (data) => {
    return request({
        url: '/dataset-source/teaching-source-user-list',
        method: 'post',
        data
    })
}

//教学资源列表
const teachingSourceList = (data) => {
    return request({
        url: '/dataset-source/teaching-source-list',
        method: 'post',
        data
    })
}

//创建教学资源文件夹
const teachingSourceCreateFolder = (data) => {
    return request({
        url: '/dataset-source/teaching-source-create-folder',
        method: 'post',
        data
    })
}

//更新教学资源文件夹
const teachingSourceUpdateFolder = (data) => {
    return request({
        url: '/dataset-source/teaching-source-update-folder',
        method: 'post',
        data
    })
}

//删除教学资源
const teachingSourceDel = (data) => {
    return request({
        url: '/dataset-source/teaching-source-del',
        method: 'post',
        data
    })
}

//课程班级列表
const courseGroupList = (data) => {
    return request({
        url: '/dataset-course/group-list',
        method: 'post',
        data
    })
}

// 添加关联课程
const classBindCourse=(data)=>{
    return request({
        url:"/dataset-course/group-create",
        method:"post",
        data
    });
}

// 移出课程
const removeCourse=(data)=>{
    return request({
        url:"/dataset-course/group-delete",
        method:"post",
        data
    });
}

//课程学员列表
const courseStudentList = (data) => {
    return request({
        url: '/dataset-course/student-list',
        method: 'post',
        data
    })
}

//任务列表
const courseTaskList = (data) => {
    return request({
        url: '/dataset-course/task-list',
        method: 'post',
        data
    })
}

//学生端课程详情
const mCourseIndex = (data) => {
    return request({
        url: '/dataset-course/m-course-index',
        method: 'post',
        data
    })
}


//创建任务
const courseTaskCreate = (data) => {
    return request({
        url: '/dataset-course/task-create',
        method: 'post',
        data
    })
}

//删除任务
const courseTaskDelete = (data) => {
    return request({
        url: '/dataset-course/task-delete',
        method: 'post',
        data
    })
}

//任务统计概要
const courseTaskStat = (data) => {
    return request({
        url: '/dataset-course/task-stat',
        method: 'post',
        data
    })
}

//任务人员列表
const courseTaskStatUserList = (data) => {
    return request({
        url: '/dataset-course/task-stat-user-list',
        method: 'post',
        data
    })
}

//学生端课程详情
const mTaskList = (data) => {
    return request({
        url: '/dataset-course/m-task-list',
        method: 'post',
        data
    })
}

//判题
const checkTrainUserData = (data) => {
    return request({
        url: '/dataset-train/check-train-user-data',
        method: 'post',
        data
    })
}

//任务学习进度上报
const mTaskEventLog = (data) => {
    return request({
        url: '/dataset-course/m-task-event-log',
        method: 'post',
        data
    })
}

//表单项
const courseForm = (data) => {
    return request({
        url: '/dataset-course/form',
        method: 'post',
        data
    })
}

//任务详情
const courseTaskDetail = (data) => {
    return request({
        url: '/dataset-course/task-detail',
        method: 'post',
        data
    })
}

//更新任务
const courseTaskUpdate = (data) => {
    return request({
        url: '/dataset-course/task-update',
        method: 'post',
        data
    })
}

//创建试卷
const examinePublish = (data) => {
    return request({
        url: '/dataset-examine/publish',
        method: 'post',
        data
    })
}
//试卷详情
const examineDetail = (data) => {
    return request({
        url: '/dataset-examine/detail',
        method: 'post',
        data
    })
}

//试卷列表
const examineList = (data) => {
    return request({
        url: '/dataset-examine/list',
        method: 'post',
        data
    })
}

//确定发布练习
const exercisePublish = (data) => {
    return request({
        url: '/dataset-exercise/publish',
        method: 'post',
        data
    })
}

//练习列表
const exerciseList = (data) => {
    return request({
        url: '/dataset-exercise/list',
        method: 'post',
        data
    })
}

//练习列表
const exerciseDel = (data) => {
    return request({
        url: '/dataset-exercise/del',
        method: 'post',
        data
    })
}

//练习详情
const exerciseDetail = (data) => {
    return request({
        url: '/dataset-exercise/detail',
        method: 'post',
        data
    })
}

//删除试卷
const examineDel = (data) => {
    return request({
        url: '/dataset-examine/del',
        method: 'post',
        data
    })
}

//考试列表
const examineMList = (data) => {
    return request({
        url: 'dataset-examine/m-list',
        method: 'post',
        data
    })
}

//练习列表
const exerciseMList = (data) => {
    return request({
        url: 'dataset-exercise/m-list',
        method: 'post',
        data
    })
}

//预览随机试题
const examinePreviewDataList = (data) => {
    return request({
        url: 'dataset-examine/preview-data-list',
        method: 'post',
        data
    })
}

//老师端试卷分析
const datasetExamineReport = (data) => {
    return request({
        url: '/dataset-examine/report',
        method: 'post',
        data
    })
}

//组卷参与用户列表
const datasetExamineUserList = (data) => {
    return request({
        url: '/dataset-examine/user-list',
        method: 'post',
        data
    })
}

const exportUserList = (data) => {
    return request({
        url: '/dataset-examine/export-user-list',
        method: 'post',
        data
    })
}

const examineDataList = (data) => {
    return request({
        url: '/dataset-examine/data-list',
        method: 'post',
        data
    })
}

//创建互动
const courseInteractCreate = (data) => {
    return request({
        url: '/dataset-course/interact-create',
        method: 'post',
        data
    })
}

//编辑互动
const courseInteractUpdate = (data) => {
    return request({
        url: '/dataset-course/interact-update',
        method: 'post',
        data
    })
}

//互动详情
const courseInteractDetail = (data) => {
    return request({
        url: '/dataset-course/interact-detail',
        method: 'post',
        data
    })
}

//互动列表
const courseInteractList = (data) => {
    return request({
        url: '/dataset-course/interact-list',
        method: 'post',
        data
    })
}

//删除互动
const courseInteractDelete = (data) => {
    return request({
        url: '/dataset-course/interact-delete',
        method: 'post',
        data
    })
}

//课程互动记录
const courseInteractTaskList = (data) => {
    return request({
        url: '/dataset-course/interact-task-list',
        method: 'post',
        data
    })
}

//发起互动
const courseInteractStart = (data) => {
    return request({
        url: '/dataset-course/interact-start',
        method: 'post',
        data
    })
}

//互动任务试题分析
const courseInteractTaskDataStat = (data) => {
    return request({
        url: '/dataset-course/interact-task-data-stat',
        method: 'post',
        data
    })
}

//互动试题用户列表
const courseInteractDataUserList = (data) => {
    return request({
        url: '/dataset-course/interact-data-user-list',
        method: 'post',
        data
    })
}

//互动任务用户列表
const courseInteractTaskUserList = (data) => {
    return request({
        url: '/dataset-course/interact-task-user-list',
        method: 'post',
        data
    })
}

//互动任务排行
const courseInteractTaskRankList = (data) => {
    return request({
        url: '/dataset-course/interact-task-rank-list',
        method: 'post',
        data
    })
}

//互动任务数据分析
const courseInteractTaskReport = (data) => {
    return request({
        url: '/dataset-course/interact-task-report',
        method: 'post',
        data
    })
}

//学生端互动任务列表
const courseMInteractTaskList = (data) => {
    return request({
        url: '/dataset-course/m-interact-task-list',
        method: 'post',
        data
    })
}

//结束答题
const courseInteractTaskOver = (data) => {
    return request({
        url: '/dataset-course/interact-task-over',
        method: 'post',
        data
    })
}

//全局待完成答题
const doingWaitExamList = (data) => {
    return request({
        url: '/dataset-examine/doing-wait-exam-list',
        method: 'post',
        data
    })
}

// 获取实验链接
const getLinkAuthUrl=(data)=>{
    return request({
        url:"/dataset-experiment/get-link-auth-url",
        method:"post",
        data
    });
}

// 章节转化为周课表
const toWeeklySchedules=(data)=>{
    return request({
        url:"/dataset-course/auto-create-task",
        method:"post",
        data
    });
}

// 整体课程统计
const courseStatistics=(data)=>{
    return request({
        url:"/dataset-course/stat-by-course",
        method:"post",
        data
    });
}

// 学生课程统计
const stuCourseStatistics=(data)=>{
    return request({
        url:"/dataset-course/stat-user-list",
        method:"post",
        data
    });
}

// 学生个人统计
 const stuPersonStatistics=(data)=>{
    return request({
        url:"/dataset-course/stat-by-user",
        method:"post",
        data
    });
 }

// 课程任务关联数据数量
const taskRelationDataCount=(data)=>{
    return request({
        url:"/dataset-course/task-relation-data-count",
        method:"post",
        data
    });
}

//学生端 - 课程列表
const courseMList = (data) => {
    return request({
        url: '/dataset-course/m-list',
        method: 'post',
        data
    })
}

//私有课程删除
const courseDelete = (data) => {
    return request({
        url: '/dataset-course/delete',
        method: 'post',
        data
    })
}

//复制课程
const courseCopy = (data) => {
    return request({
        url: '/dataset-course/copy',
        method: 'post',
        data
    })
}

export default {
    mapDataList,
    mapDataListV1,
    courseUpdate,
    courseDetail,
    courseCreate,
    courseOffline,
    courseOnline,
    courseList,
    categoryTree,
    sourceList,
    selfThirdList,
    teachingSourceBatch,
    teachingSourceUserList,
    teachingSourceList,
    teachingSourceCreateFolder,
    teachingSourceDel,
    teachingSourceUpdateFolder,
    courseGroupList,
    courseStudentList,
    courseTaskList,
    mCourseIndex,
    courseTaskCreate,
    courseTaskDelete,
    courseTaskStat,
    courseTaskStatUserList,
    mTaskList,
    checkTrainUserData,
    mTaskEventLog,
    courseForm,
    courseTaskDetail,
    courseTaskUpdate,
    examinePublish,
    examineDetail,
    examineList,
    exercisePublish,
    exerciseList,
    exerciseDel,
    exerciseDetail,
    examineDel,
    examineMList,
    exerciseMList,
    examinePreviewDataList,
    datasetExamineReport,
    datasetExamineUserList,
    exportUserList,
    examineDataList,
    courseInteractCreate,
    courseInteractUpdate,
    courseInteractDetail,
    courseInteractList,
    courseInteractDelete,
    courseInteractTaskList,
    courseInteractStart,
    courseInteractTaskDataStat,
    courseInteractDataUserList,
    courseInteractTaskUserList,
    courseInteractTaskRankList,
    courseInteractTaskReport,
    courseMInteractTaskList,
    courseInteractTaskOver,
    doingWaitExamList,
    classBindCourse,
    removeCourse,
    getLinkAuthUrl,
    toWeeklySchedules,
    courseStatistics,
    stuCourseStatistics,
    stuPersonStatistics,
    taskRelationDataCount,
    courseMList,
    courseDelete,
    courseCopy
}
