import {request} from '@/utils/request.js'

//租户列表
const userList = (data) => {
    return request({
        url: '/user/list',
        method: 'post',
        data
    })
}
const userDetail = (data) => {
    return request({
        url: '/user/detail',
        method: 'post',
        data
    })
}

const userCreate = (data) => {
    return request({
        url: '/user/create',
        method: 'post',
        data
    })
}

const userUpdate = (data) => {
    return request({
        url: '/user/update',
        method: 'post',
        data
    })
}
const userDelete = (data) => {
    return request({
        url: '/user/delete',
        method: 'post',
        data
    })
}

const userForm = (data) => {
    return request({
        url: '/user/form',
        method: 'post',
        data
    })
}

const userStatDayList = (data) => {
    return request({
        url: '/user/user-stat-day-list',
        method: 'post',
        data
    })
}


const userRecords = (data) => {
    return request({
        url: '/user/records',
        method: 'post',
        data
    })
}

//用户首页
const userIndex = (data) => {
    return request({
        url: '/user/index',
        method: 'post',
        data
    })
}

//修改密码
const updatePassword = (data) => {
    return request({
        url: '/user/update-password',
        method: 'post',
        data
    })
}

//修改头像
const updateAvatar = (data) => {
    return request({
        url: '/user/update-avatar',
        method: 'post',
        data
    })
}

//创建班级
const groupCreate = (data) => {
    return request({
        url: '/group/create',
        method: 'post',
        data
    })
}
//修改班级
const groupUpdate = (data) => {
    return request({
        url: '/group/update',
        method: 'post',
        data
    })
}

//修改班级
const groupDelete = (data) => {
    return request({
        url: '/group/delete',
        method: 'post',
        data
    })
}
//班级列表
const groupList = (data) => {
    return request({
        url: '/group/list',
        method: 'post',
        data
    })
}
//成员列表
const groupUserList = (data) => {
    return request({
        url: '/group/user-list',
        method: 'post',
        data
    })
}
//添加成员
const groupUserCreate = (data) => {
    return request({
        url: '/group/user-create',
        method: 'post',
        data
    })
}
//移除成员
const groupUserDelete = (data) => {
    return request({
        url: '/group/user-delete',
        method: 'post',
        data
    })
}

//班级详情
const groupDetail = (data) => {
    return request({
        url: '/group/detail',
        method: 'post',
        data
    })
}

//认证老师表单元素
const userTeacherForm = (data) => {
    return request({
        url: '/user/teacher-form',
        method: 'post',
        data
    })
}

//认证老师列表
const userTeacherList = (data) => {
    return request({
        url: '/user/teacher-list',
        method: 'post',
        data
    })
}

//认证老师表单元素
const userTeacherCreate = (data) => {
    return request({
        url: '/user/teacher-create',
        method: 'post',
        data
    })
}

//超管审核认证老师
const userTeacherVerify = (data) => {
    return request({
        url: '/user/teacher-verify',
        method: 'post',
        data
    })
}

//修改认证老师
const userTeacherUpdate = (data) => {
    return request({
        url: '/user/teacher-update',
        method: 'post',
        data
    })
}

//认证老师详情
const userTeacherDetail = (data) => {
    return request({
        url: '/user/teacher-detail',
        method: 'post',
        data
    })
}

//删除认证老师
const userTeacherDelete = (data) => {
    return request({
        url: '/user/teacher-delete',
        method: 'post',
        data
    })
}

//批量导入认证老师
const userTeacherImport = (data) => {
    return request({
        url: '/user/teacher-import',
        method: 'post',
        data
    })
}

//修改手机
const userUpdateMobile = (data) => {
    return request({
        url: '/user/update-mobile',
        method: 'post',
        data
    })
}


//上传简历导入老师
const teacherImportResume = (data) => {
    return request({
        url: '/user/teacher-import-resume',
        method: 'post',
        data
    })
}

//统计数据
const teacherStat = (data) => {
    return request({
        url: '/user/teacher-stat',
        method: 'post',
        data
    })
}

//认证老师通知列表
const teacherNoticeList = (data) => {
    return request({
        url: '/user/teacher-notice-list',
        method: 'post',
        data
    })
}

//认证老师通知详情
const teacherNoticeDetail = (data) => {
    return request({
        url: '/user/teacher-notice-detail',
        method: 'post',
        data
    })
}

//认证老师通知添加/更新
const teacherNoticeSave = (data) => {
    return request({
        url: '/user/teacher-notice-save',
        method: 'post',
        data
    })
}

//认证老师通知-发送明细列表
const teacherNoticeItemList = (data) => {
    return request({
        url: '/user/teacher-notice-item-list',
        method: 'post',
        data
    })
}

//批量更换组织
const siteChangeUserSite = (data) => {
    return request({
        url: '/site/change-user-site',
        method: 'post',
        data
    })
}

//用户变更自己的租户
const userChangeSite = (data) => {
    return request({
        url: '/user/change-site',
        method: 'post',
        data
    })
}

const userStatByStar = (data) => {
    return request({
        url: '/user/stat-by-star-section',
        method: 'post',
        data
    })
}

//获取用户手机号
const getUserMobile = (data) => {
    return request({
        url: '/user/get-user-mobile',
        method: 'post',
        data
    })
}

//导入老师数据
const teacherDataImport = (data) => {
    return request({
        url: '/user/teacher-data-import',
        method: 'post',
        data
    })
}

//设置黑名单
const teacherSetBlacklist = (data) => {
    return request({
        url: '/user/teacher-set-blacklist',
        method: 'post',
        data
    })
}

//设置白名单
const teacherSetWhitelist = (data) => {
    return request({
        url: '/user/teacher-set-whitelist',
        method: 'post',
        data
    })
}

//跟进列表
const teacherFollowList = (data) => {
    return request({
        url: '/user/teacher-follow-list',
        method: 'post',
        data
    })
}

//创建跟进
const teacherFollowCreate = (data) => {
    return request({
        url: '/user/teacher-follow-create',
        method: 'post',
        data
    })
}

//邀请老师填写认证信息
const teacherInvitationFillInfo = (data) => {
    return request({
        url: '/user/teacher-invitation-fill-info',
        method: 'post',
        data
    })
}

// 编辑学员信息
const groupUserUpdate=(data)=>{
    return request({
        url:"/group/user-update",
        method:"post",
        data
    });
}
// 导出老师
const exportTeacher=(data)=>{
    return request({
        url:"/user/export-user-data",
        method:"post",
        data
    });
}

// 更新跟进记录
const updateFollow=(data)=>{
    return request({
        url:"/customer-service/followup-update",
        method:"post",
        data
    });
}

// 删除跟进记录
const deleteFollow=(data)=>{
    return request({
        url:"/customer-service/followup-delete",
        method:"post",
        data
    });
}

// 置顶老师
const teacherSetTop=(data)=>{
    return request({
        url:"/user/teacher-set-top",
        method:"post",
        data
    });
}

// 取消置顶老师
const teacherCancelTop=(data)=>{
    return request({
        url:"/user/teacher-cancel-top",
        method:"post",
        data
    });
}

// 用户切换角色
const userChangeRole=(data)=>{
    return request({
        url:"/user/change-role",
        method:"post",
        data
    });
}

// 表单项
const groupForm=(data)=>{
    return request({
        url:"/group/form",
        method:"post",
        data
    });
}

export default {
    userList,
    userDetail,
    userCreate,
    userUpdate,
    userDelete,
    userForm,
    userRecords,
    userIndex,
    updatePassword,
    updateAvatar,
    groupCreate,
    groupUpdate,
    groupList,
    groupUserList,
    groupUserCreate,
    groupUserDelete,
    groupDelete,
    userTeacherForm,
    userTeacherList,
    userTeacherCreate,
    userTeacherVerify,
    userTeacherUpdate,
    userTeacherDetail,
    userTeacherDelete,
    userTeacherImport,
    userUpdateMobile,
    teacherImportResume,
    teacherStat,
    teacherNoticeList,
    teacherNoticeDetail,
    teacherNoticeSave,
    teacherNoticeItemList,
    siteChangeUserSite,
    userChangeSite,
    userStatByStar,
    getUserMobile,
    groupDetail,
    teacherDataImport,
    teacherSetBlacklist,
    teacherSetWhitelist,
    teacherFollowList,
    teacherFollowCreate,
    teacherInvitationFillInfo,
    groupUserUpdate,
    userStatDayList,
    exportTeacher,
    updateFollow,
    deleteFollow,
    teacherSetTop,
    teacherCancelTop,
    userChangeRole,
    groupForm
}
