
export default {
    site_list_column_name: '组织名称',
    site_list_column_status: '状态',
    site_list_column_last_login_time: '最后登录时间',
    site_list_column_operation: '操作',
    site_list_column_start_time: '开始时间',
    site_list_column_end_time: '截止时间',
    site_list_column_view: '查看',
    site_list_column_edit: '编辑',
}