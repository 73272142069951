export default {
    cloud_create: '新建',
    cloud_totate: '旋转',
    cloud_delete: '删除',
    cloud_delete_all: '删除全部',
    cloud_angle: '视角',
    cloud_rect_current: '矩形(当前)',
    cloud_rect_all: '矩形(所有)',
    cloud_close: '关闭',
    cloud_view: '查看',
    cloud_adjust: '调整',
    cloud_reset: '重置',
    cloud_edit: '编辑',
    cloud_add: '追加',
    cloud_subtract: '减去',
    cloud_cancel: '取消',
    cloud_complete: '完成',
    cloud_check: '检测',
    cloud_move: '移动',
    cloud_line: '轨迹线',

    cloud_delete_current_frame: '删除全部(当前帧)',
    cloud_delete_current_frame_text: '确定删除当前帧所有的物体？',

    cloud_delete_all_frame: '清空作业',
    cloud_delete_all_frame_text: '作业结果不可被恢复，确定请空整份作业中所有的标注结果？',

    cloud_delete_rect_dom_now: '删除矩形（当前）',
    cloud_delete_rect_dom_all: '删除矩形（全部）',
    copy_result_to_next_frame: '复制数据到下一帧',

    delete_mesh_all_frame: '删除（在全部帧）',
    delete_mesh_all_frame_text: "确定删除此物体在所有帧的结果？",

    cloud_ok: "确定",

    cloud_leak_mark: '有漏标的点',
    cloud_all_marked: '已全部标注',
    cloud_lw: '长: X, 宽: X',
    cloud_lh: '长: X, 高: X',
    cloud_wh: '宽: X, 高: X',

    cloud_unit: "米",
    cloud_item_label: "标签：",
    cloud_item_width: "长度：",
    cloud_item_height: "宽度：",
    cloud_item_deep: "高度：",
    cloud_item_position:"位置：",

    delete_data_text: "确定删除当前数据？",
    delete_data_all_text: "确定删除全部数据？",

    show_one: "单一显示",

    cloud_visibility:"可见",

    cloud_all:"全部",
    cloud_inside:"以内",
    cloud_outside:"以外",

    cloud_point_size:"点",
    cloud_small:"小",
    cloud_middle:"中",
    cloud_large:"大",

    cloud_full:"全屏",

    cloud_drawing_mode:"绘制模式",
    cloud_brush:"画笔",
    cloud_round:"圆",
    cloud_square:"方",

    cloud_ai_create:"智能标注",

    cloud_ground_offset:"地面偏移：",

    cloud_toggle_2D_images:"2D图切换：",

    cloud_lock_labeled_points:"锁定已标注的点",

    cloud_empty_points_message1:"当前空白点过多，无需检测",
    cloud_empty_points_message2:",无需检测"

};
