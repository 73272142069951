import common from './common';
import user from './user';
import operator from './operator';
import admin from './admin';
import template from './template';
import tool from './tool';
import audio from './audio';
import cloud from './cloud';
import video from './video';
import project from './project';
import system from './system';
import site from './site';
import auth from './auth';

export default Object.assign({},
    common,
    user,
    operator,
    admin,
    template,
    tool,
    audio,
    cloud,
    video,
    project,
    system,
    site,
    auth,
);
