import {request} from '@/utils/request.js'

//负责人省组织数据统计
const statByProvince = (data) => {
    return request({
        url: '/site/stat-by-province',
        method: 'post',
        data
    })
}


//创建区域负责人信息
const areaCreate = (data) => {
    return request({
        url: '/customer-service/area-create',
        method: 'post',
        data
    })
}

//更新区域负责人信息
const areaUpdate = (data) => {
    return request({
        url: '/customer-service/area-update',
        method: 'post',
        data
    })
}

//删除区域负责人信息
const areaDelete = (data) => {
    return request({
        url: '/customer-service/area-delete',
        method: 'post',
        data
    })
}

//批量设置区域负责人
const batchSetArea = (data) => {
    return request({
        url: '/customer-service/batch-set-area',
        method: 'post',
        data
    })
}

//添加客服
const serviceCreate = (data) => {
    return request({
        url: '/customer-service/create',
        method: 'post',
        data
    })
}

//编辑客服信息
const serviceUpdate = (data) => {
    return request({
        url: '/customer-service/update',
        method: 'post',
        data
    })
}

//删除客服信息
const serviceDelete = (data) => {
    return request({
        url: '/customer-service/delete',
        method: 'post',
        data
    })
}

//客服列表
const serviceList = (data) => {
    return request({
        url: '/customer-service/list',
        method: 'post',
        data
    })
}

//通过组织查询客服信息
const getCustomerBySite = (data) => {
    return request({
        url: '/customer-service/get-customer-by-site',
        method: 'post',
        data
    })
}

//跟进列表
const customerFollowList = (data) => {
    return request({
        url: '/customer-service/followup-list',
        method: 'post',
        data
    })
}


//创建跟进
const customerFollowCreate = (data) => {
    return request({
        url: '/customer-service/followup-create',
        method: 'post',
        data
    })
}

const createFollowBatchList=(data)=>{
    return request({
        url:"/customer-service/followup-batch-list",
        method:"post",
        data
    });
}
const customerFollowBatchCreate = (data) => {
    return request({
        url: '/customer-service/followup-batch-create',
        method: 'post',
        data
    })
}
const createFollowItemList=(data)=>{
    return request({
        url:"/customer-service/followup-item-list",
        method:"post",
        data
    });
}
const customerFollowItemCreate = (data) => {
    return request({
        url: '/customer-service/followup-item-create',
        method: 'post',
        data
    })
}

const deleteBatch=(data)=>{
    return request({
        url: '/customer-service/followup-batch-delete',
        method: 'post',
        data
    })
}

// 院校覆盖统计创建跟进
const createFollowUp=(data)=>{
    return request({
        url:"/customer-service/followup-user-create",
        method:"post",
        data
    });
}


const areaList=(data)=>{
    return request({
        url:"/customer-service/area-list",
        method:"post",
        data
    });
}

// 更新跟进状态
const updateFollowStatus=(data)=>{
    return request({
        url:"/customer-service/followup-item-update",
        method:"post",
        data
    });
}

// 更新跟进状态
const businessOpportunityBatchList=(data)=>{
    return request({
        url:"/customer-service/business-opportunity-batch-list",
        method:"post",
        data
    });
}



export default {
    statByProvince,
    areaCreate,
    areaUpdate,
    areaDelete,
    batchSetArea,
    serviceCreate,
    serviceUpdate,
    serviceDelete,
    serviceList,
    getCustomerBySite,
    customerFollowList,
    customerFollowCreate,
    createFollowUp,
    createFollowBatchList,
    customerFollowBatchCreate,
    createFollowItemList,
    customerFollowItemCreate,
    deleteBatch,
    areaList,
    updateFollowStatus,
    businessOpportunityBatchList
}
