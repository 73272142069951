import {request} from '@/utils/request.js'

//任务列表
const taskList = (data) => {
    return request({
        url: '/score/task-list',
        method: 'post',
        data
    })
}

//编辑任务
const taskUpdate = (data) => {
    return request({
        url: '/score/task-update',
        method: 'post',
        data
    })
}

//任务详情
const taskDetail = (data) => {
    return request({
        url: '/score/task-detail',
        method: 'post',
        data
    })
}

//任务上架
const taskOnline = (data) => {
    return request({
        url: '/score/task-online',
        method: 'post',
        data
    })
}

//任务下架
const taskOffline = (data) => {
    return request({
        url: '/score/task-offline',
        method: 'post',
        data
    })
}

//积分明细
const recordList = (data) => {
    return request({
        url: '/score/record-list',
        method: 'post',
        data
    })
}

//导出积分明细
const exportRecord = (data) => {
    return request({
        url: '/score/export-record',
        method: 'post',
        data
    })
}

export default {
    taskList,
    taskUpdate,
    taskDetail,
    taskOnline,
    taskOffline,
    recordList,
    exportRecord
}
