import PlatformHome from '../views/layout/platformHome.vue'
const platformRoute = {
    path: '/platform',
    redirect: '/home_platform',
    name: 'platform',
    meta:{
      title:'平台管理',
      module_route:'module_platform'
    },
    component:PlatformHome,
    children:[
      {
        path: '/home_platform',
        route:'home_platform',
        name: 'home_platform',
        meta:{
          title:'router_home',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/home/homePlatform.vue'),
      },
      // {
      //   path: '/newboss',
      //   route:'newboss',
      //   name: 'newboss',
      //   meta:{
      //     title:'router_newboss',
      //     module_route:'module_platform',
      //     img:require('@/assets/images/new_icon/data_stat.png'),
      //   },
      //   component: ()=>import('../views/home/newboss.vue'),
      // },
      {
        path: '/user/list',
        route:'user/list',
        name: 'user/list',
        meta:{
          title:'router_user',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/user_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/user/list.vue'),
      },

      {
        path: '/user/detail/:tab?/:id?',
        route:'user/detail',
        name: 'user/detail',
        meta:{
          title:'router_user',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/user/userDetail.vue'),
      },

      {
        path: '/site',
        route:'site',
        name: 'site',
        meta:{
          title:'router_site',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/site_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/site/list.vue'),
      },
      {
        path: '/site/detail/:tab?/:id?',
        route:'site/detail',
        name: 'site/detail',
        meta:{
          title:'router_site_detail',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/site/detail.vue'),
      },
      {
        path: '/site/invitation',
        route:'site/invitation',
        name: 'site/invitation',
        meta:{
          title:'router_site_invitation',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/site/invitation.vue'),
      },
      {
        path: '/business_opportunity_followup',
        route: 'business_opportunity_followup',
        name: 'business_opportunity_followup',
        meta:{
          title:'menu_business_followup',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/code_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/customer/businessFollowup.vue'),
      },
      {
        path: '/customer_service',
        route:'customer_service',
        name: 'customer_service',
        meta:{
          title:'college_coverage_statistics',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/code_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/customer/service.vue'),
      },
      {
        path: '/customer_service/user',
        route:'customer_service/user',
        name: 'customer_service/user',
        meta:{
          title:'客服管理',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/code_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/customer/serviceUser.vue'),
      },
      {
        path: '/cdkey/managelist',
        route:'cdkey/managelist',
        name: 'cdkey/managelist',
        meta:{
          title:'activation_code_list',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/by_list.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/cdkey/manageList.vue'),
      },
      // {
      //   path: '/dataset/category-to-site-apply',
      //   route:'dataset/category-to-site-apply',
      //   name: 'category-to-site-apply',
      //   meta:{
      //     title:'题库申请审核',
      //     module_route:'module_platform',
      //     img:require('@/assets/images/new_icon/tikushenhe.png'),
      //     requiresAuth:true,
      //   },
      //   component: ()=>import('../views/dataset/categoryToSiteApply.vue'),
      // },
      {
        path: '/activity',
        route:'activity',
        name: 'activity',
        meta:{
          title:'event_management',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/activity.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/activity/index.vue'),
      },
      {
        path: '/organize',
        route:'organize',
        name: 'organize',
        meta:{
          title:'主办方管理',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/code_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/activity/organize.vue'),
      },
      {
        path: '/activity/create',
        route:'activity/create',
        name: 'createActivity',
        meta:{
          title:'编辑活动',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/code_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/activity/create.vue'),
      },
      {
        path: '/activity/detail',
        route:'activity/detail',
        name: 'activityDetail',
        meta:{
          title:'报名详情',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/code_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/activity/detail.vue'),
      },
      {
        path: '/activity/category',
        route:'activity/category',
        name: 'activityCategory',
        meta:{
          title:'活动专题管理',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/code_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/activity/category.vue'),
      },
      {
        path: '/points',
        route:'points',
        name: 'points',
        meta:{
          title:'积分管理',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/score.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/score/index.vue'),
      },
      {
        path: '/score/edit',
        route:'score/edit',
        name: 'scoreEdit',
        meta:{
          title:'编辑',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/code_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/score/edit.vue'),
      },
      {
        path: '/score/editExchange',
        route:'score/editExchange',
        name: 'editExchange',
        meta:{
          title:'兑换新增/编辑',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/code_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/score/editExchange.vue'),
      },
      {
        path: '/score/exchangeDetail',
        route:'ecchangeDetail',
        name: 'ecchangeDetail',
        meta:{
          title:'兑换详情',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/code_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/score/exchangeDetail.vue'),
      },
      {
        path: '/certuser/list',
        route:'certuser/list',
        name: 'certuser/list',
        meta:{
          title:'material_management',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/cert_user.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/certUser/list.vue'),
      },
      {
        path: '/wxmini/managelist',
        route:'wxmini/managelist',
        name: 'wxmini-managelist',
        meta:{
          title:'mini_program_customization',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/wx.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/wxmini/managelist.vue'),
      },
      {
        path: '/user/authentication',
        route:'user/authentication',
        name: 'authentication',
        meta:{
          title:'师资认证',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/teacher/authentication.vue'),
      },
      {
        path: '/user/check-authentication',
        route:'user/check-authentication',
        name: 'check-authentication',
        meta:{
          title:'查看',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/teacher/checkAuthentication.vue'),
      },
      {
        path: '/user/teacher-list',
        route:'user/teacher-list',
        name: 'teacher-list',
        meta:{
          title:'menu_user_teacher_list',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/teacher_manage.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/teacher/teacherList.vue'),
      },
      {
        path: '/user/teacher-notice',
        route:'user/teacher-notice',
        name: 'teacher-notice',
        meta:{
          title:'menu_user_teacher_notice',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/teacher/teacherNotice.vue'),
      },
      {
        path: '/user/add-teacher-notice',
        route:'user/add-teacher-notice',
        name: 'add-teacher-notice',
        meta:{
          title:'menu_user_teacher_notice_add',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/teacher/addNotice.vue'),
      },
      {
        path: '/user/statistics',
        route:'user/statistics',
        name: 'user/statistics',
        meta:{
          title:'menu_user_statistics',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/user/statistics.vue'),
      },
      {
        path: '/certcode/list',
        route:'certcode/list',
        name: 'certcode-list',
        meta:{
          title:'menu_certcode_list',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/exam_code.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/certcode/list.vue'),
      },
      {
        path: '/certcode/dispense-list',
        route:'certcode/dispense-list',
        name: 'dispense-list',
        meta:{
          title:'menu_certcode_dispense_list',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/certcode/dispenseList.vue'),
      },
      {
        path: '/system/role',
        route:'system/role',
        name: 'system/role',
        meta:{
          title:'router_role',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/role.vue'),
      },
      {
        path: '/system/role-edit',
        route:'system/role-edit',
        name: 'system-role-edit',
        meta:{
          title:'router_role_edit',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/roleEdit.vue'),
      },
      {
        path: '/system/group',
        route:'system/group',
        name: 'system/group',
        meta:{
          title:'router_group',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/group.vue'),
      },
      {
        path: '/system/group-edit',
        route:'system/group-edit',
        name: 'role-edit',
        meta:{
          title:'router_role_edit',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/groupEdit.vue'),
      },
      {
        path: '/system/menu',
        route:'system/menu',
        name: 'menu',
        meta:{
          title:'router_menu',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/menu.vue'),
      },
      {
        path: '/system/permission',
        route:'system/permission',
        name: 'permission',
        meta:{
          title:'router_permission',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/permission.vue'),
      },
      {
        path: '/system/setting',
        route:'system/setting',
        name: 'setting',
        meta:{
          title:'router_setting',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/setting.vue'),
      },
      {
        path: '/system',
        route:'system',
        name: 'system',
        meta:{
          title:'router_system',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/system_seting.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/index.vue'),
      },
      {
        path: '/system/analysis',
        route:'system/analysis',
        name: 'system/analysis',
        meta:{
          title:'router_system_analysis',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/analysis.vue'),
      },
      {
        path: '/system/user-stat',
        route:'system/user-stat',
        name: 'system/user-stat',
        meta:{
          title:'router_system_user_stat',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/userStat.vue'),
      },
      {
        path: '/system/user-stat-all',
        route:'system/user-stat-all',
        name: 'system/user-stat-all',
        meta:{
          title:'router_system_user_stat_all',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/userStatAll.vue'),
      },
      {
        path: '/system/ws-stat',
        route:'system/ws-stat',
        name: 'system/ws-stat',
        meta:{
          title:'router_system_ws_stat',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/wsStat.vue'),
      },
      {
        path: '/system/ali-stat',
        route:'system/ali-stat',
        name: 'system/ali-stat',
        meta:{
          title:'router_system_ali_stat',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/aliStat.vue'),
      },
      {
        path: '/boss',
        route:'boss',
        name: 'boss',
        meta:{
          title:'router_boss',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/home/boss.vue'),
      },
      {
        path: '/system/chatgpt-list',
        route: '/system/chatgpt-list',
        name: 'system-chatgpt-list',
        meta:{
          title:'chatGPT记录列表',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/chatgptList.vue'),
      },
      {
        path: '/system/auth-refresh',
        route:'system/auth-refresh',
        name: 'auth-refresh',
        meta:{
          title:'router_setting',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/auth-refresh.vue'),
      },
      {
        path: '/system/refresh-cache',
        route:'system/refresh-cache',
        name: 'refresh-cache',
        meta:{
          title:'router_setting',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/refresh-cache.vue'),
      },
      {
        path: '/system/match-robot',
        route:'system/match-robot',
        name: 'match-robot',
        meta:{
          title:'router_setting',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/match-robot.vue'),
      },
      {
        path: '/system/user-online-everyday',
        route:'system/user-online-everyday',
        name: 'user-online-everyday',
        meta:{
          title:'router_setting',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/data_stat.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/system/user-online-everyday.vue'),
      },
      {
        path: '/dataset/feedback',
        route:'dataset/feedback',
        name: 'dataset/feedback',
        meta:{
          title:'router_dataset_feedback',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/feedback_icon.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/dataset/feedback.vue'),
      },
      {
        path: '/dataset/wxmini',
        route: 'dataset/wxmini',
        name: 'wxmini',
        meta:{
          title:'mini_program_customization',
          module_route:'module_platform',
          img:require('@/assets/images/new_icon/wx.png'),
          requiresAuth:true,
        },
        component: ()=>import('../views/wxmini/index.vue'),
      },
    ]
  };

  export default platformRoute;
