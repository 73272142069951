import CourseHome from '../views/layout/courseHome'
const courseRoute = {
    path: '/course-route',
    redirect: '/course/student-list',
    name: 'course-route',
    meta:{
        title:'课程学习',
        module_route:'module_course'
    },
    component: CourseHome,
    children:[
        {
            path: '/course/student-list/:tab?',
            name: 'course/student-list',
            route:'course/student-list',
            meta:{
                title:'router_course_student_list',
                module_route:'module_course',
                img:require('@/assets/images/new_icon/kecheng.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/studentList.vue'),
        },
        {
            path: '/trainingcamp/chapters/:tab?',
            route: 'trainingcamp/chapters',
            name: 'chapters',
            meta:{
                title:'menu_trainingcamp_chapters',
                module_route:'module_course',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/chapters.vue'),
        },
        {
            path: '/trainingcamp/pre-exam',
            route: 'trainingcamp/pre-exam',
            name: 'pre-exam',
            meta:{
                title:'menu_trainingcamp_pre_exam',
                module_route:'module_course',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/preExam.vue'),
        },
        {
            path: '/course/interactExam',
            route: 'course/interactExam',
            name: 'course/interactExam',
            meta:{
                title:'menu_course_interact_exam',
                module_route:'module_course',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/course/interactExam.vue'),
        },
        {
            path: '/trainingcamp/material-self-exam',
            route: 'trainingcamp/material-self-exam',
            name: 'material-self-exam',
            meta:{
                title:'menu_trainingcamp_material_self_exam',
                module_route:'module_course',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/materialSelfExam.vue'),
        },
        {
            path:"/liveroom",
            name:"liveroom",
            route:'liveroom',
            meta:{
                title:'live_room',
                module_route:'module_course',
                img:require('@/assets/images/new_icon/exam.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/live/liveRoomList.vue'),
        },
        {
            path: '/course/trainingcamp/trainDetail',
            route:'trainingcamp/trainDetail',
            name: 'trainingcamp-trainDetail',
            meta:{
                title:'menu_trainingcamp_train_detail',
                module_route:'module_course',
                img:require('@/assets/images/new_icon/train.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/trainDetail.vue'),
        }
    ]
};

export default courseRoute;
