const infoManagerRoute = {
    path: '/info-manager-route',
    redirect: '/user/mylist',
    name: 'info-manager-route',
    meta:{
        title:'信息管理',
        module_route:'module_info_manager'
    },
    component: ()=>import('../views/layout/infoManagerHome.vue'),
    children:[
        {
            path: '/user/mylist',
            route: 'user/mylist',
            name: 'user/mylist',
            meta:{
                title:'organizational_structure',
                module_route:'module_info_manager',
                img:require('@/assets/images/new_icon/my_site.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/user/mylist.vue'),
        },
        {
            path: '/manager/lecturer',
            route:'lecturer',
            name: 'lecturer',
            meta:{
                title:'menu_lecturer',
                module_route:'module_info_manager',
                img:require('@/assets/images/new_icon/exam.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/user/lecturer.vue'),
        },
        {
            path: '/manager/trainingcamp/mycerts',
            route: 'trainingcamp/mycerts',
            name: 'trainingcamp/mycerts',
            meta:{
                title:'route_trainingcamp_mycerts',
                module_route:'module_info_manager',
                img:require('@/assets/images/new_icon/cert_user.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/certUser/mycerts.vue'),
        },
        {
            path: '/cdkey/list',
            route: 'cdkey/list',
            name: 'cdkey-list',
            meta:{
                title:'activation_code_list',
                module_route:'module_info_manager',
                img:require('@/assets/images/new_icon/code_manage.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/cdkey/list.vue'),
        },
        {
            path: '/cdkey/detail/:id?/:tab?',
            route: 'cdkey/detail',
            name: 'cdkey-detail',
            meta:{
                title:'激活码详情',
                module_route:'module_info_manager',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/cdkey/detail.vue'),
        },
        // {
        //     path: '/myinfo/:tab?',
        //     name: 'myinfo',
        //     meta:{
        //         title:'module_info_student',
        //         requiresAuth:true,
        //     },
        //     component: ()=>import('../views/user/myinfo.vue'),
        // },
        {
            path: '/mysite/:tab?/:id?',
            route: 'mysite',
            name: 'mysite',
            meta:{
                title:'router_mysite',
                module_route:'module_info_manager',
                img:require('@/assets/images/new_icon/my_site.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/site/mysite.vue'),
        },
        {
            path: '/wxmini',
            route: 'wxmini',
            name: 'wxmini',
            meta:{
                title:'mini_program_customization',
                module_route:'module_info_manager',
                img:require('@/assets/images/new_icon/wx.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/wxmini/index.vue'),
        },
        {
            path: '/manager/user/detail/:tab?/:id?',
            route:'user/detail',
            name: 'manager/user/detail',
            meta:{
                title:'router_user',
                module_route:'module_info_manager',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/user/userDetail.vue'),
        },
    ]
};
export default infoManagerRoute
