<template>
  <div class="home">
    <Head></Head>
    <div class="main" :class="{'main-hide-text': shrink}">
      <div class="sidebar-menu-con" :style="{width: shrink ? '63px':'220px'}">
        <leftMenu :shrink="shrink"
              @shrinkHandle="shrinkHandle"
              :menu-list="menuList"
        ></leftMenu>
      </div>
      <div class="cont" id="cont" :style="{width: shrink ? 'calc(100% - 63px)' : 'calc(100% - 220px)',height:$store.state.app.chromTip ? 'calc(100% - 66px)' : '100%'}">
        <router-view></router-view>
      </div>
<!--      <div class="home-help">-->
<!--        <a href="https://alidocs.dingtalk.com/i/p/dN0G7R9PrNRrMmWY" target="_blank">-->
<!--          <img src="../../assets/images/new_icon/help.png">-->
<!--        </a>-->
<!--      </div>-->
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Cookies from 'js-cookie';
import Head from './components/head.vue'
import leftMenu from './components/menu.vue'

import util from '@/utils/tools.js'
export default {
  name: 'Home',
  components: {
    Head,
    leftMenu
  },
  data(){
    return {
      shrink: Cookies.get('liteMenuShrink') === 'true',
      isFullScreen: false,
      openedSubmenuArr: this.$store.state.app.openedSubmenuArr,
      // isChrome:true,
    }
  },
  computed: {
    menuList () {
      return this.$store.state.app.menuList;
    },
    // menuTheme () {
    //   return this.$store.state.app.menuTheme;
    // },
  },
  created(){
    // this.isChrome =  navigator.userAgent.indexOf('Chrome') > -1;
    // let token = Cookies.get('liteAccessToken');
    // if(token){
    //   let domain = util.getDomain();
    //
    //   Cookies.set(tokenName, token,{domain});
    // }
    // let aiToken = Cookies.get(tokenName);
    // if(aiToken){
    //   Cookies.set('liteAccessToken',aiToken)
    // }

    this.getUserInfo();
  },
  methods: {
    init () {
      let pathArr = util.setCurrentPath(this, this.$route.name, this.$route.meta);
      // this.$store.commit('updateMenulist');
      if (pathArr.length >= 2) {
        this.$store.commit('addOpenSubmenu', pathArr[1].name);
      }
    },
    shrinkHandle (shrink) {
      this.shrink = shrink;
      this.$store.state.app.shrink = shrink;
    },
    getUserInfo () {
      // 获取用户信息
      const userInfo = this.$store.state.user.userInfo;

      this.api.index.userDetail().then((res)=>{
        const userDetail = res.user;
        Cookies.set('liteMenuShrink', false);
        this.$store.state.app.settings = {
          ...res.settings
        };
        this.$store.commit('setUserSites',res.userSites);
        this.$store.commit('setUserSetting',res.settings);
        if (userDetail) {
          this.$store.state.user.userInfo = {
            ...this.$store.state.user.userInfo,
            ...userDetail
          };
          this.$store.commit('login', this.$store.state.user.userInfo);

          // let lang = userDetail.language === '0' ? 'zh-CN' : 'en-US';
          let lang = Cookies.get('lang') || 'zh-CN';
          // this.$store.commit('switchLang', lang);
          // 这里的直接修改store信息
          // 因为用上一行的方式切换语音是个异步操作 会导致下边的提示信息用的语言和用户看到的不一样
          localStorage.lang = lang;
          this.$store.state.app.lang = lang;
          this.$i18n.locale = lang;

          // this.$store.commit('setPermissions', userDetail.permisstions);
          // console.log(this.$route,'===============')
          this.$store.commit('updateModuleMenu', {menu:userDetail.auth_menus, all:this.$store.state.app.moduleList,route:this.$route});
          // console.log(this.$route.meta.module_route,'this.$store.state.app.menuList')
          if(this.$route.meta.module_route){
            let moduleRouteArr = this.$route.meta.module_route.split(',');
            let moduleRouteArrStatus = moduleRouteArr.every((item)=>{
              return item != this.$store.state.app.curModule;
            })
            if(moduleRouteArrStatus){
              this.$router.push({
                path: this.$store.state.app?.menuList[0]?.path
              });
            }
          }else{
            this.$router.push({
              path: this.$store.state.app?.menuList[0]?.path
            });
          }


          // this.$store.commit('updateMenulist', {menu:userDetail.menus, all:this.$store.state.app.menuListAll});
        }
        this.$Message.destroy();

        this.$store.commit('clearAllTags');
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.home{
  height: 100%;
  //background-color: #F0F0F0;
  //overflow-y: auto;
  .main{
    height: calc(100vh - 48px);
    display: flex;
    justify-content: flex-start;
    .sidebar-menu-con{
      width: 200px;
      min-height: 100%;
      transition: width 0.3s;
      background: #495060;
      //overflow: hidden;
      .ivu-shrinkable-menu {
        .sideSmall{
          .ivu-menu-item {
            padding-left: 24px !important;
          }
        }
        .ivu-menu-submenu-title-icon{
          // display: none;
          position: absolute;
          left: 24px;
          top: 18px;
        }
      }
    }
    .cont{
      width: calc(100% - 200px);
      height: 100%;
      overflow-y: auto;
      //overflow: hidden;
    }
  }
  .main-hide-text .layout-text{
    display: none;
  }
  .home-help{
    position: fixed;
    right: 10px;
    bottom: 12px;
    z-index: 999;
    >img{
      width: 68px;
      height: 68px;
    }
  }
}
</style>
