export default {
    system_upload_file: '上传文件(.zip)',
    system_maintain_file: '维护文件',
    system_update: '更新',
    system_download: '下载',
    system_operation_tip: '操作提示',
    system_sure_update: '确定要更新吗？请谨慎决定',
    system_backed_up: '已备份',
    system_back_up: '备份',
    system_upload_successful: '  上传成功',
    system_incorrent_format: '  格式不正确',
    system_upload_error: '  上传失败',
    system_daily_activity_statistics: '每日用户作业量统计',
    system_daily_traffic_statistics: '各平台每日访问量统计',
    system_online_number: '在线人数',
    system_people: '人',
    system_no_data: '暂无数据',
    system_refresh_permissions: '刷新权限',
    system_configuration_information: '配置信息...',
    system_new_identify: '新增标识',
    system_input_find_indentify: '请输入查找标识',
    system_input_input_indentify: '请输入标识',
    system_search: '搜索',
    system_save: '保存',
    system_edit: '编辑',
    system_new: '新增',
    system_cancel: '取消',
    system_refresh: '刷新',
    system_delete: '删除',
    system_new_info: '新增信息',
    system_name: '名称',
    system_input_name: '请输入名称',
    system_indentify: '标识',
    system_input_indentify: '请输入标识',
    system_value: '值',
    system_input_value: '请输入value',
    system_whether_delete: '是否可删除',
    system_is: '是',
    system_no: '否',
    system_description: '描述',
    system_input_description: '请输入描述',
    system_add: '添加',
    system_edit_info: '编辑信息',
    system_sure_delete: '确定要删除吗？',
    system_select_type: '选择类型',
    system_input_indentify_name: '请输入标识名称',
    system_value_type: '值的类型',
    system_select_value_type: '请选择值的类型',
    system_handle: '操作',
    system_indentify_null: '标识不能为空',
    system_name_not_empty: '名称不能为空',
    system_value_not_empty: 'value不能为空',
    system_select_necessity: '请选择必要性',
    system_description_not_empty: '描述不能为空',
    system_value_not_null: '值不能为空',
    system_complete_newly_added_identity: '请完成当前新增标识',
    system_new_success: '新增成功',
    system_update_success: '更新成功',

    system_func: '系统功能',
    system_auth_setting: '权限配置',
    system_index_user: '在线用户',
    system_index_statis: '实时统计',
    system_index_deploy: '数据部署',
    system_index_detection: '服务器检测',
    system_index_log: '系统日志',
    system_index_settinng: '系统设置',
    system_index_announce: '平台公告',
    system_index_notice: '系统通知',
    system_index_role_manage: '角色管理',
    system_index_rights_manage: '权限管理',

    system_onlineuser_root: 'Root在线人数：{number} 人',
    system_onlineuser_admin: 'Admin在线人数：{number} 人',
    system_onlineuser_team: 'Team在线人数：{number} 人',
    system_onlineuser_rootplat: 'ROOT平台',

    system_home_sheet: '张数',
    system_home_box: '框数',
    system_home_point: '点数',

    system_deployment_add: '新增数据',
    system_deployment_refresh: '刷新',
    system_deployment_name: '数据包名称：',
    system_deployment_input: '请输入数据包名称',
    system_deployment_search: '搜索',
    system_deployment_viewdate: '查看数据',
    system_deployment_uploaded: '已上传数据：',
    system_deployment_close: '关闭',
    system_deployment_modaltitle: '操作提示',
    system_deployment_modaltips: '确认删除该条数据吗？',
    system_deployment_cancel: '取消',
    system_deployment_sure: '确定',
    system_deployment_dataname: '数据名称',
    system_deployment_deploypath: '部署路径',
    system_deployment_ftpadd: 'FTP地址：',
    system_deployment_ftppath: 'FTP路径：',
    system_deployment_ftpname: 'FTP用户名：',
    system_deployment_ftppwd: 'FTP密码：',
    system_deployment_datasize: '数据包大小',
    system_deployment_datacount: '数据量',
    system_deployment_uploadtime: '上传时间',
    system_deployment_opera: '操作',
    system_deployment_download: '下载',
    system_deployment_view: '查看',
    system_deployment_delete: '删除',
    system_deployment_againupload: '重新上传',
    system_deployment_deleted: '已删除',

    system_info_memory_refresh: '刷新',
    system_info_memory_net_detect: '网络检测',
    system_info_server_args: '服务器参数',
    system_info_server_domainIp: '服务器域名和IP',
    system_info_server_tag: '服务器标识',
    system_info_server_os: '服务器操作系统',
    system_info_server_engine: '服务器WEB服务引擎',
    system_info_server_lang: '服务器语言',
    system_info_server_port: 'web服务端口',
    system_info_server_name: '服务器主机名',
    system_info_web_path: 'web路径',
    system_info_script_path: '本脚本所在路径',

    system_info_server_data: '服务器实时数据',
    system_info_server_curtime: '服务器当前时间',
    system_info_server_time: '服务器已运行时间',
    system_info_cpu: 'CPU型号',
    system_info_cpu_status: 'CPU使用状况',
    system_info_disk_status: '硬盘使用状况',
    system_info_disk_size: '总空间',
    system_info_disk_used: '已用',
    system_info_disk_available: '空闲',
    system_info_usage: '使用率',

    system_info_memory_status: '内存使用状况',
    system_info_memory_size: '物理内存：共',
    system_info_cache: 'Cache化内存为',

    system_info_buffers: 'Buffers缓冲为',
    system_info_memory_used: '真实内存使用',
    system_info_memory_available: '真实内存空闲',
    system_info_swap_size: 'SWAP区：共',
    system_info_used: '已使用',
    system_info_system_load_average: '系统平均负载',
    system_info_network_status: '网络使用状况',
    system_info_net: '入网：',
    system_info_real_time: '实时：',
    system_info_out_net: '出网：',
    system_info_net_desc1: '正在 Ping 服务器 具有 32 字节的数据',
    system_info_net_desc2: '来自 服务器 的回复: 字节=32 时间',
    system_info_net_desc3: '服务器 Ping 统计信息',
    system_info_net_desc4: '往返行程的估计时间(以毫秒为单位)',
    system_info_shortest: '最短',
    system_info_longest: '最长',
    system_info_average: '平均',
    system_info_closed: '关闭',
    system_info_test_again: '再次检测',


    system_setting_add:'添加设置',
    system_setting_check:'查看设置',
    system_setting_edit:'编辑设置',
    system_setting_dele_tip:'确认删除设置吗？',
    system_setting_table_index:'序号',
    system_setting_table_key:'Key',
    system_setting_table_value:'Value',
    system_setting_table_name:'名称',
    system_setting_table_desc:'描述',
    system_setting_table_op:'操作',
    system_setting_add_name_tip:'请输入名称',
    system_setting_add_key_tip:'请输入key',
    system_setting_add_value_tip:'请输入value',
    system_setting_add_desc_tip:'请输入描述',
    system_setting_search_tip:'请输入设置名称',

    system_setting_form_name: '名称 :',
    system_setting_form_input_name: '请输入名称',
    system_setting_form_key: '标识 :',
    system_setting_form_input_key: '请输入标识',
    system_setting_form_value: '值 :',
    system_setting_form_input_value: '请输入value',
    system_setting_form_can_delete: '是否可删除 :',
    system_setting_form_yes: '是',
    system_setting_form_no: '否',
    system_setting_form_desc: '描述 :',
    system_setting_form_input_desc: '请输入描述',
    system_setting_form_add: '添加',
    system_setting_form_edit: '编辑信息',
    system_setting_form_save: '保存',
    system_input_dataname_limit: '至多30个字符',

    system_script_management: '脚本管理',
    system_script_select: '请选择',
    system_script_cancel: '取消',
    system_script_confirm: '确定',
    system_script_hint: '提示',
    system_script_querycondition: '查询条件',
    system_script_id: '脚本ID',
    system_script_inputid: '请输入脚本ID',
    system_script_name: '脚本名称',
    system_script_inputname: '请输入脚本名称',
    system_script_tenement: '所属组织',
    system_script_permission: '使用权限',
    system_script_public: '公有脚本',
    system_script_time: '创建时间',
    system_script_start_end: '开始时间-结束时间',
    system_script_query: '查询',
    system_script_reset: '重置',
    system_script_datalist: '数据列表',
    system_script_addscript: '增加脚本',
    system_script_createscript: '创建脚本',
    system_script_editscript: '编辑脚本',
    system_script_url: '脚本地址',
    system_script_inputurl: '请输入脚本地址',
    system_script_classify: '适用分类',
    system_script_utility: '通用类',
    system_script_selecttemplate: '选择模板',
    system_script_confirmdel: '确定禁用此数据',
    system_script_confirmrecover: '确定恢复此数据',
    system_script_state: '状态',
    system_script_template: '模板',
    system_script_operation: '操作',
    system_script_name_nonull: '脚本名称不能为空',
    system_script_url_nonull: '脚本地址不能为空',
    system_overlay_number: '点云文件合并',
    system_script_permission_set: '请选择使用权限',


    system_check:'查看',
    system_edit:'编辑',
    system_dele:'删除',

    system_role_add:'添加角色',
    system_role_add_name:'名称',
    system_role_add_desc:'描述',
    system_role_add_name_tip:'请输入名称',
    system_role_add_desc_tip:'请输入描述',
    system_role_add_api_name:'请输入接口名称',
    system_role_add_api_list:'可选择的接口权限',
    system_role_add_menu_name:'请输入菜单名称',
    system_role_add_menu_list:'可选择的菜单权限',
    system_role_add_api_list_tip:'请选择接口权限',
    system_role_add_menu_list_tip:'请选择菜单权限',
    system_role_add_confirm:'确定',
    system_role_add_back:'返回',
    system_role_add_nodata:'暂无数据',
    system_role_dele_tip:'确认删除角色吗？',
    system_role_table_index:'序号',
    system_role_table_name:'名称',
    system_role_table_desc:'描述',
    system_role_table_num:'用户数量',
    system_role_table_op:'操作',
    system_role_search_tip:'请输入角色名称',


    system_permission_add:'添加接口权限',
    system_permission_check:'查看接口权限',
    system_permission_edit:'编辑接口权限',
    system_permission_dele_tip:'确认删除接口权限吗？',
    system_permission_table_index:'序号',
    system_permission_table_name:'名称',
    system_permission_table_desc:'描述',
    system_permission_table_op:'操作',
    system_permission_add_name_tip:'请输入名称',
    system_permission_add_desc_tip:'请输入描述',
    system_permission_search_tip: '请输入接口权限名称',

    system_menu_add:'添加菜单权限',
    system_menu_check:'查看菜单权限',
    system_menu_edit:'编辑菜单权限',
    system_menu_dele_tip:'确认删除菜单权限吗？',
    system_menu_table_index:'序号',
    system_menu_table_name:'路由',
    system_menu_table_parent:'父级',
    system_menu_table_route:'路由',
    system_menu_table_sort:'排序',
    system_menu_table_desc:'描述',
    system_menu_table_op:'操作',
    system_menu_add_name_tip:'请输入名称',
    system_menu_add_parent_tip:'请输入父级',
    system_menu_add_route_tip:'请输入路由',
    system_menu_add_sort_tip:'请输入排序',
    system_menu_add_desc_tip:'请输入描述',
    system_menu_search_tip : '请输入菜单权限名称'
};
