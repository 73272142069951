export default {
    // 我的任务
    operator_input_search: "搜索",
    operator_input_project_info: "请输入任务ID、名称",
    operator_input_text: "请输入搜索内容",
    operator_loading: "作业文本加载中...",
    operator_modification: "修改",
    operator_task: "任务",
    operator_project_id: "项目ID",
    operator_batch_id: "批次ID",
    operator_step_id: "工序ID",
    operator_task_id: "任务ID",
    operator_task_name: "任务名称",
    operator_task_type: "任务类型",
    operator_step: "工序",
    operator_working_out: "作业量",
    operator_schedule: "进度",
    operator_starting_time: "启动时间",
    operator_end_time: "结止时间",
    operator_handle: "操作",
    operator_qc: "质检",
    operator_audit: "审核",
    operator_wait_audit: '待审核',
    operator_auditing: '审核中',
    operator_audit_accuracy: "按正确率审核",
    operator_audit_editing: "修改 ： 执行驳回后的作业",
    operator_audit_review: "审核 ： 审核被驳回后重做的作业",
    operator_audit_qc: "质检 ： 质检被驳回后重做的作业",
    operator_audit_execution: "执行 ： 取消挂起作业状态，重新执行此作业",
    operator_execute: "执行",
    operator_reject_work: "驳回作业",
    operator_reject_work_reason: "错误原因",
    operator_difficult_work: "挂起作业",
    operator_difficult: '挂起中',
    operator_rework_work: "返工作业",
    operator_works: "作业数",
    operator_fields_description: "字段说明",
    operator_execute_explain: "已执行张数 / 可执行张数 / 批次作业总量",
    operator_symbol_explain: "-- 表未初始化, 可点击执行",
    operator_execute_description:
        "执行： 已执行张数 / 可执行张数 / 批次作业总量",
    operator_audit_description: "审核： 已审核张数 / 可执行张数 / 批次作业总量",
    operator_qc_description: "验收： 已验收张数 / 可执行张数 / 批次作业总量",
    operator_description: "执行、审核、质检：点击进入执行、审核、质检界面",
    operator_reject_work_description:
        "驳回作业（作业数n）：您有 n 张被驳回作业，可点击修改",
    operator_difficult_work_description:
        "挂起作业（作业数n）：您有 n 张挂起作业，可点击修改",
    operator_rework_work_description:
        "返工作业（作业数n）：您有 n 张被驳回再提交的作业，可点击审核",
    operator_nearby_no_image: "暂无作业",

    // 任務詳情
    operator_export_performance: "导出绩效",
    operator_task_description: "任务描述",
    operator_no_description: "暂无描述",
    operator_my_performance: "我的绩效",
    operator_performance_ranking: "绩效排行",
    operator_my_performance_chart: "我的绩效(图表)",
    operator_my_job: "我的作业",
    operator_download_lists: "下载列表",
    operator_download: "下载",
    operator_filename: "文件名",

    // 我的作业
    operator_all_job: "全部作业",
    operator_active: "作业中",
    operator_submitted: "已提交",
    operator_passed: "通过",
    operator_expired: "已失效",
    operator_rejected: "被驳回",
    operator_need_rework: "待返工",
    operator_need_rework_tips: "待返工：该作业的父工序未修改完成",
    operator_rework: '待复审',
    operator_job_id: "作业ID",
    operator_input_join_id: '请输入作业ID',
    operator_job_name: "作业名称",
    operator_status: "状态",
    operator_join_time: '加入时间',
    operator_update_time: "更新时间",
    operator_view_data: "查看数据",
    operator_reject_cause: '驳回原因',
    operator_remaining_effective_time: "剩余有效时间",
    operator_time_unit: "单位： 秒( s )",
    operator_cumulative: "累计工时",
    operator_mark_number: "标注数",
    operator_picture_description:
        "图片标注 ： 指标注图形数, 如框数、点数、区域数",
    operator_text_description: "文本标注 ： 指关键词条数",
    operator_voice_description: "语音标注 ： 指语音有效片段数",
    operator_button: "按钮说明",
    operator_button_edit_description: "修改 ： 取消已提交状态，重新执行此作业",
    operator_points: "点数",
    operator_audit_time: "审核时间",
    operator_failure_Cause: "失效原因",
    operator_job_results: "作业结果",
    operator_record: "操作记录",
    operator_success: "操作成功",
    operator_vpos: "操作员",
    operator_type: "类型",
    operator_operating_state: "操作状态",
    operator_operating_type: "操作类型",
    operator_receive_time: "领取时间",
    operator_submission_time: "提交时间",
    operator_creation_time: "创建时间",
    operator_totle_valid_work: "提交总张数",
    operator_totle_submitted_work: "提交总次数",
    operator_totle_lines: "总线数",
    operator_totle_boxs: "总矩形",
    operator_totle_polygons: "总多边形",
    operator_number_others: "其他总数",
    operator_number_others_desc:
        "作业中创建的除点、线、矩形、多边形之外的标注数统计",
    operator_valid_work: "提交张数",
    operator_submitted_work: "提交次数",
    operator_point: "点",
    operator_line: "线",
    operator_rectangle: "矩形",
    operator_polygon: "多边形",
    operator_other: "其他",
    operator_previous_operator: "前操作员",
    operator_before_workstatus: "前作业状态",
    operator_after_workstatus: "后作业状态",
    operator_operating_time: "操作时间",
    operator_refreshing_tip: "刷新页面会导致多次重复以下情况:",
    operator_refreshing_tip_desc1: "领取-领取-...",
    operator_refreshing_tip_desc2: "领取-执行-领取-执行-...",

    operator_shape_circler: '圆',
    operator_shape_ellipse: '椭圆',
    operator_shape_rect: '矩形',
    operator_shape_bonepoint: '骨骼点',
    operator_shape_closedcurve: '闭合曲线',
    operator_shape_cuboid: '长方体',
    operator_shape_line: '线',
    operator_shape_pencilline: '钢笔线',
    operator_shape_point: '点',
    operator_shape_polygon: '多边形',
    operator_shape_quadrangle: '四边形',
    operator_shape_splinecurve: '曲线',
    operator_shape_trapezoid: '梯形',
    operator_shape_triangle: '三角形',
    operator_shape_unclosedpolygon: '折线',
    operator_shape_rectP: '矩形+点',
    operator_shape_rectS: '矩形印章(固定宽高的矩形)',
    // 我的绩效
    operator_total_time: "总工时：{num} 秒",
    operator_total_sheet: "作业总张数",
    operator_total_mark_number: "作业总标注数",
    operator_total_count: "作业总点数",
    operator_total_approved: "已通过总数",
    operator_total_rejected: "被驳回总数",
    operator_total_reset: "被重置总数",
    operator_total_time_vocie: "作业总时长(语音)",
    operator_time: "时间",
    operator_amount: "张数",
    operator_box: "框数",
    operator_counting: "通过数",
    operator_execute_passed: "执行作业的已通过张数",
    operator_rejecting: "驳回数",
    operator_execute_rejected: "执行作业的被驳回张数",
    operator_reseting: "重置数",
    operator_execute_reseted: "执行作业的被重置张数",
    operator_reseted: "被重置",
    operator_reseted_description: "质检环节的被重置张数",
    operator_batch_editing: "批量修改",
    operator_batch_review: "批量审核",
    operator_batch_qc: "批量质检",
    operator_batch_execution: "批量执行",
    operator_unchecked_job: "暂未勾选作业",
    operator_qced: "被质检",
    operator_check: "查看",
    operator_instrument_panel: '仪表盘',
    operator_view_details: '点击查看详情',
    operator_label_amount: '标注统计细分',
    operator_query_condition: '查询条件',
    operator_start_time_and_end_time: '开始时间~结束时间',
    operator_query: '查询',
    operator_reset: '重置',
    operator_processed_work_count: '已处理作业统计',
    operator_audit_submit_total_time: '审核提交总次数',
    operator_audit_submit_total_time_dasc: '当前任务审核已做作业提交次数总和',
    operator_totle_submitted_work_dasc: '当前任务已做作业提交次数总和',
    operator_audit_submit_total_folio: '审核提交总张数',
    operator_audit_submit_total_folio_dasc: '当前任务审核已做作业提交总张数',
    operator_totle_valid_work_dasc: '当前任务已做作业提交总张数',
    operator_work_status_count: '作业状态统计',
    operator_reject_count: '审核驳回总数',
    operator_reject_count_dasc: '当前任务审核员驳回执行总次数',
    operator_reset_count: '审核重置总数',
    operator_reset_count_dasc: '当前任务审核员重置执行总次数',
    operator_rejected_count: '审核被驳回总数',
    operator_rejected_count_dasc: '当前任务被验收驳回次数总和',
    operator_label_count: '标注统计',
    operator_percent_pass_dasc: '审核已通过/已验收作业张数',
    operator_execute_percent_pass_dasc: '审核已通过张数/已审核作业张数',
    operator_work_time: '工作时长',
    operator_work_time_dasc: '前任务已审核工时总和（含有审核通过和审核未通过）',
    operator_execute_work_time_dasc: '当前任务已做作业工时总和(含有审核通过和审核未通过)',
    operator_work_total_time: '累计工作总时长(s)',
    operator_execute_work_total_time: '累计工作时长(s)',
    operator_invalid_data: '无效数据',
    operator_invalid_data_amount: '无效数据总数',
    operator_valid_data: '有效数据',
    operator_valid_data_amount: '有效数据总数',
    operator_delete_count: '删除数(仅展示)',
    operator_delete_record: '删除操作记录(仅展示)',
    operator_delete_frame: '删除框',
    operator_totle_pass_work: '被通过总张数',
    operator_totle_passed_work_dasc: '当前任务已做作业审核通过张数总和',
    operator_totle_rejected_work: '被驳回总次数',
    operator_totle_rejected_work_dasc: '当前任务被审核驳回次数总和',
    operator_totle_reseted_work: '被重置总次数',
    operator_totle_reseted_work_dasc: '当前任务被审核重置次数总和',
    operator_work_count: '作业统计',
    operator_invalid_data_amount_dasc: '当作业员在清洗控件选择no记录张数总和',
    operator_valid_data_amount_dasc: '当作业员在清洗控件选择yes记录张数总和',

    // 执行
    operator_execute_amount: "执行作业的张数",
    operator_execute_points: "执行作业的点数",
    operator_operation_time: "作业时长",
    operator_speech: "只限于语音",
    operator_audited: "被审核",
    // 审核
    operator_execute_audit_amount: "执行作业的审核张数",
    operator_mark_number_amount: "执行作业的审核标注数",
    operator_qc_passed_amount: "质检环节的已通过张数",
    operator_qc_reject_amount: "质检环节的被驳回张数",
    // 质检
    operator_qc_amount: "质检环节的质检张数",
    operator_qc_label: "质检环节的质检标注数",
    operator_qc_pass_frame: "质检环节的通过张数",
    operator_qc_reject_frame: "质检环节的驳回张数",
    operator_qc_reset_frame: "质检环节的重置张数"
};
