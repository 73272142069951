export default {
    project_team: 'Team',
    project_crowdsourcing: 'Crowdsourcing',
    project_ai: 'AI model',
    project_search: 'Search',
    project_project: 'Project',
    project_external_name: 'External name',
    project_internal_name: 'Internal name',
    project_enter_external_name: 'Enter an external name',
    project_enter_internal_name: 'Enter an internal name',
    project_search_project_nameid: 'Enter the project name, ID',
    project_search_user_nameid: 'Enter user ID, name, publisher',
    project_search_category: 'Enter the category ID, template name',
    project_search_content: 'Enter serch content',
    project_search_project_name: 'Enter the project name',
    project_cancel: 'Cancle',
    project_delete: 'Delete',
    project_remove: 'Remove',
    project_edit: 'Edit',
    project_save: 'Save',
    project_sure: 'Confirm',
    project_back: 'Back',
    project_close: 'Close',
    project_save_success: 'Successfully saved',
    project_save_error: 'Save failed',
    project_delete_success: 'Successfully deleted',
    project_create: 'Create',
    project_create_date: 'Create a date',
    project_operation_tip: 'Warning',
    project_operation: 'Operation',
    project_configuration: 'Configuration',
    project_copy: 'Copy',
    project_pause: 'Pause',
    project_complete: 'Complete',
    project_recover: 'Recover',
    project_restart: 'Restart',
    project_previous_step: 'Previous step',
    project_save_and_exit: 'Save and exit',
    project_exit: 'Exit',
    project_continue: 'Continue',
    project_next_step: 'Next step',
    project_release: 'Release',
    project_configuration_updated: 'Project configuration updated',
    project_select_type: 'Select type',

    project_add_permission: 'Add permissions',
    project_edit_permission: 'Edit permission information',
    project_permission_name: 'Permission name',
    project_enter_permission: 'Search permission',
    project_enter_permission_name: 'Enter permission name',
    project_permission_description: 'Permission description',
    project_enter_permission_description: 'Enter permission description',
    project_sure_delete_permission: 'Are you sure you want to delete this permission?',
    project_permission_name_null: 'Permission name cannot be empty',
    project_permission_description_null: 'Permission description cannot be empty',

    project_add_role: 'Add role information',
    project_role_name: 'Role Name',
    project_enter_role: 'Search role',
    project_enter_role_name: 'Enter role name',
    project_enter_role_description: 'Enter role description',
    project_sure_delete_role: 'Are you sure you want to delete this role?',
    project_role_description: 'Role description',
    project_member: 'Member',
    project_role_name_null: 'Role name cannot be empty',
    project_role_description_null: 'Role description cannot be empty',
    project_role_permission_configuration: 'Role permission configuration',
    project_name: 'Name',
    project_description: 'Description',
    project_input_template_description: 'Enter model description...',
    project_sample_request: 'Request example',
    project_input_sample_request: 'Input request example...',
    project_please_sample_request: 'Input request example',
    project_input_extract_field: 'Input extraction field',
    project_extract: 'Extract',
    project_request_field_configuration: 'Request field configuration',
    project_response_sample: 'Response example',
    project_input_response_sample: 'Input response example...',
    project_please_response_sample: 'Input response example',
    project_response_field_configuration: 'Response field configuration',
    project_input_response_field_configuration: 'Input response field configuration',
    project_input_configuration_parameters: 'Input configuration parameters...',
    project_input_value: 'Enter value',
    project_input_example_json_format: 'For response example, enter json format data.',
    project_field_configuration_value_incorrectly: 'In the field configuration, the following Value data is configured incorrectly:',
    project_requesting_field_configuration: 'Request field is being configured: ',
    project_request_field: 'Request field',
    project_responsing_field_configuration: 'Response field is being configured: ',
    project_response_field: 'Response field',
    project_input_json_data: 'Enter JSON data...',
    project_please_input_json_data: 'Enter JSON data',
    project_no_json_data: 'Not a JSON data format',

    project_no_path_to_this_field: 'Field path is not found',
    project_permission: 'Permission',
    project_configuration_success: 'Successful configuration',
    project_new_role: 'New role',
    project_new_success: 'Successfully created',
    project_add_member: 'Add member',
    project_batch_change_role: 'Batch change roles',
    project_batch_remove_member: 'Batch remove members',
    project_sure_remove_current_member: 'Are you sure you want to remove the current member?',
    project_sure_remove_checked_member: 'Are you sure you want to remove the checked members?',
    project_select_role: 'Select a role',
    project_please_select_role: 'Select a role',
    project_sure_transfer: 'Transfer',
    project_user_id: 'User ID',
    project_user_name: 'User name',
    project_user_email: 'User mailbox',
    project_removed: 'Removed',
    project_nickname: 'Nickname',
    project_email: 'Email',
    project_type: 'Type',
    project_role: 'Role',
    project_add: 'Add',
    project_added: 'Added',

    project_new_category: 'New category',
    project_preview: 'Preview',
    project_set_label: 'Set labels',
    project_task_type: 'Task type',
    project_label_type: 'Label type',
    project_category_name: 'Category name',
    project_category_type: 'Category type',
    project_keyword: 'Keyword',
    project_small_icon: 'Small_icon',
    project_big_icon: 'Big_icon',
    project_check: 'View',
    project_data_field_value: 'Data field value',
    project_template_field_value: 'Template field value',
    project_delete_category: 'Delete category',
    project_sure_delete_category: 'Delete the category',
    project_picture_class: 'Picture',
    project_voice_class: 'Voice',
    project_text_class: 'Text',
    project_video_class: 'Video',
    project_label_class: 'Lable',
    project_collection_class: 'Collection',
    project_external_links: 'External link',
    project_create_category: 'Create a category',
    project_sure_create: 'Create',
    project_base_info: 'Basic Information',
    project_enter_field_value: 'Input field value',
    project_setting_small_icon: 'Set small icons',
    project_setting_big_icon: 'Set big icons',
    project_chinese_template: 'Chinese template',
    project_keyword_separated: 'Keywords, separated by commas',
    project_preview_image: 'Preview picture',
    project_setting_previews: 'Set preview pictures',
    project_english_template: 'English template',
    project_null: 'Null is unacceptable',
    project_task_type_null: 'Task type cannot be empty',
    project_data_field_null: 'Data field value cannot be empty',
    project_template_field_null: 'Template field value cannot be empty',
    project_category_name_null: 'Category name cannot be empty',
    project_keyword_null: 'Keywords cannot be empty',
    project_description_null: 'Description cannot be empty',
    project_notice_words_limit: 'The number of characters of announcements is between 0 and 255.',
    project_edit_category: 'Edit category',
    project_sure_edit: 'Confirm modification',

    project_new_model: 'New model',
    project_edit_model_category: 'Edit model category',
    project_model_name: 'Model name',
    project_head_map: 'Head map',
    project_upload_pictures: 'Upload pictures',
    project_sort: 'Sort',
    project_program_script: 'Program script',
    project_input_program_script: 'Input program script',
    project_fill_explain: 'Complete instructions',
    project_input_name: 'Enter name',
    project_atmost_100_characters: 'No more than 100 characters',
    project_atmost_200_characters: 'No more than 200 characters',
    project_edit_model: 'Edit model',
    project_model_type: 'Model category',
    project_label: 'Lable',
    project_input_label: 'Enter tags, separated by commas',
    project_address_interface: 'interface address',
    project_input_address_interface: 'Enter interface address',
    project_request_configuration: 'Request configuration',
    project_result_configuration: 'Result configuration',
    project_not_json_data_format: 'Not json data format',
    project_please_json_data: 'Enter json data',
    project_select_model_type: 'Select model category',
    project_input_configuration_params: 'Input configuration parameters',
    project_new_model_category: 'New model category',
    project_sure_delete_model: 'Are you sure you want to delete the model?',
    project_model_category_name: 'Model category name',
    project_operation_success: 'Successful operation',
    project_model_information: 'Model information',
    project_model_id: 'Model ID',
    project_response_configuration: 'Response configuration',
    project_whether_enable: 'Whether to enable',
    project_delete_model: 'Delete model',
    project_category_no_model: 'There is no model for this category',
    project_saved: 'Saved',
    project_view_models: 'Click [View] to view all models of this type.',
    project_mdoel_category_management: 'Model category management',

    project_view_all_types: 'View all types',
    project_create_this_project: 'Create project',
    project_create_such_projects: 'Create project',
    project_content_audit: 'Content review',
    project_preview_this_template: 'Preview this template',
    project_preview_template: 'Preview template',
    project_result_null: 'The result is empty',
    project_template_preview: 'Template preview',
    project_no_preview_template_image: 'No template image to preview in this category.',
    project_project_name: 'Name',
    project_input_project_name: 'Enter your project name',
    project_project_time: 'Project time',
    project_select_start_time: 'Select start time',
    project_select_end_time: 'Select end time',
    project_peoject_description: 'project description',
    project_input_projecy_description: 'Enter project description',
    project_project_overview: 'Please outline your project requirements and acceptance criteria. You can also skip this and upload the documentation directly.',
    project_more_info: 'Supplementary note',
    project_drag_file_tip: 'Click to select a file or drag and drop it into this area',
    project_upload_file_format: 'Upload up to 50 files with extensions: .doc, .docx, .pdf, .jpg, .png, .jpeg',
    project_file_extensions: 'Supported file extensions',
    project_upload_file_extensions: 'Supported upload file extensions',
    project_work_file_extensions: 'Supported job file extensions',
    project_project_name_null: 'Project name cannot be empty',
    project_project_name_format: 'Name should have 2-30 characters',
    project_start_time_null: 'Start time cannot be empty',
    project_end_time_null: 'End time cannot be empty',
    project_upload_success: '  Successful upload',
    project_upload_failed: '  upload failed',
    project_format_incorrect: '  Incorrect format',
    project_data_saving_failed: 'Data save failed',
    project_support_extensions: 'Supported upload file extensions',
    project_ftp: 'FTP server',
    project_input_ftp: 'Enter the FTP server',
    project_ftp_tips: 'Please upload your data file to this address via FTP software.',
    project_input_user_name: 'Enter your user name',
    project_input_password: 'Enter password',
    project_uploading_wait_complete: 'The file is being uploaded, please wait for the upload to complete.',
    project_project_id: 'ID',
    project_projecy_type: 'Category',
    project_start_time: 'Start time',
    project_end_time: 'End time',
    project_upload_data: 'Uploaded package',
    project_data_package: 'data package',
    project_upload_attachment: 'Please upload the requirements file',
    project_upload_file_size: 'Size of uploaded file',
    project_upload_file_num: 'Number of uploaded file',
    project_project_template: 'Project template',
    project_release_success: 'Successfully released',
    project_release_failure: 'Release failure',
    project_your_description: 'Your description',
    project_show_operator_content: 'What you want to show to the operator',
    project_operator_module: 'Operator module',
    project_want_operator_module: 'The module you want the operator to operate',
    project_layout_modules: 'Layout module',
    project_adjust_layout: 'Adjust the layout of the operator interface',
    project_template_design: 'Template design',
    project_attribute_edit: 'Property',
    project_change_upload: 'Change data upload method',
    project_webpage_upload_csv: 'Webpage upload: tabular data .csv .xls ',
    project_webpage_upload_txt: 'Webpage upload: text file .txt',
    project_webpage_upload_zip: 'Webpage upload: data file package .zip',
    project_ftp_upload_zip: 'Upload via FTP.zip',
    project_network_upload: 'Upload via network disk (no file size limit)',
    project_step1_project: 'STEP 1: Project Description',
    project_step2_template: 'STEP 2: Template Setting',
    project_step3_data: 'STEP 3: Upload Your Data',
    project_step4_sure: 'STEP 4: Confirm project release information',
    project_preview_release: 'Preview release',
    project_template_settings: 'Template setting',
    project_category_null: 'Did not find this category, you can click this {feedback} or directly {contact}',
    project_contact_us: 'Contact us',
    project_submit_feedback: 'Submit feedback',

    project_step1_batch: 'Step 2 : Assign Batch',
    project_directory_allocation: 'Allocate by directory',
    project_draw_frame_processing: 'Whether to perform frame drawing processing',
    project_usage_scenarios: 'Usage scenarios',
    project_track_mark_video: '1.Tracking videos such as MP4 files',
    project_track_mark_picture: '2.Tracking extracted images',
    project_assigns_frames: 'Number of frames allocated per job',
    project_enter_assigns_frames: 'Please enter the number of frames per job',
    project_divide_amount_work: 'Divide jobs equally',
    project_total_work: 'Total number of jobs',
    project_set_total_work: 'Set total number of jobs',
    project_count: 'count',
    project_nodata_upload_file: 'No data, please upload files',
    project_total_job_size: 'Total size of jobs ',
    project_amount_work_batch: 'The amount of jobs for each batch',
    project_input_positive_integer: 'Enter a positive integer',
    project_save_next: 'Save, next step',
    project_free_distribution: 'Freely allocate jobs',
    project_setting_total_number: 'The total number of jobs has been set',
    project_batch_name: 'Batch name',
    project_input_batch_name: 'Enter batch name',
    project_working_out: 'Amount of jobs',
    project_delete_batch: 'Delete the batch',
    project_add_batch: 'Add batch',
    project_note_set_equal_total: 'Note: The amount of jobs set must be equal to that of the total jobs.',
    project_file_parsing: 'File parsing',
    project_tips_choose_batch: 'You have selected {batch_num} batches. Once the selection is confirmed, you will not be able to re-select, please think twice before deciding.',
    project_sure_next: 'Ok, next step',
    project_tips_setting_batch: 'The number of jobs per batch is {file_num}. Once the number is confirmed, you will not be able to re-select, please think twice before deciding.',
    project_jobs_number_null: 'The number of jobs cannot be empty',
    project_input_a_positive_integer: 'Enter a positive integer',
    project_jobs_less_one: 'Set the number of jobs to be no less than 1',
    project_setting_less_works: 'The number of jobs set should be less than the number of all jobs.',
    project_batch_one: 'Batch 1',
    project_batch_name_null: 'Batch name cannot be empty',
    project_jobs_size: '{count}jobs, total {size}',
    project_input_jobs_numbers: 'Enter the number of jobs between 0 and {num}',
    project_input_number_frames: 'Input frame number',
    project_set_equal_total: 'The total amount of jobs must be equal to that of all jobs.',
    project_at_least_one_batch: 'Keep at least one batch',
    project_input_nickname_2: 'At least 2 characters',
    project_input_nickname_30: 'At most 30 characters',
    project_current_seeting: 'Current setting',
    project_number_batch: 'Batch',

    project_step2_setting_process: 'Step 3 : Set the process',
    project_add_process: 'Add process',
    project_setting_process_information: 'Set process information',
    project_process_name: 'Process name',
    project_input_step_name: 'Enter a step name',
    project_process_type: 'Process type',
    project_parents_node: 'Parent node',
    project_not_checked: 'Not selected',
    project_load_raw_result: 'Load original result',
    project_note: 'Description',
    project_applies_initialization_data_present: 'Applicable to cases with initialized data',
    project_redo_preserve_previous_result: 'or redo and retain the previous result',
    project_category: 'Category',
    project_helpers: 'Auxiliary tools',
    project_setting_conditions: 'Set conditions',
    project_reset_condition: 'Clear condition',
    project_choose_template: 'Select template',
    project_execute: 'Executor',
    project_audit: 'Audit',
    project_qc: 'Quality check',
    project_process_name_null: 'The process name cannot be empty',
    project_process_name_dontnot_repead: 'The process name cannot be repeated',
    project_process_name_num_err: 'The process name should have 2-6 characters',
    project_please_choose_process_type: 'Select process type',
    project_please_choose_category: 'Select category',
    project_please_choose_template: 'Select template',
    project_template: 'Template',
    project_choose: 'Select',
    project_excute_condition: 'Execution condition',
    project_view_edit: 'View/Edit',
    project_right: 'Yes',
    project_no: 'No',
    project_setting: 'Settings',
    project_not_choose_template: 'No template selected',
    project_atleast_one_step: 'At least one step is reserved',
    project_step_flow_conditions: 'This step has no settable flow conditions. If you need to add a flow condition, please add a single-selection group to this step-by-step template.',
    project_step_no_template_selected: 'This step does not select a template',
    project_step_name_null: 'Step name cannot be empty',
    project_audit_qc_parents_step: 'Auditing or QC process must specify a parent distribution',
    project_execute_choose_category: 'A category must be selected to execute the process',
    project_execute_choose_template: 'A template must be selected to execute the process',
    project_parent_distribution_not_execution: 'Parent distribution is not executed',
    project_no_template_parent_distribution: 'Parent distribution does not select any template',

    project_step3_task_setting: 'Step 4: Task Settings',
    project_save_setting: 'Save settings',
    project_all_execute: 'Select all and execute',
    project_all_audit: 'Select all and audit',
    project_all_qc: 'Select all and execute quality control',
    project_batch_setting: 'Batch settings',
    project_setting_task_info: 'Set task',
    project_task_name: 'Task name',
    project_tickets_received_each_time: 'The number of sheets received each time',
    project_effective_execution_time: 'Effective execution time',
    project_effective_execution_times: 'Effective execution time (s)',
    project_setting_person: 'set people',
    project_hint: 'Tips: The platform has selected all the operators by default, click [Set People] to edit the staff.',
    project_check_all: 'Select all team members',
    project_cancel_check_all: 'cancel selected all team members',
    project_choose_platform: 'Platform',
    project_choose_target: 'Target',
    project_batch_setting_task_information: 'Batch set task',
    project_select_edit_field: 'Select edited field',
    project_number_less_one: 'The value cannot be less than 1',
    project_input_integer: 'Please enter an integer',
    project_task_starttime_lt_project_starttime: 'The task start time cannot be earlier than the project start time',
    project_task_starttime_gt_project_starttime: 'The task start time cannot be later than the task end time',
    project_task_endtime_lt_project_endtime: 'The task end time cannot be earlier than the task start time',
    project_task_endtime_gt_project_endtime: 'The task end time cannot be later than the project end time',
    project_platform_target: 'Platform and target',
    project_process: 'Process',
    project_platform: 'Platform',
    project_target: 'Target',
    project_name_null: 'Name cannot be empty',
    project_choose_targetplatform: 'Select the target platform',
    project_not_checked_task: 'Unchecked task',
    project_task_set_publishing_goals: 'Each task must set a release target',

    project_template_name: 'Name',
    project_template_category_name: 'Category',
    project_template_name_null: 'Template name cannot be empty',
    project_template_content_null: 'Template content cannot be empty',
    project_require_template_data_error: 'Get template data error',
    project_new_template: 'Create template',
    project_default_template: 'Default template',
    project_use_this_copy_template: 'Use this copy template',
    project_input_check_template_name: 'Search template name / ID',
    project_template_id: 'ID',
    project_use_copy_template: 'Use copy template',
    project_use_this_template: 'Use this template',
    project_copy_error: 'Copy failed',
    project_common_template: 'Public template',
    project_private_template: 'Private template',

    project_select_download_batch: 'Select download batch: ',
    project_select_download_step: 'Select download steps: ',
    project_select_download_file_format: 'Select download file format: ',
    project_config_options: 'Configuration',
    project_select_all: 'Select all',
    project_chinese_escape: 'escape',
    project_no_escape: 'Not escape',
    project_filter_results: 'Filter results',
    project_no_filter: 'Do not filter',
    project_delete_null_result: 'Remove empty results',
    project_ftp_cannot_empty: 'FTP address cannot be empty',
    project_ftp_push: 'Push to FTP',
    project_ftp_pushing: 'Pushing to FTP: ',
    project_time: 'Time',
    project_download_history: 'Download history',
    project_batch_download_package_results: 'Bulk download package results',
    project_Batch_download_detection_information: 'Bulk download detection information',
    project_data_packages: 'Pack data',
    project_data_packages_type: 'seleck package type',
    project_data_packages_name: 'package name',
    project_refresh: 'Refresh',
    project_result: 'Result',
    project_batch: 'Batch',
    project_batch_id: 'Batch ID',
    project_work_id: 'Job ID',
    project_batch_designation: 'Batch Name',
    project_data_name: 'Data name',
    project_input_data_name: 'Enter data name, data id',
    project_step: 'Step',
    project_file_format: 'File format',
    project_status: 'Status',
    project_packaging_progress: 'Progress',
    project_successful_jobs_packaged: 'Number of successfully packed jobs',
    project_failed_jobs_packaged: 'Number of unsuccessfully packed jobs',
    project_create_time: 'Create time',
    project_update_time: 'Update time',
    project_select_time: 'Select time',
    project_a_pack: 'A package',
    project_num_pack: 'Multiple packages',
    project_start_packing_time: 'Packing start time',
    project_wrap_up_time: 'Packing end time',
    project_download: 'Download',
    project_pack_result: 'Result',
    project_send_result_to_dataset: 'Push to data set / FTP',
    project_dataset: 'Data set',
    project_dataset_input_text: 'Enter dataset Id, name',
    project_push: 'Push',
    project_ftp_address: 'FTP address',
    project_testing_information: 'Detection information',
    project_select_atleast_one_batch: 'Select at least one batch',
    project_select_steps: 'Select step',
    project_select_file_format: 'Select a file format',
    project_select_configuration: 'Select a configuration item',
    project_change_package_type: 'please select package type before packing',
    project_submit_successful_wait_results: 'Submit successfully, wait for the result to generate',
    project_check_starting_ending_time: 'Please check the start and end time! ',
    project_check_starting_time: 'Please check the start time! ',
    project_check_ending_time: 'Please check the end time! ',
    project_try_again_after_seconds: ' Retry after {num} seconds',

    project_input_batch_name_id: 'Enter the batch name, ID',
    project_batch_status: 'Batch Status',
    project_project_count: 'Project Performance',
    project_work_schedule: 'work progress',
    project_difficulty: 'Pend',
    project_refused: 'Rejected',
    project_total_project_operation: 'Total number of files',
    project_batch_numbers: 'Batches',
    project_step_number: 'Processes',
    project_task_number: 'Tasks',
    project_participants: 'Participants',
    project_cumulative_working_hours: 'Total working time',
    project_project_schedule: 'Project progress',
    project_process_id: 'Process ID',
    project_task_id: 'Task ID',
    project_task_names: 'Task name',
    project_number_executions: 'Executed files',
    project_number_jobs: 'Number of jobs',
    project_username: 'username',
    project_password: 'password',
    project_data_file_path: 'Data file path',
    project_attachment_path: 'Attachment path',
    project_demand_attachment: 'Requirements attachment',
    project_file_name: 'Package name',
    project_file_size: 'Package size',
    project_upload_time: 'Upload time',
    project_no_description: 'No description yet',
    project_teams: ' (team)',
    project_crowdsourcings: ' (crowdsourcing)',
    project_ais: ' (AI model)',

    project_export_data: 'Export performance',
    project_total_number_workers: 'Total number of job workers',
    project_total_number_jobs: 'Total number of job work sheets',
    project_exexute_numbers: 'Number of executed sheets',
    project_execute_boxes: 'Number of executed boxes',
    project_execute_points: 'Number of points executed',
    project_total_marks_jobs: 'Total number of job work tags',
    project_total_points_jobs: 'Total number of job work points',
    project_total_time: 'Total time of job(s)',
    project_account: 'Account',
    project_belongs_team: 'Team',
    project_jobs_numbers: 'Number of job work sheets',
    project_jobs_boxes: 'Number of job work boxes',
    project_jobs_points: 'Number of job work points',
    project_correct: 'Accuracy rate',
    project_operation_time: 'Work duration (s)',
    project_operation_average_time: 'Average duration (s)',
    project_number_rejections: 'The number of rejections',
    project_distribution_platform: 'Assigning platform',
    project_fields_explain: 'Field description',
    project_unit_s: 'unit: second ( s )',

    project_batch_setup_completed: 'Batch setup is completed',
    project_batch_setup_not_completed: 'Batch setup is not completed',
    project_project_created_successfully: 'Project created successfully',
    project_input_operator: 'Enter operator',
    project_operator: 'Operator',
    project_event: 'Event',
    project_content: 'Content',
    project_create_project: 'Create project',
    project_sure_delete_current_project: 'Are you sure you want to delete the current project? ',
    project_sure_restart_current_project: 'Are you sure you want to restart the current project? ',
    project_sure_pause_current_project: 'Are you sure you want to pause the current project? ',
    project_sure_recover_current_project: 'Are you sure you want to restore the current project? ',
    project_sure_completed_current_project: 'Are you sure you want to set the current project as completed? ',
    project_publisher: 'Publisher',
    project_project_status: 'Status',
    project_starting_time: 'Start time',
    project_finish_time: 'End time',
    project_copy_success: 'Copy successfully',
    project_restart_success: 'Restart successfully',
    project_recover_success: 'Resume successfully',
    project_pauset_success: 'Pause successfully',
    project_project_setup: 'Project has been set',

    project_project_settings: 'Project Settings',
    project_publish_project: 'Publish project',
    project_start_project: 'start project',
    project_confirm_change: 'confrim change',
    project_prev_project_settings: 'previous step,project setting',
    project_next_allocation_person: 'next step,set people',
    project_save_project: 'Save project',
    project_project_start_end_time: 'Project start time/end time',
    project_sel_project_start_end_time: 'Please select time/end time',
    project_project_start_end_time_hint: 'End time cannot be same with start time',
    project_web_upload: 'Web upload',
    project_ssh_upload: 'SSH upload',
    project_input_attachment_path: 'Enter the attachment path',
    project_upload_attached_file: 'Upload your attachment file to this address',
    project_ftp_upload: 'FTP upload',
    project_ftp_server: 'FTP server',
    project_input_ftp_server: 'Enter the FTP server',
    project_upload_ftp_server: 'Please upload your data file to this address via FTP software',
    project_demand_document: 'Requirements document',
    project_upload_attachments: 'Upload file',
    project_upload_file_extension_support: 'Up to 50 files, support extensions',
    project_endtime_gt_starttime: 'End time cannot be earlier than start time',
    project_upload_file_limit: 'File upload limit',
    project_file_size_lt_500: 'The file should not be larger than 500M',
    project_file_size_mt_1: '上传的图片必须大于1KB',
    project_deleted: 'Deleted',
    project_work_process: 'work process',

    project_data_list: 'Data list',
    project_export_work_record: 'Export job record',
    project_operation_records: 'Record',
    project_founder: 'Creator',
    project_project_details: 'Project overview',
    project_batch_list: 'Batch list',
    project_project_performance: 'Project performance',
    project_quality_control: 'Acceptance check',
    project_acceptance_check: 'Acceptance check',
    project_download_result: 'Download result',
    project_releasing: 'Publishing',
    project_auditing: 'Under review',
    project_doing: 'Job in progress',
    project_paused: 'Paused',

    project_input_template_name_id: 'Enter template name, ID',
    project_sure_delete_current_template: 'Are you sure you want to delete the current template?',
    project_copy_successful_template_name: 'Copy successfully, template name: ',
    project_sure_this_edit: 'Are you sure you want to abandon current edit? ',
    project_download_CSV: 'Click to download CSV template',
    project_download_XLSX: 'Click to download XLSX template',
    project_input_datafile_path: 'Enter data file path',
    project_upload_this_address: 'Please upload your data file to this address',
    project_company_announcements: 'Announcements',
    project_edit_announcements: 'Edit Announcements',
    project_upload_filesize_limit: 'Upload image size limit',
    project_upload_filesize_limit_con: 'The file size should not exceed 2M',
    project_project_basicInfo: 'Project basic information',
    project_project_uploadfile: 'Upload data file',
    project_project_pageuploadInst: 'Page upload instructions',
    project_pageuploadInst_desc1: 'When uploading multiple files, they will be recorded as multiple batches to keep track of job progress.',
    project_pageuploadInst_desc2: 'We protect data privacy by supporting visiting labeled data through VPN or virtual private cloud. Please refer to document----',
    project_private_data_deploymen: 'private data deployment',
    project_configure_tool: 'Configure annotation tool',
    project_configure_team: 'Choose executive team',
    project_edit_template_tip: 'Ban deleting project modules being used. If deleting project module is needed, please suspend the following projects:',
    project_modifying_classification_tip: 'Changing the category will reset the template',
    project_edit_template_warning: 'Modifying this template will affect the following projects. Do you want to continue editing? ',
    project_data_container: 'Data container',
    project_operating_instruction: 'Operation instructions',
    project_layout: 'Layout',
    project_operational_components: 'Operational components',
    project_category_no_component: 'This category has no components available',
    project_lack: 'Lack ',
    project_modules: ' modules',
    project_totle_users: 'Total number of users',
    project_totle_teams: 'The total number of teams',
    project_amount_data: 'The amount of data being processed(w)',
    project_storage_capacity: 'The total storage capacity(GB)',
    project_supported_modules: 'Supported modules',
    project_acceleration_tool: 'Acceleration tools',
    project_available_data: 'Available data',
    project_remaining_space: 'Available space',
    project_no_data: 'No data',
    project_unpack_tip: 'Unpacking feedback',
    project_unpack_succ: 'Successful unpacking',
    project_unpack_err: 'Failed unpacking',
    project_unpack_process: 'Unpacking progress',
    project_process_tip: 'Project overall progress in {name} Inspection process',
    project_upload_tip1: 'Supported job file format',
    project_upload_tip2: 'Data format supported for uploading packages',

    project_projectAudit_title: 'Project audit',
    project_projectAudit_reject: 'Delete project',
    project_projectAudit_info: 'Project information',
    project_projectAudit_id: 'Project ID:',
    project_projectAudit_tenant: 'Tenant:',
    project_projectAudit_name: 'Project name:',
    project_projectAudit_input_name: 'Enter project name',
    project_projectAudit_type: 'Project type:',
    project_projectAudit_label_template: 'Annotation module:',
    project_projectAudit_choose: 'Select',
    project_projectAudit_edit: 'Edit',
    project_projectAudit_data_address: 'Data storage address',
    project_projectAudit_ftp: 'FTP server',
    project_projectAudit_ftp_address: 'FTP server address:',
    project_projectAudit_userName: 'Username:',
    project_projectAudit_password: 'Password:',
    project_projectAudit_upload_data: 'Upload data',
    project_projectAudit_reject_desc: 'Are you sure to reject the project?',
    project_projectAudit_sure: 'Confirm',
    project_projectAudit_file_name: 'File name',
    project_projectAudit_upload_time: 'Upload time',
    project_projectAudit_file_size: 'File size',
    project_projectAudit_choose_type: 'Select project type',
    project_stepSetting_end: 'End',
    project_own_team: 'Self-owned team',
    project_own_team_desc: 'Provide custom annotation templates, project configuration for workflow, split project work assigned to the free team.',
    project_beisai_center: 'Basicfinder Operation Center',
    project_beisai_center_desc: 'Professional project managers and data processing teams are responsible for docking your needs and ensuring data quality.',
    project_batch_allocation: 'Batch allocation method',
    project_batches_num: 'Number of project batch',
    project_ftp_normal: 'FTP normal',
    project_ftp_error: 'FTP anomaly, ',
    
    project_select_all: 'Select all',
    project_config_options: 'Configuration item',
    project_no_escape: 'Do not escape',
    project_no_filter: 'Do not filter',
    project_delete_null_result: 'Remove empty results',
    project_time: 'Time',
    project_start_time: 'Start time',
    project_end_time: 'End time',
    project_data_packages: 'Pack data',
    project_download_history: 'Download history',
    project_batch_download_package_results: 'Batch download packing results',
    project_Batch_download_detection_information: 'Batch download detection information',
    project_refresh: 'Refresh',
    project_result: 'Result',
    project_batch: 'Batch',
    project_batch_id: 'Batch ID',
    project_batch_name: 'Batch Name',
    project_step: 'Process',
    project_file_format: 'File format',
    project_status: 'Status',
    project_packaging_progress: 'Packing progress',
    project_successful_jobs_packaged: 'Number of successfully packed jobs',
    project_failed_jobs_packaged: 'Number of unsuccessfully packed jobs',
    project_create_time: 'Creation time',
    project_start_packing_time: 'Packing start time',
    project_wrap_up_time: 'Packing end time',
    project_download: 'Download',
    project_pack_result: 'Packing result',
    project_testing_information: 'Detection information',
    project_select_atleast_one_batch: 'Select at least one batch',
    project_select_steps: 'Select process',
    project_select_file_format: 'Select file format',
    project_select_configuration: 'Select configuration item',
    project_submit_successful_wait_results: 'Submitted successfully, generating results',
    project_check_starting_ending_time: 'Please check start & end time! ',
    project_check_starting_time: 'Please check start time! ',
    project_check_ending_time: 'Please check end time! ',
    project_input_batch_name_id: 'Enter batch name, ID',
    project_search_project_nameid: 'Enter project name, ID',
    project_search: 'Search',
    project_sort: 'Sort',
    project_batch_status: 'Batch Status',
    project_total_work: 'Total number of jobs',
    project_update_time: 'Update time',
    project_total_project_operation: 'Total number of files',
    project_batch_numbers: 'Number of batches',
    project_step_number: 'Number of processes',
    project_task_number: 'Number of tasks',
    project_participants: 'Number of operators',
    project_cumulative_working_hours: 'Accumulative work time',
    project_project_schedule: 'Batch progress',
    project_process_id: 'Process ID',
    project_task_id: 'Task ID',
    project_task_names: 'Task name',
    project_number_executions: 'Number of executed jobs',
    project_number_jobs: 'Total number of jobs',
    project_execute: 'Execute',
    project_upload_data: 'Uploaded package',
    project_ftp: 'FTP server',
    project_username: 'Username',
    project_password: 'Password',
    project_data_file_path: 'Package file path',
    project_attachment_path: 'Attachment path',
    project_project_template: 'Project template',
    project_file_name: 'Package name',
    project_file_size: 'Package size',
    project_upload_time: 'Upload time',
    project_no_description: 'No description',
    project_teams: ' (team)',
    project_crowdsourcings: ' (crowdsourcing)',
    project_ais: ' (AI model)',
    project_audit: 'Audit',
    project_qc: 'Quality check',
    project_acceptance_check: 'Acceptance check',
    project_export_data: 'Export performance',
    project_total_number_workers: 'Total number of operators',
    project_total_time: 'Total working time (s)',
    project_total_number_jobs: 'Total number of job sheets',
    project_total_marks_jobs: 'Total number of annotations',
    project_total_points_jobs: 'Total points rewarded for jobs',
    project_account: 'Account',
    project_belongs_team: 'belonged groups',
    project_jobs_numbers: 'Number of sheets',
    project_jobs_boxes: 'Number of boxes',
    project_jobs_points: 'Job points',
    project_correct: 'Accuracy rate',
    project_operation_time: 'Work time (s)',
    project_operation_average_time: 'Average work time (s)',
    project_number_rejections: 'Number of rejections',
    project_search_content: 'Serch content',
    project_task_name: 'Task name',
    project_distribution_platform: 'Designate platform',
    project_process_type: 'Process type',
    project_fields_explain: 'Field description',
    project_unit_s: 'unit: second ( s )',
    project_exexute_numbers: 'Number of executed sheets',
    project_execute_boxes: 'Number of executed boxes',
    project_execute_points: 'Points rewarded for execution',
    project_close: 'Close',
    project_founder: 'creator',
    project_name: 'Name',
    project_type: 'Type',
    project_operation: 'Operation',
    project_batch_list: 'Batch list',
    project_project_performance: 'Project performance',
    project_download_result: 'Download result',
    project_releasing: 'Publishing',
    project_auditing: 'Auditing',
    project_doing: 'Work in progress',
    project_paused: 'Paused',
    project_complete: 'Completed',

    project_detection_successful: 'Detected successfully',
    project_join_time: 'Enrollment time',
    project_check_details: 'View details',
    project_item_status: 'Project status',
    project_item_type: 'Project type',
    project_belongs_tenant: 'Tenant',
    project_view: 'View',
    project_belonged_project: 'Project',
    project_pack_status: 'Packing status',
    project_unpack_status: 'Unpacking status',
    project_pack_progress: 'Packing status',
    project_unpack_progress: 'Unpacking status',
    project_pack_succ: 'Packed successfully',
    project_pack_fail: 'Failed to pack',
    project_pack_succ_num: 'Number of successful packages',
    project_pack_fail_num: 'Number of failed packages',
    project_pack_stop: 'Stop packing',
    project_pack_restart: 'Start re-packing',
    project_pack_stop_confirm: 'Confirm to stop packing?',
    project_pack_restart_confirm: 'Confirm to start re-packing?',
    project_pack_record: 'Packing completion record',
    project_pack_sticky: 'Sticky/ Unstick',
    project_pack_refresh: 'Refresh list',
    project_result_name: 'Result name',

    project_step_name_progress: 'The total progress of the data in the project in the {name} operation',
    project_correct_calcu_instruction: 'accuracy calculation instructions',
    project_correct_calcu_desc: 'accuracy: approvals/(approvals+rejections+resets)',
};
