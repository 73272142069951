<template>
  <Breadcrumb style="margin-bottom: 20px;">
<!--    <BreadcrumbItem-->
<!--        :to="{ path: '/home' }"-->
<!--    >首页-->
<!--    </BreadcrumbItem>-->
    <template v-for="(item, index) in $store.state.app.breadCrumbList">

      <BreadcrumbItem
          :key="index + item.title"
          v-if="item.title && index < $store.state.app.breadCrumbList.length - 1 && item.path"
          :to="(item.params && (item.params.id || item.params.tab)) ? { name:item.name,params:item.params } : {path: item.path,query:item.query}"
      >{{ $t(item.title) }}
      </BreadcrumbItem>
      <BreadcrumbItem
          :key="index + item.title"
          v-else
      >{{ $t(item.title) }}
      </BreadcrumbItem>
    </template>
  </Breadcrumb>
</template>

<script>
export default {
  name: "breadcrumbView"
}
</script>

<style scoped>

</style>
