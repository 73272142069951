import CertHome from '../views/layout/certHome'
const certRoute = {
    path: '/cert',
    redirect: '/trainingcamp/train',
    name: 'cert',
    meta:{
        title:'技能评测',
        module_route:'module_certlearn'
    },
    component:CertHome,
    children:[
        {
            path: '/trainingcamp/train',
            name: 'trainingcamp/train',
            route:'trainingcamp/train',
            meta:{
                title:'router_trainingcamp_train',
                module_route:'module_certlearn',
                img:require('@/assets/images/new_icon/train.png'),
                requiresAuth:true,
            },
            // component: ()=>import('@/views/trainingcamp/train.vue'),
            component:(resolve) => require([`@/views/trainingcamp/train.vue`], resolve),
        },
        {
            path: '/cdkey/mycdkey',
            // route: 'cdkey/mycdkey',
            route:'trainingcamp/train1',
            name: 'cdkey-mycdkey',
            meta:{
                title:'menu_cdkey_mycdkey',
                module_route:'module_certlearn',
                img:require('@/assets/images/new_icon/by_list.png'),
                requiresAuth:true,
            },
            component: ()=>import('@/views/cdkey/mycdkey.vue'),
        },
        {
            path: '/trainingcamp/mytrain',
            route: 'trainingcamp/mytrain',
            name: 'my-train',
            meta:{
                title:'router_trainingcamp_mytrain',
                module_route:'module_certlearn',
                img:require('@/assets/images/new_icon/stu_report.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/mytrain.vue'),
        },
        {
            path: '/trainingcamp/selfexamrecord',
            route: 'trainingcamp/selfexamrecord',
            name: 'trainingcamp/selfexamrecord',
            meta:{
                title:'router_trainingcamp_self_exam_record',
                module_route:'module_certlearn',
                img:require('@/assets/images/new_icon/stu_report.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/selfExamRecord.vue'),
        },
        {
            path: '/trainingcamp/myteststat',
            route: 'trainingcamp/myteststat',
            name: 'trainingcamp/myteststat',
            meta:{
                title:'router_trainingcamp_myteststat',
                module_route:'module_certlearn',
                img:require('@/assets/images/new_icon/stu_analysis.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/myteststat.vue'),
        },
        {
            path: '/trainingcamp/mywrong',
            route: 'trainingcamp/mywrong',
            name: 'trainingcamp/mywrong',
            meta:{
                title:'router_trainingcamp_mywrong',
                module_route:'module_certlearn',
                img:require('@/assets/images/new_icon/error_data.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/mywrong.vue'),
        },
        {
            path: '/trainingcamp/mycollect',
            route: 'trainingcamp/mycollect',
            name: 'trainingcamp/mycollect',
            meta:{
                title:'router_trainingcamp_mycollect',
                module_route:'module_certlearn',
                img:require('@/assets/images/new_icon/collect.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/mycollect.vue'),
        },
        {
            path: '/trainingcamp/mycorrection',
            route: 'trainingcamp/mycorrection',
            name: 'mycorrection',
            meta:{
                title:'corrective_feedback',
                module_route:'module_certlearn',
                img:require('@/assets/images/new_icon/feedback.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/mycorrection.vue'),
        },


        {
            path: '/trainingcamp/trainDetail',
            route:'trainingcamp/trainDetail',
            name: 'trainingcamp-trainDetail',
            meta:{
                title:'menu_trainingcamp_train_detail',
                module_route:'module_certlearn',
                img:require('@/assets/images/new_icon/train.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/trainDetail.vue'),
        },
        {
            path: '/trainingcamp/train-result',
            route:'trainingcamp/train-result',
            name: 'trainingcamp/train-result',
            meta:{
                title:'menu_trainingcamp_train_result',
                module_route:'module_certlearn',
                img:require('@/assets/images/new_icon/train.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/trainResult.vue'),
        },
        {
            path: '/trainingcamp/train-report',
            route:'trainingcamp/train-report',
            name: 'trainingcamp/train-report',
            meta:{
                title:'menu_trainingcamp_train_report',
                module_route:'module_certlearn',
                img:require('@/assets/images/new_icon/train.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/trainReport.vue'),
        },
        {
          path: '/trainingcamp/exam',
          route:'trainingcamp/exam',
          name: 'trainingcamp/exam',
          meta:{
            title:'menu_trainingcamp_exam',
            module_route:'module_certlearn',
            img:require('@/assets/images/new_icon/exam.png'),
            requiresAuth:true,
          },
          component: ()=>import('../views/trainingcamp/exam.vue'),
        },
        {
          path: '/trainingcamp/exam-detail/:tab?/:id?',
          name: 'exam-detail',
          meta:{
            title:'menu_trainingcamp_exam_detail',
            module_route:'module_certlearn',
            img:require('@/assets/images/new_icon/data_stat.png'),
            requiresAuth:true,
          },
          component: ()=>import('../views/trainingcamp/examDetail.vue'),
        },
        {
          path: '/trainingcamp/mydetail/:tab?/:id?/:listId?',
          route: 'trainingcamp/mydetail',
          name: 'my-detail',
          meta:{
            title:'详情',
            module_route:'module_certlearn',
            img:require('@/assets/images/new_icon/collect.png'),
            requiresAuth:true,
          },
          component: ()=>import('../views/trainingcamp/myDetail.vue'),
        },
        {
            path: '/trainingcamp/category-portrait',
            route:'trainingcamp/category-portrait',
            name: 'category-portrait',
            meta:{
                title:'题库画像',
                module_route:'module_certlearn',
                img:require('@/assets/images/new_icon/train.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/categoryPortrait.vue'),
        },
        {
            path: '/cert/trainingcamp/chapters/:tab?',
            route: 'trainingcamp/chapters',
            name: 'chapters',
            meta:{
                title:'menu_trainingcamp_chapters',
                module_route:'module_certlearn',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/chapters.vue'),
        },
    ],
}
export default  certRoute;
