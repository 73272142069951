<template>
  <div class="template">
    <MyBreadcrumb v-if="breadcrumb"></MyBreadcrumb>
    <div class="template-content" ref="templateContent">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateView",
  props: {
    breadcrumb: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  },
  methods: {
    initTemplate() {
      const { breadcrumb } = this;
      if (!breadcrumb) {
        this.$refs.templateContent.style.height = "calc(100vh - 90px)";
      }
    }
  },
  mounted() {
    this.initTemplate();
  }
}
</script>

<style lang="scss" scoped>
.template {
  padding: 20px;
  box-sizing: border-box;

  .template-content {
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 4px;
    height: calc(100vh - 130px);
    overflow-y: auto;
  }
}
</style>
