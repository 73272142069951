import {request} from '@/utils/request.js'

//第三方详情
const thirdpartyDetail = (data) => {
    return request({
        url: '/thirdparty/detail',
        method: 'post',
        data
    })
}
//第三方更新信息
const thirdpartyUpdate = (data) => {
    return request({
        url: '/thirdparty/update',
        method: 'post',
        data
    })
}
//第三方列表
const thirdpartyList = (data) => {
    return request({
        url: '/thirdparty/list',
        method: 'post',
        data
    })
}
//第三方超管审核
const thirdpartyVerify = (data) => {
    return request({
        url: '/thirdparty/verify',
        method: 'post',
        data
    })
}

export default {
    thirdpartyDetail,
    thirdpartyUpdate,
    thirdpartyList,
    thirdpartyVerify
}
