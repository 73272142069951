export default {
    // menu bar
    common_operator: 'Operator',
    common_administrater: 'Administrator',
    common_root: 'ROOT',

    // the header
    common_full_screen: 'Full screen',
    common_exit_full_screen: 'Exit full screen',
    common_close_tags: 'Close tag',
    common_no_data: 'No data yet',
    // table
    common_cancel: 'Cancel',
    common_confirm: 'Confirm',
    common_save: 'Save',
    common_delete: 'Delete',
    common_amount: 'Number of sheets',
    common_box: 'Number of boxes',
    common_points: 'Number of points',
    common_set_shortcut: 'Set shortcut keys',
    // form
    common_single_option: 'single-choice',
    common_multiple_choices: 'multiple-choice',

    common_use_chrome: '请使用chrome浏览器',
    common_copyright: 'copyright',

    common_user_ownspace: 'Personal information',
    common_user_quit: 'Quit',

    // the message
    message_inform: 'Notification',
    message_getNotifications: 'Notification content',
    message_type: 'Type',
    message_time: 'Time',
    message_operate: 'Operation',
    message_notice: 'Announcement',
    message_no_announcement: 'No announcement yet',
    message_content: 'Message content',
    message_sender: 'Sender',
    message_ftgmd: 'Aquire message data failed',
    message_marked: "Marked as read",
    message_delete: 'Delete',
    message_no_message: 'No message yet',
    message_messages_content: 'Message Content',
    message_unread_messages: '{num} unread messages',
    message_no_unreadMessage: 'No unread message',

    // home
    home_last_login_time: 'Last login time',
    home_last_login_ip: 'Last login IP address',
    home_notice: 'Latest announcement',
    home_total_user: 'Total number of users',
    home_total_task: 'Total number of tasks',
    home_taowdt: 'Total amount of jobs today',
    home_workload_today: 'Amount of my job today',
    home_amount_box: 'Number of my labeled boxes today',
    home_amount_point: 'Quantity of my labeled points today',
    home_working: 'My work time today (in seconds)',
    home_team_progress: 'Team task progress',
    home_recent_performance: 'Statistics of recent team workload',
    home_recent_workload: 'Statistics of my recent workload',
    home_taskname: 'Task name',
    home_percentage_progress: "Progress percentage",
    home_start_time: 'Start time',
    home_end_time: 'End time',
    home_city: 'City',
    home_flow: 'Traffic (k)',
    home_in_flow: 'Inbound traffic ',
    home_current_capital: 'Current Inbound traffic',
    home_current_outflow: 'Current outbound traffic',
    home_type: 'Category ',
    home_pv: 'Visits',
    home_announcement: 'Company Announcement',
    home_active_user: 'Active users',
    home_active_user_desc: 'Users who have logged in in the past seven days',
    home_all_user: 'All users',
    home_all_user_desc: 'total number of registered users/maximum number of users',
    home_active_project: 'run project',
    home_active_project_desc: 'This week\'s project status is the project in progress.',
    home_all_projects: 'all projects',
    home_all_projects_desc: 'The cumulative number of projects created by this platform',
    home_executable_task: 'Executable tasks',
    home_executable_task_desc: 'The total number of executable tasks, including task of unassigned operators',
    home_executable_task_desc_person: 'Number of tasks being executed by the assigned operators',
    home_executable_work: 'Executable works',
    home_executable_work_desc: 'The total number of executable jobs, including tasks of unassigned operators',
    home_executable_work_desc_person: 'Number of executable jobs assigned to the operator\'s task',
    home_today_work: 'Number of jobs today',
    home_today_work_desc: 'Number of jobs today',
    home_data_handling_capacity: 'Data processing ability',
    home_data_size: 'Data volume',
    home_desc_workDesc2: 'The number of executable jobs of tasks that are distributed to operators',
    home_desc_taskDesc2: 'The number of running tasks distributed to operators',
    home_data_ratio: 'Data Ratio',
    home_data_volume: 'Data Files',
    home_space_occupation: 'Data Storage',
    home_project_overview: 'Project overview',
    home_data_throughput: 'Data throughput',
    home_data_published: 'Number of published projects',
    home_data_execution: 'Volume of executed data',
    home_totle_data: 'Total data volume',
    home_totle_storage: 'Total space(G)',
    home_project_num: 'Number of projects',
    home_pcs: 'proejcts', // unit:proejcts
    home_set: 'files', // unit:files
    home_person: 'individuality',
    home_all: 'All',
    home_create_project_count: 'Total number of created projects',
    home_work_count: 'job volume statistics',
    home_work_count_person: 'Number of jobs (individual)',
    home_work_count_total: 'Number of jobs (total)',
    // login
    login_enter_name: 'Please enter email/cellphone number',
    login_enter_password: 'Please enter your password',
    login_fill_account: 'Please enter a valid account',
    login_validate_password: 'Password is case sensitive, with at least 6 characters',
    login_login: 'Login ',
    login_register: 'Register',
    login_enter_tips: 'Please enter user name and password',
    login_error_tips: 'Error occurred, please try again ~',
    login_success_tips: 'Login successfully ~',
    login_quick_successful: 'Quick login successfully ~',
    login_user_not_exist: 'User does not exist',
    login_failed_user_information: 'Failed to get user information ',
    login_enterprise_portal: 'enterprises login',
    login_team_entry: 'team login',
    login_forget_password: 'Forget password？',
    login_welcome: 'Welcome to login',
    login_type_account: 'Account password login',
    login_type_phone:'SMS login',
    login_type_wx:'Wechat scanning code login',
    login_account_no:'No account？',
    login_get_code:'Get verification code',
    login_wx_scan:'Rescan code',
    login_wx_create:'Create a new account',
    login_wx_binding:'Bind existing account',
    login_wxlogin:'登录并绑定',
    login_captcha:'请输入验证码',
    login_captcha_tip:'请输入正确验证码',
    login_mobild_code_tip:'请输入短信验证码',
    login_mobild_code_again:'重新获取',
    login_mobild_user:'请输入手机号',
    login_mobild_code_unit:'秒后',

    register_welcome: '欢迎使用',
    register_has_account: '已有账号?',
    register_to_login: '去登录',
    // register_input_agreement: '我已阅读并同意用户协议',
    register_input_company:'请输入组织全称',
    register_input_company_empty:'请填写您的公司/单位/组织名称',
    register_input_company_limit_max:'组织全称长度不能超过32个字符',
    register_input_company_limit_min:'组织全称长度不能少于2个字符',
    register_input_email:'您的Email',
    register_input_email_empty: '请输入您的Email',
    register_input_email_long_error: 'Email长度不能超过32字符',
    register_input_email_format_error: '请输入正确的Email',
    register_input_password:'请输入密码',
    register_input_password_contain: '密码需要符合以下条件：',
    register_input_password_contain_long:'密码长度在6-32字符',
    register_input_password_contain_letter:'必须包含字母',
    register_input_password_contain_number:'必须包含数字',
    register_input_password_empty:'请输入密码',
    register_input_password_format_error:'必须包含字母,数字,6-32字符',
    register_input_submit:'注册',
    register_input_captcha:'请输入图形验证码',
    register_input_captcha_empty: '请填写验证码',
    register_input_captcha_format_error:'验证码不正确',
    register_input_captcha_error: '验证码不正确',
    register_input_agreement:'我已阅读并同意《用户协议》和《隐私协议》',
    register_finish_to_login: '注册成功! 请重新登录',
    register_input_mobile_empty: '手机号码不能为空',
    register_input_mobile_limit_max: '手机号码必须11位',
    register_input_mobile_limit_min: '手机号码必须11位',
    register_input_mobile: '请输入手机号',
    register_input_user:'请输入您的姓名',
    register_input_mobile_code:'请输入短信验证码',

    forgetPassword_forgot_password: '忘记密码',
    forgetPassword_to_login: '去登录',
    forgetPassword_next_step: '下一步',


    // router

    router_home: 'Home',
    router_ownspace: 'Personal Center',
    router_mc: 'Message center',
    router_setting_project: 'Set project',
    router_task_view: 'Execute task',
    router_batch_review: 'Batch audit',
    router_task_details: 'Task details',
    router_employee_details: 'User details',
    router_spl: 'Set employee label',
    router_ulm: 'User label management',
    router_ugm: 'User group management',
    router_tmm: 'Group member management',
    router_set_operators: 'Set job operators',
    router_batch_set_operators: 'Batch set job operators',
    router_my_task: 'My tasks',
    router_my_performance: 'My performance',
    router_team_task: 'Team tasks',
    router_team_performance: 'Team performance',
    router_team_performance_per_day: 'Daily team performance',
    router_team_member: 'Team members',
    router_quick_login: 'Quicklogin - Quick login',
    router_latest_notice: 'The latest announcement',
    router_site_management: 'Site management',
    router_project_management: 'Project management',
    router_template_management: 'Template management',
    router_user_management: 'User management',
    router_project_detail: 'project detail',
    router_import_user: 'Import user',
    router_edit_template: 'Edit template',

    // 40 ~
    error_return_home: 'Return to homepage',
    error_back: 'Back to previous page',
    error_no_exit: '404- page does not exist',
    // error_: "when the visited page does not exist, it will jump to 404 page. You can change the url to a non-existent path in the browser address bar and experience the effect."
    error_403: '403-insufficient authorization',
    // error_insufficient_authority: 'Jump to the page when the currently user does not have the authorization to execute the current operation. You can jump to the page when the status code returned is 403' in the ajax request method.
    error_server_error: '500-server error',
    // error_: 'jump to this page when accours a server-side error after the request. You can judge from the ajax request method that the status code returned is 500 and jump to this page'.

    // title
    title_huicui: 'Huicui',
    title_beisai: 'Beisai',
    router_system_settings: 'System Settings',
    read_check:'请勾选',

};
