import {request} from '@/utils/request.js'

//登录-发送短信验证码  登录-通过短信登录  登录-通过密码登录
const login = (data) => {
    console.log(data,'dddd')
    return request({
        url: '/index/login',
        method: 'post',
        data
    })
}
//获取图片验证码
const getCaptcha = (data) => {
    return request({
        url: '/index/captcha',
        method: 'post',
        data
    })
}
//注册-发送短信验证码  注册-提交
const signup = (data) => {
    return request({
        url: '/index/signup',
        method: 'post',
        data
    })
}

//忘记密码-发送短信验证码  忘记密码-提交表单
const forgetPassword = (data) => {
    return request({
        url: '/index/forget-password',
        method: 'post',
        data
    })
}
//用户详情
const userDetail = (data) => {
    return request({
        url: '/user/detail',
        method: 'post',
        data
    })
}

//微信扫码登录
const weixinLogin = (data) => {
    return request({
        url: '/index/weixin-qr-login',
        method: 'post',
        data
    })
}

const aliTikuLogin = (data) => {
    return request({
        url: '/index/ali-tiku-login',
        method: 'post',
        data
    })
}
const LoginQuick = (data) => {
    return request({
        url: '/index/login-quick',
        method: 'post',
        data
    })
}

const WeixinServerQrShow = (data) => {
    return request({
        url: '/index/weixin-server-qr-show',
        method: 'post',
        data
    })
}

const WeixinServerQrLogin = (data) => {
    return request({
        url: '/index/weixin-server-qr-login',
        method: 'post',
        data
    })
}

const WeixinServerQrBinding = (data) => {
    return request({
        url: '/user/weixin-server-qr-binding',
        method: 'post',
        data
    })
}

const indexBinding = (data) => {
    return request({
        url: '/index/binding',
        method: 'post',
        data
    })
}

const trainCountStatByCategory = (data) => {
    return request({
        url: '/dataset-train/stat-by-category',
        method: 'post',
        data
    })
}

const indexInit = (data) => {
    return request({
        url: '/index/init',
        method: 'post',
        data
    })
}

const uploadfileUpload = (data) => {
    return request({
        url: '/uploadfile/upload',
        method: 'post',
        data
    })
}

export default {
    login,
    getCaptcha,
    signup,
    forgetPassword,
    userDetail,
    weixinLogin,
    aliTikuLogin,
    LoginQuick,
    WeixinServerQrShow,
    WeixinServerQrLogin,
    WeixinServerQrBinding,
    indexBinding,
    trainCountStatByCategory,
    indexInit,
    uploadfileUpload
}
