<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "managerHome.vue"
}
</script>

<style scoped>

</style>
