export default {
    // ownspace
    
    user_guide: 'User document',
    user_close_tag: 'Close tag',
    user_avatar: 'Avatar',
    user_avatar_settings: "Portrait Settings",
    user_name: 'Name',
    user_email: 'Email',
    user_mobile: 'Cellphone number',
    user_phone: 'Phone',
    user_company: 'Company',
    user_language: 'Language',
    user_login_password: 'Login password',
    user_change_password: 'Change password',
    user_badge_number: 'There are {num} unread messages',
    user_cancel: 'Cancel',
    user_save: 'Save',
    user_delete: 'Delete',
    user_old_password: 'Original password',
    user_new_password: 'New password',
    user_confirm_password: 'Confirm new password',
    user_input_text_name: 'Nickname cannot be empty',
    user_input_text_password: 'Please enter password',
    user_input_text_email: 'Email cannot be empty',
    user_input_text_phone: 'Cellphone number cannot be empty',
    user_input_text_company: 'Please enter company name',
    user_form_phone_error: 'Please enter cellphone number with correct format',
    user_form_password_format: 'Please enter a combination of 6-16 digits and letters',
    user_form_password_mach: 'Inconsistency between two passwords',
    user_form_password_min: 'Please enter at least 6 characters',
    user_form_password_max: 'Please enter up to 16 characters',
    user_form_email_format: 'Incorrect email format',
    user_form_file_format: 'Incorrect upload file format',
    user_form_file_valid: 'Please upload files with floowing formats: .JPG, .PNG or .jpeg.',
    user_form_revised: 'Updated successfully',
    user_form_saved: 'Saved successfully',
    user_form_save_fail: 'Failed to save',

    user_input_text: 'Search content',
    user_input_date: 'Select date',
    user_input_name: 'Please enter name',
    user_input_email: 'Please enter email',
    user_input_phone: 'Please enter cellphone number',
    user_input_phone_number: 'Please enter phone number',
    user_input_password: 'Please enter password',
    user_input_usernameid: 'Please enter username /ID',
    user_input_oldpassword: 'Please enter original password',
    user_input_newpassword: 'Please enter new password',
    user_input_confirmpassword: 'Please confirm password again',
    user_entered_passwords_differ: 'Inconsistency between two passwords',
    user_combination_numbers_letters: 'Enter a combination of 6-16 digits and letters',
    user_validate_name: 'Composed of letters, Chinese characters, numbers and dot, 2-16 characters in total',
    // 邀请用户
    user_invate_link: 'Invite link',
    user_invate_link_audit_tip: 'Please send a link to the user who need to be invited. After the user activates the account, manual approval by administrator is required.',
    user_invate_link_valid_date: 'Valid date of invite link: 48h',
    user_invate_link_create: 'Generate invite link',
    user_invate_link_copy: 'Copy link',
    user_invate_link_copy_finished: 'Copied',
    user_mobile_bind: 'Binding telephone',
    user_mobile_bind_content: "Product upgrade, according to our company's data security management regulations, you need to go to the personal center to bind the mobile phone. Normal operation can only be carried out after verification and can be logged in by binding the phone number.",
    user_mobile_bind_to_user_center: 'Go to the user center',
    user_invitation_active: 'Activate',
    user_invitation_active_confirm: 'Are you make sure to activate?',
    user_invitation_registration_date: 'Registration date',
    user_delete_confirm: 'Are you sure you want to delete?',
    user_Invite_user_management: 'Invite user management',

    user_authenticate: '验证身份',
    user_modify_email: '修改邮箱',
    user_modify_bind_phone: '修改绑定手机',
    user_entered_passwords_cannot_match: 'New password must be different from old password',

    user_login_pwd_val: '登录密码验证',
    user_account: '账号',
    user_enter_login_pwd: '请输入登录密码',
    user_next: '下一步',
    user_email_val: '邮箱验证',
    user_verification_code: '验证码',
    user_enter_verification_code: '请输入验证码',
    user_phone_val: '手机号验证',
    user_new_email: '新邮箱',
    user_enter_new_email: '请输入新邮箱',
    user_6_num_code: '请输入6位数字验证码',
    user_get_verification_code: '获取验证码',
    user_sending: '发送中',
    user_again: '秒后重试',
    user_new_phone: '新手机号',
    user_enter_new_phone: '请输入新手机号',
    user_incorrect_phone_format: '手机号格式不正确',

    user_role: 'Role',
    ser_select_user_type: 'Please select user type',
    user_less_one_role: 'Select at least one role',
    user_select_account_status: 'Please select status',
    user_address: 'Address',
    user_user_name: 'User name',
    user_create_time: 'Create time',
    user_update_time: 'Update time',
    user_edit: 'Edit',
    user_operation_tip: 'Warning',
    user_new_member: 'New member',
    user_input_check_user: 'Search user',
    user_mass_transfer: 'Transfer',
    user_create_new_account: 'New account',
    user_input_user_name: 'Please enter user name',
    user_password: 'Password',
    user_edit_account_info: 'Update account information',
    user_remove_current_member: 'Are you sure you want to delete current member?',
    user_remove: 'Delete',
    user_please_select_team: 'Please select team',
    user_input_check_team: 'Search team',
    user_sure_transfer: 'Confirm to transfer',
    user_join_date: 'Join date',
    user_join_time: 'Create time',
    user_create_by: 'Creator',
    user_quick_login: 'Quick login',
    user_select_other_team: 'Please select other teams',
    user_cannot_delete_current_account: 'Current account cannot be deleted',
    user_successfully_added: 'Added successfully',

    user_not_opened: 'Not activated,',
    user_immediately_opened: 'activate now',
    user_new_account_information: 'Create account information',
    user_edit_account_information: 'Edit account',
    user_upload_avatar: 'Upload avatar',
    user_user_type: 'User type',
    user_input_company: 'Please enter company name',
    user_belong_team: 'Team',
    user_belong_crowdsourcing: 'Crowdsourcing',
    user_upload_maximum_file: 'Only {num} files can be uploaded',
    user_upload_refile: 'The file has been uploaded, please chosse another one',
    user_malformat: 'Incorrect format',
    user_input_event_name: 'Please enter event name',
    user_operational_events: 'Operation event',
    user_information: 'Information',
    user_time: 'Time',
    user_input_equipment: 'Please device name / APP KEY',
    user_equipment_name: 'Device name',
    user_equipment_number: 'Device number',
    user_version: 'APP build number',
    user_requests: 'Number of requests',
    user_batch_editor: 'Batch edit',
    user_sure_import: 'Confirm to import',
    user_batch_edit_account_information: 'Batch edit account',
    user_import_failed_reason: 'Reason for import failure',
    user_complete_each_user_information: 'Please complete each set of user information',
    user_import_result: 'Import result',
    user_import_data_description: 'You have successfully imported {successCount} set of data and failed to imported {errorCount} set of data',
    user_contact_person: 'Contact',
    user_contact_phone: 'Contact number',
    user_belong_group: 'Group',
    user_user_tag: 'User tag',
    user_operation_records: 'Record',
    user_user_equipment: 'Device used',
    user_ftp: 'FTP',
    user_detect_ftp: ' Detect FTP',
    user_create_detect_ftp: 'Create/detect FTP',
    user_sure_delete_current_user: 'Are you sure you want to delete current user?',
    user_new_user: 'Create user',
    user_batch_import_user: 'Batch import user',
    user_import_user_id_nickname_email: 'User ID, nickname and email',
    user_batch_delete_user: 'Delete user',
    user_batch_transfer_team: 'Change team',
    user_delete_current_user: 'Are you sure you want to delete current user?',
    user_sure_delete_checked_user: 'Are you sure you want to delete selected user?',
    user_upload_excel: 'Please upload Excel tabel',
    user_click_select_file_drag_file: 'Click to select file or drag file here',
    user_support_file_extensions: 'Supported extensions: .csv .xls, .xlsx',
    user_select_team: 'Select team',
    user_download_excel_template: 'Click to download Excel template',
    user_import_member_list: 'Import member list',
    user_import: 'Import',
    user_import_excel_file: 'Please upload Excel file',
    user_cannot_transfer: 'Unable to transfer non-team user or user not activated',
    user_transferred: 'Changed',
    user_input_user_id_nickname_email: 'User ID, nickname and email',
    user_next_step: 'Next step',
    user_add_email: 'Add email',
    user_send_email: 'Send invite email',
    user_enter_email_tip: 'Please enter an email address in each row',
    user_invitation_err_list: 'List of failed invites',
    user_invitation_err_email: 'Incorrect input format or incorrect email format',
    user_email_duplicated: 'Email already exists',
    user_invate_success: 'Invite successfully',
    user_select_role_tip: 'Please select role',
    user_name_begin_cannot_empty: 'Nickname can not begin with a space',
    user_nickname_valide: 'The length of nickname should not exceed 20 characters',
    user_name_cannot_special_characters: 'Special characters cannot be entered',
};
