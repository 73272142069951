

let util = {

};
util.title = function (title) {
    window.document.title = title;
};
// const dev = ['localhost', '192.168.'];



util.inOf = function (arr, targetArr) {
    let res = true;
    arr.forEach(item => {
        if (targetArr.indexOf(item) < 0) {
            res = false;
        }
    });
    return res;
};

util.oneOf = function (ele, targetArr) {
    if (targetArr.indexOf(ele) >= 0) {
        return true;
    } else {
        return false;
    }
};

util.showThisRoute = function (itAccess, currentAccess) {
    if (typeof itAccess === 'object' && Array.isArray(itAccess)) {
        return util.oneOf(currentAccess, itAccess);
    } else {
        return itAccess === currentAccess;
    }
};
util.checkUpdate = function (vm) {
    // if (dev.some(v => location.origin.indexOf(v) > 0)) {
    //     return;
    // }
    // if (env !== 'production' || vm.updateTipsIsOpen) {
    //     return;
    // }
    // axios.get(location.origin + '/version.json?_=' + Date.now()).then(res => {
    //     let version = +res.data.version;
    //     let cur = window._version || 0;
    //     if (version > cur && !vm.updateTipsIsOpen) {
    //         vm.updateTipsIsOpen = true;
    //         vm.$Notice.info({
    //             title: vm.$t('tool_new_version_has_release'),
    //             desc: `<p>
    //                     <a style="font-size:15px;" href="javascript:location.reload(true);">
    //                     ${vm.$t('tool_version_update_tips')}
    //                     </a></p>`,
    //             duration: 0,
    //             onClose: () => {
    //                 vm.updateTipsIsOpen = false;
    //             }
    //         });
    //     }
    // }).catch(() => {});
};
util.replaceUrl = function (url) {
    let host = 'edu360.cn';
    let index = url.indexOf(host);
    if (~index) {
        return url.substring(index + host.length);
    } else {
        return url;
    }
};
util.getRouterObjByName = function (routers, name) {
    if (!name || !routers || !routers.length) {
        return null;
    }
    // debugger;
    let routerObj = null;
    for (let item of routers) {
        if (item.name === name) {
            return item;
        }
        routerObj = util.getRouterObjByName(item.children, name);
        if (routerObj) {
            return routerObj;
        }
    }
    return null;
};
util.getKeyIndexFromTableOption = function (tableOption, key) {
    let index = -1;
    for (let i = 0; i < tableOption.length; i++) {
        if (tableOption[i].key === key) {
            index = i;
            break;
        }
    }
    return index;
};
util.handleTitle = function (vm, item) {
    if (typeof item.title === 'object') {
        return vm.$t(item.title.i18n);
    } else {
        return item.title;
    }
};

util.setCurrentPath = function (vm, name, meta) {
    let title = '';
    let isOtherRouter = false;
    vm.$store.state.app.routers.forEach(item => {
        if (item.children.length === 1) {
            if (item.children[0].name === name) {
                title = util.handleTitle(vm, item);
                if (item.name === 'otherRouter') {
                    isOtherRouter = true;
                }
            }
        } else {
            item.children.forEach(child => {
                if (child.name === name) {
                    title = util.handleTitle(vm, child);
                    if (item.name === 'otherRouter') {
                        isOtherRouter = true;
                    }
                }
            });
        }
    });
    let currentPathArr = [];
    if (name === 'home_index') {
        currentPathArr = [
            {
                title: util.handleTitle(vm, util.getRouterObjByName(vm.$store.state.app.routers, 'home_index')),
                path: '',
                name: 'home_index'
            }
        ];
    } else if ((name.indexOf('_index') >= 0 || isOtherRouter) && name !== 'home_index') {
        if (meta.parentPath) {
            currentPathArr = [
                {
                    title: util.handleTitle(vm, util.getRouterObjByName(vm.$store.state.app.routers, 'home_index')),
                    path: '/home',
                    name: 'home_index'
                },
                {
                    title: util.handleTitle(vm, util.getRouterObjByName(vm.$store.state.app.routers, meta.parent)),
                    path: meta.parentPath,
                    name: meta.parent
                },
                {
                    title: title,
                    path: '',
                    name: name
                }
            ];
        } else {
            currentPathArr = [
                {
                    title: util.handleTitle(vm, util.getRouterObjByName(vm.$store.state.app.routers, 'home_index')),
                    path: '/home',
                    name: 'home_index'
                },
                {
                    title: title,
                    path: '',
                    name: name
                }
            ];
        }
    } else {
        let currentPathObj = vm.$store.state.app.routers.filter(item => {
            if (item.children.length <= 1) {
                return item.children[0].name === name;
            } else {
                let i = 0;
                let childArr = item.children;
                let len = childArr.length;
                while (i < len) {
                    if (childArr[i].name === name) {
                        return true;
                    }
                    i++;
                }
                return false;
            }
        })[0];
        if (currentPathObj.children.length <= 1 && currentPathObj.name === 'home') {
            currentPathArr = [
                {
                    title: util.handleTitle(vm, util.getRouterObjByName(vm.$store.state.app.routers, 'home_index')),
                    path: '',
                    name: 'home_index'
                }
            ];
        } else if (currentPathObj.children.length <= 1 && currentPathObj.name !== 'home') {
            currentPathArr = [
                {
                    title: util.handleTitle(vm, util.getRouterObjByName(vm.$store.state.app.routers, 'home_index')),
                    path: '/home',
                    name: 'home_index'
                },
                {
                    title: currentPathObj.title,
                    path: '',
                    name: name
                }
            ];
        } else {
            let childObj = currentPathObj.children.filter((child) => {
                return child.name === name;
            })[0];
            currentPathArr = [
                {
                    title: util.handleTitle(vm, util.getRouterObjByName(vm.$store.state.app.routers, 'home_index')),
                    path: '/home',
                    name: 'home_index'
                },
                {
                    title: currentPathObj.title,
                    path: '',
                    name: currentPathObj.name
                },
                {
                    title: childObj.title,
                    path: currentPathObj.path + '/' + childObj.path,
                    name: name
                }
            ];
        }
    }
    vm.$store.commit('setCurrentPath', currentPathArr);

    return currentPathArr;
};

util.openNewPage = function (vm, name, argu, query) {
    let pageOpenedList = vm.$store.state.app.pageOpenedList;
    let openedPageLen = pageOpenedList.length;
    let i = 0;
    let tagHasOpened = false;
    while (i < openedPageLen) {
        if (name === pageOpenedList[i].name) { // 页面已经打开
            vm.$store.commit('pageOpenedList', {
                index: i,
                argu: argu,
                query: query
            });
            tagHasOpened = true;
            break;
        }
        i++;
    }
    if (!tagHasOpened) {
        let tag = vm.$store.state.app.tagsList.filter((item) => {
            if (item.children) {
                return name === item.children[0].name;
            } else {
                return name === item.name;
            }
        });
        tag = tag[0];
        if (tag) {
            tag = tag.children ? tag.children[0] : tag;
            if (argu) {
                tag.argu = argu;
            }
            if (query) {
                tag.query = query;
            }
            vm.$store.commit('increateTag', tag);
        }
    }
    vm.$store.commit('setCurrentPageName', name);
};

util.toDefaultPage = function (routers, name, route, next) {
    let len = routers.length;
    let i = 0;
    let notHandle = true;
    while (i < len) {
        if (routers[i].name === name && routers[i].children && routers[i].redirect === undefined) {
            route.replace({
                name: routers[i].children[0].name
            });
            notHandle = false;
            next();
            break;
        }
        i++;
    }
    if (notHandle) {
        next();
    }
};

util.fullscreenEvent = function (vm) {
    vm.$store.commit('initCachepage');
    // 权限菜单过滤相关
    vm.$store.commit('updateMenulist');
    // 全屏相关
};
util.timeToDate = function (time){
    return util.timeFormatter(
        new Date(time * 1000), 'yyyy-MM-dd hh:mm'
    );
}
util.timeToDateShort = function (time){
    return util.timeFormatter(
        new Date(time * 1000), 'MM-dd hh:mm'
    );
}
util.timeFormatter = function (data, format) {
    if (data.valueOf() == 0) {
        return '';
    }
    let o = {
        'M+': data.getMonth() + 1, // 月份
        'd+': data.getDate(), // 日
        'h+': data.getHours(), // 小时
        'm+': data.getMinutes(), // 分
        's+': data.getSeconds(), // 秒
        'q+': Math.floor((data.getMonth() + 3) / 3), // 季度
        'S': data.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (data.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
            format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
        }
    }
    return format;
};
//返回一个时间对象 天 小时 分 秒
util.timeFormate = function (data) {
    var sencond = parseInt(data);
    //3600秒等于60分钟等于1小时
    if(sencond > 3600 * 24){  //表示大于一天
        var days = parseInt(sencond / (  60 * 60 * 24));
        var hours = parseInt((sencond % ( 60 * 60 * 24)) / (60 * 60));
        var minutes = parseInt((sencond % ( 60 * 60)) / 60);
        var seconds = (sencond % ( 60)) ;

        return {
            days,
            hours,
            minutes,
            seconds
        };
    } else if(sencond > 3600){     //表示大于一个小时
        var hours = parseInt((sencond % ( 60 * 60 * 24)) / (60 * 60));
        var minutes = parseInt((sencond % ( 60 * 60)) / 60);
        var seconds = (sencond % ( 60)) ;

        return {
            hours,
            minutes,
            seconds
        };
    } else if(sencond > 60){  //表示大于一分钟
        var minutes = parseInt((sencond % ( 60 * 60)) / 60);
        var seconds = (sencond % ( 60)) ;

        return {
            hours,
            minutes,
            seconds
        };
    } else {  //不大于一天、不大于一个小时、也不大于一分钟，那就是秒数
        var seconds = (sencond % ( 60)) ;
        return {
            hours,
            minutes,
            seconds
        };
    }
};
util.getFileNameFromUrl = function (url) {
    return url.substring(url.lastIndexOf('/') + 1);
};

// 判断数组a是否包含b
util.is_array_contain = function (a, b) {
    let t;
    for (var i = 0; i < b.length; i++) {
        t = false;
        for (var j = 0; j < a.length; j++) {
            if (b[i] === a[j]) {
                t = true;
                break;
            }
        }
        if (!t) return false;
    }
    return true;
};
util.checkArr = function (arr1, arr2) {
    let t;
    let arr = [];
    for (var i = 0; i < arr2.and.length; i++) {
        t = false;
        for (var j = 0; j < arr1.length; j++) {
            if (arr2.and[i] == arr1[j]) {
                t = true;
                break;
            }
        }
    }
    if (arr2.and.length) {
        if (t) {
            arr.push(1);
        } else {
            arr.push(0);
        }
    }

    var rs = false;
    for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.or.length; j++) {
            if (arr1[i] == arr2.or[j]) {
                rs = true;
            }
        }
    }
    if (arr2.or.length) {
        if (rs) {
            arr.push(1);
        } else {
            arr.push(0);
        }
    }
    if ($.inArray(0, arr) != -1) {
        return false;
    } else {
        return true;
    }
};
util.setCookie = function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + '; ' + expires;
};
util.handleAjaxError = function (vm, res, textStatus, responseText, cb) {
    // vm 是 Vue组件示例 res text Status responseText 分别是jQuery ajax error 回调的参数
    // cb 是一个函数 用于出错时的业务逻辑处理
    typeof cb === 'function' && cb();
    let message = '';
    switch (textStatus) {
        case 'timeout' : {
            message = vm.$t('tool_request_timeout');
            break;
        }
        case 'abort': {
            return;
        }
        default: {
            message = (res.responseJSON && res.responseJSON.message) || responseText;
        }
    }
    message = message || vm.$t('tool_request_error');
    vm.$Message.destroy();
    vm.$Message.error({
        content: message,
        duration: 1
    });
};
// 功能：根据扩展名来生成不同的显示方式
util.getHtmlByFileExt = function (filename, url) {
    var index = filename.lastIndexOf(".");
    var suffix = filename.substr(index + 1).toUpperCase();
    var result = '';
    if (suffix == 'JPG' || suffix == 'JPEG' || suffix == 'PNG' || suffix == 'BMP' || suffix == 'GIF') { // 图片
        result = result + '<a target="_blank" href="' + url + '"><img height="50" width="auto" border="0" src="' + url + '"/></a>';
    } else if (suffix == 'MP3' || suffix == 'AAC' || suffix == 'WAV' || suffix == 'FLAC' || suffix == 'APE') { // 音频
        result = result + '<audio src="' + url + '" controls="controls" width="250" height="100">';
        result = result + 'Your browser does not support audio.';
        result = result + '</audio>';
    } else if (suffix == 'AVI' || suffix == 'MP4' || suffix == 'WMV' || suffix == 'RMVB' || suffix == 'MKV') { // 视频
        result = result + '<video src="' + url + '" controls="controls" width="250" height="100">';
        result = result + 'Your browser does not support video.';
        result = result + '</video>';
    } else {
        result = result + '<a class="link-1536" target="_blank" href="' + url + '">' + filename + '</a>';
    }
    return result;
};
// 功能：得到文件的类型
util.geFileTypeByExt = function (filename) {
    var index = filename.lastIndexOf(".");
    var suffix = filename.substr(index + 1).toUpperCase();
    var result = '';
    if (suffix == 'JPG' || suffix == 'JPEG' || suffix == 'PNG' || suffix == 'BMP' || suffix == 'GIF') { // 图片
        result = 'img-file';
    } else if (suffix == 'MP3' || suffix == 'AAC' || suffix == 'WAV' || suffix == 'FLAC' || suffix == 'APE') { // 音频
        result = 'audio-file';
    } else if (suffix == 'AVI' || suffix == 'MP4' || suffix == 'WMV' || suffix == 'RMVB' || suffix == 'MKV') { // 视频
        result = 'video-file';
    } else {
        result = 'other-file';
    }
    return result;
};

util.downloadFile = function (vm, url) {
    console.log(vm,url,'ddddd')
    // 利用a标签下载文件
    const a = document.createElement('a');
    a.setAttribute('download', url);
    a.setAttribute('href', url);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

/**
 * data  Date对象 或者 毫秒算的时间戳
 */
util.DateToSeconds = function (date) {
    let ret;
    if (typeof date === 'number') {
        ret = Math.floor(date / 1000);
    } else if (date instanceof Date) {
        ret = Math.floor(date.valueOf() / 1000);
    }
    return ret;
};
util.isIp = function(ip) {
    const ipre = /\b(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))\b/;
    return ipre.test(ip);
}
util.getDomain = function() {
    let domain = location.hostname;
    if (domain.indexOf('localhost') > -1){
        return domain
    } else {
        domain = domain.split('.');
        return [domain[domain.length - 2], domain[domain.length - 1]].join('.');
    }
}

util.captchaCodeCheck = function(data,hash)
{
    console.log(data,hash,'ddddd')
    var v = data;
    for (var i = v.length - 1, h = 0; i >= 0; --i) {
        h += v.charCodeAt(i);
    }
    if (h != hash) {
        return false;
    }
    return v;
}
util.pswEncryption = function(data)
{
    let newData = window.btoa(data);
    newData = newData.replace(/\//g,'_a').replace(/\+/g,'_b').replace(/\=/g,'_c');

    return newData;
}

util.decrypt = function(data)
{
    let newData = data.replace(/_a/g,'/').replace(/_b/g,'+').replace(/_c/g,'=');
    newData = decodeURIComponent(escape(window.atob(newData)));

    return newData;
}

util.base64decode = function(data)
{
    return util.decrypt(data);
}
util.getCurDate = function()
{
    let oDate = new Date();
    let y = oDate.getFullYear();
    let m = oDate.getMonth() + 1;
    let d = oDate.getDate();

    return {
        y:y,
        m:m < 10 ? '0' + m : m,
        d:d < 10 ? '0' + d : d
    }
}

util.formateMenu = function(data){
    let allData = JSON.parse(JSON.stringify(data));
    // console.log(allData)
    // console.log('formateMenu allData:',allData,allData.length,allData.menu.length,allData.all.length)

    // if (allData.length === 0 || allData.menu.length === 0 || allData.all.length === 0){
    //     return [];
    // }


    let menuList = [];
    let keys = Object.keys(allData.menu);
    let curModule = allData.curModule;

    let menuRoutes = allData.all.filter((item)=>{
        return item.children && item.children.length;
    })[0].children.filter((item)=>{

        return item.meta.module_route == curModule
    })[0]?.children;


    // console.log(menuRoutes,'menuRoutesmenuRoutes')
    menuRoutes?.forEach((item,index)=>{
        if(item.children){ //模块
            item.children.forEach((sItem,sIndex)=>{

                if(keys.findIndex((ssItem)=>{
                    return ssItem == sItem.route;
                }) != -1 && item.meta.module_route == curModule){
                    sItem.status = true;
                    item.status = true;
                }
            });
            if(item.status){
                let obj = {
                    name:item.meta.title,
                    route:item.route,
                    path:item.path || '',
                    color:item.color,
                    img:item.meta.img
                }
                let children = item.children.filter((data)=>{
                    return data.status;
                });
                if(children.length){
                    obj.children = children;
                }
                if(!data.showCdkey){
                   if(item.route != 'cdkey/list' && item.route != 'cdkey/mycdkey'){
                       menuList.push(obj);
                   }
                }else{
                    menuList.push(obj);
                }
            }
        }
        else{
            if(keys.findIndex((ssItem)=>{
                return ssItem == item.route;
            }) != -1){
                item.status = true;
                let path = item.path.split('/:')[0]
                let obj = {
                    name:item.meta.title,
                    route:item.route,
                    path:path || '',
                    color:item.color,
                    img:item.meta.img
                }
                // console.log(item.meta.title,obj)
                menuList.push(obj);
            }
        }
    });
    return menuList;
}
util.downLoad = function(data){
    let blob = this.dataURLtoBlob(data.file)
    // console.log(blob,'blobblob')
    //let blob = res.file
    let fileName = data.filename;
    if (window.navigator.msSaveOrOpenBlob) {
        //兼容 IE & EDGE
        navigator.msSaveBlob(blob, fileName)
    } else {
        var link = document.createElement('a')
        // 兼容不同浏览器的URL对象
        const url = window.URL || window.webkitURL || window.moxURL
        // 创建下载链接
        link.href = url.createObjectURL(blob)

        //console.log(link.href, 'link.href')
        //命名下载名称
        link.download = fileName;
        //点击触发下载
        link.click();
        //下载完成进行释放
        url.revokeObjectURL(link.href);
    }
}
util.dataURLtoBlob = function(base64Str) {
    // console.log(base64Str,'base64Str')
    let bstr = atob(base64Str)
    //let bstr = base64Str;
    //console.log(bstr)

    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: 'application/octet-stream' }) //该类型为xls

}
util.isEmail = function (v = "") {
    var r = /^([a-zA-Z0-9]+[_]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
    return !v ? false : r.test(v);
}
util.getMonthLastDayAndFirstDay = function(v=""){
    var date = v;
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var nextMonth = new Date(date.getFullYear(), date.getMonth()+1, 1);
    var lastDay = new Date(nextMonth.getTime()-1000*60*60*24);

    // console.log(firstDay,lastDay,'lastDaylastDaylastDaylastDaylastDay')
    return {
        firstDay:firstDay.getFullYear() + '/'+ (firstDay.getMonth() + 1) + '/' + firstDay.getDate(),
        lastDay:lastDay.getFullYear() + '/'+ (lastDay.getMonth() + 1) + '/' + lastDay.getDate()
    };
}
util.oneDecimal = function (v = ""){
    // 判断是否为整数，或者最多保留一位小数
    var r = /(^[1-9]([0-9]+)?(\.[0-9])?$)|(^(0){1}$)|(^[0-9]\.([0-9])?$)/;
    let str = '';
    if(!r.test(v)){
        str = v.substring(0,v.length - 1);
    }else{
        str = v;
    }
    return str;
};
util.isInte = function (v = ""){
    // 判断是否为正整数(1-120)
    var r = /^([0]|[1-9][0-9]*)$/;
    return r.test(v);
},

util.removeSpaces = function(str = ''){
    return str.replace(/^\s+|\s+$/g,"");
}
util.jsonToArr = function(obj){
    let arr = [];
    for(let name in obj){
        arr.push({
            name:obj[name],
            id:name
        })
    }
    return arr;
}
util.tableColumnAutoCss={
    display:"block",
    width:"100%",
    textOverflow:"ellipsis",
    overflow:"hidden",
    whiteSpace:"nowrap"
},
util.tableColumnTooltip=function(h,value,to={}){
    return h("Tooltip",{
        props: {
          placement: "bottom-start",
          transfer: true,
        },
        style: {
          color:to.name || to.path?'#2db7f5':"",
          display: "block",
          width: "100%",
        }},
        [
            to.name?
            // 如果传to是链接  (链接分name跳转和path跳转)
            h('router-link',{
                props:{
                    to:{
                        name:to.name,
                        params:to.params
                    }
                },
                style:util.tableColumnAutoCss
            },value):
            to.query?
            h('router-link',{
                props:{
                    to:{
                        path:to.path,
                        query:to.query
                    }
                },
                style:util.tableColumnAutoCss
            },value):
            // 如果没有传to说明不是链接
            h("div",{
                style: util.tableColumnAutoCss,
            },value),

            h("span",{
                slot: "content",
                style: {
                    whiteSpace: "normal",
                }
            },value)
        ]
    );
}
// 将秒转为时分秒(带冒号)
util.secondsToMS=function(seconds){
    if(isNaN(seconds)) return "";
    let hour=0;
    let minute=0;
    let second=parseInt(seconds);
    if(second>60){
        minute=parseInt(second/60);
        second=parseInt(second%60);
        if(minute>60){
            hour=parseInt(minute/60);
            minute=parseInt(minute%60);
        }
    }
    if(hour<10){
        hour="0"+hour;
    }
    if(minute<10){
        minute="0"+minute;
    }
    if(second<10){
        second="0"+second;
    }
    return `${hour}:${minute}:${second}`;
}
// 获取表格高度
util.getTableHeight=function(num){
    let height=window.innerHeight-275;
    if(typeof num==="number"){
        height-=num;
    }
    return height;
}
// 对象转数组
util.objToArr=function(obj){
    const isObj=Object.prototype.toString.call(obj) === '[object Object]';
    if(!isObj) {
        console.error("请传入对象");
        return [];
    }
    const arr=[];
    Object.keys(obj).forEach(key=>{
        arr.push(obj[key]);
    });
    return arr;
}

util.fullscreen=function (element) {
    if (element.requestFullscreen) {
        element.requestFullscreen(); // 浏览器支持全屏API
    } else if (element.mozRequestFullScreen) { // Firefox 早期的全屏方法
        element.mozRequestFullScreen(); // 早期的全屏方法，需要前缀 "moz"
    } else if (element.webkitRequestFullscreen) { // Chrome, Safari 和 Opera 的当前全屏方法
        element.webkitRequestFullscreen(); // 当前全屏方法，需要前缀 "webkit"
    } else if (element.msRequestFullscreen) { // IE/Edge 全屏方法
        element.msRequestFullscreen(); // IE/Edge 全屏方法，需要前缀 "ms"
    }
}
util.exitFullscreen=function(){
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
    }
}
// 将DataURL转换为Blob对象的方法
util.dataURItoBlob=function(dataURI) {
    const byteString = atob(dataURI.split(',')[1]); // 解析DataURL中的Base64数据
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // 获取MIME类型
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i); // 将Base64字符串转换为字节数组
    }
    return new Blob([ab], { type: mimeString }); // 创建Blob对象并指定MIME类型
  }
export default util;
