import {request} from '@/utils/request.js'

//用户首页-学生
const indexStudent = (data) => {
    return request({
        url: '/user/index-student',
        method: 'post',
        data
    })
}
const indexStudentV1 = (data) => {
    return request({
        url: '/user/index-student-v1',
        method: 'post',
        data
    })
}
//用户首页-老师
const indexTeacher = (data) => {
    return request({
        url: '/user/index-teacher',
        method: 'post',
        data
    })
}
//用户首页-组织管理
const indexManager = (data) => {
    return request({
        url: '/user/index-manager',
        method: 'post',
        data
    })
}
//用户首页-内容生产
const indexContentProduction = (data) => {
    return request({
        url: '/user/index-content-production',
        method: 'post',
        data
    })
}
//用户首页-内容管理
const indexContentManager = (data) => {
    return request({
        url: '/user/index-content-manager',
        method: 'post',
        data
    })
}
//用户首页-平台管理
const indexPlatform = (data) => {
    return request({
        url: '/user/index-platform',
        method: 'post',
        data
    })
}

//用户首页-平台管理
const statByCategoryTrainCount = (data) => {
    return request({
        url: '/dataset-train/stat-by-category-train-count',
        method: 'post',
        data
    })
}

//用户首页-按组织和日期统计
const statBySite = (data) => {
    return request({
        url: '/dataset-train/stat-by-site',
        method: 'post',
        data
    })
}

//平台管理-组织区域分布
const siteAreaDistribute = (data) => {
    return request({
        url: '/site/site-area-distribute',
        method: 'post',
        data
    })
}

//平台管理-首页
const indexPlatformNew = (data) => {
    return request({
        url: '/user/index-platform-new',
        method: 'post',
        data
    })
}

//平台管理-组织统计排行
const siteStatList = (data) => {
    return request({
        url: '/site/site-stat-list',
        method: 'post',
        data
    })
}

//平台管理-场景下刷题统计数据
const scenceStatData = (data) => {
    return request({
        url: '/dataset-train/scence-stat-data',
        method: 'post',
        data
    })
}

//平台管理-组织统计排行导出
const siteStatListExport = (data) => {
    return request({
        url: '/site/site-stat-list-export',
        method: 'post',
        data
    })
}




//平台管理-认证专项刷题统计
const statByAuthItem = (data) => {
    return request({
        url: '/dataset-train/stat-by-auth-item',
        method: 'post',
        data
    })
}

//平台管理-认证刷题功能统计
const statByAuthFunction = (data) => {
    return request({
        url: '/dataset-train/stat-by-auth-function',
        method: 'post',
        data
    })
}

//平台管理-教材练习功能刷题统计
const statByMap = (data) => {
    return request({
        url: '/dataset-train/stat-by-map',
        method: 'post',
        data
    })
}

//平台管理-竞赛功能刷题统计
const statByTypeMatch = (data) => {
    return request({
        url: '/dataset-train/stat-by-type-match',
        method: 'post',
        data
    })
}

//内容管理 - 首页
const indexContentManagerNew = (data) => {
    return request({
        url: '/user/index-content-manager-new',
        method: 'post',
        data
    })
}

//内容管理-试题已处理统计
const statByDealTimeAndUser = (data) => {
    return request({
        url: '/dataset-data/stat-by-deal-time-and-user',
        method: 'post',
        data
    })
}

//内容管理-题型总题量统计
const statByType = (data) => {
    return request({
        url: '/dataset-data/stat-by-type',
        method: 'post',
        data
    })
}

//内容管理-各图谱刷题数量
const statMapTrain = (data) => {
    return request({
        url: '/dataset-data/stat-map-train',
        method: 'post',
        data
    })
}

//内容管理-刷题频次数量统计
const statTrainFrequency = (data) => {
    return request({
        url: '/dataset-data/stat-train-frequency',
        method: 'post',
        data
    })
}

//内容管理-更新日志统计
const statRecordByAction = (data) => {
    return request({
        url: '/dataset-data/stat-record-by-action',
        method: 'post',
        data
    })
}

//内容管理-刷题类目统计
const statCategoryTrain = (data) => {
    return request({
        url: '/dataset-data/stat-category-train',
        method: 'post',
        data
    })
}

//内容管理-导出刷题类目统计
const statCategoryTrainExport = (data) => {
    return request({
        url: '/dataset-data/stat-category-train-export',
        method: 'post',
        data
    })
}

//内容管理-试题新增数量统计
const statByCreateTime = (data) => {
    return request({
        url: '/dataset-data/stat-by-create-time',
        method: 'post',
        data
    })
}


//分类-级联
const categoryTree = (data) => {
    return request({
        url: '/dataset-category/tree',
        method: 'post',
        data
    })
}


//用户首页-平台管理
const indexPlatformV1 = (data) => {
    return request({
        url: '/user/index-platform-v1',
        method: 'post',
        data
    })
}

const chatgptUseStat=(data)=>{
    return request({
        url:"/user/chatgpt-use-stat",
        method:"post",
        data
    });
}

const monthTrainStat=(data)=>{
    return request({
        url:"/dataset-category/month-train-stat",
        method:"post",
        data
    });
}



export default {
    indexStudent,
    indexTeacher,
    indexManager,
    indexContentProduction,
    indexContentManager,
    indexPlatform,
    statByCategoryTrainCount,
    statBySite,
    siteAreaDistribute,
    indexPlatformNew,
    siteStatList,
    scenceStatData,
    siteStatListExport,
    statByAuthItem,
    statByAuthFunction,
    statByMap,
    statByTypeMatch,
    indexContentManagerNew,
    statByDealTimeAndUser,
    statByType,
    statMapTrain,
    statTrainFrequency,
    statRecordByAction,
    statCategoryTrain,
    statCategoryTrainExport,
    statByCreateTime,
    categoryTree,
    indexPlatformV1,
    indexStudentV1,
    chatgptUseStat,
    monthTrainStat
}
