export default {
    // My task
    operator_input_search: 'Search',
    operator_input_project_info: 'please enter task ID, name',
    operator_input_text: 'Search content',
    operator_loading: 'Loading job text ...',
    operator_modification: 'Modify',
    operator_task: 'Task',
    operator_project_id: 'Project ID',
    operator_batch_id: 'Batch ID',
    operator_step_id: 'Process ID',
    operator_task_id: 'Task ID',
    operator_task_name: 'Task name',
    operator_task_type: 'Task type',
    operator_step: 'Process',
    operator_working_out: 'Workload',
    operator_schedule: 'Progress',
    operator_starting_time: 'Start time',
    operator_end_time: 'End time',
    operator_handle: 'Operation',
    operator_qc: 'Quality inspection',
    operator_audit: 'Audit',
    operator_wait_audit: 'to be audited',
    operator_auditing: 'auditing',
    operator_audit_accuracy: 'Audit by accuracy rate',
    operator_audit_editing: 'Modify: execute rejected job',
    operator_audit_review: 'Audit: audit rejected&revised job',
    operator_audit_qc: 'Quality inspection: inspect rejected&revised job',
    operator_audit_execution: 'Execute: cancel the dpending status and re-execute the job',
    operator_execute: 'Execute',
    operator_reject_work: 'Reject',
    operator_reject_work_reason: 'Reason for error',
    operator_difficult_work: 'Pend',
    operator_difficult: 'Pending',
    operator_rework_work: 'Revise',
    operator_works: 'Number of jobs',
    operator_fields_description: 'Field description',
    operator_execute_explain: 'Number of executed sheets/number of executable sheets/total number of batch jobs',
    operator_symbol_explain: '-- Table is not initialized, please click to execute',
    operator_execute_description: 'Execute: number of executed sheets/number of executable sheets/total number of batch jobs',
    operator_audit_description: 'Audit: number of audited sheets/number of executable sheets/total number of batch jobs',
    operator_qc_description: 'Quality inspection: number of inspected sheets/number of executable sheets/total number of batch jobs',
    operator_description: 'Perform, audit, quality inspection: click to enter interface for execution, auditing and quality inspection',
    operator_reject_work_description: 'Rejected jobs (number of jobs n) : you have n rejected jobs, you can click them to revise',
    operator_difficult_work_description: 'Pending jobs (number of jobs n) : you have n pending jobs，please click them to revise',
    operator_rework_work_description: 'Revised jobs (number of jobs n) : you have n resubmitted jobs, please click them to audit',
    operator_nearby_no_image: "Nso Data",
    // mission details
    operator_export_performance: 'Export performance',
    operator_task_description: 'Task description',
    operator_no_description: 'No description yet',
    operator_my_performance: 'My performance',
    operator_performance_ranking: 'Performance ranking',
    operator_my_performance_chart: 'My performance(Chart)',
    operator_my_job: 'My work',
    operator_download_lists: 'Download list',
    operator_download: 'Download',
    operator_filename: 'Filename',

    // my homework
    operator_all_job: 'All jobs',
    operator_active: 'Work in progress',
    operator_submitted: 'Submitted',
    operator_passed: 'Approve',
    operator_expired: 'Expired',
    operator_rejected: 'Rejected',
    operator_need_rework: 'To be revised',
    operator_need_rework_tips:
        'To be revised. The partent process has not finished modification',
    operator_rework: 'to be reviewed',
    operator_job_id: 'ID',
    operator_input_join_id: 'Please enter job ID',
    operator_job_name: 'Name',
    operator_status: 'Status',
    operator_join_time: 'Enrollment time',
    operator_update_time: 'Update time',
    operator_view_data: 'View',
    operator_reject_cause: 'rejection reason',
    operator_remaining_effective_time: 'Remaining valid time',
    operator_time_unit: 'Unit: second (s)',
    operator_cumulative: 'Accumulative work time',
    operator_mark_number: 'Number of annotations',
    operator_picture_description: 'For image annotation, number of annotations means the number of annotated graphs, including boxes, points and regions',
    operator_text_description: 'For text annotation, number of annotations means the number of annotated key entries',
    operator_voice_description: 'For voice annotation, number of annotations means valid voice fragments',
    operator_button: "Button description",
    operator_button_edit_description: 'Modify: cancel submitted status and re-execute the job',
    operator_points: 'Number of points',
    operator_audit_time: 'Audit time',
    operator_failure_Cause: 'Reason for expiration',
    operator_job_results: 'Results',
    operator_record: 'Record',
    operator_success: 'Successful operation ',
    operator_vpos: 'Operator',
    operator_type: 'Type',
    operator_operating_state: 'Operation status',
    operator_operating_type: 'Operation type',
    operator_receive_time: 'Cliam time',
    operator_submission_time: 'Submit time',
    operator_creation_time: 'Create time',
    operator_totle_valid_work: 'Total number of submitted sheets',
    operator_totle_submitted_work: 'Total number of submitted jobs',
    operator_totle_lines: 'Total number of lines',
    operator_totle_boxs: 'Total number of rectangles',
    operator_totle_polygons: 'Total number of polygons',
    operator_number_others: 'Total number of other shapes',
    operator_number_others_desc:
        'Statistics of the number of annotated shapes other than points, lines, rectangles and polygons',
    operator_valid_work: 'Number of submitted sheets',
    operator_submitted_work: 'Number of submitted jobs',
    operator_point: 'Point',
    operator_line: 'Line',
    operator_rectangle: 'Rectangle',
    operator_polygon: 'Polygon',
    operator_other: 'Others',
    operator_previous_operator: 'Previous operator',
    operator_before_workstatus: 'Status before operation',
    operator_after_workstatus: 'Status after operation',
    operator_operating_time: 'Operation time',
    operator_refreshing_tip:
        'Refreshing the page will result in the following repeated situation:',
    operator_refreshing_tip_desc1: 'Claim-claim-...',
    operator_refreshing_tip_desc2: 'Claim-execute-claim-execute-...',
    operator_shape_circler: 'Circle',
    operator_shape_ellipse: 'Ellipse',
    operator_shape_rect: 'Rectangle',
    operator_shape_bonepoint: 'BonePoints',
    operator_shape_closedcurve: 'Closedcurve',
    operator_shape_cuboid: 'Cuboid',
    operator_shape_line: 'Line',
    operator_shape_pencilline: 'PencilLine',
    operator_shape_point: 'Point',
    operator_shape_polygon: 'Polygon',
    operator_shape_quadrangle: 'Quadrangle',
    operator_shape_splinecurve: 'SplineCurve',
    operator_shape_trapezoid: 'Trapezoid',
    operator_shape_triangle: 'Triangle',
    operator_shape_unclosedpolygon: 'Polyline',
    operator_shape_rectP: 'Rect+Point',
    operator_shape_rectS: 'Rectangular seals(Rectangle with fixed width and height)',

    // my performance
    operator_total_time: 'Total work time: {num} seconds',
    operator_total_sheet: 'Total number of sheets of jobs',
    operator_total_mark_number: 'Total number of annotations of jobs',
    operator_total_count: 'Total number of points in jobs',
    operator_total_approved: 'Total number of approvals',
    operator_total_rejected: 'Total number of rejections',
    operator_total_reset: 'Total number of resets',
    operator_total_time_vocie: 'Total work time (voice)',
    operator_time: 'Time',
    operator_amount: 'Number of sheets',
    operator_box: 'Number of boxes',
    operator_counting: "Number of approvals",
    operator_execute_passed: 'Number of approved sheets of executed jobs',
    operator_rejecting: 'Number of rejections',
    operator_execute_rejected: 'Number of rejected sheets of executed jobs',
    operator_reseting: 'Number of resets',
    operator_execute_reseted: 'Number of reset sheets of executed jobs',
    operator_reseted: 'Reset',
    operator_reseted_description:
        'Number of reset sheets in quality inspection',
    operator_batch_editing: 'Batch edit',
    operator_batch_review: 'Batch audit',
    operator_batch_qc: 'Batch quality inspection',
    operator_batch_execution: 'Batch execute',
    operator_unchecked_job: 'No job has been selected',
    operator_qced: 'Inspected',
    operator_check: 'View',
    operator_instrument_panel: 'instrument panel',
    operator_view_details: 'Click to view details',
    operator_label_amount: 'Annotation statistics details',
    operator_query_condition: 'query condition',
    operator_start_time_and_end_time: 'start time~end time',
    operator_query: 'Query',
    operator_reset: 'Reset',
    operator_processed_work_count: 'Processed job statistics',
    operator_audit_submit_total_time: 'Total auditor\'s submissions',
    operator_audit_submit_total_time_dasc: 'Total auditor\'s job submissions for the current task',
    operator_totle_submitted_work_dasc: 'Total job submissions for the current task ',
    operator_audit_submit_total_folio: 'Total auditor\'s submitted sheets',
    operator_audit_submit_total_folio_dasc: 'Current task the totalauditor\'s submitted job sheets for the current task',
    operator_totle_valid_work_dasc: 'Total submitted job sheets for the current task',
    operator_work_status_count: 'Job status statistics',
    operator_reject_count: 'Total aduitor\'s rejections',
    operator_reject_count_dasc: 'Total auditor\'s rejection executions for the current task',
    operator_reset_count: 'Total auditor\'s resets',
    operator_reset_count_dasc: 'Total auditor\'s reset executions for the current task',
    operator_rejected_count: 'Total rejections by auditor',
    operator_rejected_count_dasc: 'Total rejections through Acceptance Check',
    operator_label_count: 'Annotation statistics',
    operator_percent_pass_dasc: 'Approved / Accepted sheets',
    operator_execute_percent_pass_dasc: 'Approved sheets / Audited sheets',
    operator_work_time: 'Work time',
    operator_work_time_dasc: 'Total audited work hours for the current task (including approved and rejected)',
    operator_execute_work_time_dasc: 'Total work hours of the jobs done for the current task (including approved and rejected)',
    operator_work_total_time: 'Total accumulative work time(s)',
    operator_execute_work_total_time: 'Accumulative work time(s)',
    operator_invalid_data: 'invalid data',
    operator_invalid_data_amount: 'Total invalid data',
    operator_valid_data: 'valid data',
    operator_valid_data_amount: 'Total valid data',
    operator_delete_count: 'deletions(show only)',
    operator_delete_record: 'Delete action record (show only)',
    operator_delete_frame: 'deleted boxes',
    operator_totle_pass_work: 'Total approved sheets',
    operator_totle_passed_work_dasc: 'Total approved sheets for the current task',
    operator_totle_rejected_work: 'Total rejections',
    operator_totle_rejected_work_dasc: 'Total rejections by auditor for the current task',
    operator_totle_reseted_work: 'Total resets',
    operator_totle_reseted_work_dasc: 'Total resets by auditor for the current task',
    operator_work_count: 'Job statistics',
    operator_invalid_data_amount_dasc: 'The total sheets when the operator selects no records in the cleaning control',
    operator_valid_data_amount_dasc: 'The total sheets when the operator selects yes records in the cleaning contro',

    // execution
    operator_execute_amount: 'Number of sheets of executed jobs',
    operator_execute_points: 'Number of points of executed jobs',
    operator_operation_time: 'Work time',
    operator_speech: 'Voice only',
    operator_audited: 'Audited',
    // audit
    operator_execute_audit_amount: 'Number of audited sheets of executed jobs',
    operator_mark_number_amount: 'Number of audited annotations of executed jobs',
    operator_qc_passed_amount: 'Number of approved sheets in quality inspection',
    operator_qc_reject_amount: 'Number of rejected sheets in quality inspection',
    // qc
    operator_qc_amount: 'Number of inspected sheets in quality inspection',
    operator_qc_label: 'Number of inspected annotations in quality inspection',
    operator_qc_pass_frame: 'Number of pproved sheets in quality inspection',
    operator_qc_reject_frame: 'Number of rejected sheets in quality inspection',
    operator_qc_reset_frame: 'Number of reset sheets in quality inspection'
};
