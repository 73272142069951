import {request} from '@/utils/request.js';

// 直播间列表
const liveRoomList=(data)=>{
  return request({
    url: '/live-room/m-list',
    method: 'post',
    data
  });
}
// 直播间列表(教师端)
const liveRoomTeacherList=(data)=>{
  return request({
    url: '/live-room/list',
    method: 'post',
    data
  });
}

// 创建直播
const createLiveRoom=(data)=>{
  return request({
    url: '/live-room/create',
    method: 'post',
    data
  });
}

// 进入直播间
const enterLiveRoom=(data)=>{
  return request({
    url: '/live-room/join-live',
    method: 'post',
    data
  });
}

// 离开直播间
const leaveLiveRoom=(data)=>{
  return request({
    url: '/live-room/quit-live',
    method: 'post',
    data
  });
}

// 开播推流
const startStreaming=(data)=>{
  return request({
    url: '/live-room/start-live',
    method: 'post',
    data
  });
}

// 停播
const stopStreaming=(data)=>{
  return request({
    url: '/live-room/end-live',
    method: 'post',
    data
  });
}

// 直播间成员列表
const getLiveRoomUserList=(data)=>{
  return request({
    url: '/live-room/user-list',
    method: 'post',
    data
  });
}

// 获取直播间详情
const getLiveRoomDetail=(data)=>{
  return request({
    url: '/live-room/detail',
    method: 'post',
    data
  });
}

//
const monitoringReport=(data)=>{
  return request({
    url: '/live-room/report-user-monitor-data',
    method: 'post',
    data
  });
}


// 监考端抽屉详情
const monitorUserDetail=(data)=>{
  return request({
    url: '/live-room/monitor-list',
    method: 'post',
    data
  });
}

// 讨论列表
const commentList=(data)=>{
  return request({
    url: '/comment/list',
    method: 'post',
    data
  });
}

// 开播
const attendClass=(data)=>{
  return request({
    url: '/live-room/start',
    method: 'post',
    data
  });
}

// 下课
const endClass=(data)=>{
  return request({
    url: '/live-room/end',
    method: 'post',
    data
  });
}

// 同步状态
const liveStatusSync=(data)=>{
  return request({
    url: '/live-room/user-status-sync',
    method: 'post',
    data
  });
}

// 获取进入房间的token（火山的）
const getRoomToken=(data)=>{
  return request({
    url: '/live-room/volcano-rtc-token',
    method: 'post',
    data
  });
}

export default{
  liveRoomList,
  createLiveRoom,
  enterLiveRoom,
  leaveLiveRoom,
  startStreaming,
  stopStreaming,
  getLiveRoomUserList,
  getLiveRoomDetail,
  monitoringReport,
  monitorUserDetail,
  commentList,
  attendClass,
  endClass,
  liveRoomTeacherList,
  liveStatusSync,
  getRoomToken
}
