import {request} from '@/utils/request.js'

//批量导入
const datasetCertUserImport = (data) => {
    return request({
        url: '/dataset-cert-user/import',
        method: 'post',
        data
    })
}

//证书列表
const datasetCertUserList = (data) => {
    return request({
        url: '/dataset-cert-user/list',
        method: 'post',
        data
    })
}

//领取（下载）证书
const datasetCertUserReceive = (data) => {
    return request({
        url: '/dataset-cert-user/receive',
        method: 'post',
        data
    })
}

//批次列表
const datasetCertUserImportList = (data) => {
    return request({
        url: '/dataset-cert-user/import-list',
        method: 'post',
        data
    })
}

//领取（下载）证书
const batchSendCertSms = (data) => {
    return request({
        url: '/dataset-cert-user/batch-send-cert-sms',
        method: 'post',
        data
    })
}

//删除证书
const batchSendCertDelete = (data) => {
    return request({
        url: '/dataset-cert-user/delete',
        method: 'post',
        data
    })
}


//发送短信
const datasetCertUserSendSms = (data) => {
    return request({
        url: '/dataset-cert-user/send-sms',
        method: 'post',
        data
    })
}

//导出名单
const datasetCertUserExport = (data) => {
    return request({
        url: '/dataset-cert-user/export',
        method: 'post',
        data
    })
}

//更新批次信息
const datasetCertUserImportUpdate = (data) => {
    return request({
        url: '/dataset-cert-user/import-update',
        method: 'post',
        data
    })
}

//物料详情
const datasetCertUserDetail = (data) => {
    return request({
        url: '/dataset-cert-user/detail',
        method: 'post',
        data
    })
}


export default {
    datasetCertUserImport,
    datasetCertUserList,
    datasetCertUserReceive,
    datasetCertUserImportList,
    batchSendCertSms,
    batchSendCertDelete,
    datasetCertUserSendSms,
    datasetCertUserExport,
    datasetCertUserImportUpdate,
    datasetCertUserDetail
}
