<template>
<div>
  <Page :total="total" :page-size-opts="pageSizeOpts" :page-size="pageSize" @on-change="changePage" @on-page-size-change="changePageSize" :current="current" show-total show-sizer show-elevator></Page>
</div>
</template>

<script>
export default {
  name: "myPage.vue",
  props:{
    total:{
      type:Number,
      default:0
    },
    current:{
      type:Number,
      default:0
    },
    pageSizeOpts:{
      type:Array,
      default:()=>{
        return [10,20,50,100]
      }
    },
    pageSize:{
      type:Number,
      default:10
    }
  },
  methods:{
    changePage(page){
      this.$emit('changePage',page)
    },
    changePageSize(pageSize){
      this.$emit('changePageSize',pageSize)
    }
  }
}
</script>

<style scoped>

</style>
