export default {
    user_guide: '使用文档',
    user_close_tag: '关闭标签',
    user_avatar: '头像',
    user_avatar_settings: '头像设置',
    user_name: '昵称',
    user_email: '邮箱',
    user_mobile: '手机号',
    user_phone: '联系电话',
    user_status :'状态',
    user_company: '公司',
    user_language: '语言',
    user_created_at : '创建时间',
    user_updated_at :'更新时间',
    user_operation :'操作',
    user_login_password: '登录密码',
    user_change_password: '修改密码',
    user_badge_number: '有{num}条未读消息',
    user_cancel: '取消',
    user_save: '保存',
    user_delete: '删除',
    user_old_password: '原密码',
    user_new_password: '新密码',
    user_confirm_password: '确认新密码',
    user_input_text_name: '昵称不能为空',
    user_input_text_password: '请输入密码',
    user_input_text_email: '邮箱不能为空',
    user_input_text_phone: '手机号不能为空',
    user_input_text_company: '请输入公司名',
    user_form_phone_error: '请输入正确格式的手机号',
    user_form_password_format: '请输入6-16位数字和字母的组合',
    user_form_password_mach: '两次输入密码不一致',
    user_form_password_min: '请至少输入6个字符',
    user_form_password_max: '请至多输入16个字符',
    user_form_email_format: '邮箱格式不正确',
    user_form_file_format: '上传文件格式不正确',
    user_form_file_valid: '请上传文件格式为jpg、png、jpeg',
    user_form_revised: '修改成功',
    user_form_saved: '保存成功',
    user_form_save_fail: '保存失败',
    user_nickname_tip:'',
    user_status_tip:'',
    user_mobile_tip:'',
    user_role_tip:'',
    user_dele_tip:'',
    user_search_tip:'请输入用户名,id',

    user_input_text: '请输入查找内容',
    user_input_date: '选择日期',
    user_input_name: '请输入昵称',
    user_input_email: '请输入邮箱',
    user_input_phone: '请输入手机号',
    user_input_phone_number: '请输入电话',
    user_input_password: '请输入密码',
    user_input_usernameid: '请输入用户名/ID',
    user_input_oldpassword: '请输入原密码',
    user_input_newpassword: '请输入新密码',
    user_input_confirmpassword: '请重新确认密码',
    user_entered_passwords_differ: '两次输入密码不一致',
    user_combination_numbers_letters: '请输入6-16位数字和字母的组合',
    user_validate_name: '由字母、汉字、数字和点组成，2-16字符',
    // 邀请用户
    user_invate_link: '邀请链接',
    user_invate_link_audit_tip: '请发送链接给需要邀请的成员，成员激活账号后，需管理员手动审批',
    user_invate_link_valid_date: '邀请有效期：48小时',
    user_invate_link_create: '生成邀请链接',
    user_invate_link_copy: '复制链接',
    user_invate_link_copy_finished: '已复制',
    user_mobile_bind: '手机号绑定',
    user_mobile_bind_content: '产品升级，根据我司数据安全管理条例，需前往个人中心进行手机号绑定。验证通过方可进行正常操作，且可通过绑定手机进行登录。',
    user_mobile_bind_to_user_center: '前往个人中心',
    user_invitation_active: '激活',
    user_invitation_active_confirm: '确认激活吗？',
    user_invitation_registration_date: '注册日期',
    user_delete_confirm: '确认删除吗？',
    user_Invite_user_management: '邀请用户管理',

    user_authenticate: '验证身份',
    user_modify_email: '修改邮箱',
    user_modify_bind_phone: '修改绑定手机',
    user_entered_passwords_cannot_match: '新密码不能与旧密码一致',

    user_login_pwd_val: '登录密码验证',
    user_account: '账号',
    user_enter_login_pwd: '请输入登录密码',
    user_next: '下一步',
    user_email_val: '邮箱验证',
    user_verification_code: '验证码',
    user_enter_verification_code: '请输入验证码',
    user_phone_val: '手机号验证',
    user_new_email: '新邮箱',
    user_enter_new_email: '请输入新邮箱',
    user_6_num_code: '请输入6位数字验证码',
    user_get_verification_code: '获取验证码',
    user_sending: '发送中',
    user_again: '秒后重试',
    user_new_phone: '新手机号',
    user_enter_new_phone: '请输入新手机号',
    user_incorrect_phone_format: '手机号格式不正确',

    user_add: '创建用户',
    user_role: '角色',
    user_select_user_type: '请选择用户角色',
    user_less_one_role: '至少选择一个角色',
    user_select_account_status: '请选择状态',
    user_address: '地址',
    user_name: '用户名',
    user_name_tip: '用户名',
    user_user_name: '用户名',
    user_nickname :'昵称',
    user_realname :'真名',
    user_desc: '备注',
    user_desc_tip: '备注',
    user_create_time: '创建时间',
    user_update_time: '更新时间',
    user_edit: '编辑',
    user_operation_tip: '操作提示',
    user_new_member: '创建新成员',
    user_input_check_user: '输入查找用户',
    user_mass_transfer: '批量转移',
    user_create_new_account: '创建新账号',
    user_input_user_name: '请输入用户名',
    user_password: '密码',
    user_edit_account_info: '更改账号信息',
    user_remove_current_member: '确定要删除当前成员吗？',
    user_remove: '删除',
    user_please_select_team: '请选择团队',
    user_input_check_team: '输入查找团队',
    user_sure_transfer: '确定转移',
    user_join_date: '加入日期',
    user_join_time: '创建时间',
    user_create_by: '创建人',
    user_quick_login: '快速登录',
    user_select_other_team: '请选择其他团队',
    user_cannot_delete_current_account: '无法删除当前账号',
    user_successfully_added: '添加成功',

    user_not_opened: '暂未开通，',
    user_immediately_opened: '立即开通',
    user_new_account_information: '新建账号信息',
    user_edit_account_information: '编辑账号信息',
    user_upload_avatar: '上传头像',
    user_user_type: '用户类型',
    user_input_company: '请输入公司名称',
    user_belong_team: '所属团队',
    user_belong_crowdsourcing: '所属众包',
    user_upload_maximum_file: '最多只能上传{num}个文件',
    user_upload_refile: '该文件已上传，请重新选择',
    user_malformat: '  格式不正确',
    user_input_event_name: '请输入事件名称',
    user_operational_events: '操作事件',
    user_information: '信息',
    user_time: '时间',
    user_input_equipment: '请输入设备名称 / APP KEY',
    user_equipment_name: '设备名称',
    user_equipment_number: '设备编号',
    user_version: 'APP版本号',
    user_requests: '请求次数',
    user_batch_editor: '批量编辑',
    user_sure_import: '确认导入',
    user_batch_edit_account_information: '批量编辑账号信息',
    user_import_failed_reason: '导入失败原因',
    user_complete_each_user_information: '请完善每条用户信息',
    user_import_result: '导入结果',
    user_import_data_description: '您已导入成功{successCount}条数据，导入失败{errorCount}条数据',
    user_contact_person: '联系人',
    user_contact_phone: '联系电话',
    user_belong_group: '所属小组',
    user_user_tag: '用户标签',
    user_operation_records: '操作记录',
    user_user_equipment: '使用设备',
    user_ftp: 'FTP功能',
    user_detect_ftp: '检测FTP',
    user_create_detect_ftp: '创建/检测FTP',
    user_sure_delete_current_user: '真的要删除当前用户吗？',
    user_new_user: '添加用户',
    user_batch_import_user: '批量导入用户',
    user_import_user_id_nickname_email: '输入用户ID、昵称、邮箱',
    user_batch_delete_user: '批量删除用户',
    user_batch_transfer_team: '批量转移团队',
    user_delete_current_user: '确定要删除当前用户吗？',
    user_sure_delete_checked_user: '确定要删除勾选的用户吗？',
    user_upload_excel: '请上传Excel表格',
    user_click_select_file_drag_file: '点击选择文件或拖拽文件到此处',
    user_support_file_extensions: '支持扩展名：.csv .xls .xlsx',
    user_select_team: '选择团队',
    user_download_excel_template: '点击下载 Excel 模板',
    user_import_member_list: '导入成员列表',
    user_import: '导入',
    user_import_excel_file: '请上传Excel文件',
    user_cannot_transfer: '无法转移非团队类型用户或未激活用户',
    user_transferred: '已转移',
    user_input_user_id_nickname_email: '输入用户ID、昵称、邮箱',
    user_next_step: '下一步',
    user_add_email: '添加邮箱',
    user_send_email: '发送邀请邮件',
    user_enter_email_tip: '请每行输入一个邮箱',
    user_invitation_err_list: '邀请失败列表',
    user_invitation_err_email: '输入格式或邮箱格式不正确',
    user_email_duplicated: '邮箱已重复',
    user_invate_success: '邀请成功',
    user_select_role_tip: '请选择角色',
    user_name_begin_cannot_empty: '昵称不能以空格开头',
    user_nickname_valide: '昵称长度不超过20字符',
    user_name_cannot_special_characters: '不能输入特殊字符',

    user_mygroups_add_user:'添加学员',
    user_mygroups_create_group:'创建班级',
    user_mygroups_group_id:'班级ID',
    user_mygroups_group_name:'班级名称',
    user_mygroups_group_name_placeholder:'请输入班级名称',
    user_mygroups_cancel:'取消',
    user_mygroups_confirm:'确定',
    user_mygroups_user_name:'姓名',
    user_mygroups_user_id:'学员ID',
    user_mygroups_user_nickname:'学员昵称',
    user_mygroups_create_at:'加入班级时间',
    user_mygroups_user_created:'学员注册时间',
    user_mygroups_op:'操作',
    user_mygroups_change_group:'变更班级',
    user_mygroups_create_success:'创建成功',
    user_mygroups_select_data_message:'请选择数据',
    user_mygroups_add_success:'添加成功',
    user_mygroups_group_setting:'班级设置',
    user_mygroups_group_setting_one:'每位老师自动拥有一个班级，可以修改名称',
    user_mygroups_group_setting_two:'每位学生，只可加入一个班级',
    user_mygroups_group_setting_three:'当班级下还有学员信息时，无法删除，可以关闭',
    user_mygroups_group_setting_four:'刷题数、模考数、课后练习数都为累计数量',

    user_mygroups_detail_edit:'编辑班级',
    user_mygroups_detail_delete_confirm:'确认删除',
    user_mygroups_detail_id:'班级ID',
    user_mygroups_detail_name:'班级名称',
    user_mygroups_detail_edit_btn:'编辑',
    user_mygroups_detail_dele_btn:'删除',
    user_mygroups_detail_edit_success:'修改成功',
    user_mygroups_detail_dele_success:'删除成功',
    user_mygroups_detail_create_at:'创建时间',

};
