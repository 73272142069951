import { render, staticRenderFns } from "./certHome.vue?vue&type=template&id=64baaf0e&scoped=true&"
import script from "./certHome.vue?vue&type=script&lang=js&"
export * from "./certHome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64baaf0e",
  null
  
)

export default component.exports