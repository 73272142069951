const examineRoute = {
    path: '/examine-route',
    redirect: '/trainingcamp/myexam/exam',
    name: 'examine-route',
    meta:{
        title:'在线考试',
        module_route:'module_examine'
    },
    component: ()=>import('../views/layout/examineHome'),
    children:[
        {
            path: '/trainingcamp/myexam/:tab?',
            name: 'my-exam',
            route:'trainingcamp/myexam',
            meta:{
                title:'router_trainingcamp_myexam',
                module_route:'module_examine',
                img:require('@/assets/images/new_icon/exam.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/myexam.vue'),
        },
        {
            path: '/trainingcamp/myexamRecord',
            name: 'my-exam',
            route:'trainingcamp/myexam',
            meta:{
                title:'考试记录',
                module_route:'module_examine',
                img:require('@/assets/images/new_icon/exam.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/myExamRecord.vue'),
        },
        {
            path: '/examine/trainingcamp/exam',
            route:'trainingcamp/exam',
            name: 'trainingcamp/exam',
            meta:{
                title:'menu_trainingcamp_exam',
                module_route:'module_examine',
                img:require('@/assets/images/new_icon/exam.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/exam.vue'),
        },
        {
            path: '/examine/trainingcamp/exam-detail/:tab?/:id?',
            name: 'exam-detail',
            meta:{
                title:'menu_trainingcamp_exam_detail',
                module_route:'module_examine',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/examDetail.vue'),
        },
        {
            path: '/examine/trainingcamp/train-report',
            route:'trainingcamp/train-report',
            name: 'examine/trainingcamp/train-report',
            meta:{
                title:'menu_trainingcamp_train_report',
                module_route:'module_examine',
                img:require('@/assets/images/new_icon/train.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/trainReport.vue'),
        },
    ]
};

export default examineRoute;


