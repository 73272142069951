const infoTeacherRoute = {
    path: '/info-teacher-route',
    redirect: '/user/mygroups',
    name: 'info-teacher-route',
    meta:{
        title:'信息管理',
        module_route:'module_info_teacher'
    },
    component:() => import('@/views/layout/infoTeacherHome.vue'),
    children:[
        {
            path: '/home_manager',
            route:'home_manager',
            name: 'home_manager',
            meta:{
                title:'router_home',
                module_route:'module_info_teacher',
                img:require('@/assets/images/new_icon/data_stat.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/home/homeManager.vue'),
        },
        // {
        //     path: '/home_teacher',
        //     route:'home_teacher',
        //     name: 'home_teacher',
        //     meta:{
        //         title:'router_home',
        //         module_route:'module_info_teacher',
        //         img:require('@/assets/images/new_icon/data_stat.png'),
        //         requiresAuth:true,
        //     },
        //     component: ()=>import('../views/home/homeTeacher.vue'),
        // },
        {
            path: '/user/mygroups',
            route: 'user/mygroups',
            name: 'user/mygroups',
            meta:{
                title:'route_user_mygroups',
                module_route:'module_info_teacher',
                img:require('@/assets/images/new_icon/my_group.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/user/mygroups.vue'),
        },
        {
            path: '/lecturer',
            route:'lecturer',
            name: 'lecturer',
            meta:{
                title:'menu_lecturer',
                module_route:'module_info_teacher',
                img:require('@/assets/images/new_icon/exam.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/user/lecturer.vue'),
        },
        {
            path: '/trainingcamp/mycerts',
            route: 'trainingcamp/mycerts',
            name: 'trainingcamp/mycerts',
            meta:{
                title:'route_trainingcamp_mycerts',
                module_route:'module_info_teacher',
                img:require('@/assets/images/new_icon/cert_user.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/certUser/mycerts.vue'),
        },
        // {
        //     path: '/myinfo/:tab?',
        //     name: 'myinfo',
        //     meta:{
        //         title:'module_info_teacher',
        //         requiresAuth:true,
        //     },
        //     component: ()=>import('../views/user/myinfo.vue'),
        // },


    ]
};
export default infoTeacherRoute
