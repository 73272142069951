export default {
    video_s_create: '新建', 
    video_s_set_start_time:'设为开始时间',
    video_s_set_end_time:'设为结束时间',
    video_s_save_modifications:'保存修改',
    video_s_current_progress:'当前进度',
    video_s_total_video_time:'视频总时长',
    video_s_forward:'向前',
    video_s_backward:'向后',
    video_s_totals :'共',
    video_s_items :'条',
    video_s_time_slot:'时间段',
    video_s_clear_annotated_list:'清空标注列表',
    video_s_tip_del:'提示：确定删除此时间段吗？',
    video_s_tip_emptying_all :'提示：确定清空标注结果吗？',
    video_s_process_name:'工序名',
    video_s_result_list: '结果列表',
    video_s_end_time_not_be_empty:'结束时间不能为空'
};