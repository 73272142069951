import {request} from '@/utils/request.js'

//激活码表单
const cdkeyForm = (data) => {
    return request({
        url: '/dataset-cdkey/form',
        method: 'post',
        data
    })
}
//创建激活码
const cdkeyCreate = (data) => {
    return request({
        url: '/dataset-cdkey/create',
        method: 'post',
        data
    })
}
//激活码列表
const cdkeyList = (data) => {
    return request({
        url: '/dataset-cdkey/list',
        method: 'post',
        data
    })
}
//激活码详情
const cdkeyDetail = (data) => {
    return request({
        url: '/dataset-cdkey/detail',
        method: 'post',
        data
    })
}
//更新激活码
const cdkeyUpdate = (data) => {
    return request({
        url: '/dataset-cdkey/update',
        method: 'post',
        data
    })
}
//删除激活码
const cdkeyDelete = (data) => {
    return request({
        url: '/dataset-cdkey/delete',
        method: 'post',
        data
    })
}

//激活码的用户激活列表
const cdkeyCodeList = (data) => {
    return request({
        url: '/dataset-cdkey/code-list',
        method: 'post',
        data
    })
}
//添加码
const cdkeyCodeCreate = (data) => {
    return request({
        url: '/dataset-cdkey/code-create',
        method: 'post',
        data
    })
}

//操作记录列表
const cdkeyRecordList = (data) => {
    return request({
        url: '/dataset-cdkey/record-list',
        method: 'post',
        data
    })
}

//禁用码
const cdkeyCodeDisable = (data) => {
    return request({
        url: '/dataset-cdkey/code-disable',
        method: 'post',
        data
    })
}

//码更新
const cdkeyCodeUpdate = (data) => {
    return request({
        url: '/dataset-cdkey/code-update',
        method: 'post',
        data
    })
}

//用户激活表单
const cdkeyActiveForm = (data) => {
    return request({
        url: '/dataset-cdkey/active-form',
        method: 'post',
        data
    })
}

//用户激活
const cdkeyActive = (data) => {
    return request({
        url: '/dataset-cdkey/active',
        method: 'post',
        data
    })
}

//变更激活码分类
const cdkeyCodeChange = (data) => {
    return request({
        url: '/dataset-cdkey/code-change',
        method: 'post',
        data
    })
}
//激活码列表导出
const cdkeyCodeExport = (data) => {
    return request({
        url: '/dataset-cdkey/code-export',
        method: 'post',
        data
    })
}

const uploadfileDownload = (data) => {
    return request({
        url: '/uploadfile/download-base64',
        method: 'post',
        data
    })
}

export default {
    cdkeyForm,
    cdkeyCreate,
    cdkeyList,
    cdkeyDetail,
    cdkeyUpdate,
    cdkeyDelete,
    cdkeyCodeList,
    cdkeyCodeCreate,
    cdkeyRecordList,
    cdkeyCodeDisable,
    cdkeyCodeUpdate,
    cdkeyActiveForm,
    cdkeyActive,
    cdkeyCodeChange,
    cdkeyCodeExport,
    uploadfileDownload
}
