export default {
    //左侧菜单
    dataset_category_add:'添加分类',
    dataset_category_check:'查看分类',
    dataset_category_edit:'编辑分类',
    dataset_category_dele_tip:'确认删除分类吗？',
    dataset_category_table_index:'序号',
    dataset_category_table_data_count:'数据条数',
    dataset_category_table_name:'名称',
    dataset_category_table_desc:'备注',
    dataset_category_table_op:'操作',
    dataset_category_add_name_tip:'请输入名称',
    dataset_category_add_data_count_tip:'请输入数据条数',
    dataset_category_add_desc_tip:'请输入描述',

    dataset_map_add:'添加知识图谱',
    dataset_map_check:'查看知识图谱',
    dataset_map_edit:'编辑知识图谱',
    dataset_map_dele_tip:'确认删除吗？',
    dataset_map_table_index:'序号',
    dataset_map_table_data_count:'数据条数',
    dataset_map_table_name:'名称',
    dataset_map_table_desc:'备注',
    dataset_map_table_op:'操作',
    dataset_map_add_name_tip:'请输入名称',
    dataset_map_add_data_count_tip:'请输入数据条数',
    dataset_map_add_desc_tip:'请输入描述',
    dataset_map_toNodeList:'考点列表',

    dataset_node_add:'添加技能点',
    dataset_node_check:'查看技能点',
    dataset_node_edit:'编辑技能点',
    dataset_node_dele_tip:'确认删除吗？',
    dataset_node_table_index:'序号',
    dataset_node_table_data_count:'数据条数',
    dataset_node_table_name:'名称',
    dataset_node_table_desc:'备注',
    dataset_node_table_op:'操作',
    dataset_node_add_name_tip:'请输入名称',
    dataset_node_add_data_count_tip:'请输入数据条数',
    dataset_node_add_desc_tip:'请输入描述',

    dataset_label_add:'添加考点',
    dataset_label_check:'查看考点',
    dataset_label_edit:'编辑考点',
    dataset_label_dele_tip:'确认删除吗？',
    dataset_label_table_index:'序号',
    dataset_label_table_data_count:'数据条数',
    dataset_label_table_name:'名称',
    dataset_label_table_desc:'备注',
    dataset_label_table_op:'操作',
    dataset_label_add_name_tip:'请输入名称',
    dataset_label_add_data_count_tip:'请输入数据条数',
    dataset_label_add_desc_tip:'请输入描述',

    dataset_data_add:'添加题',
    dataset_data_check:'查看题',
    dataset_data_edit:'编辑题',
    dataset_data_dele_tip:'确认删除这道题吗？',
    dataset_data_table_index:'序号',
    dataset_data_table_title:'标题',
    dataset_data_table_level:'难度等级',
    dataset_data_table_display:'审核状态',
    dataset_data_table_options_number:'选项个数',
    dataset_data_table_questions_setter:'出题人',
    dataset_data_table_analysis_people:'解析人',
    dataset_data_table_source:'来源',
    dataset_data_table_anum:'答题数',
    dataset_data_table_rnum:'答对数',
    dataset_data_table_created_at:'创建时间',
    dataset_data_table_updated_at:'更新时间',
    dataset_data_table_type:'类型',
    dataset_data_table_name:'名称',
    dataset_data_table_desc:'备注',
    dataset_data_table_op:'操作',
    dataset_data_add_name_tip:'请输入名称',
    dataset_data_add_type_tip:'请输入数据条数',
    dataset_data_add_desc_tip:'请输入描述',
    dataset_data_feedback:'反馈的异常题',
    dataset_data_correction:'纠错列表',
    dataset_data_collect:'收藏',

    dataset_group_add:'添加试卷',
    dataset_group_check:'查看试卷',
    dataset_group_edit:'编辑试卷',
    dataset_group_dele_tip:'确认删除吗？',
    dataset_group_table_index:'序号',
    dataset_group_table_title:'标题',
    dataset_group_table_type:'类型',
    dataset_group_table_total_franction:'试卷总分值',
    dataset_group_table_dnum:'做题人数',
    dataset_group_table_starttime:'开始时间',
    dataset_group_table_endtime:'结束时间',
    dataset_group_table_sort:'显示顺序',
    dataset_group_table_price:'试卷价格',
    dataset_group_table_times:'试卷时间',
    dataset_group_table_is_repeat:'重复答题',
    dataset_group_table_pass_score:'及格分数',
    dataset_group_table_category_id:'分类',
    dataset_group_table_status:'状态',
    dataset_group_table_created_at:'创建时间',
    dataset_group_table_updated_at:'更新时间',
    dataset_group_table_op:'操作',
    dataset_group_add_name_tip:'请输入名称',
    dataset_group_add_data_count_tip:'请输入数据条数',
    dataset_group_add_desc_tip:'请输入描述',

    dataset_product_add:'添加产品',
    dataset_product_check:'查看产品',
    dataset_product_edit:'编辑产品',
    dataset_product_dele_tip:'确认删除吗？',
    dataset_product_table_index:'序号',
    dataset_product_table_data_count:'数据条数',
    dataset_product_table_name:'名称',
    dataset_product_table_desc:'备注',
    dataset_product_table_op:'操作',
    dataset_product_add_name_tip:'请输入名称',
    dataset_product_add_data_count_tip:'请输入数据条数',
    dataset_product_add_desc_tip:'请输入描述',
    dataset_product_cancel:'取消',
    dataset_product_confirm:'确认',

    dataset_node_cancel:'取消',
    dataset_node_confirm:'确认',

    dataset_cancel:'取消',
    dataset_confirm:'确认',

    dataset_data_classify:'分类',


    dataset_train_add:'添加考试/练习',
    dataset_train_check:'查看试卷',
    dataset_train_edit:'编辑试卷',
    dataset_train_dele_tip:'确认删除吗？',
    dataset_train_table_index:'序号',
    dataset_train_table_title:'标题',
    dataset_train_table_type:'类型',
    dataset_train_table_total_franction:'试卷总分值',
    dataset_train_table_dnum:'做题人数',
    dataset_train_table_starttime:'开始时间',
    dataset_train_table_endtime:'结束时间',
    dataset_train_table_sort:'显示顺序',
    dataset_train_table_price:'试卷价格',
    dataset_train_table_times:'试卷时间',
    dataset_train_table_is_repeat:'重复答题',
    dataset_train_table_pass_score:'及格分数',
    dataset_train_table_category_id:'分类',
    dataset_train_table_status:'状态',
    dataset_train_table_created_at:'创建时间',
    dataset_train_table_updated_at:'更新时间',
    dataset_train_table_op:'操作',
    dataset_train_add_name_tip:'请输入名称',
    dataset_train_add_data_count_tip:'请输入数据条数',
    dataset_train_add_desc_tip:'请输入描述',

    dataset_exam_add:'创建考试',
    dataset_exam_check:'查看试卷',
    dataset_exam_edit:'编辑试卷',
    dataset_exam_dele_tip:'确认删除吗？',
    dataset_exam_table_index:'序号',
    dataset_exam_table_title:'标题',
    dataset_exam_table_type:'类型',
    dataset_exam_table_total_franction:'试卷总分',
    dataset_exam_table_dnum:'做题人数',
    dataset_exam_table_starttime:'开始时间',
    dataset_exam_table_endtime:'结束时间',
    dataset_exam_table_sort:'显示顺序',
    dataset_exam_table_price:'试卷价格',
    dataset_exam_table_times:'试卷时间',
    dataset_exam_table_is_repeat:'重复答题',
    dataset_exam_table_pass_score:'及格分数',
    dataset_exam_table_category_id:'分类',
    dataset_exam_table_status:'状态',
    dataset_exam_table_created_at:'创建时间',
    dataset_exam_table_updated_at:'更新时间',
    dataset_exam_table_op:'操作',
    dataset_exam_add_name_tip:'请输入名称',
    dataset_exam_add_data_count_tip:'请输入数据条数',
    dataset_exam_add_desc_tip:'请输入描述',

    dataset_homework_add:'创建课后作业',
    dataset_homework_check:'查看课后作业',
    dataset_homework_edit:'编辑课后作业',
    dataset_homework_dele_tip:'确认删除吗？',
    dataset_homework_table_index:'序号',
    dataset_homework_table_title:'标题',
    dataset_homework_table_type:'类型',
    dataset_homework_table_total_franction:'课后作业总分',
    dataset_homework_table_dnum:'做题人数',
    dataset_homework_table_starttime:'开始时间',
    dataset_homework_table_endtime:'结束时间',
    dataset_homework_table_sort:'显示顺序',
    dataset_homework_table_price:'课后作业价格',
    dataset_homework_table_times:'课后作业时间',
    dataset_homework_table_is_repeat:'重复答题',
    dataset_homework_table_pass_score:'及格分数',
    dataset_homework_table_category_id:'分类',
    dataset_homework_table_status:'状态',
    dataset_homework_table_created_at:'创建时间',
    dataset_homework_table_updated_at:'更新时间',
    dataset_homework_table_op:'操作',
    dataset_homework_add_name_tip:'请输入名称',
    dataset_homework_add_data_count_tip:'请输入数据条数',
    dataset_homework_add_desc_tip:'请输入描述',

    dataset_trainuser_add:'添加试卷',
    dataset_trainuser_check:'查看试卷',
    dataset_trainuser_edit:'编辑试卷',
    dataset_trainuser_dele_tip:'确认删除吗？',
    dataset_trainuser_table_index:'序号',
    dataset_trainuser_table_title:'标题',
    dataset_trainuser_table_type:'类型',
    dataset_trainuser_table_total_franction:'试卷总分值',
    dataset_trainuser_table_dnum:'做题人数',
    dataset_trainuser_table_starttime:'开始时间',
    dataset_trainuser_table_endtime:'结束时间',
    dataset_trainuser_table_sort:'显示顺序',
    dataset_trainuser_table_price:'试卷价格',
    dataset_trainuser_table_times:'试卷时间',
    dataset_trainuser_table_is_repeat:'重复答题',
    dataset_trainuser_table_pass_score:'及格分数',
    dataset_trainuser_table_category_id:'分类',
    dataset_trainuser_table_status:'状态',
    dataset_trainuser_table_created_at:'创建时间',
    dataset_trainuser_table_updated_at:'更新时间',
    dataset_trainuser_table_op:'操作',
    dataset_trainuser_add_name_tip:'请输入名称',
    dataset_trainuser_add_data_count_tip:'请输入数据条数',
    dataset_trainuser_add_desc_tip:'请输入描述',
    dataset_trainuser_table_have_finished: '练习数',
    dataset_trainuser_table_use_time:'用时',
    dataset_trainuser_table_right_count:'答对数',
    dataset_trainuser_table_category:'分类',

    dataset_map_create_date:'创建时间',
    dataset_map_update_date:'更新时间',

    dataset_map_create_name:'名称',
    dataset_map_create_name_placeholder:'请输入名称',
    dataset_map_create_add_node:'添加根节点',
    dataset_map_create_confirm:'确定',
    dataset_map_create_check:'考核范围',
    dataset_map_create_project:'项目',
    dataset_map_create_code:'代码',
    dataset_map_create_node_name:'名称',
    dataset_map_create_skills:'职业技能',
    dataset_map_create_content:'内容',
    dataset_map_create_knowledge:'考核知识点',
    dataset_map_create_importance:'重要程度',
    dataset_map_create_add:'新增',
    dataset_map_create_edit:'编辑',
    dataset_map_create_label:'标签',
    dataset_map_create_cancel:'取消',
    dataset_map_create_dele_confirm:'确认删除节点吗?',
    dataset_map_create_warn_no_node:'请输入节点名称',
    dataset_map_create_warn_no_label:'请选择标签',

    dataset_map_detail_create_date:'创建时间',
    dataset_map_detail_update_date:'更新时间',
    dataset_map_detail_remark:'备注',
    dataset_map_detail_chart:'知识图谱',
    dataset_map_detail_node:'节点列表',

    dataset_category_num:'数目',
    dataset_category_create_date:'创建时间',
    dataset_category_update_date:'更新时间',
    dataset_category_remark:'备注',
    dataset_category_questions:'试题列表',
    dataset_category_users:'人员列表',
    dataset_category_edit:'编辑',
    dataset_category_exam_name:'试题名称',
    dataset_category_analysis:'解析',
    dataset_category_correct:'正确选项',

    dataset_category_order:'顺序练习',
    dataset_category_random:'随机练习',
    dataset_category_every:'每日一练',


    dataset_user_name:'姓名',
    dataset_user_nick_name:'昵称',
    dataset_user_phone:'手机号码',
    dataset_user_create_date:'注册时间',
    dataset_user_exam_time:'考试用时',
    dataset_user_score:'分数',

    dataset_data_right_key:'正确答案',

    dataset_usercollect_add:'添加试卷',
    dataset_usercollect_check:'查看试卷',
    dataset_usercollect_edit:'编辑试卷',
    dataset_usercollect_dele_tip:'确认删除吗？',
    dataset_usercollect_table_index:'序号',
    dataset_usercollect_table_title:'标题',
    dataset_usercollect_table_type:'类型',
    dataset_usercollect_table_total_franction:'试卷总分值',
    dataset_usercollect_table_dnum:'做题人数',
    dataset_usercollect_table_starttime:'开始时间',
    dataset_usercollect_table_endtime:'结束时间',
    dataset_usercollect_table_sort:'显示顺序',
    dataset_usercollect_table_price:'试卷价格',
    dataset_usercollect_table_times:'试卷时间',
    dataset_usercollect_table_is_repeat:'重复答题',
    dataset_usercollect_table_pass_score:'及格分数',
    dataset_usercollect_table_category_id:'分类',
    dataset_usercollect_table_status:'状态',
    dataset_usercollect_table_created_at:'创建时间',
    dataset_usercollect_table_updated_at:'更新时间',
    dataset_usercollect_table_op:'操作',
    dataset_usercollect_add_name_tip:'请输入名称',
    dataset_usercollect_add_data_count_tip:'请输入数据条数',
    dataset_usercollect_add_desc_tip:'请输入描述',
    dataset_usercollect_table_have_finished: '练习数',
    dataset_usercollect_table_use_time:'用时',

    dataset_mygroup_add:'添加试卷',
    dataset_mygroup_check:'查看试卷',
    dataset_mygroup_edit:'编辑试卷',
    dataset_mygroup_dele_tip:'确认删除吗？',
    dataset_mygroup_table_index:'序号',
    dataset_mygroup_table_title:'标题',
    dataset_mygroup_table_type:'类型',
    dataset_mygroup_table_total_franction:'试卷总分值',
    dataset_mygroup_table_dnum:'做题人数',
    dataset_mygroup_table_starttime:'开始时间',
    dataset_mygroup_table_endtime:'结束时间',
    dataset_mygroup_table_sort:'显示顺序',
    dataset_mygroup_table_price:'试卷价格',
    dataset_mygroup_table_times:'试卷时间',
    dataset_mygroup_table_is_repeat:'重复答题',
    dataset_mygroup_table_pass_score:'及格分数',
    dataset_mygroup_table_category_id:'分类',
    dataset_mygroup_table_status:'状态',
    dataset_mygroup_table_created_at:'创建时间',
    dataset_mygroup_table_updated_at:'更新时间',
    dataset_mygroup_table_op:'操作',
    dataset_mygroup_add_name_tip:'请输入名称',
    dataset_mygroup_add_data_count_tip:'请输入数据条数',
    dataset_mygroup_add_desc_tip:'请输入描述',
    dataset_mygroup_table_have_finished: '练习数',
    dataset_mygroup_table_use_time:'用时',
    dataset_mygroup_add: '上传题库',
    dataset_mygroup_table_data_count: '数据量',
    dataset_mygroup_table_succ_data_count:'有效题目数',
    dataset_mygroup_table_fail_data_count:'无效题目数',

    dataset_cdkey_add:'添加',
    dataset_cdkey_check:'查看试卷',
    dataset_cdkey_edit:'编辑试卷',
    dataset_cdkey_dele_tip:'确认删除吗？',
    dataset_cdkey_table_index:'序号',
    dataset_cdkey_table_title:'名称',
    dataset_cdkey_table_type:'类型',
    dataset_cdkey_table_timelong:'时长',
    dataset_cdkey_table_amount:'总数量',
    dataset_cdkey_table_used_count:'已使用',
    dataset_cdkey_table_sort:'显示顺序',
    dataset_cdkey_table_category_id:'分类',
    dataset_cdkey_table_second_category_id:'二级分类',
    dataset_cdkey_table_is_repeat:'重复答题',
    dataset_cdkey_table_pass_score:'及格分数',
    dataset_cdkey_table_category_id:'分类',
    dataset_cdkey_table_status:'状态',
    dataset_cdkey_table_created_at:'创建时间',
    dataset_cdkey_table_updated_at:'更新时间',
    dataset_cdkey_table_op:'操作',
    dataset_cdkey_add_name_tip:'请输入名称',
    dataset_cdkey_add_data_count_tip:'请输入数据条数',
    dataset_cdkey_add_desc_tip:'请输入描述',
    dataset_cdkey_table_have_finished: '练习数',
    dataset_cdkey_table_use_time:'用时',
    dataset_cdkey_table_data_count: '数据量',
    dataset_cdkey_table_succ_data_count:'有效题目数',
    dataset_cdkey_table_fail_data_count:'无效题目数',

    dataset_userwrong_add:'添加试卷',
    dataset_userwrong_check:'查看试卷',
    dataset_userwrong_edit:'编辑试卷',
    dataset_userwrong_dele_tip:'确认删除吗？',
    dataset_userwrong_table_index:'序号',
    dataset_userwrong_table_title:'标题',
    dataset_userwrong_table_type:'类型',
    dataset_userwrong_table_total_franction:'试卷总分值',
    dataset_userwrong_table_dnum:'做题人数',
    dataset_userwrong_table_starttime:'开始时间',
    dataset_userwrong_table_endtime:'结束时间',
    dataset_userwrong_table_sort:'显示顺序',
    dataset_userwrong_table_price:'试卷价格',
    dataset_userwrong_table_times:'试卷时间',
    dataset_userwrong_table_is_repeat:'重复答题',
    dataset_userwrong_table_pass_score:'及格分数',
    dataset_userwrong_table_category_id:'分类',
    dataset_userwrong_table_status:'状态',
    dataset_userwrong_table_created_at:'创建时间',
    dataset_userwrong_table_updated_at:'更新时间',
    dataset_userwrong_table_op:'操作',
    dataset_userwrong_add_name_tip:'请输入名称',
    dataset_userwrong_add_data_count_tip:'请输入数据条数',
    dataset_userwrong_add_desc_tip:'请输入描述',
    dataset_userwrong_table_have_finished: '练习数',
    dataset_userwrong_table_use_time:'用时',

    dataset_feedback_add:'添加试卷',
    dataset_feedback_check:'查看试卷',
    dataset_feedback_edit:'编辑试卷',
    dataset_feedback_dele_tip:'确认删除吗？',
    dataset_feedback_table_index:'序号',
    dataset_feedback_table_title:'标题',
    dataset_feedback_table_type:'类型',
    dataset_feedback_table_total_franction:'试卷总分值',
    dataset_feedback_table_dnum:'做题人数',
    dataset_feedback_table_starttime:'开始时间',
    dataset_feedback_table_endtime:'结束时间',
    dataset_feedback_table_content:'内容',
    dataset_feedback_table_sort:'显示顺序',
    dataset_feedback_table_price:'试卷价格',
    dataset_feedback_table_times:'试卷时间',
    dataset_feedback_table_is_repeat:'重复答题',
    dataset_feedback_table_pass_score:'及格分数',
    dataset_feedback_table_category_id:'分类',
    dataset_feedback_table_status:'状态',
    dataset_feedback_table_created_at:'创建时间',
    dataset_feedback_table_updated_at:'更新时间',
    dataset_feedback_table_op:'操作',
    dataset_feedback_add_name_tip:'请输入名称',
    dataset_feedback_add_data_count_tip:'请输入数据条数',
    dataset_feedback_add_desc_tip:'请输入描述',
    dataset_feedback_table_have_finished: '练习数',
    dataset_feedback_table_use_time:'用时',
    dataset_feedback_table_abnormaltype:'异常类型',

    dataset_dataCorrection_add:'添加试卷',
    dataset_dataCorrection_check:'查看试卷',
    dataset_dataCorrection_edit:'编辑试卷',
    dataset_dataCorrection_dele_tip:'确认删除吗？',
    dataset_dataCorrection_table_index:'序号',
    dataset_dataCorrection_table_dataid:'题目ID',
    dataset_dataCorrection_table_title:'标题',
    dataset_dataCorrection_table_type:'类型',
    dataset_dataCorrection_table_total_franction:'试卷总分值',
    dataset_dataCorrection_table_dnum:'做题人数',
    dataset_dataCorrection_table_starttime:'开始时间',
    dataset_dataCorrection_table_endtime:'结束时间',
    dataset_dataCorrection_table_sort:'显示顺序',
    dataset_dataCorrection_table_price:'试卷价格',
    dataset_dataCorrection_table_times:'试卷时间',
    dataset_dataCorrection_table_is_repeat:'重复答题',
    dataset_dataCorrection_table_pass_score:'及格分数',
    dataset_dataCorrection_table_category_id:'分类',
    dataset_dataCorrection_table_status:'状态',
    dataset_dataCorrection_table_created_at:'创建时间',
    dataset_dataCorrection_table_updated_at:'更新时间',
    dataset_dataCorrection_table_op:'操作',
    dataset_dataCorrection_add_name_tip:'请输入名称',
    dataset_dataCorrection_add_data_count_tip:'请输入数据条数',
    dataset_dataCorrection_add_desc_tip:'请输入描述',
    dataset_dataCorrection_table_have_finished: '练习数',
    dataset_dataCorrection_table_use_time:'用时',

    dataset_collect_table_category:'分类',

    dataset_homework_add:'创建课后作业',

    dataset_match_add:'创建竞赛',
};
