import index from './index.js';
import system from './system.js';
import production from './production.js';
import site from './site.js';
import user from './user.js';
import task from './task.js';
import dataset from './dataset.js';
import codekey from './codekey.js';
import wxmini from './wxmini.js';
import evaluation from './evaluation.js';
import certcode from './certcode.js';
import home from './homedata.js';
import analysis from './analysis.js';
import certUser from './certUser.js';
import dict from './dict.js';
import customer from './customerService.js';
import activity from './activity.js';
import score from './score.js';
import course from './course.js';
import live from "./live.js";

export default {
    index,
    system,
    production,
    site,
    user,
    task,
    dataset,
    codekey,
    wxmini,
    evaluation,
    certcode,
    home,
    analysis,
    certUser,
    dict,
    customer,
    activity,
    score,
    course,
    live
}
