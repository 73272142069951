import {request} from '@/utils/request.js'

//创建语料库
const dictCreate = (data) => {
    return request({
        url: '/dict/create',
        method: 'post',
        data
    })
}
//新增语料
const dictItemCreate = (data) => {
    return request({
        url: '/dict/item-create',
        method: 'post',
        data
    })
}
//语料列表
const dictItemList = (data) => {
    return request({
        url: '/dict/item-list',
        method: 'post',
        data
    })
}
//语料详情
const dictItemDetail = (data) => {
    return request({
        url: '/dict/item-detail',
        method: 'post',
        data
    })
}
//语料更新
const dictItemUpdate = (data) => {
    return request({
        url: '/dict/item-update',
        method: 'post',
        data
    })
}
//删除语料
const dictItemDelete = (data) => {
    return request({
        url: '/dict/item-delete',
        method: 'post',
        data
    })
}
//批量导入语料
const dictItemImport = (data) => {
    return request({
        url: '/dict/item-import',
        method: 'post',
        data
    })
}

//语料库列表
const dictList = (data) => {
    return request({
        url: '/dict/list',
        method: 'post',
        data
    })
}

export default {
    dictCreate,
    dictItemCreate,
    dictItemList,
    dictItemDetail,
    dictItemUpdate,
    dictItemDelete,
    dictItemImport,
    dictList
}
