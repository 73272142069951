export default {
    system_upload_file: 'Upload file (.zip)',
    system_maintain_file: 'Maintain file',
    system_update: 'Update',
    system_download: 'Download',
    system_operation_tip: 'Warning',
    system_sure_update: 'Are you sure you want to update? Please makee your decision discreetly',
    system_backed_up: 'Backed up',
    system_back_up: 'Backup',
    system_upload_successful: 'Uploaded successfully',
    system_incorrent_format: 'Incorrect format',
    system_upload_error: 'Failed to upload',
    system_daily_activity_statistics: 'Daily statistics of user workload',
    system_daily_traffic_statistics: 'Daily statistics of visits on each platform',
    system_online_number: 'Online',
    system_people: 'people',
    system_no_data: 'No data',
    system_refresh_permissions: 'Refresh permission',
    system_configuration_information: 'Configuration information...',
    system_new_identify: 'Add identifier',
    system_input_find_indentify: 'Search identifier',
    system_input_input_indentify: 'Please enter identifier',
    system_search: 'Search',
    system_save: 'Save',
    system_edit: 'Edit',
    system_new: 'Add',
    system_cancel: 'cancel',
    system_refresh: 'Refresh',
    system_delete: 'Delete',
    system_new_info: 'Add information',
    system_name: 'Name',
    system_input_name: 'Enter name',
    system_indentify: 'ID',
    system_input_indentify: 'Enter identifier',
    system_value: 'value',
    system_input_value: 'Enter value',
    system_whether_delete: 'Deletable',
    system_is: 'Yes',
    system_no: 'No',
    system_description: 'Description',
    system_input_description: 'Enter description',
    system_add: 'Add',
    system_edit_info: 'Edit information',
    system_sure_delete: 'Are you sure you want to delete it? ',
    system_select_type: 'Select type',
    system_input_indentify_name: 'Enter identifier name',
    system_value_type: 'Value type',
    system_select_value_type: 'Select value type',
    system_handle: 'Operation',
    system_indentify_null: 'Identifier cannot be empty',
    system_name_not_empty: 'Name cannot be empty',
    system_value_not_empty: 'Value cannot be empty',
    system_select_necessity: 'Select necessity',
    system_description_not_empty: 'Description cannot be empty',
    system_value_not_null: 'Value cannot be empty',
    system_complete_newly_added_identity: 'Please complete adding new identifier',
    system_new_success: 'Added successfully',
    system_update_success: 'Updated successfully',

    system_func: 'System functions',
    system_auth_setting: 'Permission configuration',
    system_index_user: 'Online users',
    system_index_statis: 'Real-time statistics',
    system_index_deploy: 'Data deployment',
    system_index_detection: 'Server detection',
    system_index_log: 'System logs',
    system_index_settinng: 'System settings',
    system_index_announce: 'Platform announcement',
    system_index_notice: 'System notification',
    system_index_role_manage: 'Role management',
    system_index_rights_manage: 'Permission configuration',

    system_onlineuser_root: 'Root online：{number} ',
    system_onlineuser_admin: 'Admin online：{number} ',
    system_onlineuser_team: 'Team online：{number} ',
    system_onlineuser_rootplat: 'ROOT platform',

    system_home_sheet: 'Sheets',
    system_home_box: 'Boxes',
    system_home_point: 'Points',

    system_deployment_add: 'New data',
    system_deployment_refresh: 'Refresh',
    system_deployment_name: 'Packet name：',
    system_deployment_input: 'Enter packet name',
    system_deployment_search: 'Search',
    system_deployment_viewdate: 'View data',
    system_deployment_uploaded: 'Uploaded data：',
    system_deployment_close: 'Turn off',
    system_deployment_modaltitle: 'Warning',
    system_deployment_modaltips: 'Confirm to delete the data?',
    system_deployment_cancel: 'Cancel',
    system_deployment_sure: 'Confirm',
    system_deployment_dataname: 'Data name',
    system_deployment_deploypath: 'Deployment path',
    system_deployment_ftpadd: 'FTP address：',
    system_deployment_ftppath: 'FTP path：',
    system_deployment_ftpname: 'FTP username：',
    system_deployment_ftppwd: 'FTP password：',
    system_deployment_datasize: 'Packet size',
    system_deployment_datacount: 'Data volume',
    system_deployment_uploadtime: 'Upload time',
    system_deployment_opera: 'Operation',
    system_deployment_download: 'Download',
    system_deployment_view: 'View',
    system_deployment_delete: 'Delete',
    system_deployment_againupload: 'Upload again',
    system_deployment_deleted: 'Deleted',

    system_info_memory_refresh: 'Refresh',
    system_info_memory_net_detect: 'Network detection',
    system_info_server_args: 'Server parameters',
    system_info_server_domainIp: 'Server domain name and IP',
    system_info_server_tag: 'Server identifier',
    system_info_server_os: 'Server OS',
    system_info_server_engine: 'Server WEB service engine',
    system_info_server_lang: 'Server language',
    system_info_server_port: 'WEB service port',
    system_info_server_name: 'Server host name',
    system_info_web_path: 'WEB path',
    system_info_script_path: 'Script path',

    system_info_server_data: 'Server real-time data',
    system_info_server_curtime: 'Server current time',
    system_info_server_time: 'Server running time',
    system_info_cpu: 'CPU',
    system_info_cpu_status: 'CPU activity',
    system_info_disk_status: 'Disk activity',
    system_info_disk_size: 'Total space',
    system_info_disk_used: 'Occupied space',
    system_info_disk_available: 'Free space',
    system_info_usage: 'Usage rate',

    system_info_memory_status: 'Memory activity',
    system_info_memory_size: 'Physical memory: in total',
    system_info_cache: 'Cached memory',

    system_info_buffers: 'Buffers',
    system_info_memory_used: 'Actual memory activity',
    system_info_memory_available: 'Actual free memory space',
    system_info_swap_size: 'SWAP：in total',
    system_info_used: 'In use',
    system_info_system_load_average: 'Average system load',
    system_info_network_status: 'Network activity',
    system_info_net: 'Into network：',
    system_info_real_time: 'Real-time：',
    system_info_out_net: 'Out of network：',
    system_info_net_desc1: 'Pinging server with 32bytes of data',
    system_info_net_desc2: 'Server response: bytes=32 time',
    system_info_net_desc3: 'Ping statistics for server',
    system_info_net_desc4: 'Approximate round trip times (ms)',
    system_info_shortest: 'Mnimum',
    system_info_longest: 'Maximum',
    system_info_average: 'Average',
    system_info_closed: 'Close',
    system_info_test_again: 'Detect again',

    system_setting_form_name: 'Name:',
    system_setting_form_input_name: 'Enter name',
    system_setting_form_key: 'Identifier:',
    system_setting_form_input_key: 'Enter identifier',
    system_setting_form_value: 'Value:',
    system_setting_form_input_value: 'Enter value',
    system_setting_form_can_delete: 'Deletable:',
    system_setting_form_yes: 'Yes',
    system_setting_form_no: 'No',
    system_setting_form_desc: 'Description:',
    system_setting_form_input_desc: 'Enter description',
    system_setting_form_add: 'Add',
    system_setting_form_edit: 'Edit',
    system_setting_form_save: 'Save',
    system_input_dataname_limit: 'At most 30 characters',

    system_script_management: 'Script management',
    system_script_select: 'please select',
    system_script_cancel: 'cancel',
    system_script_confirm: 'confirm',
    system_script_hint: 'tip',
    system_script_querycondition: 'Query condition',
    system_script_id: 'script ID',
    system_script_inputid: 'Please enter the script ID',
    system_script_name: 'script Name',
    system_script_inputname: 'Please enter the script Name',
    system_script_tenement: 'Belongs to the tenant',
    system_script_permission: 'Use permission',
    system_script_public: 'Public script',
    system_script_time: 'creation Time',
    system_script_start_end: 'Start time - end time',
    system_script_query: 'Query',
    system_script_reset: 'Reset',
    system_script_datalist: 'Data list',
    system_script_addscript: 'Add script',
    system_script_createscript: 'Create script',
    system_script_editscript: 'Edit script',
    system_script_url: 'Script url',
    system_script_inputurl: 'Please enter the script Url',
    system_script_classify: 'Apply classification',
    system_script_utility: 'utility category',
    system_script_selecttemplate: 'optional module',
    system_script_confirmdel: 'Make sure to disable this data',
    system_script_confirmrecover: 'Make sure to recover this data',
    system_script_state: 'state',
    system_script_template: 'template',
    system_script_operation: 'operation',
    system_script_name_nonull: 'The script name cannot be empty',
    system_script_url_nonull: 'The script address cannot be empty',
    system_overlay_number: 'overlay number',
    system_script_permission_set: 'Please select permission to use',
};
