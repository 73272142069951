export default {
    // 团队任务
    admin_input_search: '搜索',
    admin_input_project_info: '请输入任务ID、名称',
    admin_input_text: '请输入搜索内容',
    admin_input_user_info: '请输入用户ID、昵称',
    admin_enter_group_info: '请输入小组ID、名称',
    admin_enter_user_info: '输入用户ID、名称',
    admin_input_user_info_email: '请输入用户ID、昵称、邮箱',

    admin_cancel: '取消',
    admin_confirm: '确定',
    admin_check: '查看',
    admin_delete: '删除',
    admin_save: '保存',
    admin_edit: '编辑',
    admin_manage: '管理',
    admin_remove: '移除',
    admin_add: '添加',
    admin_crew: '组员',

    admin_task: '任务',
    admin_item_id: '项目ID',
    admin_batch_id: '批次ID',
    admin_process_id: '工序ID',
    admin_task_id: '任务ID',
    admin_task_name: '任务名称',
    admin_task_type: '任务类型',
    admin_process: '工序',
    admin_qc: '质检',
    admin_audit: '审核',
    admin_execute: '执行',
    admin_working_out: '已提交/作业量/总量',
    admin_execute_description: '已提交张数 / 任务作业量 / 批次作业总量',
    admin_placeholder_description: '-- 表未初始化, 可点击执行',
    admin_fields_description: '字段说明',
    admin_schedule: '进度',
    admin_performance: '绩效',
    admin_performance_execute_description: '执行： 被审核通过数  /  执行张数',
    admin_performance_audit_description: '审核： 被质检通过数  /  审核张数',
    admin_performance_qc_description: '质检： 质检通过数  /  质检张数',
    admin_handle: '操作',
    admin_reset: '重置',
    admin_reject: '驳回',
    admin_setting_members: '设置人员（已分配作业人数n）：该任务有 n 位作业员，可点击设置',
    admin_operate_reject_description: '驳回作业（作业数n）：该任务有 n 张被驳回作业，可点击重置',
    admin_operate_difficult_description: '挂起作业（作业数n）：该任务有 n 张挂起作业，可点击重置',
    admin_operate_rework_description: '返工作业（作业数n）：该任务有 n 张被驳回修改后的作业，可点击重置',
    admin_start_time: '开始时间',
    admin_end_time: '截止时间',
    admin_schedule_execute_description: '执行： 已执行张数 / 可执行张数 / 批次作业总量',
    admin_schedule_audit_description: '审核： 已审核张数 / 可执行张数 / 批次作业总量',
    admin_schedule_qc_description: '质检： 已质检张数 / 可执行张数 / 批次作业总量',

    // 任務詳情
    admin_settings: '设置人员',
    admin_export_record: '导出作业记录',
    admin_export_performance: '导出绩效',
    admin_list_performance: '绩效列表',
    admin_chart_performance: '绩效图表',
    admin_list_job: '作业列表',
    admin_task_description: '任务描述',
    admin_no_description: '暂无描述',
    admin_total_time: '总工时: {num} 秒',
    admin_total_sheet: '作业总张数',
    admin_total_box: '作业总框数',
    admin_total_mark_number: '作业总标注数',
    admin_total_count: '作业总点数',
    admin_total_approved: '已通过总数',
    admin_total_rejected: '被驳回总数',
    admin_total_reset: '被重置总数',
    admin_total_time_vocie: '作业总时长(语音)',

    admin_user: '用户',
    admin_cumulative: '累计工时',
    admin_time_unit: '单位： 秒( s )',
    admin_create_time: '加入时间',
    admin_update_time: '更新时间',
    admin_amount: '张数',
    admin_box: '框数',
    admin_points: '点数',
    admin_mark_number: '标注数',
    admin_working_hours_voice: '作业时长(语音)',

    // 执行
    admin_assignments: '参与作业数',
    admin_execute_amount: '执行作业的张数',
    admin_picture_description: '图片标注 ： 指标注图形数, 如框数、点数、区域数',
    admin_text_description: '文本标注 ： 指关键词条数',
    admin_voice_description: '语音标注 ： 指语音有效片段数',
    admin_execute_points: '执行作业的点数',
    admin_operation_time: '作业时长',
    admin_speech: '只限于语音',

    admin_audited: '被审核',
    admin_daily_performance: '每日绩效',
    admin_date: '日期',
    admin_working_hours: '工时',
    admin_total_working_hours: '总工时',
    admin_jobs: '作业',

    // 审核
    admin_execute_audit_amount: '执行作业的审核张数',
    admin_mark_number_amount: '执行作业的审核标注数',
    admin_counting: '通过数',
    admin_execute_passed: '执行作业的已通过张数',
    admin_rejecting: '驳回数',
    admin_execute_rejected: '执行作业的被驳回张数',
    admin_reseting: '重置数',
    admin_execute_reseted: '执行作业的被重置张数',
    admin_qced: '被质检',
    admin_qc_passed_amount: '质检环节的已通过张数',
    adnib_qc_reject_amount: '质检环节的被驳回张数',
    admin_reseted: '被重置',
    admin_reseted_description: '质检环节的被重置张数',

    // 质检
    admin_qc_amount: '质检环节的质检张数',
    admin_qc_label: '质检环节的质检标注数',
    admin_qc_pass_frame: '质检环节的通过张数',
    admin_qc_reject_frame: '质检环节的驳回张数',
    admin_qc_reset_frame: '质检环节的重置张数',

    // 作业列表
    admin_all_job: '全部作业',
    admin_unclaimed: '待领取',
    admin_active: '作业中',
    admin_submitted: '已提交',
    admin_passed: '已通过',
    admin_rejected: '被驳回',
    admin_rejected_work: '驳回作业',
    admin_difficult_work: '挂起作业',
    admin_expired: '已失效',
    admin_rework_work: '返工作业',

    admin_job_id: '作业ID',
    admin_job_name: '作业名称',
    admin_status: '状态',
    admin_view_data: '查看数据',
    admin_operator: '操作员',
    admin_time_remaining: '作业剩余时间',
    admin_batch_reset: '批量重置',
    admin_batch_rejected: '批量驳回',
    admin_operating_points: '作业点数',
    admin_submission_time: '提交时间',
    admin_button_explain: '按钮说明',
    admin_explain_reset: '重置 ： 取消该作业所有结果，为待领取作业，所有操作员可执行',
    admin_explain_reject_submitted: '驳回 ： 取消已提交状态，该操作员可重新执行',
    admin_explain_reject_passed: '驳回 ： 取消已通过状态，该操作员可重新执行',
    admin_job_status: '作业状态',
    admin_passed_time: '通过时间',
    admin_audited_time: '审核时间',
    admin_failure_reason: '失效原因',
    admin_rejected_reason: '错误原因',
    admin_job_results: '作业结果',
    admin_record: '操作记录',
    admin_type: '类型',
    admin_operation_status: '操作状态',
    admin_operation_type: '操作类型',
    admin_receive_time: '领取时间',
    admin_creation_time: '创建时间',
    admin_operate_time: '操作时间',
    // 设置作业人员
    admin_nickname: '昵称',
    admin_type:'类型',
    admin_role: '角色',
    admin_site: '组织',
    admin_email: '邮箱',
    admin_mobile: '手机号',
    admin_group: '所属小组',
    admin_user_label: '用户标签',
    admin_allocated_number: '已分配人数',
    admin_recommend: '推荐',
    admin_updated: '已更新',
    admin_created_time: '加入日期',
    admin_add_tag: '新增标签',
    admin_tag: '标签',
    admin_enter_tag_name: '请输入标签名称',
    admin_custom_tag: '自定义标签请按回车',
    admin_no_data: '无',
    // 团队人员
    admin_new_user: '新建用户',
    admin_name: '昵称',
    admin_password: '密码',
    admin_phone: '绑定手机',
    admin_team_operator: 'Team Worker',
    admin_team_administrator: 'Team Manager',
    admin_enter_user_name: '请输入用户名',
    admin_enter_password: '请输入密码',
    admin_enter_email: '请输入邮箱',
    admin_enter_phone: '请输入联系电话',
    admin_unselected: '未选择',
    admin_non_panelist: '非小组成员',
    admin_mission: '参与任务',
    admin_contact_number: '联系电话',
    admin_edit_employee: '编辑用户',

    admin_batch_import_user: '批量导入用户',
    admin_upload_excel: '请上传Excel表格',
    admin_select_drag: '点击选择文件或拖拽文件到此处',
    admin_support_extension: '支持扩展名：.csv .xls .xlsx',
    admin_click_download: '点击下载 Excel 模板',
    admin_next_step: '下一步',
    admin_import_member: '导入成员列表',
    admin_select_excel: '重新选择Excel文件',
    admin_import: '导入',

    admin_setting_team: '团队设置',
    admin_team_name: '团队名称',
    admin_enter_team_name: '请输入团队名称',
    admin_setting_logo: '设置LOGO',
    admin_address: '地址',
    admin_domain: '域名',
    admin_payment: '支付',
    admin_open: '开启',
    admin_close: '关闭',

    admin_team_management: '小组管理',
    admin_add_group: '新增小组',
    admin_group_name: '小组名称',
    admin_number_people: '人数',
    admin_edit_group_info: '编辑小组信息',
    admin_mass_transfer: '批量转移',
    admin_input_find_user: '输入查找用户',
    admin_add_member: '添加成员',
    admin_add_email: '添加邮箱',
    admin_send_email: '发送邀请邮件',
    admin_enter_email_tip: '请每行输入一个邮箱',
    admin_invitation_err_list: '邀请失败列表',
    admin_invitation_err_email: '输入格式或邮箱格式不正确',
    admin_email_duplicated: '邮箱已重复',
    admin_invate_success: '邀请成功',
    admin_select_role_tip: '请选择角色',
    admin_operation_tips: '操作提示',
    admin_delete_current_member: '确定要删除当前成员吗？',
    admin_team_info: '新建小组信息',
    admin_sure_deleting_group: '确定删除该小组吗？',
    admin_think_again: '再想想',
    admin_remove_current_member: '真的要移除当前成员吗？',
    admin_user_label_management: '用户标签管理',
    admin_sure_delete_current_tab: '真的要删除当前用户标签',
    admin_added_successfully: '添加成功',
    admin_select_group: '选择小组',
    admin_deter_transfer: '确定转移',
    admin_sure_delete_checked_members: '确定要删除勾选的成员吗？',
    admin_valide_password: '请输入6-16位数字和字母的组合',
    admin_valide_phone: '请输入正确格式的联系电话',
    admin_valide_telephone: '请输入正确格式的电话',
    admin_valide_domain: '域名格式不正确',
    admin_valide_name: '名称不能为空',
    admin_valide_email: '邮箱不能为空',
    admin_valid_status: '请选择状态',
    admin_valid_type: '请选择用户类型',
    admin_email_format_error: '邮箱格式不正确',
    admin_password_empty: '密码不能为空',
    admin_phone_empty: '手机号不能为空',
    admin_choose_one_role: '至少选择一个角色',
    admin_import_failure_reason: '导入失败原因',
    admin_upload_excel_file: '请上传Excel文件',
    admin_import_result: '导入结果',
    admin_import_data_description: '您已导入成功{num1}条数据，导入失败{num2}条数据',
    admin_upload_file_restrictions: '最多只能上传1个文件',
    admin_incorrect_format: '格式不正确',
    admin_upload_file_format_incorrect: '上传文件格式不正确',
    admin_file_format: '请上传文件格式为jpg、png、jpeg',
    admin_new_tag_selected_user: '给选中的用户增加新标签',
    admin_rwtdtcm: '真的要删除当前成员吗？',
    admin_delete_current_members: '真的要删除当前成员吗？',
    admin_cannot_delete_current_account: '无法移除当前账号',
    admin_saved: '保存成功',
    admin_enter_group_name: '请输入小组名称',
    admin_group_name_format: '小组名称在0~25之间',
    admin_enter_label_name: '请输入标签名称...',
    admin_label_name: '标签名称',
    admin_label_name_format: '标签名称在0~255之间',
    admin_loading: '作业文本加载中...',
    admin_unchecked: '暂未勾选作业',
    admin_success: '操作成功',
    admin_resource_failed: '作业资源获取失败',
    admin_performance_ranking: '绩效排行',
    admin_batch_delete_member: '批量删除成员',
    admin_batch_transfer_member: '批量转移成员',
    admin_batch_set_member: '批量设置人员',
    admin_set_user_sel_all: '全选团队所有人员',
    admin_set_user_cancel_all: '全不选团队所有人员',
    admin_upload_filesize_limit: '图片上传文件大小限制',
    admin_upload_filesize_limit_con: '上传文件太大，不得超过2M'
};
