import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import iView from 'iview';
import 'iview/dist/styles/iview.css';    // 使用 CSS
import '@wangeditor/editor/dist/css/style.css';
import '@/assets/styles/template-common.scss';
// import WS from "@/utils/ws.js";
import constant from "@/utils/constant";


Vue.prototype.constant = constant;

// Vue.use(WS);



// import Swiper from "swiper"
import 'swiper/dist/css/swiper.css'

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;


import UUID from "vue-uuid";
Vue.use(UUID);

import mavonEditor from 'mavon-editor';  //富文本编辑器
import 'mavon-editor/dist/css/index.css';
Vue.use(mavonEditor);

import { i18n } from '@/locale';

import api from './api/api';
Vue.prototype.api = api;

import eventBus from './utils/eventBus';
Vue.prototype.$EventBus = eventBus;

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Icon,
  Select,
  Option,
  Table,
  TableColumn,
  Tree,
  Progress,
  Cascader,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  DatePicker,
  Popover,
  Form,
  FormItem,
  Upload,
  Button,
  Drawer,
  Dialog,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Alert,
  Pagination,
  Message,
  Loading,
  Row,
  Col,
  Tooltip,
  Tag,
  Switch,
  RadioButton,
  Tabs,
  TabPane,
  Timeline,
  TimelineItem,
  Statistic,
  Skeleton,
  SkeletonItem
} from 'element-ui';

Vue.config.productionTip = false
Vue.prototype.$message = Message;

// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

Vue.component(CollapseTransition.name, CollapseTransition)

// Vue.use(iView);
Vue.use(iView, {
  i18n: (key, value) => i18n.t(key, value)
});

// 引入
import myBreadcrumb from '@/components/breadcrumb.vue';
// 注册为全局组件
Vue.component('MyBreadcrumb', myBreadcrumb)

// 引入
import myPage from '@/components/myPage.vue';
import Template from "@/components/TemplateView.vue";
import myPagin from "@/components/myPagin.vue";
// 注册为全局组件
Vue.component('MyPage', myPage)
Vue.component("TemplateView",Template);
Vue.component("MyPagin",myPagin);


Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Icon);
Vue.use(Select);
Vue.use(Option);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tree);
Vue.use(Progress);
Vue.use(Cascader);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(DatePicker);
Vue.use(Popover);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Upload);
Vue.use(Button);
Vue.use(Drawer);
Vue.use(Dialog);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Alert);
Vue.use(Pagination);
Vue.use(Loading);
Vue.use(Row);
Vue.use(Col);
Vue.use(Tooltip);
Vue.use(Tag);
Vue.use(Switch);
Vue.use(RadioButton);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Statistic);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
// window._version = 20220703114654;

import "@/assets/styles/base.css";
import "@/assets/styles/dark.css";
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
