import {request} from '@/utils/request.js'

// 我的任务
const taskTasks = (data) => {
    return request({
        url: '/task/tasks',
        method: 'post',
        data
    })
}
// 我的列表
const taskList = (data) => {
    return request({
        url: '/task/list',
        method: 'post',
        data
    })
}

// 执行任务
const taskExecute = (data) => {
    return request({
        url: '/task/execute',
        method: 'post',
        data
    })
}

// 任务详情
const taskDetail = (data) => {
    return request({
        url: '/task/detail',
        method: 'post',
        data
    })
}

// 执行任务
const workList = (data) => {
    return request({
        url: '/work/list',
        method: 'post',
        data
    })
}

// 执行任务
const myTaskForm = (data) => {
    return request({
        url: '/task/my-task-form',
        method: 'post',
        data
    })
}

// 获取语料分词
const taskFetchDict = (data) => {
    return request({
        url: '/task/fetch-dict',
        method: 'post',
        data
    })
}

export default {
    taskTasks,
    taskList,
    taskExecute,
    taskDetail,
    workList,
    myTaskForm,
    taskFetchDict
}
