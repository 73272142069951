const experimentRoute = {
    path: '/experiment-route',
    redirect: '/trainingcamp/train',
    name: 'experiment-route',
    meta:{
        title:'在线实验',
        module_route:'module_experiment'
    },
    component:()=>import('../views/layout/experimentHome.vue'),
    children:[
        {
            path:"/trainingcamp/experiment-code/:tab?",
            name:"trainingcamp-experiment-code",
            route:'trainingcamp/experiment-code',
            meta:{
                title:'router_trainingcamp_online_programming',
                module_route:'module_experiment',
                img:require('@/assets/images/new_icon/exam.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/experimentCode.vue'),
        },
        {
            path:"https://developer.aliyun.com/adc/",
            name:"trainingcamp/experiment-yunqi",
            route:'trainingcamp/experiment-yunqi',
            meta:{
                title:'云起实验室',
                module_route:'module_experiment',
                img:require('@/assets/images/new_icon/link.png'),
                requiresAuth:true,
            },
            component: ()=>import('../views/trainingcamp/experimentCode.vue'),
        },
        {
            path:"",
            name:"labeltool",
            route:'labeltool_platform',
            meta:{
                title:'标注实验平台',
                module_route:'module_experiment',
                img:require('@/assets/images/new_icon/link.png'),
                requiresAuth:true,
            },
            // component: ()=>import('../views/trainingcamp/experimentCode.vue'),
        },
    ]
};

export default experimentRoute;
