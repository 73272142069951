export default {
    trainingcamp_train_second:'第',
    trainingcamp_train_topic:'题',
    trainingcamp_train_no_answer:'暂不支持作答',
    trainingcamp_train_pre:'上一题',
    trainingcamp_train_next:'下一题',
    trainingcamp_train_confirm:'提交答案',
    trainingcamp_train_collection:'收藏',
    trainingcamp_train_feedback:'反馈',
    trainingcamp_train_stu_answer:'您的答案',
    trainingcamp_train_correct_answer:'正确答案',
    trainingcamp_train_analysis:'解析',
    trainingcamp_train_duration:'练习时长',
    trainingcamp_train_hour:'小时',
    trainingcamp_train_minute:'分',
    trainingcamp_train_time_second:'秒',
    trainingcamp_train_accuracy:'正确率',
    trainingcamp_train_total:'共计',
    trainingcamp_train_right:'答对',
    trainingcamp_train_error:'答错',
    trainingcamp_train_unanswered:'未答',
    trainingcamp_train_answer_sheet:'答题卡',
    trainingcamp_train_white:'白色:未做',
    trainingcamp_train_blue:'蓝色:当前',
    trainingcamp_train_green:'绿色:回答正确',
    trainingcamp_train_red:'红色:回答错误',
    trainingcamp_train_only_error:'只看错题',
    trainingcamp_train_feedback_tit:'反馈标题',
    trainingcamp_train_feedback_tit_placeholder:'请输入反馈标题',
    trainingcamp_train_feedback_cont:'反馈内容',
    trainingcamp_train_feedback_cont_placeholder:'请输入反馈内容',
    trainingcamp_train_feedback_cancel:'取消',
    trainingcamp_train_feedback_confirm:'确定',
    trainingcamp_train_no_data:'暂无数据',
    trainingcamp_train_last_test:'已经是最后一题',
    trainingcamp_train_first_test:'已经是第一题',
    trainingcamp_train_feedback_success:'反馈成功',

    trainingcamp_train_set:'设置',
    trainingcamp_train_error_add:'答错自动加入错题本',
    trainingcamp_train_correct_next:'答对自动下一题',
    trainingcamp_train_auto_confirm:'自动提交答案（只限单选题）',
    trainingcamp_train_recitation:'背题模式',
    trainingcamp_train_set_random:'随机打乱选项次序',
    trainingcamp_train_set_protect_eye:'护眼模式',
    trainingcamp_train_set_font_size:'字体大小',
    trainingcamp_train_set_continue_right:'连续做对',
    trainingcamp_train_set_move:'次，自动移出错题本，0代表永不移出',
    trainingcamp_train_select_answer:'请选择答案',
    trainingcamp_train_set_success:'设置成功',
    trainingcamp_train_set_font_little:'A较小',
    trainingcamp_train_set_font_standard:'A标准',
    trainingcamp_train_set_font_big:'A较大',

    trainingcamp_train_list_first_category:'一级分类',
    trainingcamp_train_list_second_category:'二级分类',
    trainingcamp_train_table_name:'名称',
    trainingcamp_train_table_count:'题量',
    trainingcamp_train_table_placeholder:'练习',
    trainingcamp_train_table_order:'顺序练习',
    trainingcamp_train_table_random:'随机练习',
    trainingcamp_train_table_day:'每日一练',
    trainingcamp_train_category:'分类(系统题库)',
    trainingcamp_train_all:'全部',
    trainingcamp_train_opened:'已开通',
    trainingcamp_train_no_opened:'未开通',

    trainingcamp_train_seeting_tit:'刷题设置',
    trainingcamp_train_seeting_single:'单次出题数',
    trainingcamp_train_seeting_source:'出题来源',
    trainingcamp_train_seeting_new_error:'新题+错题',
    trainingcamp_train_seeting_new:'只出新题',
    trainingcamp_train_seeting_error:'只出错题',
    trainingcamp_train_seeting_unlimited:'不限制',
    trainingcamp_train_seeting_data_sort:'随机练习',

    trainingcamp_train_again:'重新开始',
    trainingcamp_train_again_tip:'重新开始后当前已存在的所有题目状态将重新刷新,请谨慎操作。',
    trainingcamp_train_again_check_result:'记录结果可在我的练习-查看结果中查看。',


    trainingcamp_train_correction:'纠错',
    trainingcamp_train_correction_surplus:'剩余',
    trainingcamp_train_correction_topic:'题',
    trainingcamp_train_correction_key:'请输入名称、ID',
    trainingcamp_train_correction_refresh:'刷新题卡',
    trainingcamp_train_correction_type:'题型',
    trainingcamp_train_correction_title:'试题标题',
    trainingcamp_train_correction_correct:'正确选项',
    trainingcamp_train_correction_set_correct:'设为正确选项',
    trainingcamp_train_correction_delete:'删除',
    trainingcamp_train_correction_add_option:'添加选项',
    trainingcamp_train_correction_analysis:'解析',
    trainingcamp_train_correction_option_a:'选项A',
    trainingcamp_train_correction_option_b:'选项B',
    trainingcamp_train_correction_option_c:'选项C',
    trainingcamp_train_correction_option_d:'选项D',
    trainingcamp_train_correction_option_e:'选项E',
    trainingcamp_train_correction_option_f:'选项F',
    trainingcamp_train_correction_option_g:'选项G',
    trainingcamp_train_correction_verify:'未审核',
    trainingcamp_train_correction_pass:'已通过',
    trainingcamp_train_correction_fail:'不通过',
    trainingcamp_train_correction_del:'删除题',

    trainingcamp_train_pass_success:'通过成功',
    trainingcamp_train_no_pass_success:'不通过成功',
    trainingcamp_train_option_max:'选项最多6项',
    trainingcamp_train_warning_tit:'请输入标题',
    trainingcamp_train_warning_add_option:'请添加选项',
    trainingcamp_train_warning_select:'请选择正确选项',
    trainingcamp_train_warning_input_cont:'请输入选项内容',
    trainingcamp_train_correction_success:'纠错成功',

    trainingcamp_train_btn_no_pass:'不通过',
    trainingcamp_train_btn_pass:'通过',


    trainingcamp_exam_submit:'交卷',
    trainingcamp_exam_remaining_time:'剩余时间',
    trainingcamp_exam_result:'模考结果',
    trainingcamp_exam_stu_name:'考生姓名',
    trainingcamp_exam_time:'模考时间',
    trainingcamp_exam_paper_name:'模考试卷',
    trainingcamp_exam_start_time:'开考时间',
    trainingcamp_exam_submit_time:'交卷时间',
    trainingcamp_exam_use_time:'模考用时',
    trainingcamp_exam_accuracy:'正确率',
    trainingcamp_exam_score:'得分',
    trainingcamp_exam_total_score:'总分',
    trainingcamp_exam_fail:'模考未通过',
    trainingcamp_exam_pass:'模考通过',
    trainingcamp_exam_verify:'模考批改中',
    trainingcamp_exam_notfinish:'未完成模考',
    trainingcamp_exam_notjoin:'未参加模考',

    trainingcamp_homework_time:'课后作业时间',
    trainingcamp_homework_paper_name:'课后作业试卷',
    trainingcamp_homework_fail:'课后作业未通过',
    trainingcamp_homework_pass:'课后作业通过',
    trainingcamp_homework_verify:'课后作业批改中',
    trainingcamp_homework_notfinish:'未完成课后作业',
    trainingcamp_homework_notjoin:'未参加课后作业',



    trainingcamp_exam_topic:'题',
    trainingcamp_exam_confirm_submit:'确认交卷',
    trainingcamp_exam_confirm_submit_txt:'确认交卷后，模考结束',
    trainingcamp_exam_instruction:'考前说明',
    trainingcamp_exam_instruction_name:'姓名',
    trainingcamp_exam_instruction_phone:'电话',
    trainingcamp_exam_instruction_site:'院校',
    trainingcamp_exam_instruction_attention:'模考注意事项',
    trainingcamp_exam_instruction_known:'已知悉',
    trainingcamp_exam_instruction_phone:'电话',

    trainingcamp_exam_create_step:'步骤',
    trainingcamp_exam_create_info:'基础信息',
    trainingcamp_exam_create_data:'选择数据',
    trainingcamp_exam_create_config:'配置',
    trainingcamp_exam_create_publish:'发布',

    trainingcamp_exam_detail_time:'模考时间',
    trainingcamp_exam_detail_total_score:'总分',
    trainingcamp_exam_detail_time_long:'模考时长',
    trainingcamp_exam_detail_to:'至',
    trainingcamp_exam_detail_data_count:'总题数',
    trainingcamp_exam_detail_address:'模考地点',
    trainingcamp_exam_detail_address_online:'线上',
    trainingcamp_exam_detail_stu_num:'考生人数',
    trainingcamp_exam_detail_explain:'考前说明',
    trainingcamp_exam_detail_list:'试题列表',
    trainingcamp_exam_detail_result:'成绩',
    trainingcamp_exam_detail_statistics:'统计分析',

    trainingcamp_exam_list_data_cont:'试题数量',
    trainingcamp_exam_list_use_time:'模考时长',
    trainingcamp_exam_list_create_user:'创建人',
    trainingcamp_exam_list_start_time:'开始时间',
    trainingcamp_exam_list_end_time:'结束时间',
    trainingcamp_exam_list_more:'更多',
    trainingcamp_exam_list_try_answer:'尝试作答',
    trainingcamp_exam_list_copy:'复制',
    trainingcamp_exam_list_delete:'删除',
    trainingcamp_exam_list_generate_type:'生成类型',
    trainingcamp_exam_list_generate_id:'生成对象',

    trainingcamp_myexam_room:'近期模考',
    trainingcamp_myexam_record:'我的模考记录',
    trainingcamp_myexam_timelong:'时长(分钟)',
    trainingcamp_myexam_use_time:'用时',
    trainingcamp_myexam_data_count:'题量',
    trainingcamp_myexam_user_count:'参与人数',
    trainingcamp_myexam_status:'状态',
    trainingcamp_myexam_start_time:'开始时间',
    trainingcamp_myexam_end_time:'结束时间',
    trainingcamp_myexam_start_exam:'开始考试',
    trainingcamp_myexam_joined:'您已交卷',
    trainingcamp_myexam_name:'名称',
    trainingcamp_myexam_total_count:'总题量',
    trainingcamp_myexam_finish_count:'答题数',
    trainingcamp_myexam_right_count:'答对',
    trainingcamp_myexam_score:'得分',
    trainingcamp_myexam_record_use_time:'用时',
    trainingcamp_myexam_record_status:'状态',
    trainingcamp_myexam_record_finish_time:'交卷时间',
    trainingcamp_myexam_record_check_result:'查看结果',
    trainingcamp_myexam_list_generate_user:'创建者',

    trainingcamp_myhomework_room:'课后练习',
    trainingcamp_myhomework_record:'我的练习记录',
    trainingcamp_myhomework_timelong:'时长(单位分钟)',
    trainingcamp_myhomework_use_time:'用时',
    trainingcamp_myhomework_data_count:'题量',
    trainingcamp_myhomework_user_count:'参与人数',
    trainingcamp_myhomework_status:'状态',
    trainingcamp_myhomework_start_time:'开始时间',
    trainingcamp_myhomework_end_time:'结束时间',
    trainingcamp_myhomework_start_homework:'开始模考',
    trainingcamp_myhomework_joined:'已经交卷',
    trainingcamp_myhomework_name:'名称',
    trainingcamp_myhomework_total_count:'总题量',
    trainingcamp_myhomework_finish_count:'做题数',
    trainingcamp_myhomework_right_count:'答对',
    trainingcamp_myhomework_score:'得分',
    trainingcamp_myhomework_record_use_time:'用时',
    trainingcamp_myhomework_record_status:'状态',
    trainingcamp_myhomework_record_finish_time:'交卷时间',
    trainingcamp_myhomework_record_check_result:'查看结果',

    trainingcamp_mytrain_continue:'继续刷题',

    trainingcamp_train_list_have_finished:'已完成',
    trainingcamp_train_list_wrong_count:'错题数',
    trainingcamp_train_list_all_train:'全部练习',
    trainingcamp_train_list_day:'每日一练',

    trainingcamp_exam_create_category_first:'一级分类',
    trainingcamp_exam_create_category_second:'二级分类',
    trainingcamp_exam_create_category_name:'分类名称',
    trainingcamp_exam_create_category_count:'数据量',

    trainingcamp_exam_create_rule_data_count:'设置总题数(道)',
    trainingcamp_exam_create_rule_level:'难度比例选择',
    trainingcamp_exam_create_rule_level_easy:'简单',
    trainingcamp_exam_create_rule_level_middle:'一般',
    trainingcamp_exam_create_rule_level_difficulty:'困难',
    trainingcamp_exam_create_rule_type:'题型比例选择',
    trainingcamp_exam_create_rule_type_radio:'单选',
    trainingcamp_exam_create_rule_type_checkout:'多选',
    trainingcamp_exam_create_rule_type_judge:'判断',
    trainingcamp_exam_create_rule_score:'题型分值',
    trainingcamp_exam_create_rule_total_score:'总分数',
    trainingcamp_exam_create_rule_pass_score:'及格分数',
    trainingcamp_exam_create_rule_unit:'分',
    trainingcamp_exam_create_warn_all_zero:'题型比例选择不能都为0',
    trainingcamp_exam_create_rule_warn_total:'请输入总题数',
    trainingcamp_exam_create_rule_warn_pass_score:'请输入及格分数',
    trainingcamp_exam_create_data_category:'分类',
    trainingcamp_exam_create_data_warn:'请选择数据～',
    trainingcamp_exam_create_data_warn_max:'只能选择一项数据～',


    trainingcamp_exam_create_list_type:'题型',
    trainingcamp_exam_create_list_level:'难度',
    trainingcamp_exam_create_list_index:'序号',
    trainingcamp_exam_create_list_data:'题目',

    trainingcamp_exam_create_result_name:'姓名',
    trainingcamp_exam_create_result_sex:'性别',
    trainingcamp_exam_create_result_status:'状态',
    trainingcamp_exam_create_result_total_score:'总分',
    trainingcamp_exam_create_result_use_time:'用时',

    trainingcamp_exam_create_info_name:'试卷名称:',
    trainingcamp_exam_create_info_name_placeholder:'请输入试卷名称',
    trainingcamp_exam_create_info_time:'开始时间-结束时间:',
    trainingcamp_exam_create_info_time_placeholder:'请选择时间:',
    trainingcamp_exam_create_info_long:'答题时长:',
    trainingcamp_exam_create_info_random_data:'题目顺序打乱:',
    trainingcamp_exam_create_info_open:'开启',
    trainingcamp_exam_create_info_close:'关闭',
    trainingcamp_exam_create_info_open_txt:'开启，每个考生将看到不同的试卷，尽可能防止考生作弊',
    trainingcamp_exam_create_info_close_txt:'关闭，使用统一试卷进行模考',
    trainingcamp_exam_create_info_random_option:'选项顺序打乱:',
    trainingcamp_exam_create_info_random_option_open_txt:'开启，每个考生将看到不同的选项顺序，尽可能防止考生作弊',
    trainingcamp_exam_create_info_random_option_close_txt:'关闭，使用统一选项顺序进行模考',
    trainingcamp_exam_create_info_level:'试卷难度:',
    trainingcamp_exam_create_info_attention:'注意事项:',
    trainingcamp_exam_create_info_remark_placeholder:'请输入备注:',
    trainingcamp_exam_create_info_remark:'备注:',
    trainingcamp_exam_create_info_time_warn:'请选择时间',
    trainingcamp_exam_create_info_level_no:'不限难度',
    trainingcamp_exam_create_info_level_easy:'简单',
    trainingcamp_exam_create_info_level_middle:'一般',
    trainingcamp_exam_create_info_level_difficulty:'困难',
    trainingcamp_exam_create_info_name_placeholder:'请输入试卷名称:',

    trainingcamp_exam_create_publish_select:'已选择',
    trainingcamp_exam_create_publish_term:'项',
    trainingcamp_exam_create_publish_batch_lock:'批量锁定',
    trainingcamp_exam_create_publish_batch_unlock:'批量解锁',
    trainingcamp_exam_create_publish_add_data:'增加试题',
    trainingcamp_exam_create_publish_rebuild:'重新生成',
    trainingcamp_exam_create_publish_cancel:'取消',
    trainingcamp_exam_create_publish_confirm:'确定',
    trainingcamp_exam_create_publish_index:'序号',
    trainingcamp_exam_create_publish_title:'题目',
    trainingcamp_exam_create_publish_type:'题型',
    trainingcamp_exam_create_publish_level:'难度',
    trainingcamp_exam_create_publish_status:'状态',
    trainingcamp_exam_create_publish_op:'操作',
    trainingcamp_exam_create_publish_scrap:'废弃',
    trainingcamp_exam_create_publish_lock:'锁定',
    trainingcamp_exam_create_publish_unlock:'解锁',
    trainingcamp_exam_create_publish_open:'启用',
    trainingcamp_exam_create_publish_op_sucess:'操作成功',
    trainingcamp_exam_create_publish_scrap_success:'废弃成功',
    trainingcamp_exam_create_publish_warn_select:'请选择数据',


    trainingcamp_exam_create_next:'下一步',
    trainingcamp_exam_create_pre:'上一步',
    trainingcamp_exam_create_save:'保存并退出',
    trainingcamp_exam_create_publish:'发布',

    trainingcamp_exam_feedback_save:'更新题',
    trainingcamp_exam_feedback_save_submit:'更新题并完结此反馈',
    trainingcamp_exam_feedback_ignore:'忽略此反馈',
    trainingcamp_exam_feedback_dele:'删除题并完结此反馈',
    trainingcamp_exam_feedback_no_data:'暂无数据',
    trainingcamp_exam_feedback_feedback:'反馈',
    trainingcamp_exam_feedback_feedback_tit:'反馈标题',
    trainingcamp_exam_feedback_feedback_cont:'反馈内容',
    trainingcamp_exam_feedback_surplus:'剩余',
    trainingcamp_exam_feedback_topic:'题',
    trainingcamp_exam_feedback_search:'请输入名称、ID',
    trainingcamp_exam_feedback_refresh:'刷新题卡',
    trainingcamp_exam_feedback_save_success:'保存成功',

    trainingcamp_train_cont_edit:'编辑',
    trainingcamp_train_cont_edit_success:'编辑成功',

    trainingcamp_train_avtive:'立即激活',
    trainingcamp_train_avtive_quick:'快捷激活',
    trainingcamp_train_avtive_btn:'激活',
    trainingcamp_train_avtive_manual:'手动激活',
    trainingcamp_train_code_placeholder:'请输入激活码',
    trainingcamp_train_avtive_confirm:'确认激活',
    trainingcamp_train_avtive_get:'获取激活码',
    trainingcamp_train_wx:'同微信',
    trainingcamp_train_wx_add:'微信扫码添加',
    trainingcamp_train_avtive_confirm_message:'请确认激活码',
    trainingcamp_train_avtive_success:'激活成功',


    trainingcamp_exam_blue:'蓝色：当前',
    trainingcamp_exam_white:'白色：未做',
    trainingcamp_exam_green:'绿色：已做',

    trainingcamp_exam_correction_ignore:'忽略此纠错',
    trainingcamp_exam_correction_save_submit:'更新题并完结此纠错',

    online_programming:"在线实验",
    corrective_feedback:"纠错反馈"

}
