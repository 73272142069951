export default {
    // menu bar
    common_operator: '作业员',
    common_administrater: '管理员',
    common_root: 'ROOT',

    // header
    common_full_screen: '全屏',
    common_exit_full_screen: '退出全屏',
    common_close_tags: '关闭标签',
    common_no_data: '暂无数据',
    // table
    common_cancel: '取消',
    common_confirm: '确定',
    common_save: '保存',
    common_delete: '删除',
    common_amount: '张数',
    common_box: '框数',
    common_points: '点数',
    common_set_shortcut: '设置快捷键',
    // form
    common_single_option: '单选',
    common_multiple_choices: '多选',

    common_use_chrome: '请使用chrome浏览器',
    common_copyright: '',

    common_user_ownspace: '个人信息',
    common_user_quit: '退出登录',
    common_search_keyword:'模糊搜索',

    // message
    message_inform: '通知',
    message_getNotifications: '通知内容',
    message_type: '类型',
    message_time: '时间',
    message_operate: '操作',
    message_notice: '公告',
    message_no_announcement: '暂无公告',
    message_content: '公告内容',
    message_sender: '发送人',
    message_ftgmd: '获取消息数据失败',
    message_marked: '标为已读',
    message_delete: '删除',
    message_no_message: '暂无消息',
    message_messages_content: '消息内容',
    message_unread_messages: '有{num}条未读消息',
    message_no_unreadMessage: '无未读消息',

    // home
    home_title:'首页',
    home_last_login_time: '上次登录时间',
    home_last_login_ip: '上次登录IP地址',
    home_notice: '最新公告',
    home_total_user: '用户总量',
    home_total_task: '任务总量',
    home_taowdt: '今日总作业量',
    home_workload_today: '今日我的作业量',
    home_amount_box: '今日我的标框量',
    home_amount_point: '今日我的标点量',
    home_working: '今日我的工作时长(秒)',
    home_team_progress: '团队任务进度',
    home_recent_performance: '近期团队作业量统计',
    home_recent_workload: '近期我的作业量统计',
    home_taskname: '任务名',
    home_percentage_progress: '进度百分比',
    home_start_time: '开始时间',
    home_end_time: '结束时间',
    home_city: '城市',
    home_flow: '流量(k)',
    home_in_flow: '入流量',
    home_current_inflow: '当前入流量',
    home_current_outflow: '当前出流量',
    home_type: '类目',
    home_pv: '访问量',
    home_announcement: '公司公告',
    home_active_user: '活跃用户',
    home_active_user_desc: '本周内活跃的用户，有过登录操作即视为活跃',
    home_all_user: '全部用户',
    home_all_user_desc: '本平台累计注册用户数 / 平台最多用户数限制',
    home_executable_task: '可执行任务',
    home_executable_task_desc: '整体可执行任务数，包括未分配作业人员的任务',
    home_executable_task_desc_person: '被分配作业员正在执行的任务数量',
    home_executable_work: '可执行作业数',
    home_executable_work_desc: '整体可执行作业数量，包括未分配作业人员的任务',
    home_executable_work_desc_person: '被分配到作业员的任务的可执行作业数',
    home_active_project: '运行项目',
    home_active_project_desc: '本周项目状态为作业中的项目',
    home_all_projects: '全部项目',
    home_all_projects_desc: '本平台累计创建项目数',
    home_today_work: '今日作业量',
    home_today_work_desc: '今日的作业量',
    home_data_handling_capacity: '数据处理能力',
    home_data_size: '数据量',
    home_desc_workDesc2: '被分配到作业员的任务的可执行作业数',
    home_desc_taskDesc2: '被分配到作业员的正在运行的任务数量',
    home_data_ratio: '数据占比率',
    home_data_volume: '数据量',
    home_space_occupation: '占用空间',
    home_project_overview: '项目总览',
    home_data_throughput: '数据吞吐量',
    home_data_published: '发布项目数量',
    home_data_execution: '执行数据量',
    home_totle_data: '数据总量',
    home_totle_storage: '总空间（G）',
    home_project_num: '项目数',
    home_pcs: '个', // 单位：个
    home_set: '笔', // 单位：笔
    home_person: '个人',
    home_all: '全部',
    home_create_project_count: '创建项目总数',
    home_work_count: '作业量统计',
    home_work_count_person: '作业数量(个人)',
    home_work_count_total: '作业数量(总计)',
    // login
    login_enter_name: '请输入用户名',
    login_enter_password: '请输入密码',
    login_fill_account: '请填写有效账户',
    login_validate_password: '密码区分大小写，不少于6位',
    login_login: '登录',
    login_register: '去注册',
    login_register_site:'注册运营商',
    login_enter_tips: '请输入用户名和密码',
    login_error_tips: '出现错误,请重试~',
    login_success_tips: '登录成功~',
    login_quick_successful: '快速登录成功~',
    login_user_not_exist: '用户不存在',
    login_failed_user_information: '获取用户信息失败',
    login_enterprise_portal: '企业入口',
    login_team_entry: '团队入口',
    login_forget_password: '忘记密码？',
    login_welcome: '欢迎登录',
    login_type_account:'账户密码登录',
    login_type_phone:'手机短信登录',
    login_type_wx:'微信扫码登录',
    login_type_ali:'阿里云题库登录',
    login_account_no:'没有账户？',
    login_get_code:'获取短信验证码',
    login_wx_scan:'重新扫码',
    login_wx_create:'创建新账号',
    login_wx_binding:'绑定已有账号',
    login_wxlogin:'登录并绑定',
    login_captcha:'请输入图形验证码',
    login_captcha_tip:'请输入正确验证码',
    login_mobild_code_tip:'请输入短信验证码',
    login_mobild_code_again:'重新获取',
    login_mobild_user:'请输入手机号',
    login_aliid:'请填写阿里ID',
    login_aliid_error:'请填写阿里ID',
    login_mobild_code_unit:'秒后',
    login_type_teacher_account:'老师认证扫码登录',


    register_welcome: '欢迎使用',
    register_has_account: '已有账号?',
    register_to_login: '去登录',
    // register_input_agreement: '我已阅读并同意用户协议',
    register_input_company:'请输入组织全称',
    register_input_company_empty:'请填写您的公司/单位/组织名称',
    register_input_company_limit_max:'组织全称长度不能超过32个字符',
    register_input_company_limit_min:'组织全称不能少于2个字符',
    register_input_email:'您的Email',
    register_input_email_empty: '请输入您的Email',
    register_input_email_long_error: 'Email长度不能超过32字符',
    register_input_email_format_error: '请输入正确的Email',
    register_input_password:'请输入密码',
    register_input_password_contain: '密码需要符合以下条件：',
    register_input_password_contain_long:'密码长度在6-32字符',
    register_input_password_contain_letter:'必须包含字母',
    register_input_password_contain_number:'必须包含数字',
    register_input_password_empty:'请输入密码',
    register_input_password_format_error:'必须包含字母,数字,6-32字符',
    register_input_submit:'注册',
    register_input_captcha:'请输入图形验证码',
    register_input_captcha_empty: '请填写验证码',
    register_input_captcha_format_error:'验证码不正确',
    register_input_captcha_error: '图片验证码不正确',
    register_input_agreement:'我已阅读并同意《用户协议》和《隐私协议》',
    register_finish_to_login: '注册成功! 请重新登录',
    register_input_mobile_empty: '手机号码不能为空',
    register_input_mobile_limit_max: '手机号码必须11位',
    register_input_mobile_limit_min: '手机号码必须11位',
    register_input_mobile: '请输入手机号',
    register_input_user:'请输入您的姓名',
    register_input_mobile_code:'请输入短信验证码',



    forgetPassword_forgot_password: '忘记密码',
    // forgetPassword_to_login:'去登录',
    forgetPassword_next_step: '下一步',
    forgetPassword_username:'请填写您的Email',
    forgetPassword_captcha: '图片验证码',
    forgetPassword_verification_code:'邮件验证码',
    forgetPassword_new_password: '请输入新密码',
    forgetPassword_to_register: '注册新账号',
    forgetPassword_to_login: '去登录',
    forgetPassword_submit: '确定提交',
    forgetPassword_verification_code_empty: '请填写邮件验证码',
    forgetPassword_verification_code_format_error:'邮件验证码错误',
    forgetPassword_newpassword_format_error: '必须包含字母,数字,6-32字符',
    forgetPassword_sending: '发送中',
    forgetPassword_retry_time: '倒计时 ({num})',
    forgetPassword_resend: '重新发送',
    forgetPassword_get_verification_code: '获取验证码',
    forgetPassword_send_successfully: '验证码发送成功',

    // router

    router_home: '首页',
    router_userinfo: '个人设置',
    router_role:'角色管理',
    router_role_edit:'角色编辑',
    router_menu:'菜单权限',
    router_permission:'接口权限',
    router_setting:'系统常量设置',
    router_user:'用户管理',
    router_site:'组织管理',
    router_user_list:'学员管理',
    router_user_mylist:'用户管理',
    router_site_detail:'组织详情',
    router_site_invitation:'组织邀请链接',
    router_project:'项目管理',
    router_task:'我的任务',
    router_task_execute:'任务作业',
    router_project_stat:'项目统计',
    router_project_detail:'项目详情',
    router_project_create:'新建项目',

    router_trainingcamp_train:'认证刷题',
    router_trainingcamp_mytrain:'刷题记录',
    router_trainingcamp_mywrong:'错题本',
    router_trainingcamp_mycollect:'收藏集',
    router_trainingcamp_trainlist:'训练管理',
    router_trainingcamp_generation:'智能组卷',
    router_trainingcamp_stat:'系统统计',
    router_trainingcamp_teststat:'刷题学情分析',
    router_trainingcamp_examstat:'模考学情分析',
    router_trainingcamp_dataverify:'题库复核',
    router_trainingcamp_myexam:'我的考试',
    router_trainingcamp_examlist:'组卷考试',
    router_trainingcamp_mygroup:'自有题库',
    router_trainingcamp_mygroup_create:'新建题库',
    router_trainingcamp_homeworklist:'课后练习',
    router_trainingcamp_myhomework:'课后练习',
    router_trainingcamp_mystat:'组织统计',

    router_dataset_category:'题库分类',
    router_dataset_map:'数据集知识图谱',
    router_dataset_group:'自有题库',
    router_dataset_data:'题库管理',
    router_dataset_node:'知识图谱技能点',
    router_dataset_stat:'数据集统计',
    router_dataset_product:'运营产品',
    router_dataset_feedback:'投诉&反馈管理',
    router_dataset_data_correction:'纠错列表',
    router_boss:'boss看板',
    router_newboss: 'BOSS看板',
    router_login:'登录',
    router_register:'注册账号',
    router_registersite:'注册运营商',
    router_forget_password:'忘记密码',

    router_mc: '消息中心',
    router_setting_project: '设置项目',
    router_task_view: '执行任务',
    router_batch_review: '批量审核',
    router_task_details: '任务详情',
    router_employee_details: '用户详情',
    router_spl: '设置人员标签',
    router_ulm: '用户标签管理',
    router_ugm: '用户小组管理',
    router_tmm: '小组成员管理',
    router_set_operators: '设置作业人员',
    router_batch_set_operators: '批量设置作业人员',
    router_my_task: '我的任务',
    router_my_performance: '我的绩效',
    router_team_task: '团队任务',
    router_team_performance: '团队绩效',
    router_team_performance_per_day: '团队每日绩效',
    router_team_member: '团队人员',
    router_quick_login: 'quicklogin - 快速登录',
    router_latest_notice: '最新公告',
    router_site_management: '组织管理',
    router_system_management: '系统设置',
    router_project_management: '项目管理',
    router_template_management: '模板管理',
    router_user_management: '用户管理',
    router_project_detail: '项目详情',
    router_import_user: '导入用户',
    router_edit_template: '编辑模板',
    router_mysite:'我的组织',
    router_myinfo:'我的账号',
    router_system_settings: '系统设置',
    router_task_detail:"任务详情",
    router_trainingcamp_myteststat:'学情分析',
    router_trainingcamp_myexamstat:'我的模考学情分析',
    router_trainingcamp_stat:'学员注册统计',
    route_user_mygroups:'班级管理',
    route_user_mygroups_detail:'班级设置',
    router_dataset_label:'考点管理',
    router_dataset_data_collect:'收藏',
    router_project_stats:'绩效统计',
    router_system:'系统管理',
    router_system_analysis:'消息队列统计',
    router_system_user_stat:'在线用户统计',
    router_system_user_stat_all:'今日活跃用户统计',
    router_system_ws_stat:'websocket在线用户统计',
    router_system_ali_stat:'阿里云社区题库每周统计',
    router_site_areastat: '区域统计',
    router_trainingcamp_online_programming:'在线实验',


    //左侧菜单
    menu_home: "首页",
    menu_production: "内容生产",
    menu_production_project: "项目管理",
    menu_production_stats: "绩效统计",
    menu_production_tast: "我的任务",
    menu_production_stat: "我的绩效",
    menu_production_group: "团队管理",

    menu_trainingcamp:'刷题练习',
    menu_trainingcamp_train:'刷题',
    menu_trainingcamp_mytrain:'我的刷题记录',
    menu_trainingcamp_mywrong:'我的错题本',
    menu_trainingcamp_mycollect:'我的收藏',
    menu_trainingcamp_generation:'智能组卷',
    menu_trainingcamp_train_manage:'组卷/练习管理',
    menu_trainingcamp_myteststat:'我的刷题学情分析',
    menu_trainingcamp_myexamstat:'我的模考学情分析',
    menu_trainingcamp_stat:'组织统计',
    menu_trainingcamp_teststat:'刷题学情分析',
    menu_trainingcamp_examstat:'模考学情分析',
    menu_trainingcamp_dataverify:'题库复核',

    menu_student: '考生端',
    menu_teacher: '老师端',
    menu_trainingcamp_mygroup:'自有题库',
    menu_trainingcamp_trainlist:'智能组卷(老师端)',
    menu_homework:'课后练习',
    menu_trainingcamp_myhomework:'课后练习',
    menu_trainingcamp_myhomeworkdata:'课后练习分析',
    menu_trainingcamp_homeworklist:'课后练习组卷',
    menu_exam:'模拟考试',
    menu_trainingcamp_myselfexam:'自测模拟考试',
    menu_trainingcamp_myexam:'模拟考试',
    menu_trainingcamp_myexamdata:'我的模考学情分析',
    menu_trainingcamp_examlist:'组卷考试',
    menu_trainingcamp_mystat:'组织统计',

    menu_dataset:'数据集管理',
    menu_dataset_category: '分类',
    menu_dataset_map:'知识图谱',
    menu_dataset_node:'知识图谱技能点',
    menu_dataset_label:'考点管理',
    menu_dataset_data:'题目',
    menu_dataset_group:'自有题库',
    menu_dataset_feedback:'投诉反馈',
    menu_dataset_video:'视频',
    menu_dataset_stat: '统计',


    menu_dataset_product:'运营产品',

    menu_boss:'BOSS看板',
    menu_newboss: 'BOSS看板',
    menu_user:'学员管理',
    menu_cdkey:'激活码',
    menu_cdkeys:'激活码管理(超管)',
    menu_cdkey_code:'激活码管理',
    menu_cdkey_mycdkey:'题库激活码',

    menu_system:'系统设置',
    menu_auth_role:'角色管理',
    menu_auth_permission:'接口权限管理',
    menu_auth_menu:'菜单权限管理',
    menu_setting:'全局设置',

    menu_super:'超级管理员端',
    menu_manager:'管理员端',

    menu_myinfo:'我的账号',
    menu_site: '组织管理',
    menu_mysite:'我的组织',
    menu_user_mygroups:'我的班级',
    menu_trainingcamp_mycategory:'题库管理',
    menu_trainingcamp_materialCreate:'创建试卷',

    menu_trainingcamp_chapters:'章节练习',

    // 40~
    error_return_home: '返回首页',
    error_back: '返回上一页',
    error_no_exit: '404-页面不存在',
    // error_: '当访问的页面不存在时会跳转到404页面，您可以在浏览器地址栏中修改url为一个不存在的路径，体验一下效果',
    error_403: '403-权限不足',
    // error_insufficient_authority : '在当前登录用户不具有执行当前操作的权限时跳转到该页面，您可以在ajax请求方法中判断返回的状态码为403时跳转到该页面',
    error_server_error: '500-服务端错误',
    // error_: '当请求之后出现服务端错误时跳转到该页面，您可以在ajax请求方法中判断返回的状态码为500时跳转到该页面',

    // title
    title_huicui: '荟萃众包',
    title_beisai: '倍赛团队',
    //请勾选
    read_check:'请勾选',


    menu_match:'竞赛PK',
    menu_match_four:'四人赛[研发中]',
    menu_match_two:'两人赛[研发中]',
    menu_match_more:'多人赛[研发中]',

    menu_trainingcamp_matchlist:'PK竞赛',
    menu_wxmini:'微信小程序定制',
    menu_wxmini_managelist:'微信小程序定制管理',
    router_wx_help:'帮助教程',


    menu_trainingcamp_group_detail:'试卷详情',
    menu_trainingcamp_category_detail:'分类详情',
    menu_trainingcamp_data_detail:'题目详情',
    menu_trainingcamp_train_detail:'刷题',
    menu_trainingcamp_train_result:'查看结果',
    menu_trainingcamp_train_report:'查看报告',
    menu_trainingcamp_exam_create:'新建试卷',
    menu_trainingcamp_exam_detail:'试卷详情',
    menu_trainingcamp_create_homework:'新建课后作业',
    menu_trainingcamp_homework_detail:'课后作业详情',
    menu_trainingcamp_exam:'考试作答',
    menu_cdkey_mycdkey:'题库激活码',
    menu_trainingcamp_match:'PK竞赛',
    menu_trainingcamp_match_list:'PK竞赛(敬请期待)',
    menu_trainingcamp_match_create:'新建PK竞赛',
    menu_trainingcamp_match_detail:'PK竞赛详情',
    menu_trainingcamp_create_homework:'新建课后作业',
    menu_trainingcamp_create_homework:'新建课后作业',
    menu_user_teacher:'师资管理',
    menu_user_teacher_list:'师资列表',
    menu_user_teacher_notice:'消息通知',
    menu_user_teacher_notice_add:'新增通知',
    menu_user_stu_train_stat:'学员刷题统计',
    menu_trainingcamp_mycorrection:'纠错&反馈',
    menu_dataset_evaluation_detail:'详情',
    menu_dataset_check_chart:'查看图谱',
    menu_certcode_list:'认证考试码',
    menu_certcode_dispense_list:'激活码分发管理',

    router_trainingcamp_paperAnalysis:'试卷分析',
    router_trainingcamp_stuExamDetail:'学生试卷分析',
    router_trainingcamp_trainstat:'学情分析',


    menu_evaluation:'岗位测评',
    menu_dataset_evaluation:'岗位管理',
    menu_evaluation_report:'测试报告',
    menu_dataset_chatgpt:'chatgpt',
    menu_system:'系统管理',
    menu_user_statistics:'师资注册量统计',
    menu_dataset_edit_data:'单题编辑',
    menu_trainingcamp_material_self_exam:'自测模考',
    menu_trainingcamp_pre_exam:'答题未开始',

    route_trainingcamp_mycerts:'物料管理',
    router_production_dict:'语料管理',
    router_trainingcamp_random_question:'千人千卷',
    router_course:'课程管理',
    router_course_detail:'课程详情',
    router_course_create:'新建/编辑课程',
    router_dataset_private_data:'题库管理',
    router_course_category:'分类管理',
    router_teaching_source:'资源管理',
    router_course_teacher_list:'课程管理',
    router_course_source_manage:'资源管理',
    router_course_analysis:'学习分析',
    router_course_create_tasks:'创建学习任务',
    router_course_student_list:'课程学习',
    router_task_analysis:'学习分析',
    router_trainingcamp_book:'教材学习',
    router_trainingcamp_myTrain:'我的练习',
    router_trainingcamp_exam_record:'模考记录',
    router_trainingcamp_self_exam_record:'自测模考记录',
    menu_trainingcamp_bookmanage:'教材管理',
    menu_trainingcamp_certmanage:'认证题库',
    router_course_task_list:'课程任务记录',
    router_course_task_train:'发布练习',
    router_course_task_random_exam:'随机组卷',
    router_course_task_exam:'固定组卷',
    menu_trainingcamp_preview_exam:'预览试题',
    router_course_analysis_exam:'试卷分析',
    router_course_interact_add:'添加互动答题',
    router_course_interact_detail:'实时互动答题',
    router_course_interact_record:'互动答题记录',
    menu_course_interact_exam:'互动答题',
    router_trainingcamp_task_train_detail:'课后作业',
    menu_lecturer:'师资认证',

    college_coverage_statistics:"院校覆盖统计",
    activation_code_list:"激活码列表",
    event_management:"活动管理",
    material_management:"物料管理",
    mini_program_customization:"小程序定制",
    resource_management:"资源管理",
    experimental_management:"实验管理",
    chatGPT:"chatGPT",
    organizational_structure:"组织架构",
    self_testing_mock_exam:"自测模考",
    classroom_live_streaming:"课堂直播",
    live_room:"直播列表",
    invigilation:"考试监考",
    menu_experiment_virtual:'虚拟仿真实验',
    menu_experiment_aliyun:'虚拟仿真实验(阿里云容器)',
    router_trainingcamp_practical_experiment:'三方平台实验',
    online_experiments:'流程图实验',

    router_trainingcamp_groupAnalysis:'班级分析',
    router_trainingcamp_group_course_analysis:'课程分析',
    menu_business_followup:'商机跟进',
};
