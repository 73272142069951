<template>
  <div :style="{background: bgColor,height:$store.state.app.chromTip ? 'calc(100% - 66px)' : '100%'}" class="ivu-shrinkable-menu">
    <slot name="top"></slot>
    <div class="navicon-con" :style="{width: this.shrink ? '63px' : '100%'}">
      <Button :style="{transform: 'rotateZ(' + (this.shrink ? '-90' : '0') + 'deg)'}" type="text" ghost @click="toggleClick" >
        <Icon type="ios-menu" size="20"></Icon>
      </Button>
    </div>
    <el-menu
        class="el-menu-vertical"
        background-color="#FFFFFF"
        text-color="#333333"
        style="min-height: calc(100vh - 98px)"
        :collapse="shrink"
        :router="true"
        :collapse-transition = "false"
        :default-openeds="openeds"

             ref="menu">
      <template v-for="(item) in menuList" >
        <!-- 带有二级菜单 -->
        <el-submenu :index="item.path" v-if="item.children" :key="item.path+'subMenu'">

          <!-- 显示一级菜单 -->
          <template slot="title">
            <!-- 一级菜单图标 -->
<!--            <i class="tit-icon" :style="{background: item.color}">{{ item.name.slice(0,1) }}</i>-->
            <div style="display: flex;justify-content: flex-start;align-items: center;height: 100%;">
              <div style="width: 30px;display: flex;justify-content: center;align-items: center">
                <img :src="item.img" height="18" />
              </div>
              <!-- 一级菜单名称 -->
              <span slot="title">{{ $t(item.name) }}</span>
            </div>
          </template>

          <!-- 显示二级菜单 -->
          <el-menu-item-group  >
            <!-- 二级菜单名称 -->
            <el-menu-item :index="sItem.path" v-for="(sItem,sIndex) in item.children" :key="sIndex" style="padding-left: 50px;color: #666666;">{{$t(sItem.meta.title)}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <!-- 只有一级菜单 -->
        <el-menu-item  v-else-if="item.path.indexOf('https') > -1" :key="item.path" @click="goLink(item.path)">
          <!-- 一级菜单图标 -->
<!--          <i class="tit-icon" :style="{background: item.color}">{{ item.name.slice(0,1) }}</i>-->
          <div style="display: flex;justify-content: flex-start;align-items: center;height: 100%;">
            <div style="width: 30px;display: flex;justify-content: center;align-items: center;">
              <img :src="item.img" height="18" />
            </div>
            <!-- 一级菜单名称 -->
            <span slot="title">{{ $t(item.name) }}</span>
          </div>

        </el-menu-item>
        <!-- 只有一级菜单 -->
        <el-menu-item  v-else-if="item.path == ''"  @click="goLabelTool()">
          <!-- 一级菜单图标 -->
          <!--          <i class="tit-icon" :style="{background: item.color}">{{ item.name.slice(0,1) }}</i>-->
          <div style="display: flex;justify-content: flex-start;align-items: center;height: 100%;">
            <div style="width: 30px;display: flex;justify-content: center;align-items: center;">
              <img :src="item.img" height="18" />
            </div>
            <!-- 一级菜单名称 -->
            <span slot="title">{{ $t(item.name) }}</span>
          </div>

        </el-menu-item>
        <el-menu-item :index="item.path" v-if="!item.children && item.path.indexOf('https') == -1 && item.path && showMenu(item)" :key="item.path">
          <!-- 一级菜单图标 -->
          <!--          <i class="tit-icon" :style="{background: item.color}">{{ item.name.slice(0,1) }}</i>-->
          <div style="display: flex;justify-content: flex-start;align-items: center;height: 100%;">
            <div style="width: 30px;display: flex;justify-content: center;align-items: center;">
              <img :src="item.img" height="18" />
            </div>
            <!-- 一级菜单名称 -->
            <span slot="title">{{ $t(item.name) }}</span>
          </div>

        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>
<script>
import Cookies from 'js-cookie';

export default {
  name: 'leftMenu',
  data () {
    return {
      shrink: Cookies.get('liteMenuShrink') === 'true',
      shrinkCur: true,
      version: window._version,
      lock: 0,
      curPath:'',
      isChrome:true,
      openeds:[]
    };
  },
  props: {
    menuList: {
      type: Array,
      required: true
    },
  },
  components: {
  },
  computed: {
    bgColor () {
      return this.menuTheme === 'dark' ? '#495060' : '#fff';
    },
    // site_name () {
    //   return this.$store.state.app.settings.site_name;
    // },
    userSitename(){

      if (!this.$store.state.user.userInfo.site)
      {
        return '';
      }
      return this.$store.state.user.userInfo.site.name;
    },
    // curPath(){
    //   console.log(this.$store.state.app.curPath,'this.$store.state.app.curPaththis.$store.state.app.curPath')
    //   return this.$store.state.app.curPath;
    // }
  },
  watch:{
    '$store.state.app.curPath':{
      handler(newV){
        this.$nextTick(()=>{
          if(this.$store.state.app.breadCrumbList.length > 2 && this.$store.state.app.menuList.length){
            for(let i=1;i<this.$store.state.app.breadCrumbList.length - 1;i++){

              let url = this.findUrl(this.$store.state.app.menuList,this.$store.state.app.breadCrumbList[i].path);

              if(url){
                this.$refs.menu.activeIndex = url;
                break;
              }
            }
          }else{
            this.$refs.menu.activeIndex = newV;
          }


          // this.$refs.menu.activeIndex = newV;
          // this.curPath = newV;

        })

      },
      immediate:true,
    },
    '$store.state.app.menuList':{
      handler(newV){
        if(newV.length){
          let module = this.$route.meta.module_route;

          let index = this.$store.state.app.userModuleList.findIndex((sItem)=>{
            return sItem?.meta?.module_route == module;
          });

          if(index == '-1'){
            this.$router.replace(this.$store.state.app.menuList[0].path);
          }
        }
        this.openeds = [];
        newV.forEach((item)=>{
          if(item.children && item.children.length){
            this.openeds.push(item.path);
            item.children.forEach((sItem)=>{
              this.openeds.push(sItem.path);
            })
          }
        })

        if(this.$store.state.app.breadCrumbList.length > 2 && this.$store.state.app.menuList.length){
          for(let i=1;i<this.$store.state.app.breadCrumbList.length - 1;i++){

            let url = this.findUrl(this.$store.state.app.menuList,this.$store.state.app.breadCrumbList[i].path);

            if(url){
              this.$nextTick(()=>{
                this.$refs.menu.activeIndex = url;
              })

              break;
            }
          }
        }else{
          this.$nextTick(()=>{
            this.$refs.menu.activeIndex = this.$store.state.app.curPath;
          })
        }
      },
      immediate:true,
    }
  },
  mounted(){
    // this.curPath = this.$route.path;
    // this.$store.commit('setCurPath',this.$route.path)
    this.isChrome =  navigator.userAgent.indexOf('Chrome') > -1;
  },
  methods: {
    showMenu(item){
      if(item.name==="live_room" && this.$store.state.user.userSetting.open_webrtc!=='1'){
        return false;
      }else{
        return true;
      }
    },
    goLink(data){
      window.open(data,'_blank');
    },
    findUrl(data,path){
      let url = '';
      for(let i=0;i<data.length;i++){

        if(data[i].path == path){

          url = path;
          break;
        }else{
          if(data[i].children && data[i].children.length){

            url = this.findUrl(data[i].children,path);
            if(url){
              break;
            }

          }
        }
      }

      return url;
    },
    toggleClick () {
      this.shrink = !this.shrink;
      Cookies.remove('liteMenuShrink');
      Cookies.set('liteMenuShrink', this.shrink);
      this.$emit('shrinkHandle', this.shrink);
    },

    itemTitle (item) {
      if (typeof item.name === 'object') {
        return this.$t(item.name.i18n);
      } else {
        return item.name;
      }
    },
    selectMenu(){
      // this.$store.commit('formatBreadCrumbList',)
    },
    goLabelTool(){  //去标注系统
      this.api.user.userDetail().then((res)=>{
        let url = res.labeltool_platform_url;
        window.open(url,'_blank');
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.ivu-shrinkable-menu{
  height: 100%;
  overflow-y: auto;
  position: relative;
  // width: 100%;
  .sideSmall {
    .ivu-icon{
      color: #fff;
    }
  }
  .layout-text {
    //opacity: 1;
    //transition: opacity 0.05s 0.05s;
    color:#333333;
  }
  .con-team-icon {
    color: #F0F0F0 !important;
    background-color: #6c7483;
    display: block;
    width: 100%;
    height: 20px;
    text-align: center;
  }
}
.ivu-menu-submenu-title > i,
.ivu-menu-submenu-title span > i,
.ivu-menu-item > i{
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
}
.vue-scroller-bars-content {
  height: 100% !important;
}
.navicon-con {
  padding-top: 10px;
  //text-align: center;
  background: #FFFFFF;
  height: 50px;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.navicon-con .ivu-btn-text {
  //height: 30px;
  color: #000000;
  border-radius: 0;
  &:focus{
    box-shadow: none;
  }
}
.transMenu{
  //height: 42px;
  //padding: 5px 0 10px;
  transition: width .3s;
  cursor: pointer;
  &:hover{
    background-color: rgba(58, 64, 77, 1);
    .ivu-btn-text{
      color: #333333;
    }
  }
}

.ivu-menu-dark {
  background: rgb(73, 80, 96);
}
.main-hide-text .ivu-menu-vertical .ivu-menu-item-group-title {
  padding-left: 12px !important;
  font-size: 12px;
}
.ivu-menu-item > i {
  margin-right: 10px !important;
  margin-bottom: 5px
}
.ivu-menu-item-group {
  line-height: 1.5 !important;
}
.copyRight {
  width: 95%;
  position: absolute;
  left: 0;
  bottom: 15px;
  text-align: center;
  color: #ddd;
  z-index: 1;
}
.menu-top{
  line-height: 40px;
  text-align: center;
}
.icon-style{
  color:#FFFFFF;
  padding-right: 5px;
}
.tit-icon{
  margin-right: 5px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #5cadff;
  text-align: center;
  line-height: 20px;
  font-style:normal;
  color:#FFFFFF;
}
.el-menu-vertical{
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  height: 100%;
}

</style>
<style>
.el-menu-vertical:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}
.ivu-shrinkable-menu .el-menu{
  border-right:0;
}
.el-menu-vertical .el-menu-item-group__title{
  padding: 0 !important;
}
/* .el-menu-item{
  border-right: 4px solid transparent;
} */
.el-menu-item:hover{
  font-weight: bold;
  border-right: 4px solid #4E83FF;
  background-color: #ECEDF2 !important;
}
.el-submenu__title:hover{
  font-weight: bold;
  /*border-right: 4px solid #4E83FF;*/
  background-color: #ECEDF2 !important;
}
.el-menu-item.is-active{
  background-color: #ECEDF2 !important;
  font-weight: bold;
  color:#000000;
  border-right: 4px solid #4E83FF;
}
</style>
