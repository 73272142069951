
export default {
    site_name: '组织名称',
    site_desc: '备注',
    site_last_login_time: '最后登录时间',
    site_operation: '操作',
    site_start_time: '开始时间',
    site_end_time: '截止时间',
    site_creator: '创建人',
    site_membercount: '成员数',

    site_add: '添加组织',
    site_dele_tip: '确定删除?',
    site_table_name: '组织名称',
    site_table_desc: '组织备注',
    site_add_name_tip: '组织名称',
    site_add_desc_tip: '组织备注',
    site_check: '',
    site_edit: '',
    site_search_tip:'请输入组织名称',

    site_invitation: '邀请链接',
    site_invitation_add: '创建邀请链接',
    site_invitation_dele_tip: '确定删除?',
    site_invitation_table_name: '组织名称',
    site_invitation_table_desc: '组织备注',
    site_invitation_add_name_tip: '组织名称',
    site_invitation_add_desc_tip: '组织备注',
    site_invitation_check: '',
    site_invitation_edit: '',
    site_invitation_search_tip:'请输入组织名称',

    site_invitation_sitename: '组织',
    site_invitation_link:'链接地址',
    site_invitation_token: '链接地址',
    site_invitation_last_login_time: '最后登录时间',
    site_invitation_operation: '操作',
    site_invitation_start_time: '开始时间',
    site_invitation_end_time: '截止时间',
    site_invitation_creator: '创建人',
    site_invitation_membercount: '成员数',
    site_invitation_expire: '有效期',
    site_invitation_show_count:'访问量',
    site_invitation_signup_count: '注册量',
    site_invitation_copylink:'复制邀请链接',

    site_invitation_add_date:'有效时间',
    site_invitation_add_remarks:'备注',
    site_invitation_add_date_placeholder:'请选择有效时间',
    site_invitation_add_remarks_placeholder:'备注',
    site_invitation_add_generate_link:'生成链接',
    site_invitation_add_cancel:'取消',
    site_invitation_add_confirm:'确定',
    site_invitation_add_site_name:'组织名称',
    site_invitation_add_site_ID:'组织ID',
}
