export default {
    template_audio_file_placeholder: 'Audio',
    template_image_label_tool: 'Image annotation tool',
    template_image_basic_tool: 'Basic tools',
    template_image_special_tool: 'Special tools',
    template_external_link: 'Third-party links',
    template_form_checkbox: ' Multiple-choice',
    template_form_checkbox_header: 'Please choose one of the following options',
    template_form_group: 'form group',
    template_form_group_header: 'Attribute',
    template_form_radio: 'Single-choice',
    template_form_radio_header: 'Single-choice',
    template_form_select: 'Drop-down box',
    template_form_select_header: 'Drop-down box',
    template_form_upload: 'file upload',
    template_data_is_valid: 'Data cleaning',
    template_data_is_valid_header: 'Whether the data is valid',
    template_key_point: 'Ordered key points',
    template_layout: 'layout',
    template_multi_input: 'Multiple line input',
    template_multi_input_header: 'Multiple line input',
    template_show_img: 'Image display',
    template_image_placeholders: 'Image placeholder',
    template_show_text: 'Text description',
    template_single_input: 'Single row input',
    template_single_input_header: 'Single row input',
    template_tag: 'tag',
    template_select_file: 'Select file',
    template_task_file_placeholder: 'Image',
    template_image_file_shown_here_processed_operator: 'Your image will be displayed here for the operator to process.',
    template_key_point_group: 'Ordered key point group',
    template_key_points: 'Keypoint diagram',
    template_outside_the_graph: 'Whether outside the picture',
    template_covered: 'Whether it is covered',
    template_back_to_previous_step: 'Back to previous step',
    template_back_to_step_one: 'Back to the first step',
    template_label_least_once: 'The tag is used at least once in all annotations.',
    template_tag_group_least_once: 'At least one tag in this group is used in each annotation.',
    template_text_file_placeholder: 'Text content',
    template_text_content_show_opeator_handle: 'Your text will be displayed here for the operator to process.',
    template_video_file_placeholder: 'Video',

    template_title_optional: 'Title (optional)',
    template_remarks_optional: 'Note (optional)',
    template_multi_input_maxRows: 'Multi-row input',
    template_data_source_anchor_point: 'Data source anchor',
    template_editable_not_repeat: 'Editable, do not repeat.',
    template_editing_options: 'Edit option',
    template_enter_title: 'Please enter title',
    template_enter_comments: 'Please enter note',
    template_set_option_content_sort: 'You can set and sort options',
    template_add_option: 'Add single option',
    template_batch_add_option: 'Batch add',
    template_batch_add_option_tips: 'Each row contains one entry, which will be automatically deduplicated when being saved and will be ignored if empty',
    template_sets_option_layout: 'Set layout',
    template_horizontal_array: 'Horizontal',
    template_vertical_array: 'Vertical',
    template_whether_must: 'Is it necessary or not?',
    template_whether_must_required: 'Required',
    template_upload_type: 'Upload type',
    template_set_file_format_receive: 'Set the file type and file format you receive here',
    template_picture: 'picture',
    template_audio: 'audio',
    template_video: 'video',
    template_other: 'other',
    template_file_format: 'File format',
    template_limit_size_file: 'limit single file size',
    template_min: 'minimum',
    template_max: 'maximum',
    template_limit_length_file: 'limit single file duration',
    template_shortest: 'shortest',
    template_longest: 'longest',
    template_number_file_upload: 'Maximum number of files to be uploaded',
    template_upload_one_file_default_modify: 'Only one file is allowed to be uploaded each time by default. If you need more than one, please modify it. ',
    template_key_point_group_list: 'Key point group list',
    template_key_point_group_name: 'Group name',
    template_edit_point_location: 'Edit key point position',
    template_in_figure: 'Change the map',
    template_delete: 'delete',
    template_list: 'list',
    template_add_key_points: 'Add key point',
    template_add_key_points_group: 'Add key point group',
    template_excel_import: 'Import via Excel',
    template_key_components_excel_import_templates: 'Keypoint component Excel import template',
    template_download_template: 'Download template',
    template_edit_location_key_points_sample_diagram: 'Edit key point position on the image',
    template_save: 'Save',
    template_close: 'Close',
    template_edit_key_point_location: 'Edit key point position',
    template_replace_picture: 'Change image',
    template_edit_key_points: 'Set key points',
    template_adjust_order_key_points: 'You can change the order of key points',
    template_light_position_enable_drag: 'Following the order of the key points highlighted on the right, click on the key point in the corresponding position on the image and drag to change position.',
    template_keep_at_least_one: 'At least one is reserved',
    template_upload_sample_diagram_first: 'Please upload the diagram first',
    template_upload_failed_try_again: 'Upload failed, please try again',
    template_data_empty: 'Data is empty',
    template_data_invalid: 'Data is invalid',
    template_label_attr_edit: 'Edit attributes',
    template_color: 'color',
    template_short_code: 'Display name',
    template_min_width: 'Minimum width',
    template_maxi_width: 'Maximum width',
    template_min_height: 'Minimum height',
    template_max_height: 'Maximum height',
    template_width: 'Width',
    template_height: 'Height',
    template_label_included_submitted_job_result: 'Submitted job must contain this tag',
    template_not_must: 'Yes',
    template_must: 'No',
    template_figure: 'Example image',
    template_update_figure: 'Update example image',
    template_file_upload_failes: 'File {fileName} failed to be uploaded',
    template_set_layout_width_ratio: 'Set width ratio of two columns',
    template_ratio_width_two_columns: 'Two column width ratio',
    template_upload_sample_image: 'Please upload your image',
    template_upload_file_size: 'Upload .png, .jpg or .jpeg image within 2 MB',
    template_click_select_the_image: 'Click to select image',
    template_file_size_exceeds_limit: 'File size exceeds limit',
    template_file_size_2M: 'File {fileName} is over 2M.',
    template_open_text_editor: 'Open text editor',
    template_set_label_type: 'Set tag type',
    template_single_tag: 'Single tag',
    template_label_group: 'Tag group',
    template_advanced_settings: 'Advanced settings',
    template_batch_change_label_color: 'Change color for all tags',
    template_system_default_value: 'Default',
    template_polygon_point_distance_setting: 'Set polygon point distance',
    template_whether_enable_tag_echo_search: 'Tag echo and search',
    template_no_limit: 'means no limits',
    template_open: 'Enable',
    template_can: 'OK',
    template_can_not: 'Not allowed',
    template_client_unSupport: 'This feature is not currently supported on desktop',
    template_whether_drawn_outside_picture: 'Drawing outside the image',
    template_label_coordinate_points_following_graph: 'Enbale tagging for coordinates of polygon',
    template_trapezoidal: 'trapezoid',
    template_quadrilateral: 'quadruple',
    template_cuboid: 'cuboid',
    template_line: 'line',
    template_triangle: 'triangle',
    template_open_tag_group_lock: 'Turn on tag group lock',
    template_whether_tag_group_expanded_default: 'Whether the label group expand by default',
    template_opened: 'On',
    template_off: 'Closed',
    template_unfold: 'Expand',
    template_closed: 'Closed',
    template_label_group_presentation: 'tag group display method',
    template_list_type: 'listed type',
    template_card_type: 'card type',
    template_whether_global_label_is_unique: 'Is the tag unique?',
    template_sets_whether_label_is_unique: 'Set tag\'s uniqueness',
    template_indicates_label_has_at_most_one_label: 'Yes, the annotated object can only have one tag.',
    template_indicates_label_may_have_multiple_labels: 'No, the annotated object can have multiple tags.',
    template_setting_highest_priority: 'This setting has the highest priority',
    template_label_only_have_one_label: 'Yes, the annotated object can only have one tag.',
    template_labels_can_have_multiple_labels: 'No, the annotated object can have multiple tags.',
    template_whether_the_label_must_have: 'Is tag necessary?',
    template_the_label_must_have_label: 'Yes, the annotated object must have a tag.',
    template_labels_may_not_have_labels: 'No, the annotated object can have no tags.',
    template_edit_label: 'Edit tag',
    template_editable_label_properties: 'Click on gear on the right side of the input box to️ edit tag attributes. An output value of the tag must be filled in the tag input box. The display name is the tag name shown to the operator.',
    template_add_tags: 'Add tag',
    template_whether_tags_group_unique: 'Whether the in-group label is unique',
    template_sets_whether_label_in_the_group_is_unique: 'Set whether the tags in this group are unique.',
    template_indicates_tag_one_label_within_group: 'Yes: Indicates that the marker uses at most one tag in the group',
    template_indicates_nnotator_multiple_tags_within_group: 'No: means that the marker can use multiple tags in the group',
    template_tags_may_use_most_one_label_within_group: 'Yes, the marker uses at most one tag in the group',
    template_tags_can_use_multiple_tags_within_group: 'No, the marker can use multiple tags in the group',
    template_whether_there_must_be_tags_within_group: 'Is the tag in the group required for each annotation',
    template_tags_must_have_set_tags: 'Yes, the marker must have at least one tag from this group',
    template_tags_may_not_have_set_tags: 'No, the marker can have no tags from this group',
    template_add_three_level_label: 'Add level-three tags',
    template_add_secondary_tags: 'Add level-two tags',
    template_add_tag_group: 'Add a tag group',
    template_enable_text_search: 'Text search',
    template_tag_group_title: 'Tag group title (click to filter tags)',
    template_select_the_category_tab: 'The tag of the selected category (click to select lable)',
    template_table_data_format_incorrect: 'Table data format is incorrect',
    template_shortcut_used: 'Shortcut key is being used',
    template_shape_enable: 'Enabled tool types',
    template_need_add_shape: 'Please add types that can be drawn',
    template_keyPoint_color_set_all: 'Set color for all key points',
    template_default_tag_header: 'Type selection',
    template_default_form_radio1_header: 'Is it a number',
    template_default_form_radio1_yes: 'Yes',
    template_default_form_radio1_no: 'No',
    template_default_single_input1_header: 'Please describe the picture you see',
    template_default_form_radio2_header: 'Please determine whether the transcription is accurate',
    template_default_form_radio2_yes: 'Accurate',
    template_default_form_radio2_no: 'Inaccurate',
    template_default_mul_input_header: 'If the transcription is inaccurate, please modify it',
    template_default_form_radio3_header: 'Does the description relate to finance',
    template_default_form_radio4_header: 'Role',
    template_default_form_radio4_role1: 'Role 1',
    template_default_form_radio4_role2: 'Role 2',
    template_default_form_radio5_header: 'Tone',
    template_default_form_radio5_tone1: 'peaceful',
    template_default_form_radio5_tone2: 'hurried',
    template_no_match_text: 'No matching text',

    'template-audio-file-placeholder': 'audio',
    'template-task-file-placeholder': 'image',
    'template-video-file-placeholder': 'video',
    'template-text-file-placeholder': 'text content',
    'template-form-checkbox': 'multiple-choice',
    'template-form-group': 'form group',
    'template-form-radio': 'single-choice',
    'template-form-select': 'Drop-down box',
    'template-form-upload': 'upload file',
    'template-data-is-valid': 'data cleaning',
    'template-external-link': 'third party links',
    'template-key-point-group': 'ordered key points',
    'template-show-img': 'Image display',
    'template-show-text': 'Text description',
    'template-single-input': 'Single row input',
    'template-tag': 'tag',
    'template-key-point': 'ordered key point',
    'template-layout': 'layout',
    'template-multi-input': 'multiple line input',

    'template-components-desc': 'This component will not be displayed on the execution page, only for annotation tool configuration.',
    'template-open-picture-type': 'Opened graphics type (will override the default configuration of the classification):',
    'template-add-draw-type': 'Please add types that can be drawn',

    'template-option-1': 'option 1',
    'template-option-2': 'option 2',
    'template-option-3': 'option 3',
    'template-local-settings': 'local setting',
    'template-upload-data': 'Please upload the data file you collected',
    'template-follow-illustration-annotation': 'Please annotate following the example',

    'template-nose': 'nose',
    'template-neck': 'neck',
    'template-left-eye': 'left eye',
    'template-right-eye': 'right eye',
    'template-left-ear': 'left ear',
    'template-right-ear': 'right ear',
    'template-left-shoulder': 'left shoulder',
    'template-right-shoulder': 'right shoulder',
    'template-left-elbow': 'left elbow',
    'template-right-elbow': 'right elbow',
    'template-left-wrist': 'left wrist',
    'template-right-wrist': 'right wrist',
    'template-left-hip': 'left hip',
    'template-right-hip': 'right hip',
    'template-left-knee': 'left knee',
    'template-right-knee': 'right knee',
    'template-left-ankle': 'left ankle',
    'template-right-ankle': 'right ankle',
    'template-head-key': 'head key point',
    'template-describe-picture': 'Please describe the image you see',
    'template-enter-information-input': 'Please enter information in the input box',
    'template-sample-file-annotate': 'Please annotate following the sample file below',

    'template-type-selection': 'select a type',
    'template-refrigerator': 'refrigerator',
    'template-banana': 'banana',
    'template-ham': 'ham',
    'template-setting-key-point-colors': 'key point color batch setting',
    'template-start': 'start',
    'template-end': 'end',
    'template-order-uniform': 'Sequential division',
    'template-custom-bisecting': 'customize division',
    'templat-info': 'bath add{limit} ',
    'template-info-span': 'One entry per line, it will be automatically deduplicated when saved, and empty rows will be ignored',

    template_support_file_type: 'supported uploaded file types',
    template_file_view: 'file preview',
    template_upload_file_tip: 'Tip: You can upload up to {num} files!',

};
