export default {
    tool_collection: '采集任务',
    tool_external_links: '外部链接',
    tool_picture_judgment: '图片判断',
    tool_batch_pass: '批量通过',
    tool_batch_rejection: '批量驳回',
    tool_bulk_reset: '批量重置',
    tool_batch_troublesome_work: '批量挂起',
    tool_clear_and_exit: '清除并退出',
    tool_shortcuts: '大图查看时的快捷键',
    tool_approved: '审核通过',
    tool_reject: '驳回',
    tool_reset: '重置',
    tool_previous: '上一个',
    tool_next: '下一个',
    tool_view_mask: '查看mask图',
    tool_view_original: '查看原图',
    tool_view_default: '查看mark图(默认)',
    tool_unlabeled_map: '查看mark图(无标签图)',
    tool_space: '空格',
    tool_modify: '修改',
    tool_no_remaining_jobs: '暂无剩余作业',
    tool_pass: '通过',
    tool_diffcult_job: '挂起',
    tool_rejected: '被驳回',
    tool_submit: '提交',
    tool_cancel: '取消',
    tool_label_mode: '切换标注和调整模式',
    tool_two_modes: '提交， 两种模式下都可以使用',
    tool_curve: '选择曲线',
    tool_closed_curve: '选择闭合曲线',
    tool_line: '选择直线工具',
    tool_rectangle: '选择矩形工具',
    tool_polygon_frame: '选择画多边形框工具',
    tool_quadrilateral: '选择四边形工具',
    tool_polyline: '选择多段线工具',
    tool_cuboid: '选择长方体工具',
    tool_trapezoid: '选择梯形工具',
    tool_triangle: '选择三角形工具',
    tool_select_point: '选择 点工具',
    tool_auxiliary_line: '隐藏或显示辅助线',
    tool_switch_label: '标签显示隐藏',
    tool_press_lift: '隐藏全部标记 按下隐藏抬起恢复',
    tool_narrow_picture: '缩小图片',
    tool_zoom_picture: '放大图片',
    tool_diaplasis: '复位',
    tool_tilt_left: '向左倾斜',
    tool_tilt_right: '向右倾斜',
    tool_greatly: '大幅度',
    tool_angle_reset: '角度复位',
    tool_polygon_share_N: '标注模式下N键开启多边形共享边功能',
    tool_side_share: '选择哪条边共享',
    tool_rect_size: '显示隐藏矩形框大小提示',
    tool_switch_mask: '切换mask图',
    tool_cancel_mark: '取消正在进行的标注',
    tool_left_mouse: '鼠标左键',
    tool_key_point: '调整模式下选择一个关键点',
    tool_delete_group: '高亮整组 此时右键可删除整组',
    tool_switch_picture: '上下键切换选择图形',
    tool_right_mouse: '鼠标右键',
    tool_delete_selection_label: '调整模式下删除选择中的标记',
    tool_delete_all_in_adjust_mode: '调整模式下删除全部',
    tool_delete_all: '删除全部',
    tool_fillreject_reason: '请填写驳回理由',
    tool_fillreset_reason: '请填写重置理由',
    tool_operate_tips: '操作提示',
    tool_exit_review: '确定清除已领取的作业并退出审核吗？',
    tool_request_failed: '作业资源获取失败',
    tool_timed_out: '任务已超时',
    tool_no_jobs: '没有剩余作业',
    tool_no_job: '没有剩余作业了',
    tool_fetch_succ: '已为您加载作业',
    tool_loading: '数据加载中...',
    tool_error: '发生了错误',
    tool_reject_empty: '驳回理由不能为空',
    tool_reset_empty: '重置理由不能为空',
    tool_reason_empty: '理由不能为空',
    tool_undone: '标注未完成',
    tool_result_empty: '标注结果为空',
    tool_required_item: '必填项不能为空',
    tool_quit: '退出',
    tool_all_cancel: '全部取消',
    tool_all_select: '全部选择',
    tool_determine: '确定',
    tool_original_image: '原图(E)',
    tool_enter: '确定 (Enter)',
    tool_esc: '取消 (ESC)',
    tool_reason: '原因',
    tool_result: '结果',
    tool_d: '通过(D)',
    tool_w: '驳回(W)',
    tool_q: '重置(Q)',
    tool_modify_space: '修改(空格)',
    tool_text_loading: '作业文本加载中...',
    tool_filename: '文件名',
    tool_created_time: '创建日期',
    tool_updated_time: '修改日期',
    tool_status: '状态',
    tool_reject_reason: '错误原因',
    tool_handle: '操作',
    tool_view: '查看',
    tool_failed: '请求失败',
    tool_data_loading: '作业数据加载中...',
    tool_support_video: '你的浏览器不支持视频播放.',
    tool_play_pause: '播放/暂停',
    tool_add_label: '新增标注',
    tool_cancel_label: '取消标注',
    tool_right_arrow: '右箭头',
    tool_left_arrow: '左箭头',
    tool_next_frame: '下一帧',
    tool_previous_frame: '上一帧',
    tool_analyze: '加载完成,解析中',
    tool_loadings: '加载中',
    tool_load_failed: '加载失败',
    tool_resource_failed: '作业资源加载失败',
    tool_resource_failed_tips: '原始数据错误,请记录作业ID，并按照管理员要求此处理作业.',
    tool_result_list: '标注结果列表',
    tool_Reasons_for_rejection_of_rework: '返工作业驳回原因',
    tool_request_picture_failed: '请求图片失败',
    tool_play_video: '标注未完成, 请至少将视频完整播放一遍',
    tool_submit_success: '提交成功',
    tool_play_speed: '播放速度',
    tool_total_time: '总时长',
    tool_h: '时',
    tool_m: '分',
    tool_s: '秒',
    tool_switch_label_modes: '切换标注和非标注模式',
    tool_undo_label_result: '撤销刚标注的结果',
    tool_copy_label_result: '复制选中的标注',
    tool_precision_rate: '正确率',
    tool_filter: '筛选',
    tool_close: '关闭',
    tool_rejection_accuracy: '确定驳回准确率{type}{persentage}的作业吗？',
    tool_think_again: '再想想',
    tool_pass_accuracy: '确定通过准确率{type}{persentage}的作业吗？',
    tool_gt: '大于',
    tool_lt: '小于',
    tool_ge: '大于等于',
    tool_le: '小于等于',
    tool_eq: '等于',
    tool_review_operator: '按作业员审核：',
    tool_review_order: '按数据顺序审核:',
    tool_review_type_order: '顺序',
    tool_review_type_random: '随机',
    tool_default_all: '默认全部',
    tool_qc: '已验收',
    tool_percent_pass: '合格率',
    tool_top_jobs: '前 {num} 张作业',
    tool_last_jobs: '后 {num} 张作业',
    tool_job_information: '作业信息',
    tool_job_id: '作业ID',
    tool_project_id: '项目ID',
    tool_project_name: '项目名称',
    tool_batch_id: '批次ID',
    tool_batch_name: '批次名称',
    tool_task_id: '任务ID',
    tool_operator: '作业员',
    tool_task_name: '任务名称',
    tool_job_file_name: '作业文件名称',
    tool_done: '已做',
    tool_surplus: '剩余',
    tool_overdue: '后过期',
    tool_task_timeout_alert: '任务即将超时提醒',
    tool_timeout_rollback: '任务将在 {num} 后超时, 届时将回退已领取的作业',
    tool_timeout_refresh: '任务已超时,请刷新页面重新领取',
    tool_task_timeout: '任务已超时提醒',
    tool_email: '邮箱',
    tool_form_result: '表单结果',
    tool_tagging_content: '标注内容',
    tool_label: '标签',
    tool_text_point: '文本位置',
    tool_attribute: '属性',
    tool_jmvt: '本任务业务量',
    tool_number_job: '作业个数',
    tool_mark_number: '标注条数',
    tool_point: '点数',
    tool_serial: '序号',
    tool_time: '时间',
    tool_click_selected: '点击选中',
    tool_view_reject: '查看错误原因',
    tool_draw_point: '选择画点',
    tool_draw_line: '选择画线',
    tool_draw_rec: '选择画矩形框',
    tool_draw_polygons: '选择画多边形框',
    tool_draw_polyline: '选择多段线',
    tool_check_before_after_jobs: '查看前后作业',
    tool_submit_difficult_job: '挂起',
    tool_submit_exit: '提交并退出',
    tool_submit: '提交',
    tool_submit_D: '提交(D)',
    tool_save_temp: '保存',
    tool_saving_temp: '保存中',
    tool_resource_not_picture: '作业资源不是图片',
    tool_submitexit_confirmation: '提交并退出确认',
    tool_exit_confirmation: '退出确认',
    tool_submit_exit_description: '你有 {num} 条作业未执行, 确认提交并退出? 退出后, 未执行作业将全部回退到公共任务池(包括被驳回作业).',
    tool_exit_description: '你有 {num} 条作业未执行, 确认退出? 退出后, 未执行作业将全部回退到公共任务池(包括被驳回作业).',
    tool_rotation_angle: '旋转角度',
    tool_left_rotate: '向左旋转',
    tool_right_rotate: '向右旋转',
    tool_job_result: '作业结果',
    tool_drag: '可拖拽',
    tool_annotated_schema: '标注模式',
    tool_not_annotated_schema: '非标注模式',
    tool_key_switch: '按X键切换',
    tool_edit: '编辑',
    tool_delete: '删除',
    tool_asterisk_tag_group: '带星号的标签组内的标签至少选一个',
    tool_tags_not_selected: '有必需标签未选',
    tool_selection_not_tagged: '上一条选择的文本，还未添加标签',
    tool_already_exist: ' 已存在',
    tool_audio_loading_failed: '音频加载失败',
    tool_regional_broadcasting: '区域播放',
    tool_shrink: '缩小',
    tool_amplification: '放大',
    tool_play: '播放',
    tool_pause: '暂停',
    tool_leave_content_lost: '确定离开此页面吗？ 未保存的内容可能会丢失。',
    tool_all_label_once: '表示在所有标注物中该标签至少使用一次',
    tool_label_group_once: '表示在每一个标注物中该组内标签至少使用一个',
    tool_search_tag: '搜索标签',
    tool_tag_list: '标签列表',
    tool_tag_group_locking: '已开启标签组锁定，目前只能使用 {num} 分组下的标签',
    tool_select_file: '选择文件',
    tool_file_shown_deal_operator: '您的项目文件将在此处展示，操作员将对其进行处理',
    tool_text_shown_deal_operator: '您的文本内容将在此处展示，操作员将对其进行处理',
    tool_out_picture: '是否在图外',
    tool_occluded: '是否被遮挡',
    tool_back_previous_step: '上一步',
    tool_back_step_one: '重新开始',
    tool_complete_key_points_current_group: '请将当前组的关键点绘制完成',
    tool_schematic_key_points: '关键点示意图',
    tool_currently_selected_object_tags: '当前选中对象的标签数',
    tool_mark_tabs: 'Mark图 (有标签)',
    tool_mark_tabs_m: 'Mark图(有标签)(M)',
    tool_mark_no_tabs: 'Mark图 (无标签)',
    tool_mark_no_tabs_l: 'Mark图 (无标签)(L)',
    tool_mark: 'Mask 图',
    too_mark_fill: 'Mark图(填充)',
    tool_mark_n: 'Mask 图(N)',
    tool_original: '原图',
    tool_not_support_video_playback: '你的浏览器不支持视频播放.',
    tool_last_one_received_task: '已经是已领取任务的 最后一个 了',
    tool_first_one_received_task: '已经是已领取任务的 第一个 了',
    tool_rejected_quality_inspection: '被驳回',
    tool_return: '返回',
    tool_label_group_title: '标签组标题(点击筛选标签)',
    tool_selected_categories_labels: '所选分类的标签(点击选择标签)',
    tool_enter_confirm: '回车确认',
    tool_pictures_show: '图片展示',
    tool_error_task_reason_fill_tips: '输入理由',
    tool_error_task_reason_fill_placeholder: '请输入错误原因',
    tool_new_version_has_release: '新版本已发布',
    tool_version_update_tips: '点我更新',
    tool_fill_parse_error: '文件解析失败',
    tool_request_timeout: '请求超时',
    tool_request_error: '网络异常，请稍后重试',
    tool_video_frame: '帧',
    tool_video_pre_frame: '前一帧',
    tool_video_next_frame: '后一帧',
    tool_video_play_pause: '播放|暂停',
    tool_video_add_item: '新加标注',
    tool_video_cancel_item: '取消标注',
    tool_video_help_line: '辅助线',
    tool_video_capture_finish: '提取完成,共有',
    tool_video_video_size: '视频尺寸',
    tool_video_item_visible: '是否显示?',
    tool_video_item_del: '删除',
    tool_video_play_progress: '播放进度',
    tool_video_play_speed: '速度',
    tool_single_view: '单个查看',

    tool_audio_right_view_results: '请点击右侧 "预览" 查看采集结果...',
    tool_audio_support_audio: '您的浏览器不支持 audio 标签。',
    tool_audio_click_view: '点击查看',
    tool_analy_tip_desc: '多人拟合正确率=重复的字段/全部字段  （全部字段包括被标注内容、标签、文本位置及标注属性）',
    tool_preview: '预览',
    tool_collection_information_ID: '采集信息ID',
    tool_marks_fill: 'Mark图 (填充)',
    tool_frame_20: '每秒播放帧数 (1-20的数字)',
    tool_view_job_result: '查看作业结果',
    tool_auditing: '审核中',
};
