import {request} from '@/utils/request.js'

//按用户统计
const datasetTrainStatByUser = (data) => {
    return request({
        url: '/dataset-train/stat-by-user',
        method: 'post',
        data
    })
}

//按用户和分类统计
const statByUserAndCategory = (data) => {
    return request({
        url: '/dataset-train/stat-by-user-and-category',
        method: 'post',
        data
    })
}

//按组织和分类统计
const statBySiteAndCategory = (data) => {
    return request({
        url: '/dataset-train/stat-by-site-and-category',
        method: 'post',
        data
    })
}

//老师端试卷分析
const datasetTrainReport = (data) => {
    return request({
        url: '/dataset-train/report',
        method: 'post',
        data
    })
}

//组卷参与用户列表
const datasetTrainUserList = (data) => {
    return request({
        url: '/dataset-train/user-list',
        method: 'post',
        data
    })
}

//按组织和分类统计-附加信息
const statBySiteAndCategoryMore = (data) => {
    return request({
        url: '/dataset-train/stat-by-site-and-category-more',
        method: 'post',
        data
    })
}


const exportUserList = (data) => {
    return request({
        url: '/dataset-train/export-user-list',
        method: 'post',
        data
    })
}

const courseFinishRateStatList = (data) => {
    return request({
        url: '/dataset-course/course-finish-rate-stat-list',
        method: 'post',
        data
    })
}

const courseTrainRateStatList = (data) => {
    return request({
        url: '/dataset-course/course-train-rate-stat-list',
        method: 'post',
        data
    })
}

const courseTrainUserList = (data) => {
    return request({
        url: '/dataset-course/course-train-user-list',
        method: 'post',
        data
    })
}

const courseStudyDayStat = (data) => {
    return request({
        url: '/dataset-course/course-study-day-stat',
        method: 'post',
        data
    })
}

const exportCourseTrainUser = (data) => {
    return request({
        url: '/dataset-course/export-course-train-user',
        method: 'post',
        data
    })
}

export default {
    datasetTrainStatByUser,
    statByUserAndCategory,
    statBySiteAndCategory,
    datasetTrainReport,
    datasetTrainUserList,
    statBySiteAndCategoryMore,
    exportUserList,
    courseFinishRateStatList,
    courseTrainRateStatList,
    courseTrainUserList,
    courseStudyDayStat,
    exportCourseTrainUser
}
