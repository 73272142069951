import {request,experCodeRequest,experDockerRequest,downLoadRequest} from '@/utils/request.js'

const categoryList = (data) => {
    return request({
        url: '/dataset-category/list',
        method: 'post',
        data
    })
}
const categoryForm = (data) => {
    return request({
        url: '/dataset-category/form',
        method: 'post',
        data
    })
}
const changeCategory = (data)=>{
    return request({
        url: "/dataset-map/change-category",
        method: "post",
        data
    });
}
const deleteAllNode = (data) => {
    return request({
        url:"/dataset-map/delete-all-node",
        method:"post",
        data
    });
}
const deleteAllData = (data)=> {
    return request({
        url:"/dataset-map/delete-all-data",
        method:"post",
        data
    });
}
const categoryCreate = (data) => {
    return request({
        url: '/dataset-category/create',
        method: 'post',
        data
    })
}
const categoryUpdate = (data) => {
    return request({
        url: '/dataset-category/update',
        method: 'post',
        data
    })
}
const categoryDelete = (data) => {
    return request({
        url: '/dataset-category/delete',
        method: 'post',
        data
    })
}
const mapList = (data) => {
    return request({
        url: '/dataset-map/list',
        method: 'post',
        data
    })
}
const mapDetail = (data) => {
    return request({
        url: '/dataset-map/detail',
        method: 'post',
        data
    })
}
const mapCreate = (data) => {
    return request({
        url: '/dataset-map/create',
        method: 'post',
        data
    })
}
const mapUpdate = (data) => {
    return request({
        url: '/dataset-map/update',
        method: 'post',
        data
    })
}
const mapDelete = (data) => {
    return request({
        url: '/dataset-map/delete',
        method: 'post',
        data
    })
}
const mapEnable = (data) => {
    return request({
        url: '/dataset-map/enable',
        method: 'post',
        data
    })
}
const mapDisable = (data) => {
    return request({
        url: '/dataset-map/disable',
        method: 'post',
        data
    })
}
const mapForm = (data) => {
    return request({
        url: '/dataset-map/form',
        method: 'post',
        data
    })
}
const mapCopy = (data) => {
    return request({
        url: '/dataset-map/copy',
        method: 'post',
        data
    })
}
const mapRefreshCount = (data) => {
    return request({
        url: '/dataset-map/refresh-count',
        method: 'post',
        data
    })
}
const mapAiTrainList = (data) => {
    return request({
        url: '/dataset-map/ai-train-list',
        method: 'post',
        data
    })
}
const mapAiTrainDataList = (data) => {
    return request({
        url: '/dataset-map/ai-train-data-list',
        method: 'post',
        data
    })
}
const mapAiTrainDataVerify = (data) => {
    return request({
        url: '/dataset-map/ai-train-data-verify',
        method: 'post',
        data
    })
}
const categoryGenerateStart = (data) => {
    return request({
        url: '/dataset-category/generate-start',
        method: 'post',
        data
    })
}
const mapAiGenerateList = (data) => {
    return request({
        url: '/dataset-category/generate-list',
        method: 'post',
        data
    })
}
const mapAiGenerateKeypointList = (data) => {
    return request({
        url: '/dataset-category/generate-data-list',
        method: 'post',
        data
    })
}
const mapAiMatchList = (data) => {
    return request({
        url: '/dataset-map/ai-match-list',
        method: 'post',
        data
    })
}
const mapAiMatchKeypointList = (data) => {
    return request({
        url: '/dataset-map/ai-match-keypoint-list',
        method: 'post',
        data
    })
}
const dataList = (data) => {
    return request({
        url: '/dataset-data/list',
        method: 'post',
        data
    })
}
const dataCreate = (data) => {
    return request({
        url: '/dataset-data/create',
        method: 'post',
        data
    })
}
const dataUpdate = (data) => {
    return request({
        url: '/dataset-data/update',
        method: 'post',
        data
    })
}
const dataDelete = (data) => {
    return request({
        url: '/dataset-data/delete',
        method: 'post',
        data
    })
}
const dataForm = (data) => {
    return request({
        url: '/dataset-data/form',
        method: 'post',
        data
    })
}

const dataDetail = (data) => {
    return request({
        url: '/dataset-data/detail',
        method: 'post',
        data
    })
}
const dataRecordList = (data) => {
    return request({
        url: '/dataset-data/record-list',
        method: 'post',
        data
    })
}
const dataCorrectionList = (data) => {
    return request({
        url: '/dataset-data/correction-list',
        method: 'post',
        data
    })
}

const dataUserCollect = (data) => {
    return request({
        url: '/dataset-data/user-collect',
        method: 'post',
        data
    })
}
const dataUserCollectDelete = (data) => {
    return request({
        url: '/dataset-data/user-collect-delete',
        method: 'post',
        data
    })
}
const dataUserWrong = (data) => {
    return request({
        url: '/dataset-data/user-wrong',
        method: 'post',
        data
    })
}
const dataStatByStar = (data) => {
    return request({
        url: '/dataset-data/stat-by-star-section',
        method: 'post',
        data
    })
}

const dataUserWrongDelete = (data) => {
    return request({
        url: '/dataset-data/user-wrong-delete',
        method: 'post',
        data
    })
}

const dataAbnormal = (data) => {
    return request({
        url: '/dataset-data/abnormal',
        method: 'post',
        data
    })
}
const dataAbnormalDelete = (data) => {
    return request({
        url: '/dataset-data/abnormal-delete',
        method: 'post',
        data
    })
}

const dataStatByCreateTime = (data) => {
    return request({
        url: '/dataset-data/stat-by-create-time',
        method: 'post',
        data
    })
}
const dataSimilarStatByUpdateTime = (data) => {
    return request({
        url: '/dataset-data/similar-stat-by-update-time',
        method: 'post',
        data
    })
}
const dataCorrectionStatByUpdateTime = (data) => {
    return request({
        url: '/dataset-data/correction-stat-by-update-time',
        method: 'post',
        data
    })
}

const groupList = (data) => {
    return request({
        url: '/dataset-group/list',
        method: 'post',
        data
    })
}
const groupCreate = (data) => {
    return request({
        url: '/dataset-group/create',
        method: 'post',
        data
    })
}
const groupUpdate = (data) => {
    return request({
        url: '/dataset-group/update',
        method: 'post',
        data
    })
}
const groupDelete = (data) => {
    return request({
        url: '/dataset-group/delete',
        method: 'post',
        data
    })
}
const groupForm = (data) => {
    return request({
        url: '/dataset-group/form',
        method: 'post',
        data
    })
}
//知识点列表
const nodeList = (data) => {
    return request({
        url: '/dataset-node/list',
        method: 'post',
        data
    })
}
const nodeCreate = (data) => {
    return request({
        url: '/dataset-node/create',
        method: 'post',
        data
    })
}
const nodeUpdate = (data) => {
    return request({
        url: '/dataset-node/update',
        method: 'post',
        data
    })
}
const nodeDelete = (data) => {
    return request({
        url: '/dataset-node/delete',
        method: 'post',
        data
    })
}
const nodeForm = (data) => {
    return request({
        url: '/dataset-node/form',
        method: 'post',
        data
    })
}

const productList = (data) => {
    return request({
        url: '/dataset-product/list',
        method: 'post',
        data
    })
}
const productCreate = (data) => {
    return request({
        url: '/dataset-product/create',
        method: 'post',
        data
    })
}
const productUpdate = (data) => {
    return request({
        url: '/dataset-product/update',
        method: 'post',
        data
    })
}
const productDelete = (data) => {
    return request({
        url: '/dataset-product/delete',
        method: 'post',
        data
    })
}
const productForm = (data) => {
    return request({
        url: '/dataset-product/form',
        data
    })
}
//知识点列表
const datasetNodeList = (data) => {
    return request({
        url: '/dataset-node/list',
        method: 'post',
        data
    })
}
const trainList = (data) => {
    return request({
        url: '/dataset-train/list',
        method: 'post',
        data
    })
}

const groupDetail = (data) => {
    return request({
        url: '/dataset-group/detail',
        method: 'post',
        data
    })
}

const trainUserList = (data) => {
    return request({
        url: '/dataset-train/user-list',
        method: 'post',
        data
    })
}

const groupDataList = (data) => {
    return request({
        url: '/dataset-group/data-list',
        method: 'post',
        data
    })
}

const groupRecordList = (data) => {
    return request({
        url: '/dataset-group/record-list',
        method: 'post',
        data
    })
}
const categoryDetail = (data) => {
    return request({
        url: '/dataset-category/detail',
        method: 'post',
        data
    })
}

const categoryDataList = (data) => {
    return request({
        url: '/dataset-category/data-list',
        method: 'post',
        data
    })
}

const categoryUserList = (data) => {
    return request({
        url: '/dataset-category/user-list',
        method: 'post',
        data
    })
}

const labelCreate = (data) => {
    return request({
        url: '/dataset-label/create',
        method: 'post',
        data
    })
}

const labelUpdate = (data) => {
    return request({
        url: '/dataset-label/update',
        method: 'post',
        data
    })
}

const labelDelete = (data) => {
    return request({
        url: '/dataset-label/delete',
        method: 'post',
        data
    })
}

const labelList = (data) => {
    return request({
        url: '/dataset-label/list',
        method: 'post',
        data
    })
}

const labelDetail = (data) => {
    return request({
        url: '/dataset-label/detail',
        method: 'post',
        data
    })
}

const labelDataList = (data) => {
    return request({
        url: '/dataset-label/data-list',
        method: 'post',
        data
    })
}

const labelDataAdd = (data) => {
    return request({
        url: '/dataset-label/data-add',
        method: 'post',
        data
    })
}

const labelDataDel = (data) => {
    return request({
        url: '/dataset-label/data-del',
        method: 'post',
        data
    })
}

const mapCreateNode = (data) => {
    return request({
        url: '/dataset-map/create-node',
        method: 'post',
        data
    })
}

const mapDeleNode = (data) => {
    return request({
        url: '/dataset-map/delete-node',
        method: 'post',
        data
    })
}

const mapUpdateNode = (data) => {
    return request({
        url: '/dataset-map/update-node',
        method: 'post',
        data
    })
}

const mapMoveNode = (data) => {
    return request({
        url: '/dataset-map/move-node',
        method: 'post',
        data
    })
}

const mapNodeList = (data) => {
    return request({
        url: '/dataset-map/node-list',
        method: 'post',
        data
    })
}

//后台首页
const trainExecute = (data) => {
    return request({
        url: '/dataset-train/execute',
        method: 'post',
        data
    })
}

const trainMyStat = (data) => {
    return request({
        url: '/dataset-train/my-stat',
        method: 'post',
        data
    })
}
const trainStat = (data) => {
    return request({
        url: '/dataset-train/stat',
        method: 'post',
        data
    })
}
const trainTestStat = (data) => {
    return request({
        url: '/dataset-train/test-stat',
        method: 'post',
        data
    })
}
const trainExamStat = (data) => {
    return request({
        url: '/dataset-train/exam-stat',
        method: 'post',
        data
    })
}
const trainMyTestStat = (data) => {
    return request({
        url: '/dataset-train/my-test-stat',
        method: 'post',
        data
    })
}
//产品详情
const productDetail = (data) => {
    return request({
        url: '/dataset-product/detail',
        method: 'post',
        data
    })
}

const feedbackList = (data) => {
    return request({
        url: '/dataset-feedback/list',
        method: 'post',
        data
    })
}

const correctionCreate = (data) => {
    return request({
        url: '/dataset-data/correction-create',
        method: 'post',
        data
    })
}

const trainPublish = (data) => {
    return request({
        url: '/dataset-train/publish',
        method: 'post',
        data
    })
}

const trainDataAll = (data) => {
    return request({
        url: '/dataset-train/data-all',
        method: 'post',
        data
    })
}
const trainDataList = (data) => {
    return request({
        url: '/dataset-train/data-list',
        method: 'post',
        data
    })
}

const trainDataDeal = (data) => {
    return request({
        url: '/dataset-train/data-deal',
        method: 'post',
        data
    })
}

const trainDataForbidden = (data) => {
    return request({
        url: '/dataset-train/data-forbidden',
        method: 'post',
        data
    })
}

const trainDataLock = (data) => {
    return request({
        url: '/dataset-train/data-lock',
        method: 'post',
        data
    })
}

const trainDataAdd = (data) => {
    return request({
        url: '/dataset-train/data-add',
        method: 'post',
        data
    })
}

const trainDataDetail = (data) => {
    return request({
        url: '/dataset-train/detail',
        method: 'post',
        data
    })
}

const trainDelete = (data) => {
    return request({
        url: '/dataset-train/delete',
        method: 'post',
        data
    })
}

const feedbackUpdate = (data) => {
    return request({
        url: '/dataset-feedback/update',
        method: 'post',
        data
    })
}

const cdkeyList = (data) => {
    return request({
        url: '/dataset-cdkey/list',
        method: 'post',
        data
    })
}
const cdkeyCreate = (data) => {
    return request({
        url: '/dataset-cdkey/create',
        method: 'post',
        data
    })
}
const cdkeyUpdate = (data) => {
    return request({
        url: '/dataset-cdkey/update',
        method: 'post',
        data
    })
}
const cdkeyDelete = (data) => {
    return request({
        url: '/dataset-cdkey/delete',
        method: 'post',
        data
    })
}
const cdkeyCodeList = (data) => {
    return request({
        url: '/dataset-cdkey/code-list',
        method: 'post',
        data
    })
}
const cdkeyCodeCreate = (data) => {
    return request({
        url: '/dataset-cdkey/code-create',
        method: 'post',
        data
    })
}
const cdkeyCodeDetele = (data) => {
    return request({
        url: '/dataset-cdkey/code-delete',
        method: 'post',
        data
    })
}
const cdkeyActive = (data) => {
    return request({
        url: '/dataset-cdkey/active',
        method: 'post',
        data
    })
}
//boss首页
const bossIndex = (data) => {
    return request({
        url: '/boss/index',
        method: 'post',
        data
    })
}

const bossStat = (data) => {
    return request({
        url: '/dataset-train/boss-stat',
        method: 'post',
        data
    })
}

const datasetCorrectionList = (data) => {
    return request({
        url: '/dataset-data/correction-list',
        method: 'post',
        data
    })
}

const datasetCorrectionUpdate = (data) => {
    return request({
        url: '/dataset-data/correction-update',
        method: 'post',
        data
    })
}

const datasetCorrectionDetail = (data) => {
    return request({
        url: '/dataset-data/correction-detail',
        method: 'post',
        data
    })
}

const datasetDataVerify = (data) => {
    return request({
        url: '/dataset-data/verify',
        method: 'post',
        data
    })
}



const datasetGroupDataList = (data) => {
    return request({
        url: '/dataset-group/data-list',
        method: 'post',
        data
    })
}

const datasetGroupDataDelete = (data) => {
    return request({
        url: '/dataset-group/data-delete',
        method: 'post',
        data
    })
}

const datasetGroupUploadfile = (data) => {
    return request({
        url: '/dataset-group/uploadfile',
        method: 'post',
        data
    })
}

const datasetGroupDetail = (data) => {
    return request({
        url: '/dataset-group/detail',
        method: 'post',
        data
    })
}

const datasetGroupDelete = (data) => {
    return request({
        url: '/dataset-group/delete',
        method: 'post',
        data
    })
}

const datasetGroupForm = (data) => {
    return request({
        url: '/dataset-group/form',
        method: 'post',
        data
    })
}

const datasetGroupRelease = (data) => {
    return request({
        url: '/dataset-group/release',
        method: 'post',
        data
    })
}

const datasetSimilarList = (data) => {
    return request({
        url: '/dataset-data/similar-list',
        method: 'post',
        data
    })
}

const datasetSimilarDetail = (data) => {
    return request({
        url: '/dataset-data/similar-detail',
        method: 'post',
        data
    })
}

const datasetSimilarUpdate = (data) => {
    return request({
        url: '/dataset-data/similar-update',
        method: 'post',
        data
    })
}

const datasetCategoryProfiles = (data) => {
    return request({
        url: '/dataset-category/profiles',
        method: 'post',
        data
    })
}

const datasetCategoryUploadfile = (data) => {
    return request({
        url: '/dataset-category/uploadfile',
        method: 'post',
        data
    })
}

const datasetCategoryDataDelete = (data) => {
    return request({
        url: '/dataset-category/data-delete',
        method: 'post',
        data
    })
}

const datasetCategoryRelease = (data) => {
    return request({
        url: '/dataset-category/release',
        method: 'post',
        data
    })
}

const datasetCategorySelfList = (data) => {
    return request({
        url: '/dataset-category/self-list',
        method: 'post',
        data
    })
}

const datasetCategoryVerifyList = (data) => {
    return request({
        url: '/dataset-category/verify-list',
        method: 'post',
        data
    })
}

const datasetCategoryVerifyDataList = (data) => {
    return request({
        url: '/dataset-category/verify-data-list',
        method: 'post',
        data
    })
}

const datasetCategoryVerify = (data) => {
    return request({
        url: '/dataset-category/verify',
        method: 'post',
        data
    })
}

const datasetCategoryUpdateSites = (data) => {
    return request({
        url: '/dataset-category/update-sites',
        method: 'post',
        data
    })
}

const datasetCategoryUpdateChildren = (data) => {
    return request({
        url: '/dataset-category/update-children',
        method: 'post',
        data
    })
}

const datasetCategoryCopyDataList = (data) => {
    return request({
        url: '/dataset-category/copy-data-list',
        method: 'post',
        data
    })
}
const datasetMapNodeBatchEditLabel = (data) => {
    return request({
        url: '/dataset-map/node-batch-edit-label',
        method: 'post',
        data
    })
}

const datasetMapNodeDelLabel = (data) => {
    return request({
        url: '/dataset-map/node-del-label',
        method: 'post',
        data
    })
}

const datasetDataSimilarVerify = (data) => {
    return request({
        url: '/dataset-data/similar-verify',
        method: 'post',
        data
    })
}

const datasetMapNodeBatchEditData = (data) => {
    return request({
        url: '/dataset-map/node-batch-edit-data',
        method: 'post',
        data
    })
}

// const datasetMapNodeDelData = (data) => {
//     return request({
//         url: '/dataset-map/node-del-data',
//         method: 'post',
//         data
//     })
// }

//统计用户刷题量
const datasetTrainStatUsers = (data) => {
    return request({
        url: '/dataset-train/stat-user-day-list',
        method: 'post',
        data
    })
}

//统计用户刷题量
const datasetTrainStatUserList = (data) => {
    return request({
        url: '/dataset-train/stat-user-list',
        method: 'post',
        data
    })
}

//获取岗位测评详情
const datasetTrainEvaluationDetail = (data) => {
    return request({
        url: '/dataset-train/evaluation-detail',
        method: 'post',
        data
    })
}


//岗位测评练习练习记录
const datasetTrainEvaluationTrainList = (data) => {
    return request({
        url: '/dataset-train/evaluation-train-list',
        method: 'post',
        data
    })
}

//岗位测评练习记录数据反向映射
const datasetTrainEvaluationTrainDataMapping = (data) => {
    return request({
        url: '/dataset-train/evaluation-train-data-mapping',
        method: 'post',
        data
    })
}

//开始匹配
const datasetMapAiMatchStart = (data) => {
    return request({
        url: '/dataset-map/ai-match-start',
        method: 'post',
        data
    })
}

//开始匹配
const datasetMapFileImport = (data) => {
    return request({
        url: '/dataset-map/file-import',
        method: 'post',
        data
    })
}

//租户更新图谱
const datasetMapToSiteUpdate = (data) => {
    return request({
        url: '/dataset-map/to-site-update',
        method: 'post',
        data
    })
}

//学员刷题排行-导出
const datasetTrainStatUsersExport = (data) => {
    return request({
        url: '/dataset-train/stat-user-day-export',
        method: 'post',
        data
    })
}

//租户申请题库
const datasetCategoryToSiteApply = (data) => {
    return request({
        url: '/dataset-category/to-site-apply',
        method: 'post',
        data
    })
}

//租户申请题库
const datasetCategoryToSiteVerify = (data) => {
    return request({
        url: '/dataset-category/to-site-verify',
        method: 'post',
        data
    })
}

//发布中删除试题
const datasetTrainDataDel = (data) => {
    return request({
        url: '/dataset-train/data-del',
        method: 'post',
        data
    })
}

//发布中获取试题详情
const datasetTrainDataDetail = (data) => {
    return request({
        url: '/dataset-train/data-detail',
        method: 'post',
        data
    })
}

//租户题库-申请列表
const datasetCategoryToSiteApplyList = (data) => {
    return request({
        url: '/dataset-category/to-site-apply-list',
        method: 'post',
        data
    })
}

//租户题库-申请列表
const datasetCategoryDataCount = (data) => {
    return request({
        url: '/dataset-category/data-count',
        method: 'post',
        data
    })
}

//节点试题列表
const datasetMapNodeDataList = (data) => {
    return request({
        url: '/dataset-map/node-data-list',
        method: 'post',
        data
    })
}

//节点删除试题
const datasetMapNodeDelData = (data) => {
    return request({
        url: '/dataset-map/node-del-data',
        method: 'post',
        data
    })
}

//节点添加试题
const datasetMapNodeAddData = (data) => {
    return request({
        url: '/dataset-map/node-add-data',
        method: 'post',
        data
    })
}

//节点添加标签
const datasetMapNodeAddLabel = (data) => {
    return request({
        url: '/dataset-map/node-add-label',
        method: 'post',
        data
    })
}


const datasetTrainStatUserListExport= (data) => {
    return request({
        url: 'dataset-train/stat-user-list-export',
        method: 'post',
        data
    })
}
const datasetTrainStatByType = (data) => {
    return request({
        url: 'dataset-train/stat-by-type',
        method: 'post',
        data
    })
}
const datasetTrainStatByTypeAndDataRule = (data) => {
    return request({
        url: 'dataset-train/stat-by-type-and-data-rule',
        method: 'post',
        data
    })
}
const datasetTrainStatByTypeAndGenerateType = (data) => {
    return request({
        url: 'dataset-train/stat-by-type-and-generate-type',
        method: 'post',
        data
    })
}
//节点删除标签
// const datasetMapNodeDelLabel = (data) => {
//     return request({
//         url: '/dataset-map/node-del-label',
//         method: 'post',
//         data
//     })
// }
const datasetDataSearchRecordList = (data) => {
    return request({
        url: '/dataset-data/search-record-list',
        method: 'post',
        data
    })
}
const datasetMatchRobotList = (data) => {
    return request({
        url: '/dataset-match/robot-list',
        method: 'post',
        data
    })
}
const datasetMatchRobotCreate = (data) => {
    return request({
        url: '/dataset-match/robot-create',
        method: 'post',
        data
    })
}
const datasetMatchRobotUpdate = (data) => {
    return request({
        url: '/dataset-match/robot-update',
        method: 'post',
        data
    })
}
const datasetMatchRobotDelete = (data) => {
    return request({
        url: '/dataset-match/robot-delete',
        method: 'post',
        data
    })
}

const datasetStatByCategory = (data) => {
    return request({
        url: '/dataset-data/stat-by-category',
        method: 'post',
        data
    })
}

//单条上传数据
const sourceImportSingle = (data) => {
    return request({
        url: '/dataset-source/import-single',
        method: 'post',
        data
    })
}

//批量上传数据（多个upload_id）
const sourceImportBatch = (data) => {
    return request({
        url: '/dataset-source/import-batch',
        method: 'post',
        data
    })
}
//资源详情
const sourceDetail = (data) => {
    return request({
        url: '/dataset-source/detail',
        method: 'post',
        data
    })
}
//导入资源
const sourceImportUploadfile = (data) => {
    return request({
        url: '/dataset-source/import-uploadfile',
        method: 'post',
        data
    })
}
//导入资源列表
const sourceImportDataList = (data) => {
    return request({
        url: '/dataset-source/import-data-list',
        method: 'post',
        data
    })
}
//删除导入的资源
const sourceImportDataDelete = (data) => {
    return request({
        url: '/dataset-source/import-data-delete',
        method: 'post',
        data
    })
}
//保存导入的资源
const sourceImportVerify = (data) => {
    return request({
        url: '/dataset-source/import-verify',
        method: 'post',
        data
    })
}
//删除资源
const sourceDelete = (data) => {
    return request({
        url: '/dataset-source/delete',
        method: 'post',
        data
    })
}

//节点试题数据
const mapNodeDataNums = (data) => {
    return request({
        url: '/dataset-map/map-node-data-nums',
        method: 'post',
        data
    })
}
//发布千人千面
const trainPublishVarious = (data) => {
    return request({
        url: '/dataset-train/publish-various',
        method: 'post',
        data
    })
}

//试题表单项
const datasetDataForm = (data) => {
    return request({
        url: '/dataset-data/form',
        method: 'post',
        data
    })
}
// 资源库列表
const datasetLibList=(data)=>{
    return request({
        url:"/dataset-source/list",
        method:"post",
        data
    });
}
// 添加资源
const datasetAddLib=(data)=>{
    return request({
        url:"/dataset-source/create",
        method:"post",
        data
    });
}
// 删除资源
const deleteLib=(data)=>{
    return request({
        url:"/dataset-source/delete",
        method:"post",
        data
    });
}
// 资源库关联图谱节点
const sourceToMap=(data)=>{
    return request({
        url:"/dataset-map/source-to-map-node",
        method:"post",
        data
    });
}
// 解除资源绑定
const nodeDeleteSource=(data)=>{
    return request({
        url:"/dataset-map/node-del-source",
        method:"post",
        data
    });
}
const getExperSource=(data)=>{
    return request({
        url:"/dataset-experiment/resource-list",
        method:"post",
        data
    });
}
// 编辑资源回显数据
const getLibDetail=(data)=>{
    return request({
        url:"dataset-source/detail",
        method:"post",
        data
    });
}
// 编辑资源确定
const updateLib=(data)=>{
    return request({
        url:"dataset-source/update",
        method:"post",
        data
    });
}
const uploadFile=(data)=>{
    return request({
        url:"uploadfile/upload",
        method:"post",
        data
    });
}
const libType=(data)=>{
    return request({
        url:"dataset-source/form",
        method:"post",
        data
    });
}
const aiMatchNodeData=(data)=>{
    return request({
        url:"/dataset-map/ai-match-search",
        method:"post",
        data
    });
}
const deleteSelfTestingRecords=(data)=>{
    return request({
        url:"dataset-train/user-delete",
        method:"post",
        data
    });
}

const teacherAiSearch=(data)=>{
    return request({
        url:"user/teacher-ai-search",
        method:"post",
        data
    });
}

const getNodeSource=(data)=>{
    return request({
        url:"/dataset-map/map-source-list",
        method:"post",
        data
    });
}

const getNodeExper=(data)=>{
    return request({
        url:"/dataset-map/map-experiment-list",
        method:"post",
        data
    });
}

// 获取实验列表
const getExperimentList=(data)=>{
    return request({
        url:"/dataset-experiment/list",
        method:"post",
        data
    });
}
// 创建实验
const createExperiment=(data)=>{
    return request({
        url:"/dataset-experiment/create",
        method:"post",
        data
    });
}
// 编辑实验
const editExperiment=(data)=>{
    return request({
        url:"/dataset-experiment/update",
        method:"post",
        data
    });
}
// 删除实验
const deleteExperiment=(data)=>{
    return request({
        url:"/dataset-experiment/delete",
        method:"post",
        data
    });
}
// 实验详情
const experimentDetail=(data)=>{
    return request({
        url:"/dataset-experiment/detail",
        method:"post",
        data
    });
}
// 实验绑定资源
const bindLib=(data)=>{
    return request({
        url:"/dataset-experiment/source-to-experiment",
        method:"post",
        data
    });
}
// 解除资源关联
const unBindLib=(data)=>{
    return request({
        url:"/dataset-experiment/experiment-del-source",
        method:"post",
        data
    });
}

// 节点关联实验
const nodeBindExper=(data)=>{
    return request({
        url:"/dataset-map/experiment-to-map-node",
        method:"post",
        data
    });
}
// 节点和实验解除关联
const disassExper=(data)=>{
    return request({
        url:"/dataset-map/node-del-experiment",
        method:"post",
        data
    });
}

// 数据埋点
const dataBurialPoint=(data)=>{
    return request({
        url:"/dataset-course/m-course-user-record",
        method:"post",
        data
    });
}

// 获取实验已绑定的试题列表
const boundTestList=(data)=>{
    return request({
        url:"/dataset-experiment/data-list",
        method:"post",
        data
    });
}

// 实验绑定试题
const experBindTest=(data)=>{
    return request({
        url:"/dataset-experiment/data-to-experiment",
        method:"post",
        data
    });
}

const experUnBindTest=(data)=>{
    return request({
        url:"/dataset-experiment/experiment-del-data",
        method:"post",
        data
    });
}

// 获取编程题的信息
 const getQuestionInfo=(data)=>{
    return experCodeRequest({
        url:"/dataset-experiment/code-show",
        method:"post",
        data
    });
 }


//  获取编程试题的内容
 const getQuestionContent=(url)=>{
    return downLoadRequest({
        url,
        method:"get",
    });
 }

//  保存代码
const experimentSaveCode=(data)=>{
    return experCodeRequest({
        url:"/dataset-experiment/code-save",
        method:"post",
        data
    });
}

//  运行编程代码
const experimentRunCode=(data)=>{
    return experCodeRequest({
        url:"/dataset-experiment/code-run",
        method:"post",
        data
    });
}

// 易错题列标
const errorProneQuestionList=(data)=>{
    return request({
        url:"/dataset-data/fallibility-list",
        method:"post",
        data
    });
}

// 易错题处理
const errorProneHandle=(data)=>{
    return request({
        url:"/dataset-data/fallibility-verify",
        method:"post",
        data
    });
}

// 移动课程任务
const moveTask=(data)=>{
    return request({
        url:"/dataset-course/move-task",
        method:"post",
        data
    });
}
// 流程图列表
const processList=(data)=>{
    return request({
        url:"/dataset-experiment/process-list-for-user",
        method:"post",
        data
    });
}

// 保存流程图
const flowSave=(data)=>{
    return request({
        url:"/dataset-experiment/process-save",
        method: 'post',
        data
    });
}

// 删除流程图
const delProcess=(data)=>{
    return request({
        url:"/dataset-experiment/process-delete",
        method:"post",
        data
    });
}

// 第三方系统
const thirdPartySystems=(data)=>{
    return request({
        url:"/dataset-experiment/link-list-for-user",
        method:"post",
        data
    });
}

// docker实验-列表
const dockerListForUser=(data)=>{
    return request({
        url:"/dataset-experiment/docker-list-for-user",
        method:"post",
        data
    });
}
// 考试用户详情
const trainUserDetail=(data)=>{
    return request({
        url:"/dataset-train/user-info",
        method:"post",
        data
    });
}

// docker实验-开启实验
const dockerOpen=(data)=>{
    return experDockerRequest({
        url:"/dataset-experiment/docker-open",
        method:"post",
        data
    });
}
// 考试标记作弊
const markCheating=(data)=>{
    return request({
        url:"/dataset-train/mark-cheat",
        method:"post",
        data
    });
}

// 阿里云实验列表
const aliyunListForUser=(data)=>{
    return request({
        url:"/dataset-experiment/aliyun-list-for-user",
        method:"post",
        data
    });
}
// 强制交卷
const compulsorySubmissionPapers=(data)=>{
    return request({
        url:"/dataset-train/force-submit",
        method:"post",
        data
    });
}

// 阿里云开通实验
const aliyunOpen=(data)=>{
    return request({
        url:"/dataset-experiment/aliyun-open",
        method:"post",
        data
    });
}

// 阿里云用户资源列表
const aliyunForUser=(data)=>{
    return request({
        url:"/dataset-experiment/aliyun-for-user",
        method:"post",
        data
    });
}

// 阿里云保存信息
const aliyunSave=(data)=>{
    return request({
        url:"/dataset-experiment/aliyun-save",
        method:"post",
        data
    });
}

// 用户实验
const experimentUser=(data)=>{
    return request({
        url:"/dataset-experiment/user",
        method:"post",
        data
    });
}

// 流程图-展示
const experimentProcessShow=(data)=>{
    return request({
        url:"/dataset-experiment/process-show",
        method:"post",
        data
    });
}

// 复制组卷
const trainCopy=(data)=>{
    return request({
        url:"/dataset-train/copy",
        method:"post",
        data
    });
}




export default {
    bossIndex,
    bossStat,
    categoryList,
    categoryForm,
    categoryCreate,
    categoryUpdate,
    categoryDelete,
    dataList,
    dataCreate,
    dataUpdate,
    dataDelete,
    dataForm,
    dataUserWrong,
    dataUserWrongDelete,
    dataUserCollect,
    dataUserCollectDelete,
    dataAbnormal,
    dataAbnormalDelete,
    mapList,
    mapCreate,
    mapUpdate,
    mapDelete,
    mapEnable,
    mapDisable,
    mapForm,
    mapCopy,
    mapRefreshCount,
    groupList,
    groupCreate,
    groupUpdate,
    groupDelete,
    groupForm,
    nodeList,
    nodeCreate,
    nodeUpdate,
    nodeDelete,
    nodeForm,
    productList,
    productCreate,
    productUpdate,
    productDelete,
    productForm,
    datasetNodeList,
    trainList,
    trainUserList,
    groupDetail,
    groupDataList,
    groupRecordList,
    categoryDetail,
    categoryDataList,
    categoryUserList,
    dataDetail,
    mapDetail,
    labelCreate,
    labelList,
    labelDelete,
    mapCreateNode,
    mapDeleNode,
    mapUpdateNode,
    mapMoveNode,
    mapNodeList,
    mapAiTrainList,
    mapAiTrainDataList,
    mapAiTrainDataVerify,
    mapAiMatchList,
    mapAiMatchKeypointList,
    trainExecute,
    productDetail,
    feedbackList,
    trainStat,
    trainMyStat,
    trainTestStat,
    trainExamStat,
    trainMyTestStat,
    dataCorrectionList,
    correctionCreate,
    trainPublish,
    trainDataAll,
    trainDataList,
    trainDataDeal,
    trainDataForbidden,
    trainDataLock,
    trainDataAdd,
    trainDataDetail,
    trainDelete,
    feedbackUpdate,
    cdkeyList,
    cdkeyCreate,
    cdkeyUpdate,
    cdkeyDelete,
    cdkeyCodeList,
    cdkeyCodeCreate,
    cdkeyCodeDetele,
    cdkeyActive,
    datasetCorrectionList,
    datasetCorrectionUpdate,
    datasetCorrectionDetail,
    datasetDataVerify,
    dataRecordList,
    datasetGroupDataList,
    datasetGroupDataDelete,
    datasetGroupUploadfile,
    datasetGroupDetail,
    datasetGroupDelete,
    datasetGroupForm,
    datasetGroupRelease,
    datasetSimilarList,
    datasetSimilarDetail,
    datasetSimilarUpdate,
    datasetCategoryProfiles,
    datasetCategoryUploadfile,
    datasetCategoryDataDelete,
    datasetCategoryRelease,
    datasetCategorySelfList,
    datasetCategoryVerifyList,
    datasetCategoryVerifyDataList,
    datasetCategoryVerify,
    datasetCategoryUpdateSites,
    datasetCategoryUpdateChildren,
    datasetCategoryCopyDataList,
    datasetMapNodeBatchEditLabel,
    datasetMapNodeDelLabel,
    datasetDataSimilarVerify,
    datasetMapNodeBatchEditData,
    datasetMapNodeDelData,
    datasetTrainStatUsers,
    datasetTrainEvaluationDetail,
    datasetTrainEvaluationTrainList,
    datasetTrainEvaluationTrainDataMapping,
    datasetTrainStatByType,
    labelUpdate,
    labelDetail,
    labelDataList,
    labelDataAdd,
    labelDataDel,
    datasetMapAiMatchStart,
    datasetMapFileImport,
    mapAiGenerateList,
    mapAiGenerateKeypointList,
    datasetMapToSiteUpdate,
    datasetTrainStatUsersExport,
    datasetTrainDataDel,
    datasetTrainDataDetail,
    datasetCategoryToSiteApply,
    datasetCategoryToSiteVerify,
    datasetCategoryToSiteApplyList,
    datasetCategoryDataCount,
    datasetTrainStatUserList,
    datasetMapNodeDataList,
    // datasetMapNodeDelData,
    datasetMapNodeAddData,
    datasetMapNodeAddLabel,
    // datasetMapNodeDelLabel
    datasetTrainStatUserListExport,
    dataStatByCreateTime,
    dataSimilarStatByUpdateTime,
    dataCorrectionStatByUpdateTime,
    datasetTrainStatByTypeAndDataRule,
    datasetTrainStatByTypeAndGenerateType,
    dataStatByStar,
    datasetDataSearchRecordList,
    datasetMatchRobotList,
    datasetMatchRobotCreate,
    datasetMatchRobotUpdate,
    datasetMatchRobotDelete,
    categoryGenerateStart,
    datasetStatByCategory,
    sourceImportSingle,
    sourceDetail,
    sourceImportUploadfile,
    sourceImportDataList,
    sourceImportDataDelete,
    sourceImportVerify,
    sourceDelete,
    sourceImportBatch,
    mapNodeDataNums,
    trainPublishVarious,
    datasetDataForm,
    changeCategory,
    deleteAllNode,
    deleteAllData,
    datasetLibList,
    datasetAddLib,
    deleteLib,
    sourceToMap,
    nodeDeleteSource,
    getLibDetail,
    updateLib,
    uploadFile,
    libType,
    aiMatchNodeData,
    deleteSelfTestingRecords,
    teacherAiSearch,
    getExperimentList,
    createExperiment,
    editExperiment,
    deleteExperiment,
    experimentDetail,
    bindLib,
    unBindLib,
    nodeBindExper,
    disassExper,
    dataBurialPoint,
    boundTestList,
    experBindTest,
    experUnBindTest,
    getQuestionInfo,
    getQuestionContent,
    getNodeSource,
    getNodeExper,
    getExperSource,
    experimentSaveCode,
    experimentRunCode,
    errorProneQuestionList,
    errorProneHandle,
    moveTask,
    processList,
    flowSave,
    delProcess,
    thirdPartySystems,
    dockerListForUser,
    dockerOpen,
    aliyunListForUser,
    aliyunOpen,
    aliyunForUser,
    aliyunSave,
    trainUserDetail,
    markCheating,
    compulsorySubmissionPapers,
    experimentUser,
    experimentProcessShow,
    trainCopy,
    
}
